import React from 'react';
import {
  View, Text, TouchableOpacity, Linking,
} from 'react-native';
import { Base } from '../../../types/base';
import Github from '../../../assets/icons/Github';
import GithubApp from '../../../assets/svg/GithubGray.svg';
import Stripe from '../../../assets/icons/Stripe';
import StripeApp from '../../../assets/svg/stripe.svg';
import styles from './account.style';

// TODO: Update account types
export type AccountType = 'Stripe' | 'Github';

export interface AccountProps extends Base {
  account: string;
  type: AccountType;
  url: string;
}

const Account = ({
  account, type, url, web,
}: AccountProps) => {
  const handleIcon = (accountType: string) => {
    if (accountType === 'Github') {
      return web ? (
        <Github width={30} height={30} fill="#d9d9d9" />
      ) : (
        <GithubApp width={30} height={30} />
      );
    }

    return web ? <Stripe width={30} height={30} /> : <StripeApp width={30} height={30} />;
  };

  return (
    <TouchableOpacity onPress={() => Linking.openURL(url)}>
      <View style={styles.mainContainer}>
        <View style={styles.iconContainer}>{handleIcon(type)}</View>
        <View>
          <Text style={styles.type}>{type}</Text>
          <Text style={styles.account}>{account}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );
};

export default Account;
