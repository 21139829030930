import React from 'react';
import { PathRouteProps } from 'react-router-dom';
import Login from '../pages/Login';
import SignUp from '../pages/SignUp';
import ForgotPassword from '../pages/ForgotPassword';
import ResetPassword from '../pages/ResetPassword';
import EmailConfirmation from '../pages/EmailConfirmation';

export const PUBLIC_ROUTES: PathRouteProps[] = [
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/sign-up',
    element: <SignUp />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPassword />,
  },
  {
    path: '/reset-password',
    element: <ResetPassword />,
  },
  {
    path: '/email-confirmation',
    element: <EmailConfirmation />,
  },
  // {
  //   path: '/terms-and-conditions',
  //   element: <TermsAndConditionsTemplate />,
  // },
  // {
  //   path: '/nda',
  //   element: <NDA />,
  // },
];
