import React, { SetStateAction, Dispatch } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Base } from '../../../types/base';
import formatDate from '../../../utils/formatDate';
import SoWSRow from '../../molecules/SoWSRow';
import { SoWSRowProps } from '../../molecules/SoWSRow/SoWSRow';
import styles from './sows.style';

interface SoWSProps extends Base {
  items: SoWSRowProps[];
  onClickItem: Dispatch<SetStateAction<SoWSRowProps>>;
}

const statuses = {
  'In progress': 'inProgress',
  'Approval pending': 'pending',
  Draft: 'draft',
  Closed: 'closed',
};

const SoWS = ({ items, onClickItem, web }: SoWSProps) => (
  <View style={styles.mainContainer}>
    <View
      style={[styles.labelsContainer, { paddingTop: web ? 15 : 0, paddingBottom: web ? 15 : 10 }]}
    >
      <View style={styles.flex3}>
        <Text style={styles.label}>SoWS ID</Text>
      </View>
      <View style={web ? styles.flex3 : styles.flex1}>
        <Text style={styles.label}>{web ? 'PROJECT TYPE' : 'STATUS'}</Text>
      </View>
      {web ? (
        <>
          <View style={styles.flex2}>
            <Text style={styles.label}>STATUS</Text>
          </View>
          <View style={styles.flex1}>
            <Text style={styles.label}>#ISSUE</Text>
          </View>
          <View style={styles.flex2}>
            <Text style={styles.label}>START DAY</Text>
          </View>
          <View style={styles.flex2}>
            <Text style={styles.label}>DELIVERY DAY</Text>
          </View>
          <View style={styles.flex3}>
            <Text style={styles.label}>TIME ESTIMATE</Text>
          </View>
        </>
      ) : null}
    </View>
    {items.length ? (
      items.map((item) => (
        <TouchableOpacity key={item.sowId} activeOpacity={0.8} onPress={() => onClickItem(item)}>
          <SoWSRow
            sowId={item.sowId || 'NA'}
            projectType={item.projectType || 'NA'}
            status={statuses[item.status] || 'na'}
            nIssues={item.nIssues || 'NA'}
            startDate={formatDate({ date: item.startDate })}
            endDate={formatDate({ date: item.endDate })}
            timeSpent={item.timeSpent || 0}
            timeEstimate={item.timeEstimate || 0}
            web={web}
          />
        </TouchableOpacity>
      ))
    ) : (
      <Text style={styles.noItemsMessage}>No items available</Text>
    )}
  </View>
);

export default SoWS;
