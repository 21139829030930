import { StyleSheet } from 'react-native';

const webStyles = StyleSheet.create({
  container: {
    width: '40%',
    marginTop: 30,
    alignItems: 'center',
    paddingBottom: 30,
  },
  containerXs: {
    width: '90%',
    marginTop: 30,
    alignItems: 'center',
    paddingBottom: 30,
  },
  containerApp: {
    width: '90%',
    marginTop: 30,
    alignItems: 'center',
  },
  buttonContainer: {
    width: '60%',
    marginTop: 40,
    marginBottom: 30,
  },
  buttonContainerXs: {
    width: '80%',
    margin: '40px auto',
  },
  buttonAppContainer: {
    width: '70%',
    marginTop: 40,
    marginBottom: 30,
  },
  spinnerContainer: {
    width: '100%',
    alignItems: 'center',
  },
  apiCallErrorText: {
    textAlign: 'center',
    fontSize: 14,
    color: 'red',
    marginBottom: 20,
  },
});

export default webStyles;
