import React from 'react';
import { View } from 'react-native';
import { Base } from '../../../types/base';
import CalendarCall from '../../organisms/CalendarCall/CalendarCall';
import CalendarDaySelection from '../../organisms/CalendarDaySelection';
import CalendarTimeSelection from '../../organisms/CalendarTimeSelection';
import useResponsiveStyles from './calendar.style';

interface CalendarProps extends Base {
  name: string;
  image: string;
  values: {
    callType: string;
    timezone: string;
    date: string;
    time: string;
  };
  onChangeCallType: (e: string) => void;
  onChangeTimezone: (e: string) => void;
  onChangeDate: (e: string) => void;
  onChangeTime: (e: string) => void;
  items: Array<{ hour: string }>;
  handleConfirm: () => void;
}

const CalendarTemplate = ({
  name,
  image,
  web,
  values,
  items,
  onChangeCallType,
  onChangeTimezone,
  onChangeDate,
  onChangeTime,
  handleConfirm,
}: CalendarProps) => {
  const styles = useResponsiveStyles();
  const {
    callType, timezone, date, time,
  } = values;

  return (
    <View style={styles.container}>
      <CalendarCall
        name={name}
        image={image}
        web={web}
        callType={callType}
        timeZone={timezone}
        onChangeCallType={onChangeCallType}
        onChangeTimezone={onChangeTimezone}
      />
      {callType && timezone && (
        <CalendarDaySelection web={web} onChange={onChangeDate} />
      )}
      {date && (
        <CalendarTimeSelection
          items={items}
          value={time}
          onChange={onChangeTime}
          handleConfirm={handleConfirm}
          selectedValues={{
            callType,
            timezone,
            date,
            time,
          }}
        />
      )}
    </View>
  );
};

export default CalendarTemplate;
