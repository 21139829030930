import React from 'react';
import { View, Text, Linking } from 'react-native';
import { Base } from '../../../types/base';
import StatusLabel from '../StatusLabel';
import styles from './task-info.style';
import WorkedEstimatedHours from '../WorkedEstimatedHours';

interface TaskInfoProps extends Base {
  isStory?: boolean;
  taskCode: string;
  taskName: string;
  status: string;
  workedhours: number;
  estimateHours: number;
}

const defaultProps = {
  isStory: false,
  isOpen: false,
};

const TaskInfo = ({
  isStory,
  taskCode,
  status,
  taskName,
  workedhours,
  estimateHours,
  web,
}: TaskInfoProps) => (
  <View style={[styles.fullWidth, !isStory && styles.subtaskStyles]}>
    <View style={styles.container}>
      <View style={{ width: web ? '10%' : '33.333%' }}>
        {web ? (
          <a href={`https://blackstonestudio.atlassian.net/browse/${taskCode}`} target="blank">
            <Text style={styles.issueCode}>{taskCode}</Text>
          </a>
        ) : (
          <Text
            style={styles.issueCode}
            onPress={() => {
              Linking.openURL(`https://blackstonestudio.atlassian.net/browse/${taskCode}`);
            }}
          >
            {taskCode}
          </Text>
        )}
      </View>
      {web && (
        <>
          <View style={{ width: '15%', paddingHorizontal: 5 }}>
            <StatusLabel label={workedhours > estimateHours ? 'Overdue' : 'On Time'} filled />
          </View>
          <View style={{ flex: 1, width: '35%', paddingHorizontal: 5 }}>
            <Text numberOfLines={1} style={styles.text}>
              {taskName}
            </Text>
          </View>
          <View style={{ width: '15%', paddingHorizontal: 5 }}>
            <StatusLabel label={status} />
          </View>
        </>
      )}
      <View
        style={[
          styles.row,
          {
            width: web ? '25%' : '66.666%',
            paddingLeft: web ? 20 : 0,
          },
        ]}
      >
        <WorkedEstimatedHours
          estimatedHours={(estimateHours / 3600).toFixed(0)}
          workedhours={(workedhours / 3600).toFixed(0)}
          web={web}
        />
        <WorkedEstimatedHours
          estimatedHours={estimateHours / 3600}
          workedhours={workedhours / 3600}
          isForDeviationPercentage
          web={web}
        />
      </View>
    </View>
    {!web && (
      <View style={styles.fullWidth}>
        <View style={[styles.fullWidth, styles.descriptionContainer]}>
          <Text style={styles.text} numberOfLines={1}>{taskName}</Text>
        </View>
        <View style={[styles.fullWidth, styles.row]}>
          <StatusLabel label={workedhours > estimateHours ? 'Overdue' : 'On Time'} filled />
          <StatusLabel label={status} />
        </View>
      </View>
    )}
  </View>
);

TaskInfo.defaultProps = defaultProps;

export default TaskInfo;
