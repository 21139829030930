import React from 'react';
import { View, Text } from 'react-native';
import MeasuredWeek from '../../../assets/svg/measuredWeek.svg';
import MeasuredLive from '../../../assets/svg/measuredLive.svg';
import MeasuredTimer from '../../../assets/svg/measuredSprint.svg';
import { Base } from '../../../types/base';
import Tooltip from '../Tooltip';
import styles from './dashboard-section-divider.style';

interface DashboardSectionDividerProps extends Base {
  title: string;
  tooltipContent?: string | React.ReactNode;
  iconType?: 'Live' | 'Clock' | 'Timer' | '';
  children?: React.ReactNode;
}

const defaultProps = {
  tooltipContent: '',
  iconType: '',
  children: undefined,
};

const DashboardSectionDivider = ({
  title,
  tooltipContent,
  iconType,
  children,
  web,
}: DashboardSectionDividerProps) => {
  const iconClock = web ? (
    <img src={MeasuredWeek} alt="week" width={17} height={17} />
  ) : (
    <MeasuredWeek width={17} height={17} />
  );
  const iconLive = web ? (
    <img src={MeasuredLive} alt="live" width={17} height={17} />
  ) : (
    <MeasuredLive width={17} height={17} />
  );
  const iconTimer = web ? (
    <img src={MeasuredTimer} alt="timer" width={17} height={17} />
  ) : (
    <MeasuredTimer width={17} height={17} />
  );

  return (
    <View style={styles.container}>
      <View style={[styles.container, styles.row]}>
        <Text
          style={[
            styles.title,
            {
              fontFamily: web ? 'Roboto Mono' : 'RobotoMono-Medium',
            },
          ]}
        >
          {title}
        </Text>
        {children || null}
        {iconType && !children ? (
          <Tooltip
            tooltipContent={tooltipContent}
            web={web}
            isLeftPosition
            isModal={!web}
            displayChildrenOnModal
          >
            {iconType === 'Clock' && iconClock}
            {iconType === 'Live' && iconLive}
            {iconType === 'Timer' && iconTimer}
          </Tooltip>
        ) : null}
      </View>
      <View style={[styles.dividerLine, { marginTop: web ? 30 : 10 }]} />
    </View>
  );
};

DashboardSectionDivider.defaultProps = defaultProps;

export default DashboardSectionDivider;
