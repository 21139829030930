import React from 'react';
import { View, Text } from 'react-native';
import Header from '../../molecules/Header';
import TextLink from '../../atoms/TextLink';
import SignUpForm from '../../organisms/SignUpForm';

import webStyles from './sign-up.style';
import { Base } from '../../../types/base';

interface SignupTemplateProps extends Base {
  saveData: (p: object) => void;
  redirect: () => void;
  isLoading: boolean;
  initialValues?: {
    email: string,
    password: string,
    confirmPassword: string,
  };
}

const defaultProps = {
  initialValues: {
    email: '',
    password: '',
    confirmPassword: '',
  },
};

const SignUpTemplate: React.FC<SignupTemplateProps> = ({
  web,
  saveData,
  isLoading,
  redirect,
  initialValues,
}: SignupTemplateProps) => (
  <View style={webStyles.container}>
    <Header title="Sign Up" />
    <SignUpForm saveData={saveData} web={web} isLoading={isLoading} initialValues={initialValues} />
    <TextLink onPress={redirect}>
      <Text>Do you have an account? Click here</Text>
    </TextLink>
  </View>
);

SignUpTemplate.defaultProps = defaultProps;

export default SignUpTemplate;
