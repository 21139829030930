import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    display: 'flex',
  },
  paginationWrapper: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'row',
    padding: 0,
    alignItems: 'center',
  },
  selected: {
    fontFamily: 'Roboto',
    fontWeight: '500',
    fontStyle: 'normal',
    lineHeight: 22,
    fontSize: 14,
    color: '#D9D9D9',
    padding: 8,

  },
  noselected: {
    fontWeight: '400',
    fontFamily: 'Roboto',
    fontSize: 14,
    color: '#595959',
    padding: 8,
  },
  arrow: {
    fontStyle: 'normal',
    lineHeight: 22,
    fontSize: 14,
    color: '#D9D9D9',
    padding: 8,
  },
});

export default styles;
