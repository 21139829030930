import React from 'react';
import { View } from 'react-native';
import { Base } from '../../../types/base';
import DashboardCard from '../../atoms/DashboardCard/DashboardCard';
import ProjectLabel from '../../atoms/ProjectLabel';
import Project from '../../atoms/Project';
import ProjectProgressBar from '../../atoms/ProjectProgressBar';
import styles from './project-card.style';
import ScrollHorizontalComponent from '../../atoms/ScrollHorizontalComponent';
import AllocatedHours from '../../molecules/AllocatedHours';

export interface ProjectProps extends Base {
  projectName: string;
  projectIcon: string;
  projectLabel: string;
  projectUrl?: string;
  workedHours: number;
  estimatedHours: number;
  scope: number;
  schedulePercentage: number;
  budgetPercentage: number;
  warrantyTime: number;
}

const ProjectCard = ({
  projectName,
  projectLabel,
  projectIcon,
  projectUrl,
  workedHours,
  estimatedHours,
  scope,
  schedulePercentage,
  budgetPercentage,
  warrantyTime,
  web,
}: ProjectProps) => {
  const handlePercentage = () => {
    const percentage = (+workedHours / +estimatedHours) * 100;

    if (percentage !== Infinity) {
      return percentage;
    }
    if (+workedHours <= 0 && estimatedHours <= 0) {
      return 0;
    }
    if (+workedHours > 0 && estimatedHours <= 0) {
      return 100;
    }

    return 100;
  };

  const hoursLeft = () => {
    if (+estimatedHours - +workedHours <= 0) {
      return 0;
    }

    return Number((+estimatedHours - +workedHours).toFixed(1));
  };

  const isDanger = handlePercentage() > 90;

  return (
    <DashboardCard backgroundColor isDanger={isDanger} web={web}>
      <View style={styles.headerContainer}>
        <View style={{ flex: 1 }}>
          <Project
            name={projectName}
            projectLabel={projectLabel || 'Area'}
            projectIcon={projectIcon}
            projectUrl={projectUrl || ''}
            web={web}
          />
        </View>
        <View style={styles.subContainer2}>
          <ProjectLabel
            label={`${hoursLeft()} h left`}
            isForHoursLeft
            isDanger={isDanger}
            web={web}
          />
        </View>
        {/* <TouchableOpacity>
          {web ? (
            <img src={MenuIcon} width={14} height={14} alt="menu" />
          ) : (
            <MenuIcon width={14} height={14} />
          )}
          </TouchableOpacity> */}
      </View>
      <View style={styles.progressBarContainer}>
        <ProjectProgressBar
          workedHours={workedHours}
          estimatedHours={estimatedHours}
          percentage={handlePercentage()}
          web={web}
        />
      </View>
      {web ? (
        <ScrollHorizontalComponent web={web}>
          <View style={styles.labelsContainer}>
            <ProjectLabel
              label="Schedule"
              isForSchedule
              isDanger={isDanger}
              data={schedulePercentage}
              web={web}
            />
            <ProjectLabel
              label="Budget"
              isForBudget
              isDanger={isDanger}
              data={budgetPercentage}
              web={web}
            />
            <ProjectLabel label="Scope" isForScope isDanger={isDanger} data={scope} web={web} />
          </View>
        </ScrollHorizontalComponent>
      ) : (
        <View style={styles.labelsContainerApp}>
          <ProjectLabel
            label="Schedule"
            isForSchedule
            isDanger={isDanger}
            data={schedulePercentage}
            web={web}
          />
          <ProjectLabel
            label="Budget"
            isForBudget
            isDanger={isDanger}
            data={budgetPercentage}
            web={web}
          />
          <ProjectLabel label="Scope" isForScope isDanger={isDanger} data={scope} web={web} />
        </View>
      )}
      <AllocatedHours
        backgroundColor={false}
        progressHours={warrantyTime}
        allocatedHours={estimatedHours}
        web={web}
        height="auto"
        title="Warranty Time"
        isForProject
      />
    </DashboardCard>
  );
};

export default ProjectCard;

ProjectCard.defaultProps = {
  projectUrl: '',
};
