import { StyleSheet } from 'react-native';

const textFont = {
  fontFamily: 'Oxygen-Bold',
};

const webStyles = StyleSheet.create({
  formContainer: {
    width: '60%',
    flexDirection: 'row',
    alignItems: 'center',
    margin: '30px',
  },
  formContainerXs: {
    width: '90%',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '30px',
  },
  column1: {
    width: '50%',
    paddingRight: '80px',
    borderWidth: 1,
    borderRightColor: '#ffffff4d',
  },
  column1Xs: {
    width: '100%',
  },
  column2: {
    width: '50%',
    paddingLeft: '75px',
  },
  column2Xs: {
    width: '100%',
  },
  inputContainer: {
    width: '100%',
    marginTop: 40,
  },
  buttonContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  linkContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  textError: {
    textFont,
    fontSize: 14,
    color: 'red',
    marginTop: 20,
  },
  dividerText: {
    color: '#ffffffa6',
    position: 'relative',
    right: 7,
    backgroundColor: 'black',
  },
  dividerTextXs: {
    color: '#ffffff',
    position: 'relative',
    backgroundColor: 'black',
    marginTop: 24,
    marginBottom: 4,
  },
  spinnerContainer: {
    width: '100%',
    alignItems: 'center',
  },
});

const appStyles = StyleSheet.create({
  formContainer: {
    width: '70%',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 30,
  },
  column1: {
    width: '100%',
    textAlign: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#ffffff4d',
    paddingBottom: 40,
  },
  column2: {
    width: '100%',
    textAlign: 'center',
  },
  inputContainer: {
    width: '100%',
    marginTop: 40,
  },
  buttonContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  linkContainer: {
    marginTop: 20,
    marginBottom: 20,
  },
  textError: {
    textFont,
    fontSize: 14,
    color: 'red',
    marginTop: 20,
  },
  dividerText: {
    color: '#ffffff',
    position: 'relative',
    backgroundColor: 'black',
    bottom: 9,
  },
  spinnerContainer: {
    width: '100%',
    alignItems: 'center',
  },
});

export { webStyles, appStyles };
