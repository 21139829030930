import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Oxygen-Bold',
  fontSize: 16,
  lineHeight: 18,
};

const styles = StyleSheet.create({
  fontFamily,
  container: {
    width: '100%',
    height: 'auto',
    flex: 1,
    flexDirection: 'column',
  },
  column: {
    flex: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  subTextContainer: {
    marginTop: 40,
  },
  subText: {
    textAlign: 'left',
    fontSize: 16,
    color: '#FFFFFF',
  },
});

export default styles;
