import React from 'react';
import { View, Text, useWindowDimensions } from 'react-native';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Spinner from '../../../assets/svg/spinner.svg';
import Button from '../../atoms/Button';
import OnlyTextInput from '../../atoms/OnlyTextInput';
import PhoneInput from '../../atoms/PhoneInput';
import Dropdown from '../../atoms/Dropdown';
import { Base } from '../../../types/base';
import { sizes, entities, industries } from './options';
import webStyles from './sign-up-form-2.style';

interface SignUpForm2Props extends Base {
  signUp: (param: object) => void;
  isLoading: boolean;
  initialValue?: {
    firstName: string,
    lastName: string,
    phoneNumber: string,
    title: string,
    department: string,
    name: string,
    size: string,
    entityType: string,
    industry: string,
    website: string,
  };
}

const defaultProps = {
  initialValue: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    title: '',
    department: '',
    name: '',
    size: '',
    entityType: '',
    industry: '',
    website: '',
  },
};

const SignUpForm2: React.FC<SignUpForm2Props> = ({
  web,
  signUp,
  isLoading,
  initialValue,
}) => {
  const { width } = useWindowDimensions();
  const websiteRegEx = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;
  const phoneRegEx = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s/0-9]{12}$/;
  const validationSchema = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    phoneNumber: yup
      .string()
      .matches(phoneRegEx, 'Please enter a valid phone number')
      .required('Phone number is required'),
    title: yup.string().required('Job title is required'),
    department: yup.string().required('Department is required'),
    name: yup.string().required('Company name is required'),
    size: yup.string().required('Company size is required'),
    entityType: yup.string().required('Entity is required'),
    industry: yup.string().required('Industry is required'),
    website: yup
      .string()
      .matches(websiteRegEx, 'Please enter a valid url')
      .required('Website is required'),
  });

  const formik = useFormik({
    initialValues: initialValue ?? {
      firstName: '',
      lastName: '',
      phoneNumber: '',
      title: '',
      department: '',
      name: '',
      size: '',
      entityType: '',
      industry: '',
      website: '',
    },
    validationSchema,
    onSubmit: (values) => {
      signUp(values);
    },
  });

  const styleFormContainer = width <= 800 ? webStyles.formContainerXs : webStyles.formContainer;
  const styleColumn = width <= 800 ? webStyles.columnXs : webStyles.column;

  return (
    <>
      <View style={web ? styleFormContainer : webStyles.formAppContainer}>
        <View style={web ? styleColumn : webStyles.columnApp}>
          <View style={webStyles.inputContainer}>
            <OnlyTextInput
              placeHolder="First Name"
              label="First Name"
              value={formik.values.firstName}
              onChange={formik.handleChange('firstName')}
            />
            {formik.touched.firstName && formik.errors.firstName && (
              <Text style={webStyles.textError}>{formik.errors.firstName}</Text>
            )}
          </View>
          <View style={webStyles.inputContainer}>
            <OnlyTextInput
              placeHolder="Last Name"
              label="Last Name"
              value={formik.values.lastName}
              onChange={formik.handleChange('lastName')}
            />
            {formik.touched.lastName && formik.errors.lastName && (
              <Text style={webStyles.textError}>{formik.errors.lastName}</Text>
            )}
          </View>
          <View style={webStyles.inputContainer}>
            <PhoneInput
              placeHolder="Phone Number"
              label="Phone Number"
              value={formik.values.phoneNumber}
              onChange={formik.handleChange('phoneNumber')}
            />
            {formik.touched.phoneNumber && formik.errors.phoneNumber && (
              <Text style={webStyles.textError}>{formik.errors.phoneNumber}</Text>
            )}
          </View>
          <View style={webStyles.inputContainer}>
            <OnlyTextInput
              placeHolder="Job Title"
              label="Job Title"
              value={formik.values.title}
              onChange={formik.handleChange('title')}
            />
            {formik.touched.title && formik.errors.title && (
              <Text style={webStyles.textError}>{formik.errors.title}</Text>
            )}
          </View>
          <View style={webStyles.inputContainer}>
            <OnlyTextInput
              placeHolder="Department"
              label="Department"
              value={formik.values.department}
              onChange={formik.handleChange('department')}
            />
            {formik.touched.department && formik.errors.department && (
              <Text style={webStyles.textError}>{formik.errors.department}</Text>
            )}
          </View>
        </View>
        <View style={web ? styleColumn : webStyles.columnApp}>
          <View style={webStyles.inputContainer}>
            <OnlyTextInput
              placeHolder="Company"
              label="Company"
              value={formik.values.name}
              onChange={formik.handleChange('name')}
            />
            {formik.touched.name && formik.errors.name && (
              <Text style={webStyles.textError}>{formik.errors.name}</Text>
            )}
          </View>
          <View style={[webStyles.inputContainer, { zIndex: 5000 }]}>
            <Dropdown
              placeholder="Number of employees"
              web={web}
              items={sizes}
              value={formik.values.size}
              label="Number of employees"
              onChange={formik.handleChange('size')}
            />
            {formik.touched.size && formik.errors.size && (
              <Text style={webStyles.textError}>{formik.errors.size}</Text>
            )}
          </View>
          <View style={[webStyles.inputContainer, { zIndex: 4000 }]}>
            <Dropdown
              placeholder="What are you solving for?"
              web={web}
              items={entities}
              value={formik.values.entityType}
              label="Entity"
              onChange={formik.handleChange('entityType')}
            />
            {formik.touched.entityType && formik.errors.entityType && (
              <Text style={webStyles.textError}>{formik.errors.entityType}</Text>
            )}
          </View>
          <View style={[webStyles.inputContainer, { zIndex: 3000 }]}>
            <Dropdown
              placeholder="Industry"
              web={web}
              items={industries}
              value={formik.values.industry}
              label="Industry"
              onChange={formik.handleChange('industry')}
            />
            {formik.touched.industry && formik.errors.industry && (
              <Text style={webStyles.textError}>{formik.errors.industry}</Text>
            )}
          </View>
          <View style={[webStyles.inputContainer, { zIndex: 1000 }]}>
            <OnlyTextInput
              placeHolder="Website"
              label="Website"
              value={formik.values.website}
              onChange={formik.handleChange('website')}
            />
            {formik.touched.website && formik.errors.website && (
              <Text style={webStyles.textError}>{formik.errors.website}</Text>
            )}
          </View>
        </View>
      </View>
      <View
        style={[web ? webStyles.buttonContainer : webStyles.buttonAppContainer, { zIndex: -1 }]}
      >
        {!isLoading ? (
          <Button disabled={false} onPress={formik.handleSubmit}>
            continue
          </Button>
        ) : (
          <View style={webStyles.spinnerContainer}>
            {web ? (
              <img src={Spinner} alt="spinner" width={35} height={35} />
            ) : (
              <Spinner width={35} height={35} />
            )}
          </View>
        )}
      </View>
    </>
  );
};

SignUpForm2.defaultProps = defaultProps;

export default SignUpForm2;
