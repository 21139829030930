import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: 50,
    paddingHorizontal: 30,
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  fullWidth: {
    width: '100%',
  },
  positionAbsolute: {
    position: 'absolute',
  },
  line: {
    borderColor: '#66FFF5',
    borderWidth: 0.5,
  },
  eventContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  tooltipMessage: {
    backgroundColor: '#434343',
    alignItems: 'center',
    padding: 5,
    borderRadius: 4,
    bottom: 40,
    zIndex: 9999,
    shadowColor: '#000000',
    shadowOffset: { width: -3, height: 4 },
    shadowOpacity: 0.5,
    shadowRadius: 12,
  },
  text: {
    fontFamily: 'Roboto',
    fontSize: 12,
    color: '#BABABA',
  },
});

export default styles;
