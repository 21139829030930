import { StyleSheet, useWindowDimensions } from 'react-native';

const fontFamily = {
  fontFamily: 'Oxygen-Light',
};

const useResponsiveStyles = () => {
  const { width } = useWindowDimensions();
  const containerWidth = width < 570 ? '100%' : 400;

  const styles = StyleSheet.create({
    fontFamily,
    container: {
      width: containerWidth,
      justifyContent: 'center',
      alignItems: 'center',
    },
    successContainer: {
      width: '100%',
      padding: 30,
    },
    callInfoContainer: {
      width: '100%',
      alignItems: 'center',
      marginTop: 40,
    },
    callLengthContainer: {
      flexDirection: 'row',
    },
    addToYourCalendarContainer: {
      width: '100%',
      padding: 20,
      alignItems: 'center',
      borderTopWidth: 1,
      borderTopColor: 'rgba(255, 255, 255, 0.15)',
    },
    iconsContainer: {
      width: '45%',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: 20,
    },
    text: {
      color: '#ffffffa6',
      fontSize: 16,
      fontWeight: '300',
      lineHeight: 24,
    },
    text2: {
      color: 'white',
      fontSize: 16,
      fontWeight: '700',
      marginBottom: 10,
    },
  });

  return styles;
};

export default useResponsiveStyles;
