import { StyleSheet } from 'react-native';

const webStyles = StyleSheet.create({
  container: {
    width: '25%',
  },
  containerApp: {
    width: '70%',
    alignSelf: 'center',
  },
});

export default webStyles;
