import React, { SVGProps } from 'react';

const FilterIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="12"
    height="10"
    viewBox="0 0 12 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.9301 0.207031H1.07025C0.742122 0.207031 0.537212 0.564621 0.701944 0.849888L3.81712 6.14542V9.36775C3.81712 9.6048 4.0073 9.79632 4.24302 9.79632H7.7573C7.99302 9.79632 8.18319 9.6048 8.18319 9.36775V6.14542L11.2997 0.849888C11.4631 0.564621 11.2582 0.207031 10.9301 0.207031ZM7.22427 8.83203H4.77605V6.74275H7.2256V8.83203H7.22427ZM7.35284 5.66328L7.2256 5.8856H4.77471L4.64748 5.66328L1.99168 1.17132H10.0086L7.35284 5.66328Z"
      fill="#D9D9D9"
    />
  </svg>
);

export default FilterIcon;
