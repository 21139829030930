import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Language, OrganizationEntityType, OrganizationIndustry, OrganizationSize, Role,
} from '../../../shared/types/enums';
import User from '../../../shared/types/user';
import type { RootState } from '../store';

export enum ProjectStatus {
  ACTIVE,
  INACTIVE,
  ON_HOLD,
  DELINQUENT,
  REVIEW,
}

export interface ProjectEnvironment {
  id: string;
  name: string;
  notes: string;
  url: string;
  projectId: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export interface ClientProject {
  id: string;
  slug: string;
  name: string;
  key: string;
  status: ProjectStatus | null;
  boardId: string | null;
  avatar: string;
  hero: string | null;
  url: string | null;
  description: string | null;
  jiraProjectId: string | null;
  developmentTeamId: string | null;
  organizationId: string;
  confluenceLink: string | null;
  weeklyParamsId: string;
  feedSettingsId: string;
  createdAt?: Date;
  updatedAt?: Date;
  environments: ProjectEnvironments
}

export type ProjectEnvironments = ProjectEnvironment[];
export type ClientProjects = ClientProject[];
export interface Session {
  accessToken?: string;
  user: User;
}

const initialState: Session = {
  accessToken: undefined,
  user: {
    id: '',
    email: '',
    firstName: '',
    lastName: '',
    language: Language.EN,
    department: '',
    role: Role.CLIENT,
    organization: {
      id: '',
      name: '',
      size: OrganizationSize.FROM_5_TO_9,
      industry: OrganizationIndustry.OTHER,
      createdAt: '',
      updatedAt: '',
      website: '',
      entityType: OrganizationEntityType.OTHER,
      projects: [{
        id: '',
        slug: '',
        name: '',
        key: '',
        status: null,
        boardId: '',
        avatar: '',
        hero: '',
        url: '',
        description: '',
        jiraProjectId: '',
        developmentTeamId: '',
        organizationId: '',
        confluenceLink: '',
        weeklyParamsId: '',
        feedSettingsId: '',
        environments: [{
          id: '',
          name: '',
          notes: '',
          url: '',
          projectId: '',
        }],
      }],
    },
  },
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<Session>) => ({
      ...state,
      ...action.payload,
    }),
    resetSession: () => initialState,
  },
});

export const { setSession, resetSession } = sessionSlice.actions;

export const selectSession = (state: RootState) => state.session;

export default sessionSlice.reducer;
