import React, { SVGProps } from 'react';

const Calendar: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const { stroke } = props;

  return (
    <svg
      width="10"
      height="12"
      viewBox="0 0 10 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.5" y="2.5" width="9" height="9" rx="1.5" stroke={stroke} />
      <path d="M10 6L-2.38419e-07 6" stroke={stroke} />
      <path d="M2.22223 0.888916V4.22225" stroke={stroke} />
      <path d="M5.00012 0.888916V4.22225" stroke={stroke} />
      <path d="M7.77789 0.888916V4.22225" stroke={stroke} />
    </svg>
  );
};

export default Calendar;
