import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Base } from '../../../types/base';
import styles from './tabs.style';

export interface TabProps {
  label: string;
  key: string;
  component: JSX.Element;
}

export interface TabsProps extends Base {
  tabs: TabProps[];
}

const Tabs: React.FC<TabsProps> = ({ tabs = [], web }) => {
  const [activeTabKey, setActiveTabKey] = useState<string>(tabs[0].key);

  const handleRenderComponent = () => {
    const selectedItem = tabs.find((item) => item.key === activeTabKey);

    return selectedItem?.component || null;
  };

  return (
    <View style={styles.fullWidth}>
      <View style={[styles.row]}>
        {tabs?.map(({ label, key }, index: number) => (
          <>
            <TouchableOpacity key={key} onPress={() => setActiveTabKey(key)}>
              <Text
                style={[
                  styles.text,
                  activeTabKey === key ? styles.active : styles.default,
                  { fontSize: web ? 20 : 18 },
                ]}
              >
                {label}
              </Text>
            </TouchableOpacity>
            {index < tabs.length - 1 && <Text style={styles.divider}>/</Text>}
          </>
        ))}
      </View>
      <View
        style={[
          styles.bottomLine,
          {
            marginTop: web ? 32 : 10,
            marginBottom: web ? 20 : 10,
          },
        ]}
      />
      <View style={styles.fullWidth}>{handleRenderComponent()}</View>
    </View>
  );
};

export { Tabs };
