import React from 'react';
import { View, Text } from 'react-native';
import { Base } from '../../../types/base';
import ProjectProgressBar from '../../atoms/ProjectProgressBar';
import SoWSLabel from '../../atoms/SoWSLabel';
import { DefaultStatus } from '../../atoms/SoWSLabel/SoWSLabel';
import styles from './sows-row.style';

export interface SoWSRowProps extends Base {
  sowId: string;
  projectType: string;
  status: DefaultStatus;
  nIssues: string | number;
  startDate: string;
  endDate: string;
  timeEstimate: string | number;
  timeSpent: string | number;
}

const SoWSRow = ({
  sowId,
  projectType,
  status,
  nIssues,
  startDate,
  endDate,
  timeEstimate,
  timeSpent,
  web,
}: SoWSRowProps) => {
  const handlePercentage = () => {
    const percentage = (+timeSpent / +timeEstimate) * 100;
    return Math.round(percentage);
  };

  return (
    <View style={styles.mainContainer}>
      {web ? (
        <View style={[styles.fullWidth, styles.row]}>
          <View style={[styles.flex3, styles.paddingRight]}>
            <Text style={[styles.id, styles.color1]}>{`[${sowId}]`}</Text>
          </View>
          <View style={[styles.flex3, styles.paddingRight]}>
            <Text style={[styles.type, styles.color1]} numberOfLines={1} ellipsizeMode="tail">
              {projectType}
            </Text>
          </View>
          <View style={[styles.flex2, styles.paddingRight]}>
            <SoWSLabel status={status} web={web} />
          </View>
          <View style={[styles.flex1, styles.paddingRight]}>
            <Text style={[styles.issueNumber, styles.color1]}>{nIssues}</Text>
          </View>
          <View style={[styles.flex2, styles.paddingRight]}>
            <Text style={[styles.date, styles.color2]}>{startDate}</Text>
          </View>
          <View style={[styles.flex2, styles.paddingRight]}>
            <Text style={[styles.date, styles.color2]}>{endDate}</Text>
          </View>
          <View style={[styles.flex3, styles.paddingRight]}>
            <ProjectProgressBar
              workedHours={timeSpent}
              estimatedHours={timeEstimate}
              percentage={timeSpent > 0 ? handlePercentage() : 100}
              isForClientDashboardCard
              isForSoWS
              web={web}
            />
          </View>
        </View>
      ) : (
        <View style={styles.fullWidth}>
          <View style={styles.row}>
            <View style={styles.flex3}>
              <Text style={[styles.id, styles.color1]}>{`[${sowId}]`}</Text>
            </View>
            <View style={styles.flex1}>
              <SoWSLabel status={status} web={web} />
            </View>
          </View>
          <View style={styles.marginTop20}>
            <Text style={[styles.type, styles.color1]} numberOfLines={1} ellipsizeMode="tail">
              {projectType}
            </Text>
          </View>
          <View style={[styles.fullWidth, styles.row, styles.marginTop20]}>
            <View style={[styles.flex1, styles.alignItemsLeft]}>
              <Text style={[styles.issueNumber, styles.color1]}>{`#${nIssues}`}</Text>
            </View>
            <View style={styles.flex2}>
              <Text style={[styles.date, styles.color2]}>{`Start: ${startDate}`}</Text>
            </View>
            <View style={[styles.flex2, styles.alignItemsRight]}>
              <Text style={[styles.date, styles.color2]}>{`Delivery: ${endDate}`}</Text>
            </View>
          </View>
          <View style={styles.marginTop10}>
            <ProjectProgressBar
              workedHours={timeSpent}
              estimatedHours={timeEstimate}
              percentage={timeSpent > 0 ? handlePercentage() : 100}
              isForClientDashboardCard
              isForSoWS
              web={web}
            />
          </View>
        </View>
      )}
    </View>
  );
};

export default SoWSRow;
