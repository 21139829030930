import { StyleSheet, useWindowDimensions, Platform } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const textFont = {
  fontFamily: 'Oxygen-Light',
};

const useResponsiveStyles = () => {
  const { width } = useWindowDimensions();
  const isSmartphone = width < 570;
  const isTablet = width < 900 && width > 570;
  // eslint-disable-next-line no-nested-ternary
  const imagesContainerWidth = isSmartphone ? '100%' : isTablet ? '50%' : '30%';
  const storesContainerDirection = isSmartphone ? 'column' : 'row';
  const appleIconPosition = isSmartphone ? { marginBottom: 10 } : { marginRight: 10 };

  const webStyles = StyleSheet.create({
    fontFamily,
    backgroundImageContainer: {
      width: '100%',
      minHeight: Platform.OS === 'web' ? ('100vh') : ('100%'),
      alignItems: 'center',
      justifyContent: 'center',
    },
    mainContainer: {
      width: '100%',
      alignItems: 'center',
      paddingVertical: 40,
    },
    imagesContainer: {
      width: imagesContainerWidth,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    storesContainer: {
      flexDirection: storesContainerDirection,
      justifyContent: 'center',
      marginTop: 40,
      marginBottom: 20,
    },
    appleIcon: appleIconPosition,
    title: {
      fontSize: 22,
      fontWeight: '500',
      color: '#FFFFFF',
      marginTop: 40,
      padding: 40,
      textAlign: 'center',
    },
    text: {
      textFont,
      fontSize: 14,
      color: '#ffffffa5',
      marginTop: 10,
    },
  });

  return webStyles;
};

export default useResponsiveStyles;
