import React, { useState } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';
import apiRequest from '../../../shared/services/apiRequest';
import NewPasswordTemplate from '../../../shared/components/templates/ResetPassword';
import styles from '../../styles/page.style';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const [isLoading, setIsloading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrormessage] = useState('');

  const resetPassword = (values) => {
    const resetPasswordObj = {
      password: values,
      token,
    };

    setIsloading(true);
    setErrormessage('');
    apiRequest
      .put(
        'auth/update-password',
        resetPasswordObj,
      )
      .then(() => {
        setIsloading(false);
        setSuccess(true);
        setTimeout(() => {
          navigate('/login');
        }, 1500);
      })
      .catch((error) => {
        setErrormessage(error.message);
        setIsloading(false);
        setSuccess(false);
      });
  };

  return (
    <div style={styles.container}>
      <NewPasswordTemplate
        web
        resetPasssword={resetPassword}
        isLoading={isLoading}
        success={success}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default ResetPassword;
