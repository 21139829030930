import React from 'react';
import { View, Text } from 'react-native';
import Logo from '../../atoms/Logo';
import styles from './header.style';

interface HeaderProps {
  title: string;
}

const Header = ({ title }: HeaderProps) => (
  <View style={styles.container}>
    <Logo uriImage="https://blackstonestudio.atlassian.net/s/o2joag/b/24/b8f3b866e76a1ef1293bc2772af00476/_/jira-logo-scaled.png" />
    <Text style={styles.title}>{title}</Text>
  </View>
);

export default Header;
