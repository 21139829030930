import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    width: '30%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerApp: {
    borderRadius: 20,
    padding: 20,
    width: '100%',
    marginBottom: 10,
    backgroundColor: '#262626',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    fontSize: 16,
    color: 'white',
    marginLeft: 10,
    fontFamily: 'Oxygen',
    fontWeight: '400',
  },
  titleApp: {
    fontSize: 12,
    color: 'white',
    marginLeft: 5,
    fontFamily: 'Oxygen',
  },
  label: {
    fontSize: 50,
    fontWeight: '300',
    lineHeight: 50,
  },
  labelApp: {
    fontSize: 30,
    fontWeight: '300',
    lineHeight: 30,
  },
  percent: {
    fontSize: 22,
    lineHeight: 22,
    fontWeight: '400',
  },
  percentApp: {
    fontSize: 15,
    lineHeight: 15,
    fontWeight: '400',
  },
  cardsContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
});

export default styles;
