import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    padding: 20,
    width: '100%',
    height: '100%',
  },
  title: {
    fontSize: 16,
    fontWeight: '700',
    fontFamily: 'Oxygen',
    lineHeight: 22,
    color: '#F5F5F5',
  },
});

export default styles;
