import { StyleSheet, useWindowDimensions } from 'react-native';

const useResponsiveStyles = () => {
  const { width } = useWindowDimensions();
  const containerWidth = width < 570 ? '100%' : 400;
  const borderLeftWidth = width < 1200 ? 0 : 1;
  const paddingBottom = width < 900 ? 40 : 0;

  const styles = StyleSheet.create({
    container: {
      width: containerWidth,
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingHorizontal: 40,
      borderLeftWidth,
      borderLeftColor: 'rgba(255, 255, 255, 0.15)',
      paddingBottom,
    },
    subContainer: {
      width: '100%',
      alignItems: 'center',
    },
    title: {
      fontSize: 14,
      color: '#ffffffa6',
      marginBottom: 40,
    },
    date: {
      fontSize: 24,
      color: '#ffffffa6',
      marginBottom: 40,
    },
  });

  return styles;
};

export default useResponsiveStyles;
