import React from 'react';
import { View, Text, Image } from 'react-native';
import { Base } from '../../../types/base';
import styles from './project.style';

interface ProjectProps extends Base {
  name: string;
  projectLabel: string;
  projectIcon: string;
  projectUrl: string;
}

const Project = ({
  name, projectLabel, projectIcon, web, projectUrl,
}: ProjectProps) => (
  <View style={styles.container}>
    {web ? (
      <a href={projectUrl} target="_blank" rel="noreferrer">
        <img src={projectIcon} style={{ width: 40, height: 40, borderRadius: 100 }} alt="project" />
      </a>
    ) : (
      <Image source={{ uri: projectIcon }} style={{ width: 40, height: 40, borderRadius: 100 }} />
    )}
    <View style={styles.subContainer}>
      <Text style={styles.projectName} numberOfLines={1}>{name}</Text>
      <Text numberOfLines={1} style={styles.label}>{projectLabel}</Text>
    </View>
  </View>
);

export default Project;
