import React, { SVGProps } from 'react';

const Failed: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.62862 4.05854C7.62862 4.00452 7.58443 3.96033 7.53041 3.96033L6.72014 3.96401L5.49983 5.41881L4.28074 3.96524L3.46925 3.96156C3.41523 3.96156 3.37103 4.00452 3.37103 4.05977C3.37103 4.0831 3.37963 4.10519 3.39436 4.12361L4.99157 6.02651L3.39436 7.92818C3.37952 7.94618 3.37129 7.9687 3.37103 7.99202C3.37103 8.04604 3.41523 8.09024 3.46925 8.09024L4.28074 8.08656L5.49983 6.63176L6.71891 8.08533L7.52918 8.08901C7.5832 8.08901 7.62739 8.04604 7.62739 7.9908C7.62739 7.96747 7.6188 7.94537 7.60407 7.92696L6.00931 6.02528L7.60652 4.12238C7.62126 4.10519 7.62862 4.08187 7.62862 4.05854Z"
      fill="#F93C6F"
    />
    <path
      d="M5.5 0.5C2.46272 0.5 0 2.96272 0 6C0 9.03728 2.46272 11.5 5.5 11.5C8.53728 11.5 11 9.03728 11 6C11 2.96272 8.53728 0.5 5.5 0.5ZM5.5 10.567C2.97835 10.567 0.933036 8.52165 0.933036 6C0.933036 3.47835 2.97835 1.43304 5.5 1.43304C8.02165 1.43304 10.067 3.47835 10.067 6C10.067 8.52165 8.02165 10.567 5.5 10.567Z"
      fill="#F93C6F"
    />
  </svg>
);

export default Failed;
