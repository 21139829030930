import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from './collapsable-row';
import Arrows from '../../../assets/svg/leftArrow.svg';
import { Base } from '../../../types/base';

interface CollapsableType extends Base {
  title: string;
  description: string;
  color?: string;
  isEpic?: boolean;
}

const CollapsableRow = ({
  title, description, web, isEpic, color,
}: CollapsableType) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const icon = (size: number) => (
    <TouchableOpacity
      style={[styles.button, isEpic && { width: 25 }]}
      onPress={() => setIsOpen(!isOpen)}
    >
      <View
        style={{ transform: [{ rotate: isOpen ? '270deg' : '180deg' }], width: size, height: size }}
      >
        {web ? (
          <img src={Arrows as string} alt="icon" width={size} height={size} />
        ) : (
          <Arrows width={size} height={size} />
        )}
      </View>
    </TouchableOpacity>
  );

  return (
    <View style={!isEpic ? styles.container : styles.epicContainer}>
      <View style={[styles.header, isEpic && { paddingHorizontal: 10, borderBottomWidth: 0 }]}>
        <View style={{ flexDirection: 'row', alignItems: 'center', flex: 2 }}>
          {isEpic && icon(15)}
          <Text
            style={[styles.title, isEpic && {
              fontWeight: 'normal', color: '#bfbfbf', flex: 2, paddingRight: 10,
            }]}
            numberOfLines={0}
          >
            {title}
          </Text>
        </View>
        {!isEpic ? icon(25) : <View style={[styles.epicSquare, { backgroundColor: color }]} />}
      </View>
      {isOpen && (
        <View
          style={[
            styles.body,
            isEpic && {
              paddingHorizontal: 35,
              paddingVertical: 0,
              paddingBottom: 10,
            },
          ]}
        >
          <Text
            numberOfLines={0}
            style={[styles.description, isEpic && { color: '#bfbfbf' }]}
          >
            {description}
          </Text>
        </View>
      )}
    </View>
  );
};

CollapsableRow.defaultProps = {
  isEpic: false,
  color: '#fff',
};

export default CollapsableRow;
