import React from 'react';
import { Text, View } from 'react-native';
import Logo from '../../atoms/Logo';
import Success from '../../atoms/Success';
import styles from './confirmation-NDA.style';

// interface ConfirmationNDAProps {}

const ConfirmationNDA: React.FC = () => (
  <View style={styles.container}>
    <View style={styles.column}>
      <Logo uriImage="https://blackstonestudio.atlassian.net/s/o2joag/b/24/b8f3b866e76a1ef1293bc2772af00476/_/jira-logo-scaled.png" />
    </View>
    <View style={styles.column}>
      <Success title="Congratulations">
        <View style={styles.subTextContainer}>
          <Text style={styles.subText}>
            We&apos;ll be in contact shortly to activate your account
          </Text>
        </View>
      </Success>
    </View>
  </View>
);

export default ConfirmationNDA;
