import { StyleSheet } from 'react-native';

const textFont = {
  fontFamily: 'Oxygen-Bold',
};

const webStyles = StyleSheet.create({
  formContainer: {
    width: '25%',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 30,
  },
  textError: {
    textFont,
    textAlign: 'center',
    fontSize: 14,
    color: 'red',
    marginTop: 20,
  },
  successText: {
    color: '#0ABAB5',
    fontSize: 20,
    textAlign: 'center',
  },
  apiCallErrorText: {
    textFont,
    fontSize: 14,
    color: 'red',
  },
  inputContainer: {
    width: '100%',
    marginTop: 40,
  },
  buttonContainer: {
    width: '100%',
    marginTop: 40,
    marginBottom: 20,
  },
  spinnerContainer: {
    alignItems: 'center',
  },
});

export default webStyles;
