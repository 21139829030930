import React from 'react';
import { View, Text } from 'react-native';
import { Base } from '../../../types/base';
import Calendar from '../../../assets/svg/calendar.svg';
import styles from './call-schedule.style';

interface CallScheduleProps extends Base {
  day: string;
  hour: string;
}

const CallSchedule = ({ day, hour, web }: CallScheduleProps) => (
  <View style={styles.datecontainer}>
    {web ? (
      <img src={Calendar} alt="calendar" width={20} height={20} />
    ) : (
      <Calendar width={20} height={20} />
    )}
    <Text style={styles.date}>{`${day}, ${hour}`}</Text>
  </View>
);

export default CallSchedule;
