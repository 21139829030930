import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  mainContainer: {
    paddingHorizontal: 20,
    paddingVertical: 15,
    backgroundColor: '#262626',
    borderRadius: 4,
    marginBottom: 5,
  },
  col1: {
    flex: 1,
  },
  col2: {
    flex: 2,
  },
  color1: {
    color: '#BFBFBF',
  },
  color2: {
    color: '#D9D9D9',
  },
  light: {
    fontFamily: 'Roboto',
    fontWeight: '300',
    fontSize: 12,
  },
  bold: {
    fontSize: 18,
    fontWeight: '700',
    fontFamily: 'Roboto Mono',
  },
  paddingRight10: {
    paddingRight: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  alignItemsRight: {
    justifyContent: 'flex-end',
  },
});

export default styles;
