import { StyleSheet } from 'react-native';

const textFont = {
  fontFamily: 'Oxygen-Bold',
};

const webStyles = StyleSheet.create({
  formContainer: {
    width: '60%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingBottom: 30,
  },
  formContainerXs: {
    flexDirection: 'column',
    width: '80%',
    justifyContent: 'space-between',
    paddingBottom: 30,
  },
  formAppContainer: {
    width: '70%',
    flexDirection: 'column',
  },
  column: {
    width: '45%',
  },
  columnXs: {
    width: '100%',
  },
  columnApp: {
    width: '100%',
  },
  inputContainer: {
    width: '100%',
    marginTop: 28,
  },
  textError: {
    textFont,
    textAlign: 'center',
    fontSize: 10,
    color: 'red',
    marginTop: 10,
  },
  buttonContainer: {
    width: '25%',
    marginTop: 40,
    marginBottom: 40,
  },
  buttonAppContainer: {
    width: '70%',
    marginTop: 40,
    marginBottom: 40,
  },
  successText: {
    color: 'white',
    textAlign: 'center',
    fontSize: 28,
  },
  spinnerContainer: {
    alignItems: 'center',
  },
});

export default webStyles;
