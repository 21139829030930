import { StyleSheet, useWindowDimensions } from 'react-native';

const fontFamily = {
  fontFamily: 'Oxygen-Regular',
};

const useResponsiveStyles = () => {
  const { width } = useWindowDimensions();
  const flexDirection = width < 1200 ? 'column' : 'row';
  const containerWidth = width < 570 ? '100%' : 'auto';
  const borderRadius = width < 570 ? 0 : 5;

  const styles = StyleSheet.create({
    fontFamily,
    mainContainer: {
      width: containerWidth,
      borderRadius,
      backgroundColor: '#2D2D2D',
    },
    subContainer: {
      flexDirection,
      paddingVertical: 20,
    },
    buttonsContainer: {
      width: '100%',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      paddingVertical: 20,
      paddingHorizontal: 40,
      alignItems: 'center',
      borderTopWidth: 1,
      borderTopColor: 'rgba(255, 255, 255, 0.15)',
    },
    buttonContainer: {
      marginLeft: 20,
    },
  });

  return styles;
};

export default useResponsiveStyles;
