import React from 'react';
import { View, Text } from 'react-native';
import styles from './dashboard-card.style';
import Tooltip from '../Tooltip';
import MeasuredWeek from '../../../assets/svg/measuredWeek.svg';
import MeasuredLive from '../../../assets/svg/measuredLive.svg';
import MeasuredTimer from '../../../assets/svg/measuredSprint.svg';
import { Base } from '../../../types/base';

interface DashboardCardProps extends Base {
  children: React.ReactNode;
  isDanger?: boolean;
  height?: number | string;
  width?: number | string;
  backgroundColor?: boolean;
  title?: string;
  iconType?: 'Live' | 'Clock' | 'Timer' | '';
  isForAllocatedHoursCard?: boolean;
  tooltipContent?: string | React.ReactNode;
  isForProject?: boolean;
  isTooltipLeft?: boolean;
  marginTop?: number;
  largeTitlePadding?: boolean;
}
const DashboardCard = ({
  children,
  isDanger,
  height,
  backgroundColor,
  title,
  iconType,
  isForAllocatedHoursCard,
  tooltipContent,
  largeTitlePadding,
  web,
  marginTop,
  width,
  isForProject,
  isTooltipLeft,
}: DashboardCardProps) => {
  let color = 'transparent';
  const basefont = web ? 'RobotoMono-Regular, monospace' : 'RobotoMono-Medium';

  const iconClock = web ? (
    <img src={MeasuredWeek} alt="week" width={17} height={17} />
  ) : (
    <MeasuredWeek width={17} height={17} />
  );
  const iconLive = web ? (
    <img src={MeasuredLive} alt="live" width={17} height={17} />
  ) : (
    <MeasuredLive width={17} height={17} />
  );
  const iconTimer = web ? (
    <img src={MeasuredTimer} alt="timer" width={17} height={17} />
  ) : (
    <MeasuredTimer width={17} height={17} />
  );

  if (backgroundColor) {
    color = isDanger ? '#A02244' : '#262626';
  }

  const getTitlePadding = () => {
    if (web && largeTitlePadding) {
      return 32;
    }
    return 10;
  };

  return (
    <View
      style={[
        styles.container,
        {
          marginTop: marginTop ?? 0,
          backgroundColor: color,
          height,
          padding: web ? 20 : 10,
          width: width || '100%',
          paddingLeft: !backgroundColor ? 0 : 20,
          paddingRight: !backgroundColor ? 0 : 20,
        },
      ]}
    >
      {title && (
        <View
          style={[
            styles.titleContainer,
            {
              borderBottomWidth: isForAllocatedHoursCard ? 0 : 2,
              paddingBottom: isForAllocatedHoursCard ? 0 : getTitlePadding(),
              marginBottom: web ? 20 : 10,
            },
          ]}
        >
          <Text
            style={[
              styles.title,
              { fontSize: isForAllocatedHoursCard ? 16 : 20 },
              {
                fontFamily: isForProject ? 'Oxygen' : basefont,
              },
            ]}
          >
            {title}
          </Text>
          {iconType ? (
            <Tooltip
              tooltipContent={tooltipContent}
              web={web}
              isLeftPosition={!web || isTooltipLeft}
              isModal={!web}
              displayChildrenOnModal
            >
              {iconType === 'Clock' && iconClock}
              {iconType === 'Live' && iconLive}
              {iconType === 'Timer' && iconTimer}
            </Tooltip>
          ) : null}
        </View>
      )}
      {children}
    </View>
  );
};

DashboardCard.defaultProps = {
  isDanger: false,
  height: 'auto',
  width: '100%',
  marginTop: 0,
  backgroundColor: false,
  title: null,
  iconType: '',
  isForAllocatedHoursCard: false,
  tooltipContent: '',
  isForProject: false,
  isTooltipLeft: false,
  largeTitlePadding: false,
  web: false,
};
export default DashboardCard;
