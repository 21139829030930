import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto-Mono',
};

const styles = StyleSheet.create({
  fontFamily,
  row: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 15,
  },
  col1: {
    flex: 1,
  },
  col2: {
    flex: 2,
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: '700',
    color: '#AAAAAA',
    letterSpacing: 2,
  },
  paddingRight10: {
    paddingRight: 10,
  },
});

export default styles;
