import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto-Mono',
};

const styles = StyleSheet.create({
  fontFamily,
  subtitleCard: {
    fontSize: 12,
    color: '#8C8C8C',
    lineHeight: 22,
    letterSpacing: 1,
    fontFamily: 'Roboto',
    fontWeight: '700',
  },
  textCard: {
    fontSize: 15,
    fontFamily: 'Oxygen',
    color: '#EAEBEB',
    lineHeight: 22,
    fontWeight: '400',
    letterSpacing: 0.5,
    textTransform: 'capitalize',
  },
  textCardDates: {
    fontSize: 14,
    fontFamily: 'Roboto',
    color: '#CFD0D0',
    lineHeight: 22,
    fontWeight: '400',
  },
  description: {
    fontSize: 14,
    color: '#EAEBEB',
    lineHeight: 21,
    fontWeight: '400',
    letterSpacing: 0.5,
    textAlign: 'justify',
    fontFamily: 'Oxygen',
  },
  datesContainer: {
    width: '50%',
  },
  timingInfo: {
    display: 'flex',
    flexDirection: 'row',
  },
  descContainer: {
    height: 130,
    padding: 15,
    backgroundColor: 'black',
    marginVertical: 10,
    borderRadius: 8,
  },
});

export default styles;
