import React, { useState } from 'react';
import { pdfjs, Document, Page } from 'react-pdf';
import './PDFWebViewer.style.css';

// Sets the proper worker
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

export interface PDFWebViewerProps {
  source: string;
  onError: (error) => void;
}

const PDFWebViewer: React.FC<PDFWebViewerProps> = ({ source, onError }) => {
  const [numOfPages, setNumOfPages] = useState<number>(0);

  const onDocumentLoadSuccess = (numPages) => {
    setNumOfPages(numPages);
  };
  return (
    <div className="pdfContainer">
      <div className="pdfWrapper">
        <Document
          file={source}
          onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)}
          onLoadError={onError}
        >
          {Array.from(new Array(numOfPages), (el, index) => (
            <Page key={`page_${index + 1}`} pageNumber={index + 1} />
          ))}
        </Document>
      </div>
    </div>
  );
};

export default PDFWebViewer;
