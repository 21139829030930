import React from 'react';
import Icon from '../../../shared/assets/svg/google.svg';

const items = [
  {
    cardInfo: {
      projectName: 'Invasion',
      Icon,
      workedHours: 56,
      estimatedHours: 50,
      items: [
        {
          storyData: {
            data: {
              taskCode: 'INVA-123',
              taskName: 'Test name',
              status: 'In progress',
              workedHours: '2h 20m',
            },
            subtasks: [
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
            ],
          },
        },
        {
          storyData: {
            data: {
              taskCode: 'INVA-123',
              taskName: 'Test name',
              status: 'In progress',
              workedHours: '2h 20m',
            },
            subtasks: [
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
            ],
          },
        },
      ],
    },
  },
  {
    cardInfo: {
      projectName: 'Taxaroo',
      Icon,
      workedHours: 13,
      estimatedHours: 45,
      items: [
        {
          storyData: {
            data: {
              taskCode: 'INVA-123',
              taskName: 'Test name',
              status: 'In progress',
              workedHours: '2h 20m',
            },
            subtasks: [
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
            ],
          },
        },
        {
          storyData: {
            data: {
              taskCode: 'INVA-123',
              taskName: 'Test name',
              status: 'In progress',
              workedHours: '2h 20m',
            },
            subtasks: [
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
            ],
          },
        },
      ],
    },
  },
  {
    cardInfo: {
      projectName: 'Blackstone App',
      Icon,
      workedHours: 120,
      estimatedHours: 100,
      items: [
        {
          storyData: {
            data: {
              taskCode: 'INVA-123',
              taskName: 'Test name',
              status: 'In progress',
              workedHours: '2h 20m',
            },
            subtasks: [
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
            ],
          },
        },
        {
          storyData: {
            data: {
              taskCode: 'INVA-123',
              taskName: 'Test name',
              status: 'In progress',
              workedHours: '2h 20m',
            },
            subtasks: [
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
            ],
          },
        },
      ],
    },
  },
  {
    cardInfo: {
      projectName: 'Kip Therapy',
      Icon,
      workedHours: 113,
      estimatedHours: 80,
      items: [
        {
          storyData: {
            data: {
              taskCode: 'INVA-123',
              taskName: 'Test name',
              status: 'In progress',
              workedHours: '2h 20m',
            },
            subtasks: [
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
            ],
          },
        },
        {
          storyData: {
            data: {
              taskCode: 'INVA-123',
              taskName: 'Test name',
              status: 'In progress',
              workedHours: '2h 20m',
            },
            subtasks: [
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
              {
                taskCode: 'INVA-123',
                taskName: 'Test name',
                status: 'In progress',
                workedHours: '2h 20m',
              },
            ],
          },
        },
      ],
    },
  },
];

const eventItems = [
  {
    name: 'Taxaroo',
    icon: Icon,
    description: 'SCOPE_OF_CHANGE',
    hours: 1,
  },
  {
    name: 'Occam',
    icon: Icon,
    description: 'KICK_OFF_CALL',
    hours: 7,
  },
  {
    name: 'Project Name',
    icon: Icon,
    description: 'BLOCKER_CALL',
    hours: 13,
  },
];

const projectsItems = [
  {
    projectName: 'Test 1',
    projectIcon: 'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectLabel: 'Front End',
    workedHours: 12,
    estimatedHours: 48,
    scope: 12,
    schedulePercentage: 2,
    budgetPercentage: 14,
  },
  {
    projectName: 'Test 2',
    projectIcon: 'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectLabel: 'Back End',
    workedHours: 77,
    estimatedHours: 89,
    scope: 4,
    schedulePercentage: 12,
    budgetPercentage: 3,
  },
  {
    projectName: 'Test 3',
    projectIcon: 'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectLabel: 'Back End',
    workedHours: 28,
    estimatedHours: 30,
    scope: 6,
    schedulePercentage: 26,
    budgetPercentage: 7,
  },
  {
    projectName: 'Test 4',
    projectIcon: 'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectLabel: 'Full Stack',
    workedHours: 24,
    estimatedHours: 45,
    scope: 0,
    schedulePercentage: 8,
    budgetPercentage: 3,
  },
  {
    projectName: 'Test 5',
    projectIcon: 'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectLabel: 'Full Stack',
    workedHours: 14,
    estimatedHours: 57,
    scope: 9,
    schedulePercentage: 16,
    budgetPercentage: 4,
  },
  {
    projectName: 'Test 6',
    projectIcon: 'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectLabel: 'Front End',
    workedHours: 68,
    estimatedHours: 98,
    scope: 3,
    schedulePercentage: 16,
    budgetPercentage: 25,
  },
];

const issueSummaryItems = [
  {
    status: 'reopened',
    total: 13,
    sort: 1,
  },
  {
    status: 'blocked',
    total: 6,
    sort: 2,
  },
  {
    status: 'todo',
    total: 147,
    sort: 3,
  },
  {
    status: 'codeReview',
    total: 23,
    sort: 4,
  },
  {
    status: 'readyForQA',
    total: 58,
    sort: 5,
  },
  {
    status: 'readyForRelease',
    total: 36,
    sort: 6,
  },
  {
    status: 'done',
    total: 213,
    sort: 6,
  },
];

const issuesItems = [
  {
    projectIcon: 'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectName: 'Project 1',
    projectCode: 'TEST-123',
    githubUrl: 'https://github.com/BlackstoneStudio',
    priority: 'Highest',
    devName: 'Tosin Abasi',
    devAvatarUrl:
      'https://images.fineartamerica.com/images/artworkimages/mediumlarge/3/tosin-abasi-guitarist-mal-bray.jpg',
  },
  {
    projectIcon: 'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectName: 'Project 2',
    projectCode: 'TEST-123',
    githubUrl: 'https://github.com/BlackstoneStudio',
    priority: 'High',
    devName: 'Greg Puciato',
    devAvatarUrl:
      'https://i0.wp.com/confinedrock.com/wp-content/uploads/2020/07/Puciato2.jpg?fit=780%2C488&ssl=1',
  },
  {
    projectIcon: 'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectName: 'Project 3',
    projectCode: 'TEST-123',
    githubUrl: 'https://github.com/BlackstoneStudio',
    priority: 'Medium',
    devName: 'Maynard Keenan',
    devAvatarUrl:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkcqZslRGADHiwVLKD4153JJKhcJ2Ta8p-uA&usqp=CAU',
  },
  {
    projectIcon: 'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectName: 'Project 4',
    projectCode: 'TEST-123',
    githubUrl: 'https://github.com/BlackstoneStudio',
    priority: 'Low',
    devName: 'Trent Reznor',
    devAvatarUrl:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZYDOB2cY5SJPrrQY7XEyVXi8IQ1CEs1syzQ&usqp=CAU',
  },
  {
    projectIcon: 'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectName: 'Project 5',
    projectCode: 'TEST-123',
    githubUrl: 'https://github.com/BlackstoneStudio',
    priority: 'Lowest',
    devName: 'Mikael Akerfeldt',
    devAvatarUrl:
      'http://pm1.narvii.com/6918/9a186655733934415b57cdcb700b157e246e6913r1-384-384v2_uhq.jpg',
  },
  {
    projectIcon: 'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectName: 'Project 6',
    projectCode: 'TEST-123',
    githubUrl: 'https://github.com/BlackstoneStudio',
    priority: 'Lowest',
    devName: '',
    devAvatarUrl: '',
  },
];

const blockedIssuesItems = [
  {
    projectIcon:
      'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectName: 'Project 1',
    projectCode: 'TEST-123',
    devName: 'Tosin Abasi',
    devAvatarUrl:
      'https://images.fineartamerica.com/images/artworkimages/mediumlarge/3/tosin-abasi-guitarist-mal-bray.jpg',
  },
  {
    projectIcon:
      'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectName: 'Project 2',
    projectCode: 'TEST-123',
    devName: 'Greg Puciato',
    devAvatarUrl:
      'https://i0.wp.com/confinedrock.com/wp-content/uploads/2020/07/Puciato2.jpg?fit=780%2C488&ssl=1',
  },
  {
    projectIcon:
      'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectName: 'Project 3',
    projectCode: 'TEST-123',
    devName: 'Maynard Keenan',
    devAvatarUrl:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTkcqZslRGADHiwVLKD4153JJKhcJ2Ta8p-uA&usqp=CAU',
  },
  {
    projectIcon:
      'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectName: 'Project 4',
    projectCode: 'TEST-123',
    devName: 'Trent Reznor',
    devAvatarUrl:
      'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQZYDOB2cY5SJPrrQY7XEyVXi8IQ1CEs1syzQ&usqp=CAU',
  },
  {
    projectIcon:
      'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectName: 'Project 5',
    projectCode: 'TEST-123',
    devName: 'Mikael Akerfeldt',
    devAvatarUrl:
      'http://pm1.narvii.com/6918/9a186655733934415b57cdcb700b157e246e6913r1-384-384v2_uhq.jpg',
  },
  {
    projectIcon:
      'https://cdn.icon-icons.com/icons2/836/PNG/512/Google_icon-icons.com_66793.png',
    projectName: 'Project 6',
    projectCode: 'TEST-123',
    devName: '',
    devAvatarUrl: '',
  },
];

const miscCardItems = [
  {
    issueName: 'Test Issue',
    issueCode: 'TAXR-1345',
    projectName: 'Taxaroo',
    devName: 'Diego Alvarez',
    projectIcon: Icon,
    devAvatar: Icon,
  },
  {
    issueName: 'Test Issue',
    issueCode: 'TAXR-1345',
    projectName: 'Taxaroo',
    devName: 'Diego Alvarez',
    projectIcon: Icon,
    devAvatar: Icon,
  },
  {
    issueName: 'Test Issue',
    issueCode: 'TAXR-1345',
    projectName: 'Taxaroo',
    devName: 'Diego Alvarez',
    projectIcon: Icon,
    devAvatar: Icon,
  },
  {
    issueName: 'Test Issue',
    issueCode: 'TAXR-1345',
    projectName: 'Taxaroo',
    devName: 'Diego Alvarez',
    projectIcon: Icon,
    devAvatar: Icon,
  },
  {
    issueName: 'Test Issue',
    issueCode: 'TAXR-1345',
    projectName: 'Taxaroo',
    devName: 'Diego Alvarez',
    projectIcon: Icon,
    devAvatar: Icon,
  },
];

const cardContainer = [
  {
    title: 'Overdue Issues',
    items: miscCardItems,
  },
  {
    title: 'Reopened Issues',
    items: miscCardItems,
  },
  {
    title: 'Issues Due Today',
    items: miscCardItems,
  },
  {
    title: 'Issues In Code Review',
    items: miscCardItems,
  },
  {
    title: 'Longevity Issues',
    items: miscCardItems,
  },
];

const openPRsData = [
  {
    projectName: 'Invasion',
    projectIcon: Icon,
    prData: {
      prName: 'pull/312',
      branch1: 'story/BLKS-904',
      branch2: 'subtask/BLKS-909',
      date: '5d ago',
    },
  },
  {
    projectName: 'Invasion',
    projectIcon: Icon,
    prData: {
      prName: 'pull/312',
      branch1: 'story/BLKS-904',
      branch2: 'subtask/BLKS-909',
      date: '5d ago',
    },
  },
  {
    projectName: 'Invasion',
    projectIcon: Icon,
    prData: {
      prName: 'pull/312',
      branch1: 'story/BLKS-904',
      branch2: 'subtask/BLKS-909',
      date: '5d ago',
    },
  },
  {
    projectName: 'Invasion',
    projectIcon: Icon,
    prData: {
      prName: 'pull/312',
      branch1: 'story/BLKS-904',
      branch2: 'subtask/BLKS-909',
      date: '5d ago',
    },
  },
  {
    projectName: 'Invasion',
    projectIcon: Icon,
    prData: {
      prName: 'pull/312',
      branch1: 'story/BLKS-904',
      branch2: 'subtask/BLKS-909',
      date: '5d ago',
    },
  },
  {
    projectName: 'Invasion',
    projectIcon: Icon,
    prData: {
      prName: 'pull/312',
      branch1: 'story/BLKS-904',
      branch2: 'subtask/BLKS-909',
      date: '5d ago',
    },
  },
  {
    projectName: 'Invasion',
    projectIcon: Icon,
    prData: {
      prName: 'pull/312',
      branch1: 'story/BLKS-904',
      branch2: 'subtask/BLKS-909',
      date: '5d ago',
    },
  },
];

const ciStatus: Array<{
  cardInfo: {
    projectName: string;
    status: 'Success' | 'Error' | 'Hold';
    Icon: React.ReactNode;
    subProjects?: Array<{
      projectName: string;
      status: 'Success' | 'Error' | 'Hold';
      Icon: React.ReactNode;
    }>;
  };
}> = [
  {
    cardInfo: {
      projectName: 'Invasion',
      status: 'Error',
      Icon,
    },
  },
  {
    cardInfo: {
      projectName: 'Blackstone',
      status: 'Success',
      Icon,
      subProjects: [
        {
          projectName: 'Blackstone App',
          status: 'Success',
          Icon,
        },
        {
          projectName: 'Blackstone API',
          status: 'Success',
          Icon,
        },
      ],
    },
  },
  {
    cardInfo: {
      projectName: 'Taxaroo',
      status: 'Hold',
      Icon,
      subProjects: [
        {
          projectName: 'Taxaroo IU',
          status: 'Success',
          Icon,
        },
        {
          projectName: 'Taxaroo API',
          status: 'Hold',
          Icon,
        },
      ],
    },
  },
];

export {
  items,
  eventItems,
  projectsItems,
  issueSummaryItems,
  issuesItems,
  cardContainer,
  blockedIssuesItems,
  openPRsData,
  ciStatus,
};
