import React from 'react';
import { PathRouteProps } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';
import Calendar from '../pages/Calendar';

export const PRIVATE_ROUTES: PathRouteProps[] = [
  {
    path: '/dashboard',
    element: <Dashboard />,
  },
  {
    path: '/calendar',
    element: <Calendar />,
  },
];
