import React, { FC } from 'react';
import { View, Text } from 'react-native';
import ProjectProgressBar from '../../atoms/ProjectProgressBar';
import { Base } from '../../../types/base';
import styles from './progress-card.style';

interface ProgressCardProps extends Base {
  totalHours: number;
  completedHours: number;
  isForClientDashboardCard?: boolean;
}

const defaultProps = {
  isForClientDashboardCard: false,
};

const ProgressCard: FC<ProgressCardProps> = ({
  totalHours,
  completedHours,
  isForClientDashboardCard,
  web,
}) => {
  const handlePercentage = () => {
    const percentage = (+completedHours / +totalHours) * 100;
    return Math.round(percentage);
  };

  return (
    <View
      style={[
        styles.container,
        {
          backgroundColor: (completedHours || 0) >= (totalHours || 0) ? '#113536' : '#262626',
          paddingBottom: isForClientDashboardCard ? 20 : 40,
          height: isForClientDashboardCard && web ? '100%' : 'auto',
        },
      ]}
    >
      <Text style={[styles.title, { marginBottom: web ? 20 : 0 }]}>Progress</Text>
      <ProjectProgressBar
        workedHours={completedHours}
        estimatedHours={totalHours}
        percentage={totalHours > 0 ? handlePercentage() : 100}
        isForClientDashboardCard={isForClientDashboardCard}
        web={web}
      />
    </View>
  );
};

ProgressCard.defaultProps = defaultProps;

export default ProgressCard;
