import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const styles = StyleSheet.create({
  fontFamily,
  container: {
    borderRadius: 4,
    padding: 20,
    width: '100%',
    backgroundColor: '#1F1F1F',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
  },
  title: {
    fontSize: 16,
    fontFamily: 'Oxygen',
    fontWeight: '400',
    color: '#ffffff',
    textTransform: 'capitalize',
    letterSpacing: 0.5,
    marginTop: 4,
  },
  hours: {
    color: '#BFBFBF',
    fontSize: 12,
    fontWeight: '400',
    fontFamily: 'Roboto',
    lineHeight: 23,
    marginTop: 5,
  },
  description: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: 14,
    color: '#BFBFBF',
  },
  priorityDiv: {
    display: 'flex',
    alignItems: 'flex-end',
    marginTop: 20,
    width: '100%',
  },
  arrowsContainer: {
    width: '100%',
    alignItems: 'center',
    top: 10,
  },
  title2: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: '700',
    color: 'white',
  },
});

export default styles;
