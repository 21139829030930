import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#171717',
    borderRadius: 20,
    padding: 20,
    flex: 1,
    height: '100%',
    width: '100%',
  },
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#8C8C8C',
    zIndex: 200,
  },
  title: {
    color: '#D9D9D9',
    fontSize: 20,
    fontWeight: '700',
    marginRight: 20,

  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  issuesAmount: {
    color: '#D9D9D9',
    fontSize: 24,
    fontWeight: '700',
    marginRight: 10,
  },
  noItemsText: {
    alignSelf: 'center',
    color: '#D9D9D9',
  },
});

export default styles;
