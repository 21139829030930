import React from 'react';
import { View, Text } from 'react-native';
import { Base } from '../../../types/base';
import Timer from '../../../assets/svg/timer.svg';
import Google from '../../../assets/svg/googleGray.svg';
import Outlook from '../../../assets/svg/outlook.svg';
import Office from '../../../assets/svg/office.svg';
import Calendar from '../../../assets/svg/calendar.svg';
import Success from '../../atoms/Success';
import CallSchedule from '../../atoms/CallSchedule';
import useResponsiveStyles from './calendar-confirmation.style';

interface CalendarConfirmationProps extends Base {
  name: string;
  callType: string;
  day: string;
  hour: string;
  customerName: string;
}

const CalendarConfirmation = ({
  web,
  name,
  callType,
  day,
  hour,
  customerName,
}: CalendarConfirmationProps) => {
  const styles = useResponsiveStyles();

  return (
    <View style={styles.container}>
      <View style={styles.successContainer}>
        <Success title="Booking confirmed">
          <Text style={styles.text}>{`You are scheduled with ${name}`}</Text>
        </Success>
        <View style={styles.callInfoContainer}>
          <Text style={styles.text2}>{`${callType} with ${customerName}`}</Text>
          <View style={styles.callLengthContainer}>
            {web ? (
              <img src={Timer} alt="timer" width={20} height={20} />
            ) : (
              <Timer width={20} height={20} />
            )}
            <Text style={styles.text}>
              {callType === 'Silver Call' ? '30m' : '60m'}
            </Text>
          </View>
          <CallSchedule day={day} hour={hour} web={web} />
        </View>
      </View>
      <View style={styles.addToYourCalendarContainer}>
        <Text style={styles.text}>Add to your calendar</Text>
        <View style={styles.iconsContainer}>
          {web ? (
            <>
              <img src={Google} alt="google" width={20} height={20} />
              <img src={Outlook} alt="google" width={20} height={20} />
              <img src={Office} alt="google" width={20} height={20} />
              <img src={Calendar} alt="google" width={20} height={20} />
            </>
          ) : (
            <>
              <Google width={20} height={20} />
              <Outlook width={20} height={20} />
              <Office width={20} height={20} />
              <Calendar width={20} height={20} />
            </>
          )}
        </View>
      </View>
    </View>
  );
};

export default CalendarConfirmation;
