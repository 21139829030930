import { StyleSheet, useWindowDimensions } from 'react-native';

const useResponsiveStyles = () => {
  const { width } = useWindowDimensions();
  const containerWidth = width < 570 ? '100%' : 400;
  const imageSize = width < 570 ? 50 : 100;
  const containerPadding = width < 570 ? 30 : 60;

  const styles = StyleSheet.create({
    container: {
      width: containerWidth,
      paddingHorizontal: containerPadding,
      paddingVertical: 20,
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      height: 'max-content',
    },
    img: {
      width: imageSize,
      height: imageSize,
    },
    dropdownContainer1: {
      width: '100%',
    },
    dropdownContainer2: {
      width: '100%',
      marginTop: 30,
    },
    imageContainer: {
      width: imageSize,
      height: imageSize,
      borderRadius: 50,
      overflow: 'hidden',
    },
    name: {
      color: '#ffffffa6',
      fontSize: 16,
      marginVertical: 20,
    },
  });

  return styles;
};

export default useResponsiveStyles;
