import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  fontFamily: {
    fontFamily: 'Oxygen-Bold',
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    borderBottomWidth: 2,
    borderBottomColor: '#ffffff',
    width: '100%',
  },
  inputContainerFocus: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    position: 'relative',
    borderBottomWidth: 2,
    borderBottomColor: '#0ABAB5',
    width: '100%',
  },
  inputContainerFilled: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    position: 'relative',
    borderBottomWidth: 2,
    borderBottomColor: '#ffffffa6',
    width: '100%',
  },
  label: {
    textAlign: 'left',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: 15,
    color: '#ffffff',
    position: 'absolute',
    top: -14,
  },
  labelFocus: {
    textAlign: 'left',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: 15,
    color: '#0ABAB5',
    position: 'absolute',
    top: -14,
  },
  input: {
    width: '100%',
    fontSize: 16,
    paddingBottom: 5,
    paddingTop: 7,
    color: '#ffffff',
    WebkitBoxShadow: '0 0 0 1000px black inset',
    WebkitTextFillColor: '#ffffff',
  },
  width90Input: {
    width: '90%',
    fontSize: 16,
    paddingBottom: 5,
    paddingTop: 7,
    color: '#ffffff',
    WebkitBoxShadow: '0 0 0 1000px black inset',
    WebkitTextFillColor: '#ffffff',
  },
  iconContainer: {
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  EyeIcon: {
    width: 25,
    height: 25,
    justifyContent: 'center',
  },
  arrowsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 14.01,
  },
  arrowIcon: {
    height: 20,
  },
  upIcon: {
    transform: [{ rotate: '180deg' }],
  },
  arrowTouchable: {
    paddingLeft: 0,
  },
  filled: {},
});

export default styles;
