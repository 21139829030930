import React, { useState } from 'react';
import { Text, TouchableHighlight, View } from 'react-native';
import styles from './baseItem.style';
import { DropdownItem } from '../Dropdown';
import HoverableItem from '../../../molecules/HoverableItem';

const lighter = 'rgba(255, 255, 255, 0.3)';
const light = 'rgba(255, 255, 255, 0.65)';

interface DropdownItemProps {
  onShowUnderlay: () => void;
  onHideUnderlay: () => void;
  setValue: (item: DropdownItem) => void;
  id: string;
  active: string;
  title: string;
  itemValue: string;
  type: string;
  ItemIcon?: React.ReactNode;
}

const defaultProps = {
  ItemIcon: undefined,
};

const BaseItem: React.FC<DropdownItemProps> = ({
  id,
  title,
  itemValue,
  active,
  onShowUnderlay,
  onHideUnderlay,
  setValue,
  type,
  ItemIcon,
}) => {
  const [isHover, setIsHover] = useState(false);

  const mouseEnter = () => {
    setIsHover(true);
  };

  const mouseLeave = () => {
    setIsHover(false);
  };
  return (
    <HoverableItem onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <TouchableHighlight
        key={id}
        underlayColor={type === 'basic' ? light : lighter}
        activeOpacity={0.6}
        style={[
          type === 'basic' ? styles.item : styles.item2,
          isHover && styles.onHoverItem,
        ]}
        onShowUnderlay={onShowUnderlay}
        onHideUnderlay={onHideUnderlay}
        onPress={() => setValue({
          id,
          title,
          active,
          itemValue,
          ItemIcon,
        })}
      >
        <View style={styles.row}>
          {ItemIcon ? (
            <View style={styles.marginRight5}>{ItemIcon}</View>
          ) : null}
          <Text
            style={[type === 'basic' && { color: active }, styles.textRight]}
          >
            {title}
          </Text>
        </View>
      </TouchableHighlight>
    </HoverableItem>
  );
};

BaseItem.defaultProps = defaultProps;

export default BaseItem;
