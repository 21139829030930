import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto-Mono',
};

const styles = StyleSheet.create({
  fontFamily,
  headerContainer: {
    width: '100%',
  },
  bgContainer: {
    width: '100%',
    height: 200,
    justifyContent: 'flex-end',
    padding: 15,
  },
  dropdownContainer: {
    position: 'absolute',
    flexDirection: 'row',
    alignItems: 'center',
  },
  avatarContainer: {
    position: 'absolute',
    bottom: -40,
    left: 50,
  },
  avatarContainerResponsive: {
    position: 'absolute',
    top: 30,
    left: 30,
  },
  avatarContainerApp: {
    position: 'absolute',
    top: 15,
    left: 15,
  },
  headerItems: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textLinkContainer: {
    marginLeft: 30,
  },
  btn: {
    fontSize: 12,
    fontWeight: '700',
    lineHeight: 22,
    textAlign: 'center',
    backgroundColor: 'transparent',
    letterSpacing: 2.4,
    fontFamily: 'Roboto',
    fontStyle: 'normal',
  },
  submenuContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 20,
  },
  submenuItem: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'center',
    paddingRight: 20,
  },
  textSow: {
    color: '#c5c5c5',
    fontSize: 16,
    fontWeight: '400',
    fontFamily: 'Roboto',
    lineHeight: 22,
  },
  arrowContainer: {
    marginLeft: 10,
  },
  nullAvatarContainer: {
    width: 85,
    height: 85,
    borderRadius: 100,
    overflow: 'hidden',
    borderWidth: 1,
    borderColor: '#0ABAB5',
    backgroundColor: '#000000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userAcronym: {
    fontSize: 25,
    color: '#0ABAB5',
  },
});

export default styles;
