const formatDate = ({
  date,
  yearFormat = 'numeric',
  monthFormat = 'short',
  dayFormat = 'numeric',
  displayHours = false,
}: {
  date: string;
  yearFormat?: 'numeric' | '2-digit';
  monthFormat?: 'numeric' | '2-digit' | 'short' | 'long' | 'narrow';
  dayFormat?: 'numeric' | '2-digit';
  displayHours: boolean;
}) => {
  let formatedDate = '';

  if (date) {
    const localDate = new Date(date || '');

    formatedDate = localDate.toLocaleString('en', {
      year: yearFormat,
      month: monthFormat,
      day: dayFormat,
    });

    if (displayHours) {
      const time = localDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

      formatedDate = `${formatedDate} at ${time}`;
    }
  }

  return formatedDate || 'NA';
};

export default formatDate;
