import React, { SVGProps } from 'react';

const Hotfix: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.21739 0C0.545045 0 0 0.545046 0 1.21739V16.7826C0 17.455 0.545046 18 1.21739 18H16.7826C17.455 18 18 17.455 18 16.7826V1.21739C18 0.545045 17.455 0 16.7826 0H1.21739ZM9.2 12.4C10.9673 12.4 12.4 10.9673 12.4 9.2C12.4 7.43269 10.9673 6 9.2 6C7.43269 6 6 7.43269 6 9.2C6 10.9673 7.43269 12.4 9.2 12.4Z"
      fill="#FF5630"
    />
  </svg>
);

export default Hotfix;
