import React from 'react';
import { View, Image, Text } from 'react-native';
import { Base } from '../../../types/base';
import Map from '../../../assets/svg/map.svg';
import Dropdown from '../../atoms/Dropdown';
import CallType from '../../atoms/CallType';
import { callTimeOptions, timezonesExample } from './callTimeOptions';
import useResponsiveStyles from './calendar-call.style';

interface CalendarCallProps extends Base {
  name: string;
  image: string;
  callType: string;
  timeZone: string;
  onChangeCallType: (e: string) => void;
  onChangeTimezone: (e: string) => void;
}

const CalendarCall = ({
  name,
  image,
  web,
  callType,
  timeZone,
  onChangeCallType,
  onChangeTimezone,
}: CalendarCallProps) => {
  const styles = useResponsiveStyles();

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        {web ? (
          <img src={image} alt="user" />
        ) : (
          <Image source={{ uri: image }} />
        )}
      </View>
      <Text style={styles.name}>{name}</Text>
      <View style={[styles.dropdownContainer1, { zIndex: 1000 }]}>
        <Dropdown
          placeholder="Select Call"
          value={callType}
          onChange={onChangeCallType}
          label=""
          type="call"
          web={web}
          items={callTimeOptions}
        />
      </View>
      {callType && <CallType callType={callType} web={web} />}
      <View style={[styles.dropdownContainer2, { zIndex: 500 }]}>
        <Dropdown
          Icon={Map}
          onChange={onChangeTimezone}
          placeholder="Select Timezone"
          label=""
          type="secondary"
          web={web}
          items={timezonesExample}
          value={timeZone}
        />
      </View>
    </View>
  );
};
export default CalendarCall;
