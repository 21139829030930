import React, { SVGProps } from 'react';

const Success: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="11"
    height="12"
    viewBox="0 0 11 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.44137 4.13635H6.89176C6.77223 4.13635 6.65856 4.19377 6.58825 4.29221L4.74606 6.8469L3.91168 5.68909C3.84137 5.59182 3.72887 5.53323 3.60817 5.53323H3.05856C2.98239 5.53323 2.93786 5.61995 2.98239 5.68206L4.44254 7.70706C4.47704 7.7552 4.52251 7.79443 4.57519 7.82149C4.62787 7.84856 4.68625 7.86267 4.74547 7.86267C4.8047 7.86267 4.86307 7.84856 4.91576 7.82149C4.96844 7.79443 5.01391 7.7552 5.0484 7.70706L7.51637 4.28518C7.56208 4.22307 7.51754 4.13635 7.44137 4.13635Z"
      fill="#58D1C9"
    />
    <path
      d="M5.25 0.75C2.35078 0.75 0 3.10078 0 6C0 8.89922 2.35078 11.25 5.25 11.25C8.14922 11.25 10.5 8.89922 10.5 6C10.5 3.10078 8.14922 0.75 5.25 0.75ZM5.25 10.3594C2.84297 10.3594 0.890625 8.40703 0.890625 6C0.890625 3.59297 2.84297 1.64062 5.25 1.64062C7.65703 1.64062 9.60938 3.59297 9.60938 6C9.60938 8.40703 7.65703 10.3594 5.25 10.3594Z"
      fill="#58D1C9"
    />
  </svg>
);

export default Success;
