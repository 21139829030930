import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiRequest from '../../../shared/services/apiRequest';
import SignUpTemplate from '../../../shared/components/templates/SignUp';
import SignUpTemplate2 from '../../../shared/components/templates/SignUp2';
import TermsAndConditionsTemplate from '../../../shared/components/templates/TermsAndConditions';
import NDATemplate from '../../../shared/components/templates/NDA';
import NDAConfirmationTemplate from '../../../shared/components/templates/NDAConfirmation';
import NDAConfifrmationClientTemplate from '../../../shared/components/templates/NDAConfirmationClient';
import styles from '../../styles/page.style';

const SignUp = () => {
  const navigate = useNavigate();
  const [arrIndex, setArrIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [acceptTermsAndConditions, setAcceptTermsAndConditions] = useState(false);
  const [acceptNDA, setAcceptNDA] = useState(false);
  const [errorMessage, setErrormessage] = useState('');
  const [form, setForm] = useState({});
  const [formCompanyValue, setFormCompanyValue] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    title: '',
    department: '',
    name: '',
    size: '',
    entityType: '',
    industry: '',
    website: '',
  });

  const redirect = () => navigate('/login');

  const changeArrIndex = () => {
    setIsLoading(true);
    setTimeout(() => {
      setArrIndex(arrIndex + 1);
      setIsLoading(false);
    }, 500);
  };

  const handleGoBack = () => {
    setArrIndex(arrIndex - 1);
    setErrormessage('');
  };

  const saveData = (values) => {
    setEmail(values.email);
    setPassword(values.password);
    changeArrIndex();
  };

  const signUp = (values) => {
    setForm({
      email,
      password,
      profile: {
        firstName: values.firstName,
        lastName: values.lastName,
        phoneNumber: values.phoneNumber,
        title: values.title,
        department: values.department,
        language: 'EN',
      },
      company: {
        name: values.name,
        size: values.size,
        entityType: values.entityType,
        industry: values.industry,
        website: values.website,
      },
    });

    setFormCompanyValue({
      firstName: values.firstName,
      lastName: values.lastName,
      phoneNumber: values.phoneNumber,
      title: values.title,
      department: values.department,
      name: values.name,
      size: values.size,
      entityType: values.entityType,
      industry: values.industry,
      website: values.website,
    });

    setIsLoading(true);
    setErrormessage('');
    setIsLoading(false);
    setArrIndex(arrIndex + 1);
  };

  const handleAcceptTermsAndConditions = () => {
    setAcceptTermsAndConditions(true);
    changeArrIndex();
  };

  const handleAcceptNDA = () => {
    setAcceptNDA(true);
    setIsLoading(true);

    apiRequest
      .post('auth/register', form)
      .then(() => {
        setErrormessage('');
        setIsLoading(false);
        changeArrIndex();
      })
      .catch((error) => {
        const reponseErr = error.response?.data;
        let message = reponseErr?.description ?? reponseErr?.message ?? error.message;
        (reponseErr?.errors ?? []).forEach((element) => {
          element.children.forEach((child) => {
            message += `\n${child.field}: ${child.message}`;
          });
        });

        setErrormessage(message);
        setIsLoading(false);
      });
  };

  const redirectToDashboard = () => {
    if (acceptTermsAndConditions && acceptNDA) navigate('/dashboard');
  };

  const componentsArr = [
    <SignUpTemplate
      web
      saveData={saveData}
      isLoading={isLoading}
      redirect={redirect}
      initialValues={{
        confirmPassword: password,
        password,
        email,
      }}
    />,
    <SignUpTemplate2
      web
      signUp={signUp}
      isLoading={isLoading}
      redirect={redirect}
      errorMessage={errorMessage}
      initialValue={formCompanyValue}
      handleGoBack={handleGoBack}
    />,
    <TermsAndConditionsTemplate
      web
      action={handleAcceptTermsAndConditions}
      isLoading={isLoading}
      handleGoBack={handleGoBack}
    />,
    <NDATemplate
      web
      action={handleAcceptNDA}
      isLoading={isLoading}
      handleGoBack={handleGoBack}
      errorMessage={errorMessage}
    />,
    <NDAConfirmationTemplate web action={changeArrIndex} isLoading={isLoading} />,
    <NDAConfifrmationClientTemplate redirect={redirectToDashboard} />,
  ];

  return <div style={styles.container}>{componentsArr[arrIndex]}</div>;
};

export default SignUp;
