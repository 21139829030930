import React, { ReactNode } from 'react';
import { useWindowDimensions } from 'react-native';

interface HoverableItemProps {
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  children: ReactNode;
}

const HoverableItem = ({ onMouseEnter, onMouseLeave, children }: HoverableItemProps) => {
  const { width } = useWindowDimensions();
  if (React.isValidElement(children)) {
    return React.cloneElement(React.Children.only(children), {
      onMouseEnter: () => {
        if (width > 576) {
          onMouseEnter();
        }
      },
      onMouseLeave: () => {
        if (width > 576) {
          onMouseLeave();
        }
      },
    });
  }
  return null;
};

export default HoverableItem;
