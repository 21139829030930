import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Oxygen-Light',
  fontSize: 12,
};

const styles = StyleSheet.create({
  fontFamily,
  container: {
    width: '100%',
    paddingVertical: 15,
    borderBottomWidth: 1,
    borderBottomColor: 'gray',
  },
  projectContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
  },
  grey: {
    color: '#A8A8A8',
  },
  white: {
    color: 'white',
  },
  ml: {
    marginLeft: 10,
  },
  category: {
    backgroundColor: '#595959',
    borderRadius: 5,
    paddingHorizontal: 5,
    flexDirection: 'row',
    alignItems: 'center',
    marginRight: 5,
  },
  dot: {
    width: 6,
    height: 6,
    borderRadius: 10,
    marginRight: 5,
  },
});

export default styles;
