import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useGoogleLogin } from 'react-google-login';
import { useDispatch } from 'react-redux';
import { Toaster, toast } from 'react-hot-toast';
import { Session, setSession } from '../../redux/slices/sessionSlice';
import apiRequest from '../../../shared/services/apiRequest';
import LoginTemplate from '../../../shared/components/templates/Login';
import styles from '../../styles/page.style';
import { Role } from '../../../shared/types/enums';

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isLoading, setIsLoading] = useState(false);
  const clientId = '391146327907-tfl2ch1l9riim7faj8fuo67j9mrvefrr.apps.googleusercontent.com';

  const validationSchema = yup.object({
    username: yup.string().email('Please enter a valid email').required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/, 'Incorrect format'),
  });

  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
    },
    validationSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      setErrorMessage('');
      apiRequest
        .post<Session>('/auth/login', values)
        .then((apiRes) => {
          setIsLoading(false);

          if (apiRes.data.user.role === Role.UNASSIGNED) {
            toast('Please contact your POC to activate your account', {
              icon: '❕',
              style: {
                fontFamily: 'Oxygen',
                borderRadius: '10px',
                background: '#333',
                color: '#fff',
              },
            });

            return;
          }

          dispatch(setSession(apiRes.data));
          const user = apiRes.data.user.role;
          if (user === Role.CLIENT) {
            navigate('/client_dashboard');
          } else {
            navigate('/dashboard');
          }
        })
        .catch((error) => {
          setIsLoading(false);

          if (error.request.status === 404 && !values.username.includes('@blackstone.studio')) {
            navigate('/sign-up');
            return;
          }

          if (error.message === 'Request failed with status code 401') {
            setErrorMessage('Invalid credentials');
          } else if (typeof error.message === 'string') {
            setErrorMessage(error.message);
          }
        });
    },
  });

  const onSuccess = (res) => {
    const tokenObj = { token: res.tokenId };

    apiRequest
      .post<Session>('auth/google', tokenObj)
      .then((apiRes) => {
        dispatch(setSession(apiRes.data));
        navigate('/dashboard');
      })
      .catch((error) => setErrorMessage(error.message));
  };

  const onFailure = (res) => {
    if (typeof res?.details === 'string') {
      setErrorMessage(res.details);
    }
  };

  const signUpRedirect = () => navigate('/sign-up');

  const forgotPasswordRedirect = () => navigate('/forgot-password');

  const { signIn } = useGoogleLogin({
    onSuccess,
    onFailure,
    clientId,
    isSignedIn: false,
    accessType: 'offline',
    scope:
      'https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/user.gender.read https://www.googleapis.com/auth/user.birthday.read https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.owned https://www.googleapis.com/auth/calendar.freebusy',
  });

  return (
    <div style={styles.container}>
      <LoginTemplate
        web
        signIn={signIn}
        errormessage={errorMessage}
        signUpRedirect={signUpRedirect}
        forgotPasswordRedirect={forgotPasswordRedirect}
        values={formik.values}
        handleChangeEmail={formik.handleChange('username')}
        handleChangePassword={formik.handleChange('password')}
        handleSubmit={formik.handleSubmit}
        formikErrors={formik.errors}
        formikTouched={formik.touched}
        isLoading={isLoading}
      />
      <Toaster position="top-center" reverseOrder={false} />
    </div>
  );
};

export default Login;
