import React from 'react';
import { View, Text } from 'react-native';
import Header from '../../molecules/Header';
import TextLink from '../../atoms/TextLink';
import SignUpForm2 from '../../organisms/SignUpForm2';
import { Base } from '../../../types/base';
import webStyles from './sign-up-2.style';

interface SignupTemplate2Props extends Base {
  signUp: (param: object) => void;
  redirect: () => void;
  isLoading: boolean;
  errorMessage: string;
  initialValue?: {
    firstName: string,
    lastName: string,
    phoneNumber: string,
    title: string,
    department: string,
    name: string,
    size: string,
    entityType: string,
    industry: string,
    website: string,
  };
  handleGoBack: () => void;
}

const defaultProps = {
  initialValue: {
    firstName: '',
    lastName: '',
    phoneNumber: '',
    title: '',
    department: '',
    name: '',
    size: '',
    entityType: '',
    industry: '',
    website: '',
  },
};

const SignUpTemplate2: React.FC<SignupTemplate2Props> = ({
  web,
  signUp,
  isLoading,
  redirect,
  errorMessage,
  initialValue,
  handleGoBack,
}: SignupTemplate2Props) => (
  <View style={webStyles.container}>
    <Header title="Sign Up" />
    <SignUpForm2 signUp={signUp} web={web} isLoading={isLoading} initialValue={initialValue} />
    {errorMessage ? <Text style={webStyles.apiCallErrorText}>{errorMessage}</Text> : null}

    <View style={{ marginBottom: 24, marginTop: 24 }}>
      <TextLink onPress={handleGoBack}>
        <Text>Back</Text>
      </TextLink>
    </View>

    <View style={{ paddingBottom: 30 }}>
      <TextLink onPress={redirect}>
        <Text>Do you have an account? Click here</Text>
      </TextLink>
    </View>
  </View>
);

SignUpTemplate2.defaultProps = defaultProps;

export default SignUpTemplate2;
