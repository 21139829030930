import React, { SVGProps } from 'react';

const Medium: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="11"
    height="8"
    viewBox="0 0 11 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 1H9.21598"
      stroke="#FAFAFA"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M1 6.26025H9.21598"
      stroke="#FAFAFA"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Medium;
