import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  infoContainer: {
    width: '100%',
    backgroundColor: '#222222',
    padding: 10,
    borderRadius: 5,
    flexDirection: 'row',
    alignItems: 'center',
  },
  itemsContainer: {
    width: '100%',
  },
});

export default styles;
