import { StyleSheet, useWindowDimensions } from 'react-native';

const useResponsiveStyles = () => {
  const { width } = useWindowDimensions();
  const flexDirection = width < 1200 ? 'column' : 'row';
  // const containerWidth = width < 570 ? '100%' : 'auto';
  const borderRadius = width < 570 ? 0 : 5;

  const styles = StyleSheet.create({
    container: {
      width: 'max-content',
      display: 'flex',
      flexDirection,
      borderRadius,
      paddingVertical: 20,
      backgroundColor: '#2D2D2D',
      height: 'max-content',
    },
  });

  return styles;
};

export default useResponsiveStyles;
