import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  circleWrap: {
    width: 65,
    height: 65,
    borderRadius: 100,
    borderWidth: 3,
    borderColor: '#333333',
    alignItems: 'center',
    justifyContent: 'center',
  },
  progress: {
    position: 'absolute',
    width: 65,
    height: 65,
    borderWidth: 3,
    alignSelf: 'center',
    borderRadius: 100,
    borderLeftColor: 'transparent',
    borderBottomColor: 'transparent',
    borderRightColor: '#0ABAB5',
    borderTopColor: '#0ABAB5',
    transform: [{ rotateZ: '-135deg' }],
  },
  secondProgress: {
    position: 'absolute',
    width: 65,
    height: 65,
    borderWidth: 3,
    alignSelf: 'center',
    borderRadius: 100,
    borderLeftColor: 'transparent',
    borderBottomColor: 'transparent',
    borderRightColor: '#0ABAB5',
    borderTopColor: '#0ABAB5',
    transform: [{ rotateZ: '45deg' }],
  },
  offsetLayer: {
    position: 'absolute',
    width: 65,
    height: 65,
    borderWidth: 3,
    borderRadius: 100,
    borderLeftColor: 'transparent',
    borderBottomColor: 'transparent',
    borderRightColor: '#333333',
    borderTopColor: '#333333',
    transform: [{ rotateZ: '-135deg' }],
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    fontFamily: 'Roboto',
    color: '#BFBFBF',
    fontWeight: '300',
    fontSize: 27,
    marginRight: 2,
  },
  text2: {
    fontFamily: 'Roboto',
    color: '#595959',
    fontWeight: '400',
    fontSize: 14,
  },
});

export default styles;
