import axios from 'axios';
import fileDownload from 'js-file-download';

const downloadPdf = (url: string) => {
  axios
    .get(url, {
      responseType: 'blob',
    })
    .then((res) => {
      fileDownload(res.data, url);
    })
    .catch((err) => console.log(err));
};

export default downloadPdf;
