import React from 'react';
import { View, Text, Image } from 'react-native';
import { Base } from '../../../types/base';
import CallType from '../../atoms/CallType';
import CallSchedule from '../../atoms/CallSchedule';
import useResponsiveStyles from './calendar-call-info.style';

interface CalendarCallInfoProps extends Base {
  image: string,
  name: string,
  callType: string;
  day: string;
  hour: string;
}

const CalendarCallInfo = ({
  name,
  image,
  callType,
  day,
  hour,
  web,
}: CalendarCallInfoProps) => {
  const styles = useResponsiveStyles();

  return (
    <View style={styles.container}>
      <View style={styles.imageContainer}>
        {web ? <img src={image} alt="user" /> : <Image source={{ uri: image }} />}
      </View>
      <Text style={styles.name}>{name}</Text>
      <View style={styles.callTypeContainer}>
        <CallType callType={callType} web={web} />
      </View>
      <CallSchedule day={day} hour={hour} web={web} />
    </View>
  );
};

export default CalendarCallInfo;
