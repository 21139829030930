import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  item: {
    padding: 10,
    paddingHorizontal: 20,
  },
  item2: {
    width: '100%',
    padding: 10,
    paddingHorizontal: 20,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  textRight: {
    color: 'rgba(255, 255, 255, 0.65)',
    textAlign: 'center',
    fontSize: 16,
  },
  onHoverItem: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  marginRight5: {
    marginRight: 5,
  },
});

export default styles;
