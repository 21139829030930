import React, { FC, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import moment from 'moment';
import { ProjectStatus } from '../../../types/ClientDashboardDto';
import styles from './staging-card.style';
import { Base } from '../../../types/base';
import ArrowsApp from '../../../assets/svg/pagination-item-ellipsis.svg';
import StatusLabel from '../../atoms/StatusLabel';
import { statusLabelText } from '../../atoms/StatusLabel/StatusLabel';
import Button from '../../atoms/Button';
import { Arrows } from '../../../assets/icons';

export interface StagingCardProps extends Base {
  title: string;
  statusLabel: statusLabelText | ProjectStatus;
  releaseNotes?: Array<string>;
  onOpenEnvironment?: () => void;
  lastUpdate: string;
  updatedAt: string;
}

const StagingCard: FC<StagingCardProps> = ({
  title,
  lastUpdate,
  statusLabel,
  releaseNotes,
  web,
  onOpenEnvironment,
  updatedAt,
}) => {
  const [areAllItemsDisplayed, setAreAllItemsDisplayed] = useState(false);

  const handleFormatTimeAgo = (date: Date | string) => {
    const timeAgo = moment(date).fromNow();

    if (timeAgo.includes('a ')) {
      const format = timeAgo.replace('a', '1');
      return format;
    }

    return timeAgo;
  };

  const handleDisplayMoreItems = () => {
    setAreAllItemsDisplayed(!areAllItemsDisplayed);
  };

  return (
    <View>
      <View style={[styles.container, { marginBottom: areAllItemsDisplayed ? 10 : 20 }]}>
        <View style={styles.header}>
          <Text style={styles.title}>{title}</Text>
          <View style={{ maxWidth: 100 }}>
            {statusLabel && <StatusLabel label={statusLabel} web={web} />}
            <Text style={styles.hours}>
              {lastUpdate ? handleFormatTimeAgo(lastUpdate) : 'There is no CI info'}
            </Text>
          </View>
        </View>
        <Button disabled={false} onPress={onOpenEnvironment}>
          open environment
        </Button>
        <View
          style={[
            styles.arrowsContainer,
            {
              transform: [{ rotate: areAllItemsDisplayed ? '180deg' : '0deg' }],
            },
          ]}
        >
          <TouchableOpacity onPress={handleDisplayMoreItems}>
            {web ? <Arrows width={35} height={35} /> : <ArrowsApp width={35} height={35} />}
          </TouchableOpacity>
        </View>
      </View>
      {areAllItemsDisplayed && (
        <View style={[styles.container, { marginBottom: 10 }]}>
          <View style={styles.header}>
            <Text style={styles.title2}>Release Notes</Text>
            <Text style={styles.hours} numberOfLines={1}>
              {handleFormatTimeAgo(updatedAt)}
            </Text>
          </View>
          {releaseNotes?.map((item, index) => (
            <Text key={item} style={[styles.description]}>
              {`${index + 1}.${item}`}
            </Text>
          ))}
        </View>
      )}
    </View>
  );
};
export default StagingCard;

StagingCard.defaultProps = {
  releaseNotes: [],
  onOpenEnvironment: () => null,
};
