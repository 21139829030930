import React, { SVGProps } from 'react';

const WebApp: React.FC<SVGProps<SVGSVGElement>> = ({ color, ...rest }) => (
  <svg
    width="17"
    height="11"
    viewBox="0 0 17 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.46429 0H16.5357C16.7925 0 17 0.207478 17 0.464286V7.66072C17 7.91752 16.7925 8.125 16.5357 8.125H11.0223V9.75H13.5179C13.6455 9.75 13.75 9.85447 13.75 9.98214V10.6786C13.75 10.7424 13.6978 10.7946 13.6339 10.7946H7.36607C7.30223 10.7946 7.25 10.7424 7.25 10.6786V9.98214C7.25 9.85447 7.35446 9.75 7.48214 9.75H9.97768V8.125H7V7.08036H15.9554V1.04464H5.04464V1.89551H4V0.464286C4 0.207478 4.20748 0 4.46429 0Z"
      fill={color || '#959595'}
    />
    <path
      d="M5.45946 2.84551H1.54054C1.2122 2.84551 0.95 3.1268 0.95 3.46694V10.3241C0.95 10.6642 1.2122 10.9455 1.54054 10.9455H5.45946C5.7878 10.9455 6.05 10.6642 6.05 10.3241V3.46694C6.05 3.1268 5.7878 2.84551 5.45946 2.84551ZM5.34189 10.2027H1.65811V3.58837H5.34189V10.2027ZM3.11216 9.34194C3.11216 9.44905 3.15239 9.55229 3.22479 9.62884C3.29728 9.70547 3.39621 9.74908 3.5 9.74908C3.60379 9.74908 3.70272 9.70547 3.77521 9.62884C3.84761 9.55229 3.88784 9.44905 3.88784 9.34194C3.88784 9.23482 3.84761 9.13158 3.77521 9.05504C3.70272 8.9784 3.60379 8.93479 3.5 8.93479C3.39621 8.93479 3.29728 8.9784 3.22479 9.05504C3.15239 9.13158 3.11216 9.23482 3.11216 9.34194Z"
      fill={color || '#959595'}
      stroke={color || '#959595'}
      strokeWidth="0.1"
    />
  </svg>
);

export default WebApp;
