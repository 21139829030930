import React, { useCallback } from 'react';
import {
  Linking, Alert, View, TouchableOpacity, Text,
} from 'react-native';
import styles from './IssueLink.style';
import { IssueLinkComponent } from './IssueLink.types';

// eslint-disable-next-line react/prop-types
export const IssueLink: IssueLinkComponent = ({ url, title }): JSX.Element => {
  const handlePress = useCallback(async () => {
    const supported = await Linking.canOpenURL(url);

    if (supported && url !== '') {
      await Linking.openURL(url);
    } else {
      Alert.alert("Cant' open the url");
    }
  }, [url]);
  return (
    <View>
      {url !== '' ? (
        <TouchableOpacity onPress={handlePress}>
          <Text style={styles.issueLink}>{title}</Text>
        </TouchableOpacity>
      ) : (
        <Text style={styles.issueText}>{title}</Text>
      )}
    </View>
  );
};
