import React from 'react';
import { View, Text, Linking } from 'react-native';
import { Base } from '../../../types/base';
import IssuePriority from '../../atoms/IssuePriority';
import IconWithText from '../../atoms/IconWithText';
import styles from './issue.style';
import IssueIcon from '../../atoms/IssueIcon';
import { IssueType } from '../IssueCard/IssueCard.types';

export interface IssueProps extends Base {
  projectIcon: string | React.ElementType;
  projectName: string;
  projectCode: string;
  type?: IssueType;
  priority?: string;
  backgroundColor?: string;
  isForTeamLeadDashboard?: boolean;
  isForOverdue?: boolean;
  status?: string;
  issueName?: string;
}
// QUITAR LOS PORCENTAJES QUE
const defaultProps = {
  isForBlockedIssues: false,
  githubUrl: undefined,
  isForTeamLeadDashboard: false,
  type: 'Story',
  priority: undefined,
  status: '',
  issueName: '',
  backgroundColor: '',
  isForOverdue: false,
  workedHours: 0,
  estimatedHours: 0,
};

const getWidthIcon = (web: boolean, isForOverdue: boolean) => {
  if (web) {
    if (isForOverdue) {
      return '16%';
    }
    return '25%';
  }
  return '50%';
};

const IssueUnassigned = ({
  projectIcon,
  projectName,
  type,
  projectCode,
  priority,
  isForOverdue,
  isForTeamLeadDashboard,
  backgroundColor,
  status,
  issueName,
  web,
}: IssueProps) => {
  const widthIcon = getWidthIcon(web, isForOverdue);

  return (
    <View
      style={[
        styles.mainContainer,
        { backgroundColor },
        isForTeamLeadDashboard && { justifyContent: 'flex-start' },
      ]}
    >
      {isForTeamLeadDashboard && status ? (
        <View style={styles.fullWidth}>
          <View style={[styles.fullWidth, styles.row]}>
            <View style={[styles.issueIconContainer, { width: web ? '5%' : '25%' }]}>
              <IssueIcon type={type} web={web} />
            </View>
            {web ? (
              <View
                style={[
                  {
                    width: '15%',
                    justifyContent: 'flex-start',
                  },
                ]}
              >
                <a
                  href={`https://blackstonestudio.atlassian.net/browse/${projectCode}`}
                  target="blank"
                >
                  <Text style={styles.issueCode}>{projectCode}</Text>
                </a>
              </View>
            ) : (
              <View
                style={[
                  {
                    width: '30%',
                    justifyContent: 'flex-start',
                  },
                ]}
              >
                <Text
                  style={styles.issueCode}
                  onPress={() => {
                    Linking.openURL(
                      `https://blackstonestudio.atlassian.net/browse/${projectCode}`,
                    );
                  }}
                >
                  {projectCode}
                </Text>
              </View>
            )}

            <View
              style={[
                {
                  /* flex: 1, */
                  width: '40%',
                  justifyContent: 'flex-start',
                },
              ]}
            >
              <Text numberOfLines={1} style={styles.issueDescription}>
                {issueName}
              </Text>
            </View>
            { web && (
              <>
                <View
                  style={[
                    styles.overflowHidden,
                    {
                      width: '30%',
                      borderRightWidth: web ? 0 : 10,
                      paddingRight: web ? 0 : 10,
                    },
                  ]}
                >
                  <IconWithText Icon={projectIcon} text={projectName} web={web} />
                </View>
                <View
                  style={[
                    styles.overflowHidden,
                    {
                      width: widthIcon,
                      borderRightWidth: web ? 0 : 10,
                      paddingRight: web ? 20 : 10,
                    },
                  ]}
                >
                  <IssuePriority web priority={priority} />
                </View>
              </>
            ) }
          </View>
        </View>
      ) : null}
    </View>
  );
};

IssueUnassigned.defaultProps = defaultProps;

export default IssueUnassigned;
