import React, { ReactNode, Dispatch, SetStateAction } from 'react';
import {
  ImageBackground, View, TouchableOpacity, Text, ScrollView,
} from 'react-native';
import { Base } from '../../../types/base';
import Avatar from '../../atoms/Avatar';
import headerClients from '../../../assets/images/header-clients.png';
import DownIcon from '../../../assets/icons/DownIcon';
import ArrowApp from '../../../assets/svg/dropDownIcon.svg';
import styles from './client-dashboard-header-style';

export type ClientDashboardTab = 'STATUS REPORTS' | 'DASHBOARD' | 'BACKLOG' | 'SoWS' | 'DOCUMENTS';

interface ClientDashboardProps extends Base {
  onSelectTab: Dispatch<SetStateAction<string>>;
  selectedTab: ClientDashboardTab;
  clientAvatarUrl: string;
  clientFirstName: string;
  clientLastName: string;
}

const Wrapper = (children: ReactNode, web?: boolean) => (web ? (
  <View style={[styles.headerItems, { justifyContent: 'flex-end' }]}>{children}</View>
) : (
  <View style={[styles.headerItems]}>
    <ScrollView
      style={{ paddingBottom: 5 }}
      horizontal
      automaticallyAdjustContentInsets
      indicatorStyle="white"
    >
      {children}
    </ScrollView>
  </View>
));

const ClientDashboardHeader = ({
  onSelectTab,
  selectedTab,
  clientAvatarUrl,
  clientFirstName,
  clientLastName,
  web,
}: ClientDashboardProps) => {
  const linksArray: ClientDashboardTab[] = [
    'STATUS REPORTS',
    'DASHBOARD',
    'BACKLOG',
    'SoWS',
    'DOCUMENTS',
  ];

  const userAcronym = () => {
    const first = clientFirstName?.charAt(0);
    const last = clientLastName?.charAt(0);

    return `${first}${last}`.toUpperCase();
  };

  return (
    <View style={styles.headerContainer}>
      <ImageBackground
        resizeMode="cover"
        style={[styles.bgContainer, { paddingBottom: 15, marginBottom: web ? 30 : 20 }]}
        imageStyle={{ borderRadius: 9 }}
        source={headerClients}
      >
        {['DASHBOARD', 'BACKLOG'].includes(selectedTab) && (
          <View style={[styles.dropdownContainer, { right: web ? 15 : 10, top: web ? 15 : 10 }]}>
            <Text style={styles.textSow}>Sow [321637469124]</Text>
            <View style={styles.arrowContainer}>
              <TouchableOpacity>
                {web ? <DownIcon width={10} /> : <ArrowApp width={12} />}
              </TouchableOpacity>
            </View>
          </View>
        )}
        {Wrapper(
          <>
            {linksArray.map((tl, key) => (
              <TouchableOpacity
                key={key}
                style={{ marginLeft: key === 0 ? 0 : 30 }}
                onPress={() => onSelectTab(tl)}
              >
                <Text
                  style={[
                    styles.btn,
                    {
                      color: tl === selectedTab ? 'white' : '#434343',
                    },
                  ]}
                >
                  {tl}
                </Text>
              </TouchableOpacity>
            ))}
          </>,
          web,
        )}
        <View style={web ? styles.avatarContainer : styles.avatarContainerApp}>
          {clientAvatarUrl ? (
            <Avatar size="xLarge" imgUrl={clientAvatarUrl} />
          ) : (
            <View style={styles.nullAvatarContainer}>
              <Text style={styles.userAcronym}>{userAcronym()}</Text>
            </View>
          )}
        </View>
      </ImageBackground>
      <View style={styles.submenuContainer}>
        {selectedTab === 'STATUS REPORTS' && (
          <View style={styles.submenuItem}>
            <Text style={styles.textSow}>Period 22W27</Text>
            <View style={styles.arrowContainer}>
              <TouchableOpacity>
                {web ? <DownIcon width={10} /> : <ArrowApp width={10} />}
              </TouchableOpacity>
            </View>
          </View>
        )}
      </View>
    </View>
  );
};

export default ClientDashboardHeader;
