import React, { useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Menu from '../../../shared/components/organisms/Menu';
import items, {
  itemsClient,
  itemsProjectManager,
  itemsTeamLead,
} from '../../../shared/components/templates/Menu/data';
import { Role } from '../../../shared/types/enums';
import { RootState } from '../../redux/store';
import styles from './test-layout.style';

interface MenuLayoutProps {
  children: React.ReactNode;
}

const MenuLayout = ({ children }: MenuLayoutProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();
  const {
    user: { role },
  } = useSelector((state: RootState) => state.session);

  const openNav = () => {
    if (document.getElementById('mySidenav') && document.getElementById('main')) {
      document.getElementById('mySidenav').style.width = '300px';
      document.getElementById('main').style.paddingLeft = '300px';
      setIsOpen(true);
    }
  };

  const closeNav = () => {
    if (document.getElementById('mySidenav') && document.getElementById('main')) {
      document.getElementById('mySidenav').style.width = '86px';
      document.getElementById('main').style.paddingLeft = '86px';
      setIsOpen(false);
    }
  };

  const handleLogout = () => {
    navigate('/login');
  };

  const getRoleItems = (userRole: Role) => {
    switch (userRole) {
      case Role.CLIENT:
        return itemsClient;
      case Role.TEAM_LEAD:
        return itemsTeamLead;
      case Role.PROJECT_MANAGEMENT:
        return itemsProjectManager;
      default:
        return items;
    }
  };

  return (
    <View style={styles.container as React.CSSProperties}>
      <div id="mySidenav" style={styles.navContainer as React.CSSProperties}>
        {
          // items={items}
          <Menu
            web
            isOpen={isOpen}
            items={getRoleItems(role)}
            isForClient={role === Role.CLIENT}
            handleCloseMenu={closeNav}
            handleOpenMenu={openNav}
            onLogout={handleLogout}
          />
        }
      </div>
      <div id="main" style={styles.mainContainer}>
        {children}
      </div>
    </View>
  );
};

export default MenuLayout;
