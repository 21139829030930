import React, { useEffect, useRef, useState } from 'react';
import {
  View, Text, ScrollView, TouchableOpacity,
} from 'react-native';
import SearchBar from '../../atoms/SearchBar/SearchBar';
import { FilterIcon } from '../../../assets/icons';
import FilterSvg from '../../../assets/svg/filter.svg';
import { Base } from '../../../types/base';
import Event from '../../molecules/Event';
import styles from './events.style';
import { EventItemsEntity } from '../../../types/TeamLeadDashboard';

export type EventItemsProps = {
  project: string;
  userName: string;
  activity: string;
  hours: string | number;
};

export interface A {
  name: string;
  icon: string;
  description: string;
  hours: number;
}

interface EventsProps extends Base {
  items: Array<EventItemsEntity>;
}

const Events = ({ web, items }: EventsProps) => {
  const ref = useRef(null);

  const [search, setSearch] = useState('');
  const [localItems, setLocalItems] = useState<EventItemsEntity[]>([]);
  const [seeItems, setSeeItems] = useState<boolean>(false);
  const [seeFilter, setSeeFilter] = useState<boolean>(false);

  // const [onClickOutside, setOnClickOutside] = useState<boolean>(false);

  useEffect(() => {
    const f = items?.filter((obj) => obj?.name?.toLowerCase().includes(search.toLowerCase()));

    if (seeItems) {
      setLocalItems(f);
    }
    if (f.length > 9 && !seeItems) {
      setLocalItems(f.slice(0, 8));
    }
  }, [search, items, seeItems]);

  /* useEffect(() => {
   const handleClickOutside = (event: { target: unknown }) => {
      if (ref.current && !ref.current.contains(event.target)) {
        setOnClickOutside(false);
      }
      document.addEventListener('click', handleClickOutside, true);

      return () => {
        document.removeEventListener('click', handleClickOutside, true);
      };
    };
  }, [onClickOutside, web]); */

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Project Activity</Text>
      <View style={styles.searchContainer}>
        <SearchBar
          search={search}
          onChange={(e) => setSearch(e)}
          onSearchPress={() => {
            throw new Error('Function not implemented');
          }}
          onErasePress={() => {
            throw new Error('Function not implemented');
          }}
          web={web}
        />
      </View>
      <View style={styles.borderBottom}>
        <Text style={{ color: 'white' }}>RECENT</Text>
        <TouchableOpacity onPress={() => setSeeFilter(!seeFilter)}>
          {web ? <FilterIcon /> : <FilterSvg width={20} height={20} />}
        </TouchableOpacity>
      </View>
      {web && seeFilter ? (
        <View style={[styles.mainContainer, { left: false || !web ? -160 : 50 }]} ref={ref}>
          <Text style={styles.text}>Filter</Text>
          <View style={styles.optionscontainer} />
        </View>
      ) : (
        // <IssuesFilterApp
        //   isOpen={seeFilter}
        //   filterOptions={[]}
        //   setFilterOptions={[]}
        //   setFilteredItems={[]}
        //   items={[]}
        //   isForBlockedIssues={false}
        //   web={web}
        //   isForTeamLeadDashboard={false}
        // />
        <View />
      )}
      <ScrollView style={{ maxHeight: web ? '100%' : '85%' }} indicatorStyle="white">
        {localItems
          && localItems.map((item, index) => (
            <Event
              key={index}
              project={item.name}
              userName=""
              activity={item.description}
              hours={item.hours}
            />
          ))}
        {items.length > 9 && (
          <TouchableOpacity onPress={() => setSeeItems(!seeItems)}>
            <Text style={styles.linkButton}>
              {!seeItems ? 'View all notifications' : 'See less...'}
            </Text>
          </TouchableOpacity>
        )}
      </ScrollView>
    </View>
  );
};

export default Events;

Events.defaultProps = {
  title: undefined,
};
