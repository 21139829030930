import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: 87,
    height: 75,
    borderRadius: 15,
    alignItems: 'center',
    justifyContent: 'center',
  },
  amount: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 20,
    letterSpacing: 0.24,
    lineHeight: 22,
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 12,
    letterSpacing: 0.24,
    lineHeight: 22,
  },
});

export default styles;
