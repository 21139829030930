import React from 'react';
import { TouchableWithoutFeedback, Keyboard, View } from 'react-native';
import { Base } from '../../../types/base';

interface DismissKeyboardProps extends Base {
  children: React.ReactNode;
}

const DismissKeyboard: React.FC<DismissKeyboardProps> = ({ children, web }) => (web ? (
  <View>
    {children}
  </View>
) : (
  <TouchableWithoutFeedback onPress={Keyboard.dismiss}>
    {children}
  </TouchableWithoutFeedback>
));

DismissKeyboard.defaultProps = {
  web: false,
};

export default DismissKeyboard;
