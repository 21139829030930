import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const styles = StyleSheet.create({
  fontFamily,
  mainContainer: {
    width: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#121212',
  },
  componentContainer: {
    width: '100%',
    marginBottom: 30,
  },
  subContainer2: {
    width: '20%',
    minHeight: '100%',
    maxHeight: '100%',
  },
  issuesContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  issuesSubContainer1: {
    width: '60%',
    paddingRight: 15,
  },
  issuesSubContainer2: {
    width: '40%',
    paddingLeft: 15,
  },
  textTimer: {
    color: 'rgba(255, 255, 255, 1)',
  },
  timerSection: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 30,
  },
});

export default styles;
