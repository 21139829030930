import React from 'react';
import { View, Text } from 'react-native';
import { EventItemsProps } from '../../organisms/Events/Events';
import styles from './event.style';

interface Events {
  [key: string]: {
    value: string;
    color: string;
  };
}

const activityColors = {
  SCOPE_OF_CHANGE: {
    value: 'Scope of change',
    color: '#948BF4',
  },
  KICK_OFF_CALL: {
    value: 'Kick off Call',
    color: '#29ABE2',
  },
  DEMO_CALL: {
    value: 'Demo Call',
    color: '#0ABAB5',
  },
  ONBOARDING: {
    value: 'Onboarding',
    color: '#F0CC28',
  },
  BLOCKER_CALL: {
    value: 'Blocker Call',
    color: '#F93C6F',
  },
} as Events;

const Event = ({
  hours, project, userName, activity,
}: EventItemsProps) => (
  <View style={styles.container}>
    <View style={styles.projectContainer}>
      <Text style={styles.white}>{project}</Text>
      <Text style={[styles.grey, styles.ml]}>{hours}</Text>
    </View>
    <View style={styles.projectContainer}>
      <Text style={styles.grey}>{userName}</Text>
      <View style={[styles.category, styles.ml]}>
        <View style={[styles.dot, { backgroundColor: activityColors[activity]?.color }]} />
        <Text style={[styles.white]}>{activityColors[activity]?.value}</Text>
      </View>
    </View>
  </View>
);

export default Event;
