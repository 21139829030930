import Scope29ABE2 from '../../../assets/svg/ScopeColors/scope#29ABE2.svg';
import ScopeF0CC28 from '../../../assets/svg/ScopeColors/scope#F0CC28.svg';
import ScopeF93C6F from '../../../assets/svg/ScopeColors/scope#F93C6F.svg';

import Schedule0ABAB5 from '../../../assets/svg/ScheduleColors/schedule#0ABAB5.svg';
import Schedule29ABE2 from '../../../assets/svg/ScheduleColors/schedule#29ABE2.svg';
import ScheduleF0CC28 from '../../../assets/svg/ScheduleColors/schedule#F0CC28.svg';
import ScheduleF93C6F from '../../../assets/svg/ScheduleColors/schedule#F93C6F.svg';

import Budget0ABAB5 from '../../../assets/svg/BudgetColors/budget#0ABAB5.svg';
import Budget29ABE2 from '../../../assets/svg/BudgetColors/budget#29ABE2.svg';
import BudgetF0CC28 from '../../../assets/svg/BudgetColors/budget#F0CC28.svg';
import BudgetF93C6F from '../../../assets/svg/BudgetColors/budget#F93C6F.svg';

const handleScheduleBudgetPercentageColor = (dataInfo: string | number) => {
  if (+dataInfo <= 5) {
    return '#0ABAB5';
  }
  if (+dataInfo > 5 && +dataInfo <= 12) {
    return '#29ABE2';
  }
  if (+dataInfo > 12 && +dataInfo <= 20) {
    return '#F0CC28';
  }
  return '#F93C6F';
};

const handleScopeColor = (dataInfo: string | number) => {
  if (+dataInfo <= 4) {
    return '#29ABE2';
  }
  if (+dataInfo >= 5 && +dataInfo <= 10) {
    return '#F0CC28';
  }
  if (+dataInfo > 10) {
    return '#F93C6F';
  }
  return '#29ABE2';
};

const IconAppScope = (dataInfo: string | number) => {
  const color = handleScopeColor(dataInfo);
  if (color === '#29ABE2') {
    return Scope29ABE2;
  } if (color === '#F0CC28') {
    return ScopeF0CC28;
  } if (color === '#F93C6F') {
    return ScopeF93C6F;
  }
  return Scope29ABE2;
};
const IconScheduleApp = (dataInfo: string | number) => {
  const color = handleScheduleBudgetPercentageColor(dataInfo);
  if (color === '#0ABAB5') {
    return Schedule0ABAB5;
  } if (color === '#29ABE2') {
    return Schedule29ABE2;
  }
  if (color === '#F0CC28') {
    return ScheduleF0CC28;
  }
  return ScheduleF93C6F;
};
const IconBudgetApp = (dataInfo: string | number) => {
  const color = handleScheduleBudgetPercentageColor(dataInfo);
  if (color === '#0ABAB5') {
    return Budget0ABAB5;
  } if (color === '#29ABE2') {
    return Budget29ABE2;
  }
  if (color === '#F0CC28') {
    return BudgetF0CC28;
  }
  return BudgetF93C6F;
};

export {
  handleScheduleBudgetPercentageColor,
  handleScopeColor,
  IconAppScope,
  IconBudgetApp,
  IconScheduleApp,
};
