import axios, { AxiosRequestConfig } from 'axios';
import { resetSession } from '../../src/redux/slices/sessionSlice';
import store from '../../src/redux/store';

const Api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'https://api-dev.blackstone.studio',
});

Api.interceptors.request.use(
  async (config: AxiosRequestConfig) => {
    const { accessToken } = store.getState().session;
    if (!accessToken) {
      return config;
    }
    const configSetup = {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    };
    config.headers = configSetup;
    return config;
  },
  (error: unknown) => Promise.reject(error),
);

Api.interceptors.response.use((response) => response, (error) => {
  if (!error?.response?.status) {
    return Promise.reject(error);
  }

  if (error.response.status === 401) {
    store.dispatch(resetSession());
  }
  return Promise.reject(error);
});

export default Api;
