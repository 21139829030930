import React from 'react';
import { Text, View } from 'react-native';
import ScrollComponent from '../../atoms/ScrollComponent';
import { Base } from '../../../types/base';
import styles from './project-card.style';
import DashboardCard from '../../atoms/DashboardCard';

export interface ProjectCardProps extends Base {
  name?: string;
  description?: string;
  startDate?: string;
  endDate?: string;
}

const Description = ({ description }: { description: string }) => (
  <View style={{ width: '100%' }}>
    <Text style={styles.description}>{description}</Text>
  </View>
);

const ProjectCard = ({
  name, description, startDate, endDate, web,
}: ProjectCardProps) => (
  <DashboardCard web={web} title="PROJECT CARD">
    <Text style={styles.subtitleCard}>PROJECT NAME</Text>
    <Text style={[styles.textCard, { marginBottom: web ? 24 : 5 }]}>{name}</Text>
    <Text style={styles.subtitleCard}>DESCRIPTION</Text>
    <View style={[styles.descContainer, { backgroundColor: web ? 'black' : '#0c0c0c' }]}>
      {description?.length > 100 || web ? (
        <ScrollComponent web={web}>
          <Description description={description} />
        </ScrollComponent>
      ) : (
        <Description description={description} />
      )}
    </View>
    <View style={styles.timingInfo}>
      <View style={styles.datesContainer}>
        <Text style={styles.subtitleCard}>DATE START</Text>
        <Text style={styles.textCardDates}>{startDate}</Text>
      </View>
      <View style={styles.datesContainer}>
        <Text style={styles.subtitleCard}>END DATE</Text>
        <Text style={styles.textCardDates}>{endDate}</Text>
      </View>
    </View>
  </DashboardCard>
);

ProjectCard.defaultProps = {
  name: '',
  description: '',
  startDate: '',
  endDate: '',
};

export default ProjectCard;
