import React from 'react';
import { View } from 'react-native';
import TimeOption from '../../atoms/TimeOption';
import styles from './time-options.style';

interface TimeOptionsProps {
  items: Array<{ hour: string }>;
  onChange: (e: string) => void;
  value: string;
}

const TimeOptions = ({ items, onChange, value }: TimeOptionsProps) => (
  <View style={styles.mainContainer}>
    {items.map((item, index) => (
      <TimeOption
        key={index}
        hour={item.hour}
        onChange={onChange}
        value={value}
      />
    ))}
  </View>
);

export default TimeOptions;
