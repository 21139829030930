import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  labelsContainer: {
    backgroundColor: 'transparent',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  label: {
    fontSize: 12,
    fontWeight: '700',
    color: '#8C8C8C',
  },
  detailsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemsContainer: {
    width: '100%',
  },
  itemContainer: {
    marginBottom: 10,
  },
  arrowsContainer: {
    width: '100%',
    alignItems: 'center',
  },
});

export default styles;
