import React from 'react';
import { View, Text } from 'react-native';

import Lowest from '../../../assets/icons/Lowest';
import Low from '../../../assets/icons/Low';
import Medium from '../../../assets/icons/Medium';
import High from '../../../assets/icons/High';
import Highest from '../../../assets/icons/Highest';

import LowestApp from '../../../assets/svg/lowest.svg';
import LowApp from '../../../assets/svg/low.svg';
import MediumApp from '../../../assets/svg/medium.svg';
import HighApp from '../../../assets/svg/high.svg';
import HighestApp from '../../../assets/svg/highest.svg';

import styles from './issue-priority.style';
import { Base } from '../../../types/base';

interface IssuePriorityProps extends Base {
  priority: string;
}

const IssuePriority = ({ priority, web }: IssuePriorityProps) => {
  const handlePriorityIcon = (category: string) => {
    if (category === 'Lowest') { return web ? <Lowest /> : <LowestApp />; }
    if (category === 'Low') { return web ? <Low /> : <LowApp />; }
    if (category === 'Medium') { return web ? <Medium /> : <MediumApp />; }
    if (category === 'High') { return web ? <High /> : <HighApp />; }
    if (category === 'Highest') { return web ? <Highest /> : <HighestApp />; }
    return null;
  };

  const handlePriorityColor = (category: string) => {
    if (category === 'Lowest') { return '#4382F7'; }
    if (category === 'Low') { return '#2A63F6'; }
    if (category === 'Medium') { return '#F3AF3D'; }
    if (category === 'High') { return '#EC6240'; }
    if (category === 'Highest') { return '#E7431B'; }
    return 'transparent';
  };

  return (
    <View style={[styles.container, { backgroundColor: handlePriorityColor(priority) }]}>
      <View style={styles.iconContainer}>{handlePriorityIcon(priority)}</View>
      <Text style={styles.text}>{priority}</Text>
    </View>
  );
};

export default IssuePriority;
