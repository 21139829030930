import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 20,
    lineHeight: 22,
    letterSpacing: 1,
    fontWeight: '700',
    color: '#D9D9D9',
  },
  dividerLine: {
    width: '100%',
    padding: 0,
    borderTopWidth: 1,
    borderTopColor: '#8C8C8C',
    marginBottom: 4.91,
  },
});

export default styles;
