import React, { SVGProps } from 'react';

const Minus: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#ffffff"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M0 10h24v4h-24z" />
  </svg>
);

export default Minus;
