import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Oxygen-Regular',
};

const styles = StyleSheet.create({
  fontFamily,
  menuContainer: {
    height: '100%',
    justifyContent: 'space-between',
  },
  logoContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 49,
    paddingTop: 70.17,
  },
  menuSectionContainer: {
    flex: 1,
    overflow: 'hidden',
  },
});

export default styles;
