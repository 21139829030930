import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: '#222222',
    padding: 10,
    borderRadius: 5,
  },
  row: {
    flexDirection: 'row',
  },
  header: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  titleContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  icon: {
    marginRight: 10,
  },
  image: {
    width: 25,
    height: 25,
    borderRadius: 100,
  },
});

export default styles;
