import React from 'react';
import { View } from 'react-native';
import { Base } from '../../../types/base';
import Header from '../../molecules/Header';
import TextBoxWithButton from '../../molecules/TextBoxWithButton';
import styles from './nda.style';

interface NDAProps extends Base {
  action: () => void;
  isLoading: boolean;
  handleGoBack: () => void;
  errorMessage: string;
}

const NDATemplate = ({
  action,
  web,
  isLoading,
  handleGoBack,
  errorMessage,
}: NDAProps) => (
  <View style={styles.container}>
    <Header title="Mutual NDA" />
    <TextBoxWithButton
      buttonText="sign & continue"
      action={action}
      web={web}
      isLoading={isLoading}
      handleGoBack={handleGoBack}
      errorMessage={errorMessage}
    >
      Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rem obcaecati
      ratione repellat excepturi culpa. Cumque, deleniti laborum quidem
      doloribus corrupti quibusdam, necessitatibus laudantium rerum voluptatem
      dignissimos quia, impedit excepturi ullam. Lorem ipsum dolor sit amet
      consectetur, adipisicing elit. Assumenda amet eaque hic. Placeat,
      praesentium id. Sequi nihil quia eos, vitae reprehenderit voluptatem
      repellendus quos molestias id nostrum fuga dolor. Dolorem. Lorem ipsum
      dolor sit amet consectetur adipisicing elit. Perspiciatis perferendis
      voluptate voluptatibus porro eum omnis illo doloribus, amet, voluptatum
      ducimus sint consectetur nam culpa sit, asperiores repellat adipisci
      mollitia! Deleniti. Lorem ipsum dolor sit amet consectetur adipisicing
      elit. Itaque aspernatur nesciunt laborum officiis vero deleniti totam
      tempora aliquid eveniet doloribus ratione odit illo, soluta at, culpa
      architecto quidem dolorum corrupti. Lorem ipsum dolor sit amet
      consectetur, adipisicing elit. Magni accusamus voluptatibus labore eum
      beatae voluptas enim? Quod voluptates dolore reprehenderit veniam beatae
      autem quisquam eveniet distinctio vel. Cupiditate, est officia? Lorem
      ipsum dolor sit amet consectetur adipisicing elit. Possimus expedita quo
      cum voluptatibus quaerat magnam in maxime, animi, sequi rerum facere
      impedit molestiae odio excepturi voluptate incidunt, sit placeat ad. Lorem
      ipsum, dolor sit amet consectetur adipisicing elit. Odio cumque facilis
      natus praesentium commodi distinctio quidem, deleniti corrupti asperiores
      labore sed enim delectus assumenda, laboriosam magni ipsam! A, accusantium
      harum!
    </TextBoxWithButton>
  </View>
);

export default NDATemplate;
