import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  labelContainer: {
    borderWidth: 1,
    borderRadius: 2,
    padding: 5,
    width: 100,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
  },
  labelText: {
    fontSize: 12,
    lineHeight: 20,
  },
  iconContainer: {
    marginRight: 5,
  },
});

export default styles;
