import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Oxygen-Regular',
};

const styles = StyleSheet.create({
  fontFamily,
  container: {
    width: '100%',
  },
  navContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
  },
  yearMonthContainer: {
    flexDirection: 'row',
  },
  month: {
    fontSize: 24,
    color: '#ffffffa6',
    marginRight: 5,
  },
  daysContainer: {
    width: '100%',
  },
  daysOfTheWeekContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  dayContainer: {
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  selectedDayContainer: {
    width: 50,
    height: 50,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#0ABAB5',
    borderRadius: 40,
  },
  day: {
    fontSize: 16,
    fontWeight: '400',
    color: '#ffffffa6',
  },
  prevNextMonthDay: {
    fontSize: 16,
    fontWeight: '300',
    color: '#ffffff33',
  },
  noAvailableContainer: {
    width: 200,
    position: 'absolute',
    zIndex: 100,
    backgroundColor: 'gray',
  },
});

export default styles;
