import React, { useState } from 'react';
import { Dimensions, TouchableOpacity, View } from 'react-native';
import { EventItemsEntity } from '../../../types/TeamLeadDashboard';
import { Base } from '../../../types/base';
import Events from '../../organisms/Events';
import styles from './dashboard-layout.style';
import { Arrows } from '../../../assets/icons';
import ArrowsApp from '../../../assets/svg/pagination-item-ellipsis.svg';
import ScrollComponent from '../../atoms/ScrollComponent';

interface Props extends Base {
  eventsItems: EventItemsEntity[];
  children: React.ReactNode;
}

const DashboardLayout = ({ web, eventsItems, children }: Props) => {
  const { width } = Dimensions.get('window');
  // if window width is less than 1000 it will be start closed
  const [showProjectActivity, setShowProjectActivity] = useState<boolean>(!(width < 1000));

  return (
    <View style={styles.mainContainer}>
      <View
        style={[
          styles.subContainer1,
          { zIndex: 1000, width: !showProjectActivity ? '97%' : '80%' },
        ]}
      >
        <ScrollComponent web={web}>{children}</ScrollComponent>
      </View>
      <View
        style={[styles.subContainer2, { zIndex: 100, width: !showProjectActivity ? '3%' : '20%' }]}
      >
        <TouchableOpacity onPress={() => setShowProjectActivity(!showProjectActivity)}>
          <View>
            <View style={{ transform: [{ rotate: showProjectActivity ? '-90deg' : '90deg' }] }}>
              {web ? <Arrows width={30} height={30} /> : <ArrowsApp width={30} height={30} />}
            </View>
          </View>
        </TouchableOpacity>
        {showProjectActivity && <Events items={eventsItems} web={web} />}
      </View>
    </View>
  );
};
export default DashboardLayout;
