import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const textFont = {
  fontFamily: 'Oxygen-Bold',
};

const webStyles = StyleSheet.create({
  fontFamily,
  container: {
    flex: 1,
    alignItems: 'center',
  },
  subTitle: {
    fontSize: 20,
    textAlign: 'center',
    fontWeight: '500',
    color: '#FFFFFF',
  },
  textContainer: {
    width: '25%',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 30,
  },
  text: {
    textFont,
    fontWeight: '400',
    fontSize: 14,
    color: '#FFFFFF',
    textAlign: 'center',
    marginTop: 20,
  },
});

export default webStyles;
