import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'row',
  },
  search: {
    paddingVertical: 10,
    paddingHorizontal: 12,
    flexDirection: 'row',
    backgroundColor: '#262626',
    borderRadius: 8,
    width: '100%',
    borderColor: '#ffffff',
    borderWidth: 1,
    alignItems: 'center',
  },
  searchMini: {
    display: 'flex',
    flexDirection: 'row',
    paddingVertical: 10,
    paddingLeft: 10,
    justifyContent: 'space-between',
    borderBottomWidth: 1,
    borderBottomColor: '#8C8C8C',
  },
  input: {
    fontSize: 14,
    flex: 1,
    color: 'white',
    paddingRight: 5,
    width: '100%',
  },
  erasePress: { marginRight: 15 },
});

export default styles;
