import React from 'react';
import { Text, View } from 'react-native';
import ProjectLabel from '../../atoms/ProjectLabel';
import StatusReport from '../../templates/StatusReport';
import styles from './status-report-row.style';

interface Props extends StatusReport {
  web: boolean;
}

export const StatusReportRow = ({
  web,
  period,
  sowsId,
  startDate,
  endDate,
  time,
  currentWeekStatus,
}: Props) => {
  // TODO: implemnent
  const handlePercentage = (x: number) => x >= 90;

  return (
    <View style={[styles.mainContainer, styles.fullWidth]}>
      {web ? (
        <View style={[styles.row, styles.fullWidth]}>
          <View style={styles.col1}>
            <Text style={[styles.bold, styles.color1]}>{period}</Text>
          </View>

          <View style={styles.col2}>
            <Text style={[styles.bold, styles.color1]}>{`[${sowsId}]`}</Text>
          </View>

          <View style={styles.col1}>
            <Text style={[styles.light, styles.color2]}>{startDate}</Text>
          </View>

          <View style={styles.col1}>
            <Text style={[styles.light, styles.color2]}>{endDate}</Text>
          </View>

          <View style={styles.col1}>
            <Text style={[styles.light, styles.color2]}>{time}</Text>
          </View>

          <View style={[styles.col1, styles.paddingRight10]}>
            <ProjectLabel
              label="Schedule"
              isForSchedule
              isDanger={handlePercentage(currentWeekStatus.schedule)}
              data={currentWeekStatus.schedule}
              web={web}
            />
          </View>

          <View style={[styles.col1, styles.paddingRight10]}>
            <ProjectLabel
              label="Budget"
              isForBudget
              isDanger={handlePercentage(currentWeekStatus.budget)}
              data={currentWeekStatus.budget}
              web={web}
            />
          </View>

          <View style={[styles.col1]}>
            <ProjectLabel
              label="Scope"
              isForScope
              isDanger={handlePercentage(currentWeekStatus.scope)}
              data={currentWeekStatus.scope}
              web={web}
            />
          </View>
        </View>
      ) : (
        <>
          <View style={[styles.row, styles.col1]}>
            <View style={[styles.row, styles.col1]}>
              <Text style={[styles.bold, styles.color1]}>{period}</Text>
            </View>
            <View style={[styles.row, styles.col1]}>
              <Text style={[styles.bold, styles.color1]}>{`[${sowsId}]`}</Text>
            </View>
          </View>
          <View style={[styles.row, styles.col1, styles.marginTop20]}>
            <View style={[styles.row, styles.col2]}>
              <Text style={[styles.light, styles.color2]}>{`Start: ${startDate}`}</Text>
            </View>
            <View style={[styles.row, styles.col2]}>
              <Text style={[styles.light, styles.color2]}>{`Delivery: ${endDate}`}</Text>
            </View>
            <View style={[styles.row, styles.col1]}>
              <Text style={[styles.light, styles.color2]}>{`Time: ${time}`}</Text>
            </View>
          </View>
          <View style={[styles.row, styles.col1, styles.marginTop20]}>
            <View style={[styles.row, styles.col2]}>
              <ProjectLabel
                label="Schedule"
                isForSchedule
                isDanger={handlePercentage(currentWeekStatus.schedule)}
                data={currentWeekStatus.schedule}
                web={web}
              />
            </View>
            <View style={[styles.row, styles.col2]}>
              <ProjectLabel
                label="Budget"
                isForBudget
                isDanger={handlePercentage(currentWeekStatus.budget)}
                data={currentWeekStatus.budget}
                web={web}
              />
            </View>
            <View style={[styles.row, styles.col1, styles.alignItemsRight]}>
              <ProjectLabel
                label="Scope"
                isForScope
                isDanger={handlePercentage(currentWeekStatus.scope)}
                data={currentWeekStatus.scope}
                web={web}
              />
            </View>
          </View>
        </>
      )}
    </View>
  );
};
