import React from 'react';
import { View } from 'react-native';
import { Base } from '../../../types/base';
import IssuesGraph from '../../organisms/IssuesGraph';
import Projects from '../../organisms/Projects';
import Issues from '../../organisms/Issues';
import { ProjectProps } from '../../organisms/ProjectCard/ProjectCard';
import { IssueProps } from '../../molecules/Issue/Issue';
import DashboardHeader from '../../organisms/DashboardHeader';
import DashboardSectionDivider from '../../atoms/DashboardSectionDivider';
import styles from './dashboard.style';
import './dashboard.styles.css';
import { EventItemsEntity } from '../../../types/TeamLeadDashboard';
import DashboardLayout from '../dashboardLayout/DashboardLayout';

type IssueSummaryItemsProps = {
  status: string;
  total: number;
  sort: number;
};
interface DashboardProps extends Base {
  eventsItems: Array<EventItemsEntity>;
  issuesItems: Array<IssueProps>;
  blockedIssuesItems: Array<IssueProps>;
  projectsItems: Array<ProjectProps>;
  issueSummaryItems: Array<IssueSummaryItemsProps>;
  pastIssuesDueTodayItems: Array<IssueProps>;
  upcomingProjects: number;
  // onReload?: () => void;
  // reload?: number;
  // timeout?: number;
}

const DashboardTemplate = ({
  web,
  eventsItems,
  issuesItems,
  blockedIssuesItems,
  projectsItems,
  issueSummaryItems,
  pastIssuesDueTodayItems,
  upcomingProjects = 0,
}: DashboardProps) => (
  <DashboardLayout eventsItems={eventsItems} web={web}>
    <View style={styles.componentContainer}>
      <DashboardHeader projects={projectsItems.length} upcomingProjects={upcomingProjects} />
    </View>
    {/* <View style={styles.timerSection}>
            <Button variant="outlined" onPress={onReload}>
              Reload
            </Button>
            <Timer timeout={timeout} reload={reload} />
          </View> */}
    <DashboardSectionDivider
      title="PROJECTS"
      web={web}
      tooltipContent="Measured by Sprint"
      iconType="Timer"
    />
    <View style={styles.componentContainer}>
      <Projects items={projectsItems} web={web} />
    </View>
    <DashboardSectionDivider
      title="ISSUES"
      web={web}
      tooltipContent="Measured by Sprint"
      iconType="Timer"
    />
    <View style={styles.componentContainer}>
      <IssuesGraph data={issueSummaryItems} web={web} />
    </View>
    <View style={styles.issuesContainer}>
      <View style={[styles.issuesSubContainer1, { zIndex: 999 }]}>
        <View style={{ zIndex: 1 }}>
          <Issues title="ISSUES DUE TODAY" items={issuesItems} web={web} />
        </View>

        <View style={{ marginTop: 30 }}>
          <Issues
            title="PAST ISSUES DUE TODAY"
            items={pastIssuesDueTodayItems}
            web={web}
            isForDueToday
          />
        </View>
      </View>
      <View style={[styles.issuesSubContainer2, { zIndex: 998 }]}>
        <Issues title="BLOCKED ISSUES" items={blockedIssuesItems} web={web} isForBlockedIssues />
      </View>
    </View>
  </DashboardLayout>
);

export default DashboardTemplate;
