import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  marginTop20: {
    marginTop: 20,
  },
  text: {
    fontFamily: 'Roboto Mono',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: 22,
    letterSpacing: 0.06,
    textTransform: 'uppercase',
  },
  default: {
    color: '#434343',
  },
  active: {
    color: '#D9D9D9',
  },
  divider: {
    fontFamily: 'Roboto Mono',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 20,
    lineHeight: 22,
    letterSpacing: 0.06,
    textTransform: 'uppercase',
    paddingHorizontal: 4,
    color: '#434343',
  },
  bottomLine: {
    width: '100%',
    padding: 0,
    borderTopWidth: 1,
    borderTopColor: '#8C8C8C',

  },
});

export default styles;
