/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useEffect, useState } from 'react';
import { ClientsDashboardDto } from '../types/ClientDashboardDto';
import store from '../../src/redux/store';
import { Role } from '../types/enums';
import apiRequest from '../services/apiRequest';
import { DashboardTeamLeadState, OperationsState } from '../types/TeamLeadDashboard';
import { getSocket, removeSocket } from './socket';

const dashboardEvents: Record<Role, string> = {
  TEAM_LEAD: 'TLDashboard',
  PROJECT_MANAGEMENT: 'operationsDashboard',
  CLIENT: 'clientDashboard',
  GUEST: '',
  ADMIN: '',
  HR_MANAGER: '',
  DEVELOPER: '',
  SYSTEM: '',
};

export const useDashboard = (role: Role, onUpdate: () => void) => {
  const [dashboard, setDashboard] = useState<
    DashboardTeamLeadState | OperationsState | ClientsDashboardDto
  >();
  const [isLoading, setIsLoading] = useState(false);
  const [isConnected, setIsConnected] = useState(false);

  useEffect(() => {
    const { accessToken } = store.getState().session;
    const socket = getSocket(accessToken!);

    socket.on('connect', () => {
      setIsConnected(true);
    });

    socket.on('disconnect', () => {
      setIsConnected(false);
    });

    socket.emit('join'); // connects to respective room

    socket.on(dashboardEvents[role], (data) => {
      setDashboard(data);
      if (isLoading) {
        setIsLoading(false);
      }
      onUpdate();
    });

    return () => {
      socket.off('connect');
      socket.off('disconnect');
      socket.off(dashboardEvents[role]);
      socket.close();
      removeSocket();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [role]);

  useEffect(() => {
    const getDashboardData = async () => {
      setIsLoading(true);
      try {
        if (role === Role.PROJECT_MANAGEMENT) {
          const res = await apiRequest.get<OperationsState>(
            '/dashboard/operations',
          );
          setDashboard(res.data);
        }

        if (role === Role.TEAM_LEAD) {
          const res = await apiRequest.get<DashboardTeamLeadState>(
            '/dashboard/team-leader-dashboard',
          );
          setDashboard(res.data);
        }

        if (role === Role.CLIENT) {
          const res = await apiRequest.get<OperationsState>('/dashboard/client');
          setDashboard(res.data);
        }
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };
    getDashboardData();
  }, [role]);

  return {
    dashboard,
    setDashboard,
    isLoading,
    isConnected,
  };
};
