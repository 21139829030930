import React, { SVGProps } from 'react';

const Highest: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 9.01344L5.54295 6.3833L10.0859 9.01344"
      stroke="#FAFAFA"
      strokeOpacity="0.7"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M1 4.63014L5.54295 2L10.0859 4.63014"
      stroke="#FAFAFA"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Highest;
