import React from 'react';
import {
  View,
  Modal,
  Text,
  TouchableOpacity,
  ScrollView,
} from 'react-native';
import { Base } from '../../../types/base';
import CloseApp from '../../../assets/svg/close.svg';
import styles from './modal-app.style';

interface ModalProps extends Base {
  isOpen: boolean;
  closeModal: () => void;
  body: React.ReactNode,
  header?: string;
  animation?: 'slide' | 'fade' | 'none';
  showCloseIcon?: boolean;
  fullWidth?: boolean;
}

const defaultProps = {
  header: '',
  animation: 'slide',
  showCloseIcon: false,
  fullWidth: false,
};

const ModalApp = ({
  isOpen,
  closeModal,
  body,
  header,
  animation,
  showCloseIcon,
  fullWidth,
}: ModalProps) => (
  <View>
    <Modal visible={isOpen} transparent animationType={animation}>
      <TouchableOpacity onPress={closeModal} style={styles.overlay} />
      <View style={[styles.mainContainer, fullWidth && { width: '100%' }]}>
        {header ? (
          <View style={styles.headerContainer}>
            <Text style={styles.header}>{header}</Text>
          </View>
        ) : null}
        <ScrollView indicatorStyle="white">{body}</ScrollView>
        {showCloseIcon ? (
          <View style={styles.closeIconContainer}>
            <TouchableOpacity onPress={closeModal}>
              <CloseApp />
            </TouchableOpacity>
          </View>
        ) : null}
      </View>
    </Modal>
  </View>
);

ModalApp.defaultProps = defaultProps;

export default ModalApp;
