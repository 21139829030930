import React, { SVGProps } from 'react';

const Story: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.21739 0C0.545045 0 0 0.545046 0 1.21739V16.7826C0 17.455 0.545046 18 1.21739 18H16.7826C17.455 18 18 17.455 18 16.7826V1.21739C18 0.545045 17.455 0 16.7826 0H1.21739ZM6.05909 13.835L8.5496 11.0954C8.79111 10.8298 9.20889 10.8298 9.4504 11.0954L11.9409 13.835C12.3152 14.2467 13 13.9819 13 13.4255V4.6087C13 4.27252 12.7275 4 12.3913 4H5.6087C5.27252 4 5 4.27252 5 4.6087V13.4255C5 13.9819 5.68484 14.2467 6.05909 13.835Z"
      fill="#36B37E"
    />
  </svg>
);

export default Story;
