import React from 'react';
import { View, Text, useWindowDimensions } from 'react-native';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Spinner from '../../../assets/svg/spinner.svg';
import Button from '../../atoms/Button';
import OnlyTextInput from '../../atoms/OnlyTextInput';
import PasswordInput from '../../atoms/PasswordInput';
import { Base } from '../../../types/base';
import styles from './sign-up-form.style';

interface SignUpFormProps extends Base {
  saveData: (p: object) => void;
  isLoading: boolean;
  initialValues?: {
    email: string,
    password: string,
    confirmPassword: string
  };
}

const defaultProps = {
  initialValues: {
    email: '',
    password: '',
    confirmPassword: '',
  },
};

const SignUpForm: React.FC<SignUpFormProps> = ({

  web,
  saveData,
  isLoading,
  initialValues,
}) => {
  const { width } = useWindowDimensions();
  const validationSchema = yup.object({
    email: yup.string().email('Please enter a valid email').required('Email is required'),
    password: yup
      .string()
      .required('Password is required')
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/, 'Incorrect format'),
    confirmPassword: yup
      .string()
      .required('Please confirm your password')
      .equals([yup.ref('password'), null], 'Password does not match'),
  });

  const formik = useFormik({
    initialValues: initialValues ?? {
      email: '',
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values) => {
      saveData(values);
    },
  });

  const styleFormContainer = { ...styles.formContainer, width: width <= 800 ? '80%' : styles.formContainer.width };

  return (
    <View style={web ? styleFormContainer : styles.formAppContainer}>
      <View style={styles.inputContainer}>
        <OnlyTextInput
          placeHolder="Email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange('email')}
        />
        {formik.touched.email && formik.errors.email && (
          <Text style={styles.textError}>{formik.errors.email}</Text>
        )}
      </View>
      <View style={styles.inputContainer}>
        <PasswordInput
          placeHolder="Password"
          label="Password"
          value={formik.values.password}
          onChange={formik.handleChange('password')}
          web={web}
        />
        {formik.touched.email && formik.errors.password && (
          <Text style={styles.textError}>{formik.errors.password}</Text>
        )}
      </View>
      <View style={styles.inputContainer}>
        <PasswordInput
          placeHolder="Confirm password"
          label="Confirm password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange('confirmPassword')}
          web={web}
        />
        {formik.touched.email && formik.errors.confirmPassword && (
          <Text style={styles.textError}>{formik.errors.confirmPassword}</Text>
        )}
      </View>
      <View style={styles.buttonContainer}>
        {!isLoading ? (
          <Button disabled={false} onPress={formik.handleSubmit}>
            create account
          </Button>
        ) : (
          <View style={styles.spinnerContainer}>
            {web ? (
              <img src={Spinner} alt="spinner" width={35} height={35} />
            ) : (
              <Spinner width={35} height={35} />
            )}
          </View>
        )}
      </View>
    </View>
  );
};

SignUpForm.defaultProps = defaultProps;

export default SignUpForm;
