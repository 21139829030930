const styles = {
  container: {
    minHeight: '100vh',
    width: '100%',
    display: 'flex',
    background: 'black',
  },
  navContainer: {
    height: '100%',
    width: 86,
    position: 'fixed',
    background: 'black',
    top: 0,
    left: 0,
    transition: '0.5s',
    zIndex: 2,
  },
  mainContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transition: '0.5s',
    paddingLeft: 86,
    zIndex: 1,
    backgroundColor: '#121212',
  },
  menuContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '15px',
    transition: '0.5s',
    borderRight: '1px #424242 solid',
  },
  menuIconContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-end',
  },
  menuIconsContainer: {
    flex: 1,
    paddingTop: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  menuIconsSection: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  userImageContainer: {
    width: 25,
    height: 25,
    borderRadius: 40,
    overflow: 'hidden',
  },
  notificationsCircle: {
    width: 5,
    height: 5,
    borderRadius: 40,
    border: 'red',
    backgroundColor: 'red',
    position: 'absolute',
    top: 3,
    left: 15,
    transform: 'translate(100%,-50%)',
  },
};

export default styles;
