import React from 'react';
import { View, Text } from 'react-native';
import { Base } from '../../../types/base';
import Spinner from '../../../assets/svg/spinner.svg';
import GitHubIcon from '../../../assets/svg/gitHub.svg';
import BitmapIcon from '../../../assets/svg/Bitmap.svg';
import Button from '../../atoms/Button';
import ConfirmationNDA from '../../molecules/ConfirmationNDA';
import Banner from '../../molecules/Banner';
import styles from './nda-confirmation.style';

interface NDAConfirmationProps extends Base {
  action: () => void;
  isLoading: boolean;
}

const NDAConfirmation = ({ web, action, isLoading }: NDAConfirmationProps) => (
  <View style={styles.container}>
    <ConfirmationNDA />
    <Text style={styles.subText}>
      In the meantime you can share any existing repositories to our GitHub
      account.
    </Text>
    <View style={styles.bannersContainer}>
      <Banner Icon={GitHubIcon} web={web} />
      <Banner Icon={BitmapIcon} web={web} />
    </View>
    <View>
      {isLoading ? (
        <View style={styles.spinnerContainer}>
          {web ? (
            <img src={Spinner} alt="spinner" width={35} height={35} />
          ) : (
            <Spinner width={35} height={35} />
          )}
        </View>
      ) : (
        <Button disabled={false} onPress={action}>
          continue
        </Button>
      )}
    </View>
  </View>
);

export default NDAConfirmation;
