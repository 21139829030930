import React from 'react';
import { FlatList, View } from 'react-native';
import { SvgProps } from 'react-native-svg';
import simpifiedLogo from '../../../assets/images/BSLogoSquare.png';
import { Base } from '../../../types/base';
import Logo from '../../atoms/Logo';
import MenuSection from '../../molecules/MenuSection';
import Account from '../../molecules/Account';
import styles from './menu.style';
import './index.css';
import HoverableItem from '../../molecules/HoverableItem';

export type MenuSectionExtraProps = {
  sectionTitle: string;
  link: string;
  icon?: React.Component | React.FC<SvgProps>;
  items?: Array<{
    title: string;
    link: string;
    icon?: React.Component | React.FC<SvgProps>
  }>;
};

interface MenuProps extends Base {
  handleCloseMenu: () => void;
  handleOpenMenu: () => void;
  items: Array<MenuSectionExtraProps>;
  isOpen?: boolean;
  onLogout?: () => void;
  isForClient?: boolean;
}

const Menu = ({
  web,
  items,
  handleCloseMenu,
  handleOpenMenu,
  isOpen,
  onLogout,
  isForClient,
}: MenuProps) => {
  const renderItem = ({ item }) => (
    <MenuSection
      key={item.sectionTitle}
      sectionTitle={item.sectionTitle}
      sectionIcon={item.sectionIcon || undefined}
      items={item.items}
      link={item.link}
      web={web}
      icon={item.icon}
      isOpen={isOpen}
    />
  );

  const handleMouseEnter = () => {
    handleOpenMenu();
  };

  const handleMouseLeave = () => {
    handleCloseMenu();
  };

  return (
    <HoverableItem onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <View style={styles.menuContainer}>
        <View style={styles.menuSectionContainer}>
          <View style={[styles.logoContainer, { paddingLeft: isOpen ? 40.99 : 24 }]}>
            {isOpen ? (
              <Logo
                uriImage="https://blackstonestudio.atlassian.net/s/o2joag/b/24/b8f3b866e76a1ef1293bc2772af00476/_/jira-logo-scaled.png"
                size="sm"
              />
            ) : (
              <img src={simpifiedLogo} alt="squareLogo" width={41} height={40} />
            )}
          </View>
          <FlatList
            style={{
              overflow: isOpen ? 'hidden' : 'visible',
              marginTop: isForClient ? 81.3 : 162.61,
            }}
            data={items}
            renderItem={renderItem}
            keyExtractor={(item) => item.sectionTitle}
          />
        </View>
        <Account
          isOpen={isOpen}
          handleCloseMenu={handleCloseMenu}
          handleOpenMenu={handleOpenMenu}
          web={web}
          onLogout={onLogout}
        />
      </View>
    </HoverableItem>
  );
};

export default Menu;

Menu.defaultProps = {
  isOpen: false,
  onLogout: undefined,
  isForClient: false,
};
