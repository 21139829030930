/* eslint-disable no-nested-ternary */
import React, { useState, useRef, useEffect } from 'react';
import {
  View, Text, TouchableOpacity, Animated, useWindowDimensions,
} from 'react-native';
import formatHours from '../../../utils/formatHours';
import SearchBar from '../../atoms/SearchBar';
import IssueRow from '../../atoms/IssueRow';
import { IssuePriority } from '../../atoms/IssueRow/IssueRow.types';
import CollapsableRow from '../../atoms/CollapsableRow';
import DashboardSectionDivider from '../../atoms/DashboardSectionDivider';
import BacklogIssue from '../../organisms/BacklogIssue';
import ModalApp from '../../atoms/ModalApp';
import { AccountType } from '../../atoms/Account/Account';
import { Base } from '../../../types/base';
import { styles } from './ClientBacklog.style';
import { useDebounceValue } from '../../../hooks/useDebounceValue';
import Api from '../../../services/apiRequest';

interface Epic {
  key: string;
  issueName: string;
  description: string;
  color?: string;
}

interface SubtaskProps {
  key: string;
  name: string;
  priority: string;
}

const colors = ['#C3FDFB', '#E9F8B9', '#FFC1D2', '#C8C4FA'];
const getRandomColor = () => colors[Math.round(Math.random() * (colors.length - 1 - 0) + 0)];

export interface IssueProps {
  key: string;
  id?: string;
  type: 'Story' | 'Bug' | 'Sub-task';
  projectName: string;
  projectIcon: string;
  devName: string;
  devAvatarUrl: string;
  projectCode: string;
  status: string;
  issueName: string;
  priority: IssuePriority;
  link: string;
  description: string;
  actionTrigger: string;
  expectedOutcome: string;
  howToReplicate: string[];
  preconditions: string;
  attachments: Array<{ attachmentName: string; type: 'pdf' | 'url' | 'figma' }>;
  components: string[];
  dependencies: string;
  accounts: Array<{
    account: AccountType;
    type: string;
    url: string;
  }>;
  path: string;
  notes: string;
  created: string;
  updated: string;
  epic: string;
  timeEstimate?: number;
  issues?: IssueProps[];
  definitionOfDone?: string;
  subtasks?: SubtaskProps[];
}

interface ClientBacklogProps extends Base {
  isForSowScreen?: boolean;
  issues: IssueProps[];
  epics?: Epic[];
}

const defaultProps = {
  isForSowScreen: false,
  epics: [],
};

const ClientBacklog = ({
  isForSowScreen, web, issues, epics,
}: ClientBacklogProps) => {
  const { width } = useWindowDimensions();
  const [searchQuery, setSearchQuery] = useState<string>('');
  const debouncedValue = useDebounceValue(searchQuery, 1000);
  const [filteredIssues, setFilteredIssues] = useState<IssueProps[]>(issues);
  const [containerWidth, setContainerWidth] = useState<number>(0);
  const [showEpics, setShowEpics] = useState<boolean>(false);
  const [activeIssue, setActiveIssue] = useState<IssueProps | null>(null);
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const animEpicsFade = useRef(new Animated.Value(-500)).current;
  const animWidth = useRef(new Animated.Value(0)).current;
  const isMobile = width < 900;

  // For some animations we need to use interpolation
  // since Animated doesn't support layout animations
  // and LayoutAnimation doesn't work in web.
  // You can find more info here:
  // https://eveningkid.medium.com/interpolation-with-react-native-animations-853e467fe5c1
  const issueWidth = animWidth.interpolate({
    inputRange: [0, 1],
    outputRange: ['100%', '50%'],
  });

  const handleShowEpics = () => setShowEpics(!showEpics);

  const handleSelectIssue = (issue: IssueProps) => {
    if (!activeIssue) {
      setActiveIssue(issue);
      return;
    }

    if (isForSowScreen) {
      setActiveIssue(issue.id === activeIssue.id ? null : issue);
    } else {
      setActiveIssue(issue.key === activeIssue.key ? null : issue);
    }
  };

  const handleSelectId = (id: string) => {
    if (!selectedId) {
      setSelectedId(id);
      return;
    }
    setSelectedId(id === selectedId ? null : id);
  };

  const handleCloseIssue = () => setActiveIssue(null);

  const handleClickAttachment = (attachment: string) => {
    // TODO: Replace with the actual function
    console.log(attachment);
  };

  const handleSearch = () => {
    const filtered = issues.filter(
      (item) => item.projectCode?.match(searchQuery)
        || item.description?.match(searchQuery)
        || item.epic?.match(searchQuery)
        || item.issueName?.match(searchQuery)
        || item.priority?.match(searchQuery)
        || item.type?.match(searchQuery),
    );
    setActiveIssue(null);
    setFilteredIssues(filtered);
  };

  const searchOnApi = async () => {
    const { data } = await Api.get(
      `/dashboard/client/backlog/search?searchTerm=${searchQuery.trim()}`,
    );
    setActiveIssue(null);
    setFilteredIssues(data);
  };

  useEffect(() => {
    setFilteredIssues(issues);
  }, [issues]);

  useEffect(() => {
    if (searchQuery.trim().length) {
      searchOnApi();
    } else {
      setFilteredIssues(issues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  // This only applies if the isForSowScreen prop is true

  useEffect(() => {
    if (!selectedId) {
      setActiveIssue(null);
    }
  }, [selectedId]);

  // Please note that we need to use Animated
  // since native doesn't support the CSS property 'transition' for smooth animations.
  // The idea is to reuse this component for the app version.
  useEffect(() => {
    if (activeIssue) {
      Animated.timing(animWidth, {
        toValue: 1,
        duration: 200,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(animWidth, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,
      }).start();
    }

    if (showEpics) {
      Animated.timing(animEpicsFade, {
        toValue: 0,
        duration: 200,
        useNativeDriver: false,
      }).start();
    } else {
      Animated.timing(animEpicsFade, {
        toValue: -500,
        duration: 200,
        useNativeDriver: false,
      }).start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIssue, showEpics]);

  return (
    <View style={[styles.mainContainer, { paddingTop: isForSowScreen ? 0 : !isMobile ? 30 : 0 }]}>
      {!isForSowScreen && (
        <View style={{ marginLeft: !isMobile ? (showEpics ? '20%' : !isMobile ? 40 : 20) : 0 }}>
          <DashboardSectionDivider title={`BACKLOG ${issues?.length || 0}`} web={web}>
            <View style={{ width: !isMobile ? 'auto' : '50%' }}>
              <SearchBar
                mini
                web={web}
                search={searchQuery}
                onChange={setSearchQuery}
                onSearchPress={handleSearch}
                onErasePress={() => setSearchQuery('')}
              />
            </View>
          </DashboardSectionDivider>
        </View>
      )}
      <View
        style={[styles.content, { flexDirection: !isMobile ? 'row' : 'column' }]}
        onLayout={(event) => {
          setContainerWidth(event.nativeEvent.layout.width);
        }}
      >
        {!isForSowScreen && (
          <View
            style={[
              {
                paddingTop: showEpics ? 20 : !isMobile ? 34 : 10,
              },
            ]}
          >
            <TouchableOpacity
              style={{
                transform: [{ rotateZ: showEpics || isMobile ? '0deg' : '90deg' }],
              }}
              onPress={handleShowEpics}
            >
              <Text style={styles.epicsLabel}>EPICS</Text>
            </TouchableOpacity>
            {showEpics && (
              <Animated.View
                style={[
                  styles.overflowHidden,
                  {
                    transform: [{ translateX: animEpicsFade }],
                    paddingRight: !isMobile ? 12 : 0,
                  },
                ]}
              >
                {epics?.map((epic) => (
                  <CollapsableRow
                    key={epic.key}
                    title={epic.issueName}
                    description={epic.description}
                    color={getRandomColor()}
                    isEpic
                    web={web}
                  />
                ))}
              </Animated.View>
            )}
          </View>
        )}

        <View
          style={[
            styles.overflowHidden,
            {
              width: showEpics
                ? !isMobile
                  ? '80%'
                  : '0%'
                : isForSowScreen
                  ? '100%'
                  : containerWidth - (!isMobile ? 40 : 10),
              flexDirection: !isMobile ? 'row' : 'column',
            },
          ]}
        >
          <Animated.View
            style={{
              width: !isMobile ? issueWidth : '100%',
              backgroundColor: '#121212',
            }}
          >
            <View
              style={[
                styles.labelsContainerIssue,
                {
                  paddingVertical: activeIssue && !isForSowScreen && !isMobile ? 7 : 0,
                  paddingRight: isForSowScreen ? 15 : 60,
                  paddingLeft: isForSowScreen ? 40 : 20,
                  marginTop: !isMobile ? 20 : 0,
                  marginBottom: !isMobile ? 5 : 10,
                },
              ]}
            >
              {!activeIssue && !isMobile && (
                <>
                  <View style={{ width: isForSowScreen ? '50%' : '60%' }}>
                    <Text style={styles.label}>NAME ISSUE</Text>
                  </View>
                  <View style={[styles.row, { width: isForSowScreen ? '50%' : '40%' }]}>
                    <View style={styles.labelContainer}>
                      <Text style={styles.label}>EPIC</Text>
                    </View>
                    <View style={styles.labelContainer}>
                      <Text style={styles.label}>ISSUE</Text>
                    </View>
                    <View style={styles.labelContainer}>
                      <Text style={styles.label}>PRIORITY</Text>
                    </View>
                    {isForSowScreen ? (
                      <View style={styles.labelContainer}>
                        <Text style={styles.label}>TIME ESTIMATE</Text>
                      </View>
                    ) : null}
                  </View>
                </>
              )}
              {activeIssue && !isMobile && isForSowScreen && (
                <View style={styles.labelsSubContainer}>
                  <Text style={[styles.label, { flex: 1 }]}>ISSUE</Text>
                </View>
              )}
            </View>
            {filteredIssues?.map((issue, index) => (
              <View key={index} style={styles.overflowHidden}>
                {!isForSowScreen ? (
                  <View style={{ zIndex: 100 }}>
                    <IssueRow
                      type={issue.type}
                      priority={issue.priority}
                      name={issue.issueName}
                      epicName={epics?.find((e) => issue.epic === e.key)?.issueName}
                      epic={issue.epic || 'NA'}
                      issue={issue.key}
                      link=""
                      isAccordionOpen={!!activeIssue || isMobile}
                      isRowSelected={issue.key === activeIssue?.key}
                      onCollapse={() => handleSelectIssue(issue)}
                      web={web}
                    />
                  </View>
                ) : (
                  <View>
                    <IssueRow
                      type={issue.type}
                      priority={issue.priority}
                      name={issue.issueName}
                      epicName={epics?.find((e) => issue.epic === e.key)?.issueName}
                      epic={issue.epic || 'NA'}
                      issue={issue.key}
                      link=""
                      isAccordionOpen={!!activeIssue || isMobile}
                      isRowSelected={
                        issue.subtasks?.length
                          ? issue.id === selectedId
                          : issue.id === activeIssue?.id
                      }
                      onCollapse={() => {
                        if (issue.subtasks.length) {
                          handleSelectId(issue.key);
                        } else {
                          handleSelectIssue(issue);
                        }
                      }}
                      estimate={formatHours(issue.timeEstimate)}
                      web={web}
                      hasChildren={!!issue.subtasks?.length}
                    />
                    <View style={[styles.childrenContainer, { paddingLeft: !isMobile ? 40 : 20 }]}>
                      {issue.id === selectedId
                        && issue.issues?.length
                        && issue.issues.map((item, i) => (
                          <>
                            <IssueRow
                              key={i}
                              type={item.type}
                              priority={item.priority}
                              name={item.issueName}
                              epic={item.epic || 'NA'}
                              issue={item.projectCode}
                              link=""
                              isAccordionOpen={!!activeIssue || isMobile}
                              isRowSelected={item.id === activeIssue?.id}
                              onCollapse={() => handleSelectIssue(item)}
                              estimate={formatHours(item?.timeEstimate)}
                              web={web}
                            />
                            {isMobile && activeIssue && activeIssue.id === item.id ? (
                              // TODO: We need to create an exclusive modal for the web version,
                              // this has been created with the component Modal from React Native
                              // but looks weird on the mobile web view
                              <ModalApp
                                isOpen={!!activeIssue || false}
                                closeModal={handleCloseIssue}
                                body={(
                                  <BacklogIssue
                                    activeIssue={activeIssue}
                                    onCloseIssue={handleCloseIssue}
                                    onClickAttachment={handleClickAttachment}
                                    isForSowScreen={isForSowScreen}
                                    web={web}
                                  />
                                )}
                                fullWidth
                              />
                            ) : null}
                          </>
                        ))}
                    </View>
                  </View>
                )}
                {/* This will work only if the isForSowScreen prop is false */}
                {isMobile && activeIssue && activeIssue.id === issue.id ? (
                  // TODO: We need to create an exclusive modal for the web version,
                  // this has been created with the component Modal from React Native
                  // but looks weird on the mobile web view
                  <ModalApp
                    isOpen={!!activeIssue || false}
                    closeModal={handleCloseIssue}
                    body={(
                      <BacklogIssue
                        activeIssue={activeIssue}
                        onCloseIssue={handleCloseIssue}
                        onClickAttachment={handleClickAttachment}
                        isForSowScreen={isForSowScreen}
                        web={web}
                      />
                    )}
                    fullWidth
                  />
                ) : null}
              </View>
            ))}
          </Animated.View>
          {!isMobile ? (
            <BacklogIssue
              activeIssue={activeIssue}
              onCloseIssue={handleCloseIssue}
              onClickAttachment={handleClickAttachment}
              isForSowScreen={isForSowScreen}
              web={web}
            />
          ) : null}
        </View>
      </View>
    </View>
  );
};

ClientBacklog.defaultProps = defaultProps;

export { ClientBacklog };
