import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const styles = StyleSheet.create({
  fontFamily,
  img: {
    width: 60,
    height: 60,
  },
  title: {
    paddingTop: 20,
    fontWeight: '500',
    fontSize: 24,
    lineHeight: 32,
    textAlign: 'center',
    color: '#fff',
  },
  container: {
    alignItems: 'center',
  },
});

export default styles;
