import io, { Socket } from 'socket.io-client';
// pseudo-singleton
let socket: Socket | null = null;

export const getSocket = (accessToken: string) => {
  socket = null;
  socket = io(process.env.REACT_APP_API_URL || 'https://api-dev.blackstone.studio', {
    transports: ['polling'],
    auth: { authorization: `Bearer ${accessToken}` },
  });
  return socket;
};

export const removeSocket = () => {
  socket = null;
};
