import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const webStyles = StyleSheet.create({
  fontFamily,
  container: {
    flex: 1,
    alignItems: 'center',
    height: '100%',
  },
  apiCallErrorText: {
    textAlign: 'center',
    fontSize: 14,
    color: 'red',
    marginBottom: 20,
  },
});

export default webStyles;
