import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const styles = StyleSheet.create({
  fontFamily,
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: '#ffffff',
    fontSize: 15,
    textTransform: 'capitalize',
    textAlign: 'left',
    fontWeight: '400',
    fontFamily: 'Oxygen-Light',
  },
  userAcronymContainer: {
    width: 30,
    height: 30,
    borderRadius: 100,
    borderWidth: 1,
    borderColor: 'gray',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userAcronym: {
    color: '#0ABAB5',
    fontSize: 12,
  },
  userImageContainer: {
    width: 30,
    height: 30,
    borderRadius: 100,
    overflow: 'hidden',
  },
});

export default styles;
