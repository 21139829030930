import { StyleSheet, useWindowDimensions } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const useResponsiveStyles = () => {
  const { width } = useWindowDimensions();
  const containerWidth = width < 570 ? '100%' : 400;
  const borderLeftWidth = width < 1200 ? 0 : 1;
  const paddingTop = width < 1200 ? 40 : 1;

  const styles = StyleSheet.create({
    fontFamily,
    container: {
      width: containerWidth,
      paddingHorizontal: 40,
      borderLeftWidth,
      borderLeftColor: 'rgba(255, 255, 255, 0.15)',
      paddingBottom: 40,
      paddingTop,
    },
    inputContainer: {
      width: '100%',
      marginTop: 20,
    },
    title: {
      color: 'white',
      fontSize: 24,
      marginBottom: 20,
    },
    message: {
      fontSize: 14,
      marginTop: 20,
      color: '#ffffffa6',
    },
  });

  return styles;
};

export default useResponsiveStyles;
