import React from 'react';
import { Text, Pressable } from 'react-native';
import styles from './time-option.style';

interface TimeOptionProps {
  hour: string;
  value: string;
  onChange: (e: string) => void;
}

const TimeOption = ({ hour, onChange, value }: TimeOptionProps) => {
  const selectTime = () => {
    onChange(hour);
  };

  return (
    <Pressable
      onPress={selectTime}
      style={hour === value ? styles.selectedItemContainer : styles.container}
    >
      <Text style={hour === value ? styles.selectedText : styles.text}>
        {hour}
      </Text>
    </Pressable>
  );
};

export default TimeOption;
