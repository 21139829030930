import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { useFormik } from 'formik';
import * as yup from 'yup';
import CalendarTemplate from '../../../shared/components/templates/Calendar';
import CalendarCallInfoTemplate from '../../../shared/components/templates/CalendarCallInfo';
import styles from './calendar.style';

const exampleSchedules = [
  {
    hour: '11:00',
  },
  {
    hour: '12:30',
  },
  {
    hour: '13:00',
  },
  {
    hour: '17:00',
  },
  {
    hour: '19:00',
  },
];

const Calendar = () => {
  const [view, setView] = useState(true);
  const [onSuccess, setOnSuccess] = useState(false);
  const [, setIsLoading] = useState(false);
  const validationSchema = yup.object({
    callType: yup.string().required('Please select an option'),
    timezone: yup.string().required('Please select an option'),
    date: yup.string().required('Please select an option'),
    time: yup.string().required('Please select an option'),
    customerName: yup.string().required('Please enter your name'),
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Please enter your email'),
    notes: yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      callType: '',
      timezone: '',
      date: '',
      time: '',
      customerName: '',
      email: '',
      notes: '',
    },
    validationSchema,
    onSubmit: () => {
      setOnSuccess(true);
      setIsLoading(true);
      setTimeout(() => {
        setIsLoading(false);
      }, 1500);
    },
  });

  const onReturn = () => setView(true);

  const handleConfirm = () => setView(false);

  return (
    <div style={styles.container}>
      {view ? (
        <CalendarTemplate
          web
          items={exampleSchedules}
          values={formik.values}
          onChangeCallType={formik.handleChange('callType')}
          onChangeTimezone={formik.handleChange('timezone')}
          onChangeDate={formik.handleChange('date')}
          onChangeTime={formik.handleChange('time')}
          handleConfirm={handleConfirm}
          name="Diego Alvarez"
          image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiN5myeq5ISYYuY26hd8SblQ6N8zFlwX4HST4aqguVLdsy4JMUO_mbu-gjR2dNPbRACeI&usqp=CAU"
        />
      ) : (
        <motion.div
          initial={{ x: 50, opacity: 0 }}
          animate={{ x: 0, opacity: 1 }}
          transition={{ type: 'tween', delay: 0.5, duration: 1 }}
        >
          <CalendarCallInfoTemplate
            web
            name="Diego Alvarez"
            image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSiN5myeq5ISYYuY26hd8SblQ6N8zFlwX4HST4aqguVLdsy4JMUO_mbu-gjR2dNPbRACeI&usqp=CAU"
            values={formik.values}
            onChangeName={formik.handleChange('customerName')}
            onChangeEmail={formik.handleChange('email')}
            onChangeNotes={formik.handleChange('notes')}
            onSubmit={formik.handleSubmit}
            onSuccess={onSuccess}
            onReturn={onReturn}
          />
        </motion.div>
      )}
    </div>
  );
};

export default Calendar;
// isLoading={isLoading}
