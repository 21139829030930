import React, { FC } from 'react';
import { Text, View, Image } from 'react-native';
import SucessIcon from '../../../assets/images/Combined_Shape.png';
import styles from './success.style';

interface SuccessProps {
  title: string;
  children: React.ReactNode;
}

const Success: FC<SuccessProps> = ({ children, title }) => (
  <View style={styles.container}>
    <Image source={SucessIcon} resizeMode="cover" style={styles.img} />
    <Text style={styles.title}>{title}</Text>
    {children}
  </View>
);

export default Success;
