import React, { useState, useRef, useEffect } from 'react';
import {
  Text, View, Animated, TouchableOpacity, Platform,
} from 'react-native';
import moment from 'moment';
import { StatusReportDto } from '../../../services/data-contracts';
import { Base } from '../../../types/base';
import DownloadIcon from '../../../assets/svg/Download.svg';
import Back from '../../../assets/svg/Back.svg';
import DashboardSectionDivider from '../../atoms/DashboardSectionDivider';
import StatusReportList from '../../organisms/StatusReportList';
import SoWSCard from '../../organisms/SoWSCard';
import Timeline from '../../organisms/TimelineApp';
import { TimelineProps } from '../../organisms/Timeline/Timeline';
import BasicTable from '../../organisms/BasicTable';
import ProjectProgressBar from '../../atoms/ProjectProgressBar';
import IssuePriority from '../../atoms/IssuePriority';
import StatusLabel from '../../atoms/StatusLabel';
import { statusType } from '../../atoms/StatusLabel/defaultTemplates';
import SoWSLabel from '../../atoms/SoWSLabel';
import { StatusType } from '../../atoms/SoWSLabel/SoWSLabel';
import CircularProgress from '../../atoms/CircularProgress';
import styles from './status-report.style';
import Api from '../../../services/apiRequest';

export interface TimeSheetProps {
  issueName: string;
  startDate: string;
  endDate: string;
  status: statusType;
  deliveryStatus: StatusType;
  workedHours: string | number;
  estimatedHours: string | number;
}

export interface RisksAndBlockersProps {
  name: string;
  description: string;
  risk: string;
}

export interface StatusReport {
  period: string;
  sowsId: string;
  startDate: string;
  endDate: string;
  timeSpent: number; // hours
  timeEstimate: number; // hours
  status: string;
  currentWeekStatus: {
    schedule: string;
    budget: string;
    scope: string;
  };
  sow: {
    sowsId: string;
    scope: string;
    description: string;
    workedHours: string | number;
    estimatedHours: string | number;
  };
  events: TimelineProps;
  timeSheet: TimeSheetProps[];
  risksAndBlockers: RisksAndBlockersProps[];
  changeRequests: RisksAndBlockersProps[];
}

type Props = Base;

export const StatusReport = ({ web }: Props) => {
  const [activeReport, setActiveReport] = useState<StatusReport | undefined>(undefined);
  const [reports, setReports] = useState<StatusReport[]>([]);
  const animListContainer = useRef(new Animated.Value(0)).current;
  const animDetailsContainer = useRef(new Animated.Value(2000)).current;

  useEffect(() => {
    const getStatusReports = async () => {
      const { data } = await Api.get<StatusReportDto[]>('/status-reports');
      const resportsFormatted = data.map(
        (sr): StatusReport => ({
          period: `${String(sr.week.year).slice(2, 4)}W${sr.week.weekNumber}`,
          sowsId: sr.scopeOfWorkId,
          startDate: moment(sr.week.startDate).format('MMM D, YYYY'),
          endDate: moment(sr.week.endDate).format('MMM D, YYYY'),
          timeSpent: Number(sr.sow.timeSpent.toFixed(0)),
          timeEstimate: Number(sr.sow.timeSpent.toFixed(0)),
          status: sr.status.toLowerCase(),
          currentWeekStatus: {
            budget: `${sr.budgetStatus.actualUse}`,
            schedule: `${sr.budgetStatus.originalBudget}`,
            scope: `${sr.budgetStatus.developmentProgress}`,
          },
          sow: {
            sowsId: sr.scopeOfWorkId,
            description: sr.sow.description,
            estimatedHours: sr.sow.timeEstimate.toFixed(0),
            scope: `${sr.sow.currentWeekStatus.scope}`,
            workedHours: sr.sow.timeSpent.toFixed(0),
          },
          events: {
            events: sr.events?.map((e) => ({ date: new Date(e.date), title: e.notes })),
          },
          changeRequests: sr.changeRequests,
          risksAndBlockers: sr.risksAndBlockers,
          timeSheet:
            sr.sow.issues?.map((issue) => ({
              issueName: issue.issueName,
              startDate: moment(issue.startDate).format('MMM D, YYYY'),
              endDate: moment(issue.endDate).format('MMM D, YYYY'),
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              status: issue.status as any,
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              deliveryStatus: issue.status as any,
              estimatedHours: Math.round(issue.timeEstimate),
              workedHours: Math.round(issue.timeSpent),
            })) || [], // TODO
        }),
      );
      setReports(resportsFormatted);
    };
    getStatusReports();
  }, []);

  const sowsDetails = {
    labels: [
      {
        label: 'SOWS ID',
        flex: 2,
      },
      {
        label: 'SCOPE OF WORK NAME',
        flex: 2,
      },
      {
        label: 'DESCRIPTION',
        flex: 3,
      },
      {
        label: 'PROGRESS',
        flex: 1,
      },
    ],
    rows: [
      {
        alignCenter: true,
        items: [
          <Text key={activeReport?.sow.sowsId} style={styles.text2}>
            {`[${activeReport?.sow.sowsId || 'NA'}]`}
          </Text>,
          activeReport?.sow.sowsId || 'NA',
          activeReport?.sow.description || 'NA',
          <CircularProgress
            key={activeReport?.sow.sowsId}
            totalHours={activeReport?.sow.estimatedHours || 0}
            completedHours={activeReport?.sow.workedHours || 0}
          />,
        ],
      },
    ],
  };

  const timeSheetData = {
    labels: [
      {
        label: 'NAME ISSUE',
        flex: 4,
      },
      {
        label: 'START DAY',
        flex: 2,
      },
      {
        label: 'END DAY',
        flex: 2,
      },
      {
        label: 'STATUS',
        flex: 3,
      },
      {
        label: 'TIME',
        flex: 1,
      },
      {
        label: 'DELIVERY STATUS',
        flex: 2,
      },
    ],
    rows:
      activeReport?.timeSheet?.map((item, index) => ({
        items: web
          ? [
            item.issueName,
            item.startDate,
            item.endDate,
            <StatusLabel key={index} label={item.status} status={item.status} web={web} filled />,
            `${item.workedHours}:00 h`,
            <SoWSLabel key={index} web={web} type="delivery" status={item.deliveryStatus} />,
          ]
          : [
            item.issueName,
            <View key={index} style={[styles.row, styles.spaceBetween, !web && styles.fullWidth]}>
              <Text style={styles.text3}>{`Start: ${item.startDate}`}</Text>
              <Text style={styles.text3}>{`End: ${item.endDate}`}</Text>
            </View>,
            <View key={index} style={[styles.row, styles.spaceBetween, !web && styles.fullWidth]}>
              <StatusLabel
                key={index}
                label={item.status}
                status={item.status}
                web={web}
                filled
              />
              <Text style={styles.text3}>{`${item.workedHours}:00 h`}</Text>
              <SoWSLabel key={index} web={web} type="delivery" status={item.deliveryStatus} />
            </View>,
          ],
      })) || [],
  };

  const risksAndBlockersData = {
    labels: [
      {
        label: 'NAME',
        flex: 2,
      },
      {
        label: 'DESCRIPTION',
        flex: 4,
      },
      {
        label: 'RISK',
        flex: 1,
      },
    ],
    rows:
      activeReport?.risksAndBlockers?.map((item, index) => ({
        alignCenter: true,
        items: [
          item.name,
          item.description,
          <IssuePriority key={index} priority={item.risk} web={web} />,
        ],
      })) || [],
  };

  const changeRequestsData = {
    labels: [
      {
        label: 'NAME',
        flex: 2,
      },
      {
        label: 'DESCRIPTION',
        flex: 4,
      },
      {
        label: 'RISK',
        flex: 1,
      },
    ],
    rows:
      activeReport?.changeRequests?.map((item, index) => ({
        alignCenter: true,
        items: [
          item.name,
          item.description,
          <IssuePriority key={index} priority={item.risk} web={web} />,
        ],
      })) || [],
  };

  const handleMakeSum = (arr: (string | number)[]) => {
    const total = arr.reduce((prev, current) => +prev + +current, 0);

    return total || 0;
  };

  const handlePercent = () => {
    const totalWorked = handleMakeSum(activeReport?.timeSheet?.map((item) => item.workedHours));
    const totalEstimate = handleMakeSum(
      activeReport?.timeSheet?.map((item) => item.estimatedHours),
    );
    const percentage = (+totalWorked / +totalEstimate) * 100;

    if (percentage > 100) {
      return 100;
    }

    return Math.round(percentage);
  };

  const genCsv = (): void => {
    if (!activeReport?.timeSheet.length) { return; }

    const keys = `${Object.keys(activeReport?.timeSheet[0]).join(', ')}\n`;
    const values = activeReport?.timeSheet.reduce(
      (acc, ts) => `${
        acc
          + Object.values({
            ...ts,
            startDate: moment(ts.startDate).format('DD/MM/YYYY'),
            endDate: moment(ts.endDate).format('DD/MM/YYYY'),
          }).join(',')
      }\n`,
      '',
    );

    if (Platform.OS === 'web') {
      const link = document.createElement('a');
      link.href = `data:text/csv;charset=utf-8,${encodeURIComponent(keys + values)}`;
      link.download = 'data.csv';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const downloadButton = (
    <TouchableOpacity onPress={() => genCsv()}>
      <View style={[styles.row, styles.downloadButton]}>
        {web ? (
          <img src={DownloadIcon} width={15} height={15} alt="download" />
        ) : (
          <DownloadIcon width={15} height={15} />
        )}
        <Text style={styles.backText}>Download</Text>
      </View>
    </TouchableOpacity>
  );

  const handleCloseReport = () => {
    setActiveReport(undefined);
  };

  // Please note that we need to use Animated
  // since native doesn't support the CSS property 'transition' for smooth animations.
  // The idea is to reuse this component for the app version.
  useEffect(() => {
    if (activeReport) {
      Animated.parallel([
        Animated.timing(animListContainer, {
          toValue: -2000,
          duration: 200,
          useNativeDriver: false,
        }),
        Animated.timing(animDetailsContainer, {
          toValue: 0,
          duration: 200,
          useNativeDriver: false,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.timing(animListContainer, {
          toValue: 0,
          duration: 200,
          useNativeDriver: false,
        }),
        Animated.timing(animDetailsContainer, {
          toValue: 2000,
          duration: 200,
          useNativeDriver: false,
        }),
      ]).start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeReport]);

  return (
    <View style={[styles.mainContainer, styles.fullWidth]}>
      <DashboardSectionDivider title="STATUS REPORT" />
      <View>
        {!activeReport ? (
          <Animated.View
            style={[styles.fullWidth, { transform: [{ translateX: animListContainer }] }]}
          >
            <StatusReportList onClickItem={setActiveReport} reports={reports} web={web} />
          </Animated.View>
        ) : (
          <Animated.View
            style={[styles.fullWidth, { transform: [{ translateX: animDetailsContainer }] }]}
          >
            <TouchableOpacity onPress={handleCloseReport} style={styles.marginTop10}>
              <View style={styles.row}>
                {web ? (
                  <img src={Back} width={15} height={15} alt="arrow" />
                ) : (
                  <Back width={15} height={15} />
                )}
                <Text style={styles.backText}>Back</Text>
              </View>
            </TouchableOpacity>
            <View style={styles.marginTop20}>
              {activeReport ? (
                <>
                  <SoWSCard
                    id={activeReport.sowsId || 'NA'}
                    startDay={activeReport.startDate || 'NA'}
                    deliveryDay={activeReport.endDate || 'NA'}
                    currentWeekStatus={activeReport.currentWeekStatus}
                    period={activeReport.period || 'NA'}
                    status={activeReport.status}
                    web={web}
                  />
                  <View style={styles.marginTop10}>
                    <BasicTable data={sowsDetails} web={web} />
                  </View>
                  <View style={{ marginTop: web ? 30 : 0 }}>
                    <Timeline events={activeReport.events} web={web} />
                  </View>
                  <View style={web ? styles.marginTop30 : styles.marginTop10}>
                    <BasicTable
                      hasBackground
                      title="TIME SHEET"
                      data={timeSheetData}
                      headerTools={downloadButton}
                      web={web}
                    >
                      <View style={[styles.fullWidth, web && styles.row, styles.spaceBetween]}>
                        <View style={styles.flex1}>
                          <Text style={styles.text}>WEEK&apos;S PROGRESS STATUS</Text>
                        </View>
                        <View style={{ flex: web ? 2 : 1 }}>
                          <ProjectProgressBar
                            workedHours={handleMakeSum(
                              activeReport?.timeSheet.map((item) => item.workedHours),
                            )}
                            estimatedHours={handleMakeSum(
                              activeReport?.timeSheet.map((item) => item.estimatedHours),
                            )}
                            percentage={handlePercent()}
                            web={web}
                            isForClientDashboardCard
                            isForSoWS
                          />
                        </View>
                      </View>
                    </BasicTable>
                  </View>
                  <View style={web ? styles.marginTop30 : styles.marginTop10}>
                    <BasicTable
                      hasBackground
                      title="RISKS & BLOCKERS"
                      data={risksAndBlockersData}
                      web={web}
                    />
                  </View>
                  <View style={web ? styles.marginTop30 : styles.marginTop10}>
                    <BasicTable
                      hasBackground
                      title="CHANGE REQUESTS"
                      data={changeRequestsData}
                      web={web}
                    />
                  </View>
                </>
              ) : null}
            </View>
          </Animated.View>
        )}
      </View>
    </View>
  );
};
