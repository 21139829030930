import React, { useState } from 'react';
import { View, TouchableOpacity } from 'react-native';
import Close from '../../../assets/svg/dropDownIcon.svg';
import Open from '../../../assets/svg/rightArrow.svg';
import TaskInfo from '../TaskInfo';
import { Base } from '../../../types/base';
import styles from './story.style';
import { CustomIssue } from '../../../types/TeamLeadDashboard';

interface StoryProps extends Base {
  subtasks: CustomIssue[];
  storyData: CustomIssue;
}

const Story = ({ subtasks = [], storyData, web }: StoryProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const openIcon = web ? (
    <img src={Open} width={20} height={20} alt="minus" />
  ) : (
    <Open width={20} height={20} />
  );

  const closeIcon = web ? (
    <img src={Close} width={15} height={15} alt="minus" />
  ) : (
    <Close width={15} height={15} />
  );

  const handleOpen = () => setIsOpen(!isOpen);

  return (
    <View style={styles.container}>
      <View style={styles.infoContainer}>
        <View style={{ width: web ? '3%' : '8%' }}>
          {subtasks.length > 0 && (
            <TouchableOpacity onPress={handleOpen} style={{ marginRight: 10 }}>
              {isOpen ? closeIcon : openIcon}
            </TouchableOpacity>
          )}
        </View>
        <View style={{ width: web ? '97%' : '92%' }}>
          <TaskInfo
            taskCode={storyData.taskCode}
            taskName={storyData.taskName}
            status={storyData.status}
            workedhours={storyData.workedHours}
            estimateHours={storyData.estimateHours}
            isStory
            web={web}
          />
        </View>
      </View>
      {isOpen && (
        <View style={[styles.itemsContainer, { paddingLeft: 35 }]}>
          {subtasks?.map((item) => (
            <TaskInfo
              key={item.taskCode}
              taskCode={item.taskCode}
              taskName={item.taskName}
              status={item.status}
              workedhours={item.workedHours}
              estimateHours={item.estimateHours}
              web={web}
            />
          ))}
        </View>
      )}
    </View>
  );
};

export default Story;
