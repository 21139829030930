import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import apiRequest from '../../../shared/services/apiRequest';
import ForgotPasswordTemplate from '../../../shared/components/templates/ForgotPassword';
import styles from '../../styles/page.style';

const ForgotPassword = () => {
  const navigate = useNavigate();
  const [isLoading, setIsloading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [errorMessage, setErrormessage] = useState('');

  const sendData = (data) => {
    setIsloading(true);
    setErrormessage('');
    apiRequest
      .post('auth/reset-password', data)
      .then(() => {
        setIsloading(false);
        setSuccess(true);
        navigate('/email-confirmation');
      })
      .catch((error) => {
        setErrormessage(error.message);
        setIsloading(false);
        setSuccess(false);
      });
  };

  return (
    <div style={styles.container}>
      <ForgotPasswordTemplate
        web
        sendData={sendData}
        isLoading={isLoading}
        success={success}
        errorMessage={errorMessage}
      />
    </div>
  );
};

export default ForgotPassword;
