/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useState, useRef, useEffect, SetStateAction, Dispatch,
} from 'react';
import {
  View, Text, TouchableOpacity, Animated,
} from 'react-native';
import formatDate from '../../../utils/formatDate';
import downloadPdf from '../../../../src/utils/downloadPdf';
import Back from '../../../assets/svg/Back.svg';
import Spinner from '../../../assets/svg/spinner.svg';
import DownloadIcon from '../../../assets/svg/Download.svg';
import { Base } from '../../../types/base';
import DashboardSectionDivider from '../../atoms/DashboardSectionDivider';
import SearchBar from '../../atoms/SearchBar';
import { Pagination } from '../../atoms/Pagination/Pagination';
import SoWS from '../../organisms/SoWS';
import SoWSCard from '../../organisms/SoWSCard';
import Tabs from '../../molecules/Tabs';
import ClientBacklog from '../ClientBacklog';
import { IssueProps } from '../ClientBacklog/ClientBacklog';
import { DefaultStatus } from '../../atoms/SoWSLabel';
import styles from './sows.style';

export interface SowProps {
  sowId: string;
  projectType: string;
  status: DefaultStatus;
  nIssues: string;
  startDate: string;
  endDate: string;
  hours: string | number;
  workedHours: string | number;
  issues: IssueProps[];
  currentWeekStatus: {
    schedule: string;
    budget: string;
    scope: string;
  };
  pdf: string;
}

interface SowsProps extends Base {
  sows: SowProps[];
  pdfViewerComponent: React.ReactElement;
  setPdf: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
  navigateStatusReportsFunc?: () => void;
}

const defaultProps = {
  navigateStatusReportsFunc: undefined,
};

const statuses = {
  'In progress': 'inProgress',
  'Approval pending': 'pending',
  Draft: 'draft',
  Closed: 'closed',
};

const SoWSTemplate = ({
  sows,
  pdfViewerComponent,
  setPdf,
  isLoading,
  navigateStatusReportsFunc,
  web,
}: SowsProps) => {
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [filteredSows, setFilteredSows] = useState<SowProps[]>(sows);
  const [paginationArray, setpPaginationArray] = useState<SowProps[][]>([]);
  const [activeSow, setActiveSow] = useState<SowProps | undefined>();
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const animListContainer = useRef(new Animated.Value(0)).current;
  const animDetailsContainer = useRef(new Animated.Value(2000)).current;

  const handOnClick = (updatePage: number) => setPage(updatePage);

  const handleDownloadPdf = async (url?: string) => {
    if (url) {
      downloadPdf(url);
    }
  };

  const tabs = [
    {
      label: 'SCOPE OF WORK',
      key: 'item-1',
      component: <ClientBacklog issues={activeSow?.issues} web={web} isForSowScreen />,
    },
    {
      label: 'STATEMENT OF WORK',
      key: 'item-2',
      component: activeSow?.pdf ? (
        <View>
          <TouchableOpacity onPress={() => handleDownloadPdf(activeSow?.pdf)}>
            <View style={[styles.row, styles.downloadButton, web && styles.downloadButtonWeb]}>
              {web ? (
                <img src={DownloadIcon} width={15} height={15} alt="download" />
              ) : (
                <DownloadIcon width={15} height={15} />
              )}
              <Text style={styles.backText}>Download</Text>
            </View>
          </TouchableOpacity>
          {pdfViewerComponent}
        </View>
      ) : (
        <Text style={[styles.backText, styles.alignSelf, styles.marginBottom40]}>
          No PDF file available
        </Text>
      ),
    },
  ];

  const handleCloseSow = () => {
    setActiveSow(undefined);
  };

  const handleSearch = () => {
    const pattern = new RegExp(searchQuery, 'i');

    const filtered = sows.filter(
      (item) => item.sowId?.match(pattern) || item.projectType?.match(pattern),
    );

    setFilteredSows(filtered);
  };

  // Please note that we need to use Animated
  // since native doesn't support the CSS property 'transition' for smooth animations.
  // The idea is to reuse this component for the app version.
  useEffect(() => {
    if (activeSow) {
      Animated.parallel([
        Animated.timing(animListContainer, {
          toValue: -2000,
          duration: 200,
          useNativeDriver: false,
        }),
        Animated.timing(animDetailsContainer, {
          toValue: 0,
          duration: 200,
          useNativeDriver: false,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.timing(animListContainer, {
          toValue: 0,
          duration: 200,
          useNativeDriver: false,
        }),
        Animated.timing(animDetailsContainer, {
          toValue: 2000,
          duration: 200,
          useNativeDriver: false,
        }),
      ]).start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSow]);

  useEffect(() => {
    if (searchQuery.length) {
      handleSearch();
    } else {
      setFilteredSows(sows);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery.length]);

  useEffect(() => {
    if (activeSow?.pdf) {
      setPdf(activeSow.pdf);
    }
  }, [activeSow?.pdf]);

  useEffect(() => {
    if (sows.length) {
      setFilteredSows(sows);
    }
  }, [sows]);

  useEffect(() => {
    const arrayLength = filteredSows?.length;
    const tempArray = [];
    for (let index = 0; index < arrayLength; index += 3) {
      const myChunk = filteredSows.slice(index, index + 3);
      tempArray.push(myChunk);
    }
    setpPaginationArray(tempArray);
    setTotalPages(tempArray.length);
  }, [filteredSows]);

  return (
    <View style={[styles.fullWidth, styles.mainContainer, { paddingVertical: web ? 30 : 0 }]}>
      <DashboardSectionDivider title={`SoWS ${sows?.length}`} web={web}>
        <View
          style={{
            width: web ? 'auto' : '50%',
            paddingVertical: activeSow ? 19 : 0,
          }}
        >
          {!activeSow ? (
            <SearchBar
              onChange={setSearchQuery}
              onSearchPress={handleSearch}
              onErasePress={() => setSearchQuery('')}
              search={searchQuery}
              mini
              web={web}
            />
          ) : null}
        </View>
      </DashboardSectionDivider>
      {isLoading ? (
        <View style={[styles.alignSelf, styles.marginTop40]}>
          {web ? (
            <img src={Spinner} width={30} height={30} alt="spinner" />
          ) : (
            <Spinner width={30} height={30} />
          )}
        </View>
      ) : (
        <View style={[styles.fullWidth, styles.row, styles.overflowHidden, styles.marginTop10]}>
          <>
            {!activeSow ? (
              <Animated.View
                style={[styles.fullWidth, { transform: [{ translateX: animListContainer }] }]}
              >
                {/* ISSUES FOR PAGINATION */}
                <SoWS
                  items={paginationArray[page - 1] || []}
                  onClickItem={setActiveSow}
                  web={web}
                />
                {/* ------------------------------------------------------ */}
                <View style={styles.pagination}>
                  <Pagination
                    defaultPage={page}
                    totalPages={totalPages}
                    handOnClick={handOnClick}
                    web={web}
                  />
                </View>
              </Animated.View>
            ) : null}
            <Animated.View
              style={[styles.fullWidth, { transform: [{ translateX: animDetailsContainer }] }]}
            >
              <View style={styles.fullWidth}>
                <TouchableOpacity onPress={handleCloseSow}>
                  <View style={styles.row}>
                    {web ? (
                      <img src={Back} width={15} height={15} alt="arrow" />
                    ) : (
                      <Back width={15} height={15} />
                    )}
                    <Text style={styles.backText}>Back</Text>
                  </View>
                </TouchableOpacity>
                <View style={styles.marginTop20}>
                  {activeSow ? (
                    <>
                      <SoWSCard
                        id={activeSow.sowId || 'NA'}
                        issueNumber={activeSow.nIssues || 'NA'}
                        startDay={formatDate({ date: activeSow.startDate })}
                        deliveryDay={formatDate({ date: activeSow.endDate })}
                        projectType={activeSow.projectType || 'NA'}
                        status={statuses[activeSow.status] || 'na'}
                        currentWeekStatus={activeSow.currentWeekStatus}
                        web={web}
                        isForSoWS
                        navigateStatusReportsFunc={navigateStatusReportsFunc}
                      />
                      <View style={web ? styles.marginTop40 : styles.marginTop20}>
                        <Tabs tabs={tabs} web={web} />
                      </View>
                    </>
                  ) : null}
                </View>
              </View>
            </Animated.View>
          </>
        </View>
      )}
    </View>
  );
};

SoWSTemplate.defaultProps = defaultProps;

export default SoWSTemplate;
