import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  issueLabelContainer: {
    width: '100%',
    marginBottom: 5,
  },
  label: {
    color: '#F5F5F5',
    fontSize: 12,
    fontWeight: '700',
  },
  lerop: {
    color: 'rgb(185, 180, 180)',
    fontFamily: 'Oxygen',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 21,
    textAlign: 'justify',
  },
  paddingHorizontal10: {
    paddingHorizontal: 10,
  },
});

export default styles;
