/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  View, Text, Image, Linking, TouchableOpacity,
} from 'react-native';
import { Base } from '../../../types/base';
import GitHubIcon from '../../../assets/svg/gitHub.svg';
import IssuePriority from '../../atoms/IssuePriority';
import IconWithText from '../../atoms/IconWithText';
import styles from './issue.style';
import { Github } from '../../../assets/icons';
import StatusLabel from '../../atoms/StatusLabel';
import WorkedEstimatedHours from '../../atoms/WorkedEstimatedHours';

export interface IssueProps extends Base {
  projectIcon: string | React.ElementType;
  projectName: string;
  projectCode: string;
  githubUrl?: string;
  priority?: string;
  devName: string;
  devAvatarUrl: string;
  isForBlockedIssues?: boolean;
  backgroundColor?: string;
  isForTeamLeadDashboard?: boolean;
  isForOverdue?: boolean;
  status?: string;
  issueName?: string;
  workedHours?: number;
  estimatedHours?: number;
}
// QUITAR LOS PORCENTAJES QUE
const defaultProps = {
  isForBlockedIssues: false,
  githubUrl: undefined,
  isForTeamLeadDashboard: false,
  priority: undefined,
  status: '',
  issueName: '',
  backgroundColor: '',
  isForOverdue: false,
  workedHours: 0,
  estimatedHours: 0,
};

const getWidthIcon = (web: boolean, isForOverdue: boolean) => {
  if (web) {
    if (isForOverdue) {
      return '16%';
    }
    return '25%';
  }
  return '50%';
};

const Issue = ({
  projectIcon,
  projectName,
  projectCode,
  githubUrl,
  devName,
  priority,
  devAvatarUrl,
  isForBlockedIssues,
  isForOverdue,
  isForTeamLeadDashboard,
  backgroundColor,
  status,
  issueName,
  web,
  workedHours,
  estimatedHours,
}: IssueProps) => {
  const widthIcon = getWidthIcon(web, isForOverdue);

  return (
    <View
      style={[
        styles.mainContainer,
        { backgroundColor },
        isForTeamLeadDashboard && { justifyContent: 'flex-start' },
      ]}
    >
      {isForTeamLeadDashboard && status ? (
        <View style={styles.fullWidth}>
          <View style={[styles.fullWidth, styles.row]}>
            <View
              style={[
                styles.overflowHidden,
                {
                  width: widthIcon,
                  borderRightWidth: web ? 0 : 18,
                  paddingRight: web ? 0 : 10,
                },
              ]}
            >
              <IconWithText Icon={projectIcon} text={projectName} web={web} />
            </View>
            <View
              style={[
                styles.overflowHidden,
                {
                  width: widthIcon,
                  borderRightWidth: web ? 0 : 10,
                  paddingRight: web ? 0 : 10,
                },
              ]}
            >
              <IconWithText Icon={devAvatarUrl} text={devName} web={web} />
            </View>
            {web && (
              <View
                style={[
                  {
                    width: isForOverdue ? '16%' : '13%',
                    justifyContent: 'flex-start',
                  },
                ]}
              >
                <a
                  href={`https://blackstonestudio.atlassian.net/browse/${projectCode}`}
                  target="blank"
                >
                  <Text style={styles.issueCode}>{projectCode}</Text>
                </a>
              </View>
            )}
            {web ? (
              <View
                style={[
                  {
                    width: isForOverdue ? '16%' : '15%',
                    justifyContent: 'flex-start',
                    paddingRight: 20,
                  },
                ]}
              >
                <StatusLabel filled label={status} />
              </View>
            ) : null}
            {web ? (
              <View
                style={[
                  {
                    flex: 1,
                    justifyContent: 'flex-start',
                  },
                ]}
              >
                <Text numberOfLines={1} style={styles.issueDescription}>
                  {issueName}
                </Text>
              </View>
            ) : null}
            {web && isForOverdue ? (
              <View
                style={[
                  {
                    width: '16%',
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  },
                ]}
              >
                <WorkedEstimatedHours
                  estimatedHours={estimatedHours || 0}
                  workedhours={workedHours || 0}
                  isForDeviationPercentage
                  web={web}
                />
              </View>
            ) : null}
          </View>
          {!web ? (
            <View style={styles.fullWidth}>
              <View
                style={[
                  styles.fullWidth,
                  styles.row,
                  styles.descriptionContainerApp,
                  { justifyContent: 'space-between' },
                ]}
              >
                <View style={{ flex: !isForOverdue ? 1 : 3 }}>
                  <Text numberOfLines={1} style={styles.issueDescription}>
                    {issueName}
                  </Text>
                </View>
                {isForOverdue ? (
                  <View style={{ flex: 1 }}>
                    <WorkedEstimatedHours
                      estimatedHours={estimatedHours || 0}
                      workedhours={workedHours || 0}
                      isForDeviationPercentage
                      web={web}
                    />
                  </View>
                ) : null}
              </View>
              <View style={[styles.fullWidth, styles.row, { justifyContent: 'space-between' }]}>
                <View style={{ flex: 1 }}>
                  <Text
                    style={styles.issueCode}
                    onPress={() => {
                      Linking.openURL(
                        `https://blackstonestudio.atlassian.net/browse/${projectCode}`,
                      );
                    }}
                  >
                    {projectCode}
                  </Text>
                </View>
                <View style={{ flex: 1 }}>
                  <StatusLabel filled label={status} />
                </View>
              </View>
            </View>
          ) : null}
        </View>
      ) : null}

      {!isForTeamLeadDashboard ? (
        <View style={[styles.fullWidth]}>
          <View style={[styles.fullWidth, styles.row]}>
            {web || isForBlockedIssues ? (
              <View
                style={{
                  width: isForBlockedIssues ? '33.333%' : '30%',
                  paddingRight: 10,
                }}
              >
                <IconWithText
                  Icon={projectIcon}
                  text={isForBlockedIssues ? '' : projectName}
                  web={web}
                />
              </View>
            ) : null}

            {web || isForBlockedIssues ? (
              <View
                style={[
                  styles.alignItems,
                  { width: isForBlockedIssues || !web ? '33.333%' : '20%' },
                ]}
              >
                {web ? (
                  <a
                    href={`https://blackstonestudio.atlassian.net/browse/${projectCode}`}
                    target="blank"
                  >
                    <Text style={styles.issueCode}>{projectCode}</Text>
                  </a>
                ) : (
                  <TouchableOpacity
                    onPress={() => Linking.openURL(`https://blackstonestudio.atlassian.net/browse/${projectCode}` || '')}
                  >
                    <Text style={styles.issueCode}>{projectCode}</Text>
                  </TouchableOpacity>
                )}
              </View>
            ) : null}

            {!isForBlockedIssues ? (
              web ? (
                <>
                  <View style={[styles.alignItems, styles.iconContainer]}>
                    <TouchableOpacity onPress={() => Linking.openURL(githubUrl || '')}>
                      {web ? (
                        <Github width={30} height={30} />
                      ) : (
                        <GitHubIcon width={30} height={30} />
                      )}
                    </TouchableOpacity>
                  </View>
                  <View style={styles.priorityContainer}>
                    <IssuePriority priority={priority || ''} web={web} />
                  </View>
                </>
              ) : null
            ) : null}

            {web || isForBlockedIssues ? (
              <View
                style={{
                  width: isForBlockedIssues || !web ? '33.333%' : '15%',
                  alignItems: isForBlockedIssues || !web ? 'flex-end' : 'center',
                }}
              >
                <View style={styles.devAvatar}>
                  {web ? (
                    <img src={devAvatarUrl} alt="devAvatar" height="100%" />
                  ) : (
                    <IconWithText Icon={devAvatarUrl} text="" web={web} />
                  )}
                </View>
              </View>
            ) : null}
          </View>

          {!isForBlockedIssues && !web ? (
            <View style={[styles.fullWidth]}>
              <View style={[styles.row2, styles.bottomLine]}>
                <Text style={styles.label}>PROJECT:</Text>
                <Image source={{ uri: projectIcon }} style={styles.devAvatar} />
              </View>
              <View style={[styles.row2, styles.bottomLine]}>
                <Text style={styles.label}>ASSIGNED:</Text>
                <Image source={{ uri: devAvatarUrl }} style={styles.devAvatar} />
              </View>
              <View style={[styles.row2, styles.bottomLine]}>
                <Text style={styles.label}>ISSUE:</Text>
                <TouchableOpacity
                  onPress={() => Linking.openURL(`https://blackstonestudio.atlassian.net/browse/${projectCode}` || '')}
                >
                  <Text style={styles.issueCode}>{projectCode}</Text>
                </TouchableOpacity>
              </View>
              <View style={[styles.row2, styles.bottomLine]}>
                <Text style={styles.label}>GITHUB:</Text>
                <TouchableOpacity onPress={() => Linking.openURL(githubUrl || '')}>
                  <GitHubIcon width={30} height={30} />
                </TouchableOpacity>
              </View>
              <View style={styles.row2}>
                <Text style={styles.label}>PRIORITY:</Text>
                <IssuePriority priority={priority || ''} web={web} />
              </View>
            </View>
          ) : null}
        </View>
      ) : null}
    </View>
  );
};

Issue.defaultProps = defaultProps;

export default Issue;
