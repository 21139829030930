import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: {
    width: 200,
    padding: 15,
    paddingRight: 20,
    backgroundColor: '#141414',
    position: 'absolute',
    top: 25,
    shadowColor: '#000000',
    shadowOffset: { width: -5, height: 5 },
    shadowOpacity: 0.4,
    shadowRadius: 12,
    borderRadius: 2,
  },
  text: {
    color: '#A8A8A8',
    fontSize: 12,
    fontWeight: '400',
  },
  optionscontainer: {
    width: '100%',
    marginTop: 20,
  },
  categoryContainer: {
    width: '100%',
  },
  subCategoriesContainer: {
    width: '100%',
    paddingLeft: 30,
  },
});

export default styles;
