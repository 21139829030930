import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const webStyles = StyleSheet.create({
  fontFamily,
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'space-around',
    minHeight: '100%',
  },
});

export default webStyles;
