import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import MenuLayout from '../MenuLayout';

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { accessToken } = useSelector((state: RootState) => state.session);

  if (!accessToken) {
    return <Navigate to="/login" />;
  }

  return <MenuLayout>{children}</MenuLayout>;
};

export default PrivateRoute;
