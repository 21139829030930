import { StyleSheet } from 'react-native';

const textFont = {
  fontFamily: 'Oxygen-Bold',
};

const webStyles = StyleSheet.create({
  formContainer: {
    width: '25%',
    alignItems: 'center',
    marginBottom: 30,
  },
  formAppContainer: {
    width: '70%',
    alignItems: 'center',
    marginBottom: 30,
  },
  inputContainer: {
    width: '100%',
    marginTop: 40,
  },
  textError: {
    textFont,
    textAlign: 'center',
    fontSize: 10,
    color: 'red',
    marginTop: 10,
  },
  buttonContainer: {
    width: '100%',
    marginTop: 40,
  },
  spinnerContainer: {
    alignItems: 'center',
  },
});

export default webStyles;
