import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const textFont = {
  fontFamily: 'Oxygen-Light',
};

const appStyles = StyleSheet.create({
  fontFamily,
  image: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '100%',
    alignItems: 'center',
  },
  imagesContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 40,
  },
  triangleContainer: {
    justifyContent: 'center',
  },
  pentagonHexagonContainer: {
    justifyContent: 'space-between',
  },
  rocket: {
    transform: [{ rotate: '-15deg' }],
    width: 200,
    height: 250,
  },
  triangle: {
    width: 130,
    height: 120,
  },
  hexagon: {
    width: 140,
    height: 140,
    position: 'relative',
    bottom: 30,
  },
  pentagon: {
    width: 90,
    height: 90,
    position: 'relative',
    right: 20,
  },
  storesContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: 40,
    marginBottom: 20,
  },
  appleIcon: {
    marginRight: 0,
  },
  title: {
    width: '60%',
    fontSize: 22,
    fontWeight: '500',
    color: '#FFFFFF',
    marginTop: 40,
    textAlign: 'center',
  },
  text: {
    textFont,
    fontSize: 14,
    color: '#ffffffa5',
    marginTop: 10,
  },
});

export default appStyles;
