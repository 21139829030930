import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Base } from '../../../types/base';
import styles from './Pagination.style';

export interface Props extends Base {
  defaultPage: number;
  totalPages: number;
  handOnClick: (page: number) => void;
}

export const PaginationComponent: React.FC<Props> = ({
  defaultPage,
  totalPages,
  handOnClick,
  web,
}) => {
  const [active, setActive] = useState(defaultPage);

  const handleOnPress = (change: number) => {
    if (active + change < 1 || active + change > totalPages) {
      setActive(active);
    } else {
      setActive(active + change);
      handOnClick(active + change);
    }
  };

  const handleOnSelect = (selected: number) => {
    setActive(selected);
    handOnClick(selected);
  };

  return (
    <View style={[styles.container, { paddingTop: web ? 40 : 10, paddingBottom: 60 }]}>
      <View style={styles.paginationWrapper}>
        <TouchableOpacity onPress={() => handleOnPress(-1)}>
          <Text style={styles.arrow}>{'<'}</Text>
        </TouchableOpacity>
        {Array.from({ length: totalPages }).map((e, index) => (
          <TouchableOpacity key={index + 1} onPress={() => handleOnSelect(index + 1)}>
            <Text style={index + 1 === active ? styles.selected : styles.noselected}>
              {index + 1}
            </Text>
          </TouchableOpacity>
        ))}
        <TouchableOpacity onPress={() => handleOnPress(+1)}>
          <Text style={styles.arrow}>{'>'}</Text>
        </TouchableOpacity>
      </View>
    </View>
  );
};

export const Pagination = PaginationComponent;
