import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  datecontainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  date: {
    color: '#ffffffa6',
    fontSize: 16,
    fontWeight: '300',
    lineHeight: 24,
    marginLeft: 10,
  },
});

export default styles;
