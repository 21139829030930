import React from 'react';
import { PathRouteProps } from 'react-router-dom';
import Dashboard from '../pages/Dashboard';

export const CLIENT_ROUTES: PathRouteProps[] = [
  {
    path: '/client_dashboard',
    element: <Dashboard />,
  },
];
