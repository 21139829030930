import React from 'react';
import { View, Text } from 'react-native';
import { Base } from '../../../types/base';
import Clock8BFF00 from '../../../assets/svg/clock#8BFF00.svg';
import Clock6BABFF from '../../../assets/svg/clock#6BABFF.svg';
import ClockE5E90C from '../../../assets/svg/clock#E5E90C.svg';
import ClockRed from '../../../assets/svg/clockred.svg';
import ClockIcon from '../../../assets/icons/Clock';
import GrayClock from '../../../assets/svg/clockGray.svg';
import styles from './worked-estimated-hours';
import { calcPercentage } from '../../../utils/calcPercentage';
import { isNormalNumber } from '../../../utils/isNormalNumber';

interface ProjectPercentageProps extends Base {
  estimatedHours: string | number;
  workedhours: string | number;
  isForDeviationPercentage?: boolean;
}

const defaultProps = {
  isForDeviationPercentage: false,
};

const WorkedEstimatedHours = ({
  estimatedHours,
  workedhours,
  isForDeviationPercentage,
  web,
}: ProjectPercentageProps) => {
  const deviationPercentage = () => {
    const deviation = calcPercentage(+workedhours, +estimatedHours);
    const result = isNormalNumber(deviation) ? (100 - deviation).toFixed(1) : '0.0';
    return result.length > 5 ? `${result.slice(0, 2)}...` : result;
  };

  const percentageColor = () => {
    const percentage = deviationPercentage();
    if (+percentage <= 5) {
      return '#8BFF00';
    }
    if (+percentage > 5 && +percentage <= 12) {
      return '#6babff';
    }
    if (+percentage > 12 && +percentage <= 20) {
      return '#e5e90c';
    }
    return 'red';
  };

  const percentageAppIcon = () => {
    const percentage = deviationPercentage();
    if (+percentage <= 5) {
      return <Clock8BFF00 width={15} height={12} />;
    }
    if (+percentage > 5 && +percentage <= 12) {
      return <Clock6BABFF width={15} height={12} />;
    }
    if (+percentage > 12 && +percentage <= 20) {
      return <ClockE5E90C width={15} height={12} />;
    }
    return <ClockRed width={15} height={12} />;
  };

  const estimatedHoursFormatted = (hours: string | number) => {
    if (hours < 1 || hours.toString() === 'NaN') {
      return '0h';
    }
    return `${hours}h`;
  };

  return (
    <View style={{ maxWidth: '100%' }}>
      {isForDeviationPercentage ? (
        <View style={styles.subContainer}>
          {web ? (
            <ClockIcon width={12} height={12} stroke={percentageColor()} />
          ) : (
            <>{percentageAppIcon()}</>
          )}
          <Text numberOfLines={1} style={[styles.percentage, { color: percentageColor() }]}>
            {`${deviationPercentage()}%`}
          </Text>
        </View>
      ) : (
        <View style={styles.subContainer}>
          {web ? (
            <img src={GrayClock} width={12} height={12} alt="clock" />
          ) : (
            <GrayClock width={15} height={12} />
          )}
          <Text style={{ color: '#fff', fontSize: 12, marginLeft: 10 }}>
            {`${workedhours}h / ${estimatedHoursFormatted(estimatedHours)}`}
          </Text>
        </View>
      )}
    </View>
  );
};

WorkedEstimatedHours.defaultProps = defaultProps;

export default WorkedEstimatedHours;
