import React, { FC } from 'react';
import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
} from 'react-native';
import { InputBase } from '../../../types/inputs';
import PasswordEye from '../../../assets/svg/password-eye.svg';
import styles from '../../../styles/inputs.style';

interface PasswordInputProps extends InputBase {
  placeHolder?: string;

  value?: string;

  label?: string;

  onChange?: (val: string) => void;

  require?: boolean;

  web?: boolean;
}

const defaultProps = {
  web: false,
  placeHolder: '',
  value: '',
  label: '',
  onChange: undefined,
  require: false,
};

const PasswordInput: FC<PasswordInputProps> = ({
  placeHolder,
  value,
  label,
  onChange,
  require,
  web,
}) => {
  const [val, setVal] = React.useState(value);
  const [inputStyle, setInputStyle] = React.useState(false);
  const [isHidden, setIsHidden] = React.useState(true);

  const onChangeLocal = (str: string) => {
    if (onChange) {
      onChange(str);
    }
    setVal(str);
  };

  const onBlurLocal = () => {
    setInputStyle(false);
  };

  const inputContainerStyle = () => (val !== '' ? styles.inputContainer : styles.inputContainerFilled);

  return (
    <View
      style={inputStyle ? styles.inputContainerFocus : inputContainerStyle()}
    >
      {label && val !== '' && (
        <Text style={inputStyle ? styles.labelFocus : styles.label}>
          {label}
          {require}
        </Text>
      )}
      <View style={styles.iconContainer}>
        <TextInput
          style={styles.width90Input}
          value={val}
          onChangeText={(e) => onChangeLocal(e)}
          onBlur={onBlurLocal}
          onFocus={() => setInputStyle(true)}
          placeholderTextColor="#ffffffa6"
          placeholder={require ? placeHolder : placeHolder}
          secureTextEntry={isHidden}
        />
        <TouchableOpacity
          onPress={() => setIsHidden(!isHidden)}
          style={styles.EyeIcon}
        >
          {web ? (
            <img width="25" height="15" src={PasswordEye} alt="icon" />
          ) : (
            <PasswordEye width="25" height="15" />
          )}
        </TouchableOpacity>
      </View>
    </View>
  );
};

PasswordInput.defaultProps = defaultProps;

export default PasswordInput;
