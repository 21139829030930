import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: {
    alignItems: 'center',
    paddingHorizontal: 20,
    paddingVertical: 15,
    backgroundColor: '#262626',
    borderRadius: 4,
    marginBottom: 5,
  },
  fullWidth: {
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flex1: {
    flex: 1,
    alignItems: 'center',
  },
  flex2: {
    flex: 2,
    alignItems: 'center',
  },
  flex3: {
    flex: 3,
  },
  paddingRight: {
    paddingRight: 15,
  },
  alignItemsRight: {
    alignItems: 'flex-end',
  },
  alignItemsLeft: {
    alignItems: 'flex-start',
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  color1: {
    color: '#BFBFBF',
  },
  color2: {
    color: '#D9D9D9',
  },
  id: {
    fontSize: 18,
    fontWeight: '700',
    fontFamily: 'Roboto Mono',
  },
  type: {
    fontSize: 14,
    fontWeight: '400',
    fontFamily: 'Roboto',
  },
  issueNumber: {
    fontSize: 18,
    fontWeight: '400',
    fontFamily: 'Roboto',
  },
  date: {
    fontSize: 12,
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
});

export default styles;
