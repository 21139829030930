import React, { SVGProps } from 'react';

const Logout: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    {...props}
  >
    <path d="M16.152 13.0806H14.7397C14.6432 13.0806 14.5528 13.1217 14.4926 13.1942C14.352 13.3606 14.2013 13.5212 14.0426 13.6739C13.3935 14.3072 12.6246 14.8118 11.7785 15.16C10.9019 15.5209 9.95977 15.706 9.0082 15.7043C8.04593 15.7043 7.11378 15.5203 6.23789 15.16C5.39179 14.8118 4.62293 14.3072 3.97383 13.6739C3.32357 13.0427 2.80507 12.2947 2.44704 11.4711C2.07539 10.6174 1.88856 9.71087 1.88856 8.77298C1.88856 7.8351 2.0774 6.92854 2.44704 6.07485C2.80463 5.25052 3.31892 4.50844 3.97383 3.87209C4.62874 3.23573 5.39012 2.73448 6.23789 2.38596C7.11378 2.02568 8.04593 1.84163 9.0082 1.84163C9.97048 1.84163 10.9026 2.02373 11.7785 2.38596C12.6263 2.73448 13.3877 3.23573 14.0426 3.87209C14.2013 4.02677 14.3499 4.18733 14.4926 4.3518C14.5528 4.42425 14.6453 4.46536 14.7397 4.46536H16.152C16.2785 4.46536 16.3569 4.3283 16.2866 4.22453C14.7457 1.89058 12.0497 0.345713 8.98611 0.353545C4.17271 0.365293 0.313561 4.17362 0.361775 8.85913C0.409989 13.4702 4.26312 17.1924 9.0082 17.1924C12.0638 17.1924 14.7477 15.6495 16.2866 13.3214C16.3549 13.2177 16.2785 13.0806 16.152 13.0806ZM17.9379 8.64963L15.0872 6.45666C14.9807 6.37442 14.8261 6.44883 14.8261 6.58001V8.0681H8.51803C8.42963 8.0681 8.35731 8.13859 8.35731 8.22474V9.32122C8.35731 9.40738 8.42963 9.47787 8.51803 9.47787H14.8261V10.966C14.8261 11.0971 14.9828 11.1715 15.0872 11.0893L17.9379 8.89634C17.9571 8.88168 17.9726 8.86296 17.9833 8.84159C17.994 8.82022 17.9996 8.79676 17.9996 8.77298C17.9996 8.74921 17.994 8.72575 17.9833 8.70437C17.9726 8.683 17.9571 8.66428 17.9379 8.64963V8.64963Z" fill="#FAFAFA" />
  </svg>
);

export default Logout;
