import React from 'react';
import { View, Text } from 'react-native';
import Timer from '../../../assets/svg/timer.svg';
import { Base } from '../../../types/base';
import styles from './call-type.style';

interface CallTypeProps extends Base {
  callType: string;
}

const CallType = ({ web, callType }: CallTypeProps) => (
  <View style={styles.container}>
    <Text style={styles.callType}>{callType}</Text>
    <View style={styles.lengthContainer}>
      {web ? (
        <img src={Timer} alt="timer" width={16} height={16} />
      ) : (
        <Timer width={20} height={20} />
      )}
      <Text style={styles.length}>{callType === 'Silver Call' ? '30m' : '60m'}</Text>
    </View>
  </View>
);

export default CallType;
