import React, { SVGProps } from 'react';

const Github: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const { fill } = props;

  return (
    <svg
      width="33"
      height="32"
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Group 3">
        <mask id="mask0_0_192" maskUnits="userSpaceOnUse" x="0" y="-1" width="33" height="33">
          <path
            id="Clip 2"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 -0.000488281H32.579V31.7743H0V-0.000488281Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_0_192)">
          <path
            id="Fill 1"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.289 -0.000488281C7.294 -0.000488281 0 7.29351 0 16.2905C0 23.4865 4.667 29.5925 11.141 31.7465C11.956 31.8955 12.253 31.3935 12.253 30.9615C12.253 30.5745 12.239 29.5505 12.231 28.1905C7.7 29.1755 6.744 26.0075 6.744 26.0075C6.003 24.1255 4.935 23.6245 4.935 23.6245C3.456 22.6135 5.047 22.6345 5.047 22.6345C6.682 22.7495 7.542 24.3125 7.542 24.3125C8.995 26.8025 11.354 26.0835 12.283 25.6665C12.431 24.6135 12.851 23.8955 13.317 23.4885C9.7 23.0775 5.897 21.6805 5.897 15.4375C5.897 13.6595 6.532 12.2055 7.574 11.0665C7.406 10.6545 6.847 8.99851 7.733 6.75651C7.733 6.75651 9.101 6.31751 12.213 8.42551C13.512 8.06451 14.906 7.88351 16.291 7.87751C17.674 7.88351 19.068 8.06451 20.369 8.42551C23.479 6.31751 24.844 6.75651 24.844 6.75651C25.733 8.99851 25.174 10.6545 25.006 11.0665C26.05 12.2055 26.681 13.6595 26.681 15.4375C26.681 21.6955 22.872 23.0725 19.243 23.4765C19.828 23.9785 20.349 24.9735 20.349 26.4925C20.349 28.6705 20.329 30.4275 20.329 30.9615C20.329 31.3965 20.622 31.9035 21.449 31.7445C27.916 29.5865 32.58 23.4845 32.58 16.2905C32.58 7.29351 25.286 -0.000488281 16.289 -0.000488281Z"
            fill={fill || '#FEFEFE'}
          />
        </g>
      </g>
    </svg>
  );
};

export default Github;
