import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'RobotoMono-Regular, monospace',
  letterSpacing: 1,
};

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
  },
  labelsContainer: {
    width: '100%',
    flexDirection: 'row',
    paddingBottom: 20,
  },
  labelContainer: {
    width: '14.28%',
    justifyContent: 'space-between',
  },
  label: {
    color: '#E5E5E5',
    fontSize: 16,
    ...fontFamily,
    fontWeight: '400',
    lineHeight: 20,
    marginVertical: 10,
  },
  amount: {
    color: '#D9D9D9',
    fontWeight: '700',
    lineHeight: 20,
    fontSize: 24,
    ...fontFamily,
  },
  graphSectionsContainer: {
    width: '100%',
    flexDirection: 'row',
    borderBottomWidth: 0.5,
    borderBottomColor: '#595959',
    borderLeftWidth: 0.5,
    borderLeftColor: '#595959',
  },
  graphSectionContainer: {
    width: '14.28%',
    borderRightWidth: 0.5,
    borderRightColor: '#595959',
  },
  linesContainer: {
    position: 'absolute',
    width: '100%',
    height: '100%',
  },
  line: {
    width: '100%',
    height: '10%',
    borderTopWidth: 0.5,
    borderTopColor: '#333333',
  },
  gradientsContainer: {
    width: '100%',
    height: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    overflow: 'hidden',
    position: 'absolute',
    justifyContent: 'space-evenly',
  },
});

export default styles;
