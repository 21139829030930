import React from 'react';
import { Text } from 'react-native';
import { Base } from '../../../types/base';
import Google from '../../../assets/svg/google.svg';
import styles from './GoogleLoginButton.style';
import Button from '../Button';

type GoogleLoginButtonProps = Base & {
  onPress: () => void;
};

const GoogleLoginButton: React.FC<GoogleLoginButtonProps> = ({
  web, onPress,
}) => (
  <Button
    style={styles.button}
    onPress={onPress}
    Icon={web ? (
      <img src={Google} alt="google-logo" width={16} height={16} />
    ) : (
      <Google width={16} height={16} />
    )}
  >
    <Text style={styles.text}>
      Log in with Google
    </Text>
  </Button>
);

export { GoogleLoginButton };
