import React, { useRef, useEffect } from 'react';
import moment from 'moment';
import {
  Animated, View, Text, useWindowDimensions,
} from 'react-native';
import { Base } from '../../../types/base';
import { IssueProps } from '../../templates/ClientBacklog/ClientBacklog';
import IssueCardComponent from '../../molecules/IssueCard';
import Collapsable from '../../molecules/Collapsable';
import Attachment from '../../atoms/Attachment';
import Account from '../../atoms/Account';
import BasicTable from '../BasicTable';
import IssuePriority from '../../atoms/IssuePriority';
import IssueLink from '../../atoms/IssueLink';
import styles from './backlog-issue.style';

interface BacklogIssue extends Base {
  activeIssue: IssueProps | null;
  onCloseIssue: () => void;
  onClickAttachment: (attachment: string) => void;
  isForSowScreen?: boolean;
}

const defaultProps = {
  isForSowScreen: false,
};

const BacklogIssue = ({
  activeIssue,
  onCloseIssue,
  onClickAttachment,
  isForSowScreen,
  web,
}: BacklogIssue) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const animOpacity = useRef(new Animated.Value(0)).current;
  const animIssueFade = useRef(new Animated.Value(-500)).current;

  const subtasksTableData = {
    labels: [
      {
        label: 'ISSUE',
        flex: 2,
      },
      {
        label: 'NAME ISSUE',
        flex: 5,
      },
      {
        label: 'PRIORITY',
        flex: 2,
      },
    ],
    rows:
      activeIssue?.subtasks?.map((item, index) => ({
        items: [
          <IssueLink key={index} url="" title={item.key} />,
          item.name,
          <IssuePriority key={index} priority={item.priority} web={web} />,
        ],
      })) || [],
  };

  // Please note that we need to use Animated
  // since native doesn't support the CSS property 'transition' for smooth animations.
  // The idea is to reuse this component for the app version.
  useEffect(() => {
    if (activeIssue) {
      Animated.parallel([
        Animated.timing(animOpacity, {
          toValue: 1,
          duration: 300,
          useNativeDriver: false,
        }),
        Animated.timing(animIssueFade, {
          toValue: 0,
          duration: 500,
          useNativeDriver: false,
        }),
      ]).start();
    } else {
      Animated.parallel([
        Animated.timing(animOpacity, {
          toValue: 0,
          duration: 300,
          useNativeDriver: false,
        }),
        Animated.timing(animIssueFade, {
          toValue: -500,
          duration: 300,
          useNativeDriver: false,
        }),
      ]).start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeIssue]);

  return (
    <Animated.View
      style={[
        {
          opacity: animOpacity,
          width: !isMobile ? '50%' : '100%',
          paddingLeft: activeIssue && !isMobile ? 12 : 0,
        },
        !isMobile && { transform: [{ translateY: animIssueFade }] },
      ]}
    >
      <View style={[styles.issueLabelContainer, { paddingVertical: 10 }]}>
        <Text style={styles.label}>{!isForSowScreen ? 'ISSUE' : 'ISSUE DESCRIPTION'}</Text>
      </View>
      <IssueCardComponent
        issue={activeIssue?.key || ''}
        name={activeIssue?.issueName || 'NA'}
        type={activeIssue?.type || 'Story'}
        priority={activeIssue?.priority || 'Medium'}
        width="100%"
        onClose={onCloseIssue}
        created={activeIssue?.created !== '' ? moment(activeIssue?.created).format('LLL') : 'NA'}
        update={activeIssue?.updated !== '' ? moment(activeIssue?.updated).format('LLL') : 'NA'}
        link={activeIssue?.link || ''}
        web={web}
      >
        <Collapsable title="DESCRIPTION" collapsed web={web}>
          <Text style={styles.lerop}>{activeIssue?.description || 'NA'}</Text>
        </Collapsable>
        {activeIssue?.type === 'Story' ? (
          <>
            <Collapsable title="DEFINITION OF DONE" web={web}>
              <Text style={styles.lerop}>{activeIssue?.definitionOfDone || 'NA'}</Text>
            </Collapsable>
            <View style={styles.paddingHorizontal10}>
              <BasicTable web={web} data={subtasksTableData} darkRows />
            </View>
          </>
        ) : null}
        {activeIssue?.type === 'Bug' || activeIssue?.type === 'Sub-task' ? (
          <>
            <Collapsable title="ACTION TRIGGER" web={web}>
              <Text style={styles.lerop}>{activeIssue?.actionTrigger || 'NA'}</Text>
            </Collapsable>
            <Collapsable title="EXPECTED OUTCOME" web={web}>
              <Text style={styles.lerop}>{activeIssue?.expectedOutcome || 'NA'}</Text>
            </Collapsable>
            <Collapsable title="HOW TO REPLICATE" web={web}>
              {activeIssue?.howToReplicate?.length ? (
                activeIssue.howToReplicate.map((item, index) => (
                  <Text key={index} style={styles.lerop}>{`${index + 1}. ${item}`}</Text>
                ))
              ) : (
                <Text style={styles.lerop}>NA</Text>
              )}
            </Collapsable>
            <Collapsable title="PRECONDITIONS" web={web}>
              <Text style={styles.lerop}>{activeIssue?.preconditions || 'NA'}</Text>
            </Collapsable>
            <Collapsable title="ATTACHMENTS" web={web}>
              {activeIssue?.attachments?.length ? (
                activeIssue.attachments.map(({ attachmentName, type }, index) => (
                  <Attachment
                    key={index}
                    attachmentName={attachmentName}
                    type={type}
                    onClick={() => onClickAttachment(attachmentName)}
                    web={web}
                  />
                ))
              ) : (
                <Text style={styles.lerop}>NA</Text>
              )}
            </Collapsable>
            <Collapsable title="COMPONENTS" web={web}>
              {activeIssue?.components?.length ? (
                activeIssue.components.map((item, index) => (
                  <Text key={index} style={styles.lerop}>{`${index + 1}. ${item}`}</Text>
                ))
              ) : (
                <Text style={styles.lerop}>NA</Text>
              )}
            </Collapsable>
            <Collapsable title="DEPENDENCIES" web={web}>
              <Text style={styles.lerop}>{activeIssue?.dependencies || 'NA'}</Text>
            </Collapsable>
            <Collapsable title="ACCOUNTS" web={web}>
              {activeIssue?.accounts?.length ? (
                activeIssue.accounts.map(({ account, type, url }, index) => (
                  <Account key={index} account={account} type={type} url={url} web={web} />
                ))
              ) : (
                <Text style={styles.lerop}>NA</Text>
              )}
            </Collapsable>
            <Collapsable title="PATH" web={web}>
              <Text style={styles.lerop}>{activeIssue?.path || 'NA'}</Text>
            </Collapsable>
            <Collapsable title="NOTES" web={web}>
              <Text style={styles.lerop}>{activeIssue?.notes || 'NA'}</Text>
            </Collapsable>
          </>
        ) : null}
      </IssueCardComponent>
    </Animated.View>
  );
};

BacklogIssue.defaultProps = defaultProps;

export default BacklogIssue;
