import React from 'react';
import {
  View, Text, TouchableOpacity, Linking,
} from 'react-native';
import { Base } from '../../../types/base';
import styles from './ci-sub-projects-status';
// import CIProjectStatus from '../../atoms/CIProjectStatus';
// import ScrollComponent from '../../atoms/ScrollComponent';
import Success from '../../../assets/svg/success2.svg';
import FailedIcon from '../../../assets/svg/failed.svg';
import InProgressIcon from '../../../assets/svg/inProgress.svg';
import QueuedIcon from '../../../assets/svg/queued.svg';
import { statusType } from '../../atoms/StatusLabel/defaultTemplates';

const icons = {
  Success,
  Error: FailedIcon,
  Hold: QueuedIcon,
  Running: InProgressIcon,
};

const iconsElements = {
  Success: () => <Success width={15} height={15} />,
  Error: () => <FailedIcon width={15} height={15} />,
  Hold: () => <QueuedIcon width={15} height={15} />,
  Running: () => <InProgressIcon width={15} height={15} />,
};

interface ItemProps {
  item: {
    projectName: string;
    status: statusType;
    url: string;
  };
}
interface CISubProjectsStatusProps extends Base {
  items: Array<ItemProps['item']>;
}

const CISubProjectsStatus = ({ items, web }: CISubProjectsStatusProps) => (
  <View style={styles.container}>
    {items?.map((item) => (
      <View style={styles.itemContainer} key={item.status}>
        <View style={styles.item}>
          {web ? (
            <img src={icons[item.status] || icons.Error} alt={item.status} width={15} height={15} />
          ) : (
            iconsElements[item.status]()
          )}
          <TouchableOpacity onPress={() => Linking.openURL(item.url)}>
            <Text style={styles.title}>{item.projectName}</Text>
          </TouchableOpacity>
        </View>
      </View>
    ))}
  </View>
);

export default CISubProjectsStatus;
