import React, { useState, useEffect } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Base } from '../../../types/base';
import Arrows from '../../../assets/svg/pagination-item-ellipsis.svg';
import ExpandableProjectCard from '../../molecules/ExpandableProjectCard';
import styles from './expandable-projects-container.syle';
import ScrollComponent from '../../atoms/ScrollComponent';
import DashboardCard from '../../atoms/DashboardCard';
import { Workload } from '../../../types/TeamLeadDashboard';

interface projectsListContainerProps extends Base {
  title: string;
  ciStatus?: boolean;
  isForDeviationPercentage?: boolean;
  items: { [key: string]: Workload };
}

const defaultProps = {
  isForDeviationPercentage: false,
  ciStatus: false,
};

const ExpandableProjectsContainer = ({
  title,
  ciStatus,
  isForDeviationPercentage,
  items,
  web,
}: projectsListContainerProps) => {
  const projects = Object.keys(items);
  const [sliceParam2, setSliceParam2] = useState<number>(projects?.length);
  const [areAllItemsDisplayed, setAreAllItemsDisplayed] = useState(false);

  const handleDisplayMoreItems = () => {
    setAreAllItemsDisplayed(true);
    setSliceParam2(projects?.length);
  };

  const handleHideItems = () => {
    setAreAllItemsDisplayed(false);
    setSliceParam2(3);
  };

  useEffect(() => {
    if (!web) {
      setSliceParam2(3);
    }
  }, [web]);

  return (
    <DashboardCard
      title={title}
      height={web ? 520 : 'auto'}
      iconType="Clock"
      tooltipContent="Measured by Week"
      web={web}
      isTooltipLeft
    >
      <View style={[styles.labelsContainer, { marginBottom: web ? 20 : 10 }]}>
        <View style={{ width: web ? 'auto' : '40%' }}>
          <Text style={styles.label}>PROJECT</Text>
        </View>
        <View
          style={[
            styles.detailsContainer,
            { width: web ? '22%' : '60%', paddingRight: web ? 20 : 0 },
          ]}
        >
          <Text style={styles.label}>WORKLOAD</Text>
          <Text style={styles.label}>DEVIATION</Text>
        </View>
      </View>
      {web ? (
        <ScrollComponent web={web}>
          {projects?.map((item) => (
            <View style={styles.itemContainer} key={item}>
              <ExpandableProjectCard
                isForDeviationPercentage={isForDeviationPercentage}
                cardInfo={items[item] as Workload}
                ciStatus={ciStatus}
                title={items[item].name}
                web={web}
              />
            </View>
          ))}
        </ScrollComponent>
      ) : (
        <>
          <View style={styles.itemsContainer}>
            {projects.slice(0, sliceParam2).map((item) => (
              <View style={styles.itemContainer} key={item}>
                <ExpandableProjectCard
                  isForDeviationPercentage={isForDeviationPercentage}
                  cardInfo={items[item] as Workload}
                  ciStatus={ciStatus}
                  title={item}
                  web={web}
                />
              </View>
            ))}
          </View>
          {projects?.length > 3 && (
            <View
              style={[
                styles.arrowsContainer,
                {
                  transform: [{ rotate: areAllItemsDisplayed ? '180deg' : '0deg' }],
                },
              ]}
            >
              {areAllItemsDisplayed ? (
                <TouchableOpacity onPress={handleHideItems}>
                  <Arrows width={35} height={35} />
                </TouchableOpacity>
              ) : (
                <TouchableOpacity onPress={handleDisplayMoreItems}>
                  <Arrows width={35} height={35} />
                </TouchableOpacity>
              )}
            </View>
          )}
        </>
      )}
    </DashboardCard>
  );
};

ExpandableProjectsContainer.defaultProps = defaultProps;

export default ExpandableProjectsContainer;
