import { StyleSheet, useWindowDimensions } from 'react-native';

const fontFamily = {
  fontFamily: 'Oxygen-Normal',
};

const useResponsiveStyles = () => {
  const { width } = useWindowDimensions();
  const containerWidth = width < 570 ? '100%' : 400;

  const styles = StyleSheet.create({
    fontFamily,
    container: {
      width: containerWidth,
      paddingHorizontal: 40,
      alignItems: 'center',
      justifyContent: 'center',
    },
    imageContainer: {
      width: 100,
      height: 100,
      borderRadius: 50,
      overflow: 'hidden',
    },
    name: {
      color: '#ffffffa6',
      fontSize: 16,
      marginVertical: 20,
    },
    callTypeContainer: {
      marginBottom: 20,
    },
  });

  return styles;
};

export default useResponsiveStyles;
