import React from 'react';
import { View, Text } from 'react-native';
import OnlyTextInput from '../../atoms/OnlyTextInput';
import responsiveStyles from './calendar-contact-form.style';

interface CalendarContactFormProps {
  customerName: string;
  email: string;
  notes: string;
  onChangeName: (e: string) => void;
  onChangeEmail: (e: string) => void;
  onChangeNotes: (e: string) => void;
}

const CalendarContactForm = ({
  customerName,
  email,
  notes,
  onChangeName,
  onChangeEmail,
  onChangeNotes,
}: CalendarContactFormProps) => {
  const styles = responsiveStyles();

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Contact info</Text>
      <View style={styles.inputContainer}>
        <OnlyTextInput
          placeHolder="Your name"
          label="Your name"
          value={customerName}
          onChange={onChangeName}
        />
      </View>
      <View style={styles.inputContainer}>
        <OnlyTextInput
          placeHolder="Email"
          label="Email"
          value={email}
          onChange={onChangeEmail}
        />
      </View>
      <View style={styles.inputContainer}>
        <OnlyTextInput
          placeHolder="Aditional notes"
          label="Aditional notes"
          value={notes}
          onChange={onChangeNotes}
        />
      </View>
      <Text style={styles.message}>
        Please share anything that will help prepare for our meeting.
      </Text>
    </View>
  );
};

export default CalendarContactForm;
