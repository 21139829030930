import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const styles = StyleSheet.create({
  fontFamily,
  mainContainer: {
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#121212',
  },
  subContainer1: {
    width: '80%',
    paddingLeft: 20,
  },
  subContainer2: {
    width: '20%',
    minHeight: '100%',
    maxHeight: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#262626',
  },
});

export default styles;
