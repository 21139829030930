/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Base } from '../../../types/base';
import Ellipse from '../../../assets/svg/Ellipse.svg';
import EllipseSmall from '../../../assets/svg/EllipseSmall.svg';
import DashboardCard from '../../atoms/DashboardCard';
import styles from './timeline.style';

interface Event extends Base {
  title: string;
  date: Date;
  width?: number;
}

interface TimelineProps extends Base {
  events: Event[];
}

const eventDefaultProps = {
  width: 0,
};

const Event = ({
  title,
  date,
  width,
  web,
}: Event) => {
  const [isSelected, setIsSelected] = useState<boolean>(false);
  const tooltipContent = (
    <View
      style={[
        styles.tooltipMessage,
        styles.positionAbsolute,
        { display: isSelected ? 'flex' : 'none' },
      ]}
    >
      <Text numberOfLines={1} style={styles.text}>{date.toDateString()}</Text>
      <Text numberOfLines={1} style={styles.text}>{title}</Text>
    </View>
  );

  const handleSelect = () => setIsSelected(!isSelected);

  return (
    <TouchableOpacity
      onPress={handleSelect}
      style={[styles.eventContainer, { width: `${width}%` }]}
    >
      {title && date ? tooltipContent : null}
      {web ? (
        <img src={isSelected ? Ellipse : EllipseSmall} alt="ellipse" />
      ) : (
        isSelected ? <Ellipse /> : <EllipseSmall />
      )}
    </TouchableOpacity>
  );
};

Event.defaultProps = eventDefaultProps;

const Timeline = ({ events, web }: TimelineProps) => {
  const handleCalculateWidth = () => {
    const width = 100 / events.length;

    return width;
  };

  return (
    <DashboardCard title="WEEK'S TIMELINE" iconType="" web={web}>
      <View style={[styles.mainContainer, styles.fullWidth]}>
        {events.length ? (
          <>
            <View
              style={[styles.line, styles.fullWidth, styles.positionAbsolute]}
            />
            <View
              style={[styles.row, styles.fullWidth, styles.positionAbsolute]}
            >
              {events.map((item, index) => (
                <Event
                  key={index}
                  title={item.title}
                  date={item.date}
                  width={handleCalculateWidth()}
                  web={web}
                />
              ))}
            </View>
          </>
        ) : (
          <Text style={styles.text}>No events available</Text>
        )}
      </View>
    </DashboardCard>
  );
};

export default Timeline;
