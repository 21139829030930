import React, { SVGProps } from 'react';

const SmallArrow: React.FC<SVGProps<SVGSVGElement>> = ({ color, ...rest }) => (
  <svg
    width="8"
    height="6"
    viewBox="0 0 8 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.65018 5.59239C4.26202 5.84869 3.73452 5.80599 3.3928 5.46427L0.564378 2.63584C0.173853 2.24532 0.173853 1.61216 0.564378 1.22163C0.954902 0.831106 1.58807 0.831106 1.97859 1.22163L4.09991 3.34295L6.22126 1.2216C6.61179 0.83108 7.24495 0.83108 7.63548 1.2216C8.026 1.61213 8.026 2.24529 7.63548 2.63582L4.80705 5.46424C4.80704 5.46426 4.80703 5.46427 4.80702 5.46428C4.75821 5.51308 4.70562 5.55578 4.65018 5.59239Z"
      fill={color || '#D9D9D9'}
    />
  </svg>
);

export default SmallArrow;
