import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  attachmentContainer: {
    flexDirection: 'row',
    backgroundColor: '#8c8c8c',
    borderRadius: 6,
    padding: 5,
    marginBottom: 5,
  },
  attachmentText: {
    fontSize: 14,
    color: '#141414',
    fontWeight: '400',
    marginLeft: 5,
  },
});

export default styles;
