import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Role } from '../shared/types/enums';
import Root from './pages/Root';
import PrivateRoute from './components/privateRoute';
import NotFound from './pages/NotFound';
import { PUBLIC_ROUTES, PRIVATE_ROUTES, CLIENT_ROUTES } from './routes';
import { RootState } from './redux/store';
import './index.css';

const App: React.FC = () => {
  const {
    user: { role },
  } = useSelector((state: RootState) => state.session);

  return (

    <Router>
      <Routes>
        <Route path="/" element={<Root />} />

        {/* public routes */}
        {PUBLIC_ROUTES.map((route) => (

          <Route key={route.path} {...route} />
        ))}

        {/* CLIENT_ROUTES routes */}
        {[...(role === Role.CLIENT
          ? CLIENT_ROUTES
          : PUBLIC_ROUTES)].map(({ element, ...route }) => (

            <Route
              key={route.path}
              element={<PrivateRoute>{element}</PrivateRoute>}
              {...route}
            />
        ))}

        {/* PRIVATE_ROUTES routes */}
        {[...(role !== Role.GUEST
          ? PRIVATE_ROUTES
          : PUBLIC_ROUTES)].map(({ element, ...route }) => (

            <Route
              key={route.path}
              element={<PrivateRoute>{element}</PrivateRoute>}
              {...route}
            />
        ))}

        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
