import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
  },
  text: {
    color: '#A8A8A8',
    fontSize: 12,
    fontWeight: '400',
  },
  optionscontainer: {
    width: '100%',
    marginTop: 20,
  },
  categoryContainer: {
    width: '100%',
  },
  subCategoriesContainer: {
    width: '100%',
    paddingLeft: 30,
  },
});

export default styles;
