import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  textContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    marginRight: 5,
    fontSize: 35,
    fontWeight: '200',
  },
  text2: {
    fontSize: 16,
    fontWeight: '400',
  },
});

export default styles;
