import React, { useState } from 'react';
import { Text, View, TouchableHighlight } from 'react-native';
import Timer from '../../../../assets/svg/timer.svg';
import HoverableItem from '../../../molecules/HoverableItem';
import { DropdownItem } from '../Dropdown';
import styles from './call.style';

interface CallProps {
  onShowUnderlay: () => void;
  onHideUnderlay: () => void;
  setValue: (item: DropdownItem) => void;
  id: string;
  active: string;
  title: string;
  time: string;
  web: boolean;
}

const Call: React.FC<CallProps> = ({
  id,
  title,
  time,
  active,
  web,
  onShowUnderlay,
  onHideUnderlay,
  setValue,
}) => {
  const [isHover, setIsHover] = useState(false);

  const mouseEnter = () => {
    setIsHover(true);
  };

  const mouseLeave = () => {
    setIsHover(false);
  };
  return (
    <HoverableItem onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <TouchableHighlight
        key={id}
        style={isHover && styles.onHoverItem}
        underlayColor="lighter"
        activeOpacity={0.6}
        onShowUnderlay={onShowUnderlay}
        onHideUnderlay={onHideUnderlay}
        onPress={() => setValue({
          id,
          title,
          time,
          active,
        })}
      >
        <View style={styles.containerCall}>
          <View style={styles.left}>
            <Text style={styles.textLeft}>{title}</Text>
          </View>
          <View style={styles.timerIcon}>
            {web ? (
              <img src={Timer} alt="timer" width={12} height={12} />
            ) : (
              <Timer width={12} height={12} />
            )}
          </View>
          <View style={styles.right}>
            <Text style={styles.textRight}>{time}</Text>
          </View>
        </View>
      </TouchableHighlight>
    </HoverableItem>
  );
};

export default Call;
