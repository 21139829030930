import { StyleSheet } from 'react-native';

export default StyleSheet.create({
  button: {
    backgroundColor: '#fff',
  },
  text: {
    color: 'gray',
    textAlign: 'right',
    textTransform: 'uppercase',
    flex: 1,
    fontWeight: '600',
  },
});
