import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import Checked from '../../../assets/svg/checkMark.svg';
import Minus from '../../../assets/svg/minus.svg';
import { Base } from '../../../types/base';
import { FilterCategory } from '../../organisms/IssuesFilter/IssuesFilter';
import styles from './filter-checkbox.style';

interface FilterCheckBoxProps extends Base {
  isChecked?: boolean;
  isCategory?: boolean;
  isSelectAll?: boolean;
  setSelectAll?: Dispatch<SetStateAction<boolean>>;
  category?: string;
  filterOptions: FilterCategory[];
  setFilterOptions: Dispatch<SetStateAction<FilterCategory[]>>;
  disabled?: boolean;
  value: string;
}

const defaultProps = {
  isChecked: false,
  isCategory: false,
  isSelectAll: false,
  disabled: false,
  category: undefined,
  setSelectAll: undefined,
};

const FilterCheckBox = ({
  isChecked,
  isCategory,
  isSelectAll,
  setSelectAll,
  category,
  filterOptions,
  setFilterOptions,
  disabled,
  value,
  web,
}: FilterCheckBoxProps) => {
  const [isSomeSelected, setIsSomeSelected] = useState(false);
  const minusIcon = web ? (
    <img src={Minus} alt="minus" width={10} height={10} />
  ) : (
    <Minus width={10} height={10} />
  );

  const handleCheck = () => {
    const updatedFilterOptions: FilterCategory[] = [...filterOptions];

    if (isCategory) {
      const index = filterOptions.findIndex((item) => item.category === value);
      const categoryToUpdate: FilterCategory = updatedFilterOptions[index];

      updatedFilterOptions[index] = {
        ...categoryToUpdate,
        subcategories: categoryToUpdate.subcategories.map((subCat) => ({
          ...subCat,
          isChecked: false,
        })),
      };

      setFilterOptions(updatedFilterOptions);
    } else if (isSelectAll) {
      const allSelected = updatedFilterOptions.map((item) => ({
        ...item,
        subcategories: item.subcategories.map((subCat) => ({
          ...subCat,
          isChecked: !isChecked,
        })),
      }));

      setSelectAll(!isChecked);
      setFilterOptions(allSelected);
    } else {
      const index = filterOptions.findIndex((item) => item.category === category);
      const categoryToUpdate: FilterCategory = updatedFilterOptions[index];
      const subcategoriesUpdated = [...filterOptions[index].subcategories];
      const subcategoryIndex = categoryToUpdate.subcategories.findIndex((item) => (
        item.label === value
      ));
      const subcategoryToUpdate = categoryToUpdate.subcategories[subcategoryIndex];

      subcategoriesUpdated[subcategoryIndex] = {
        ...subcategoryToUpdate,
        isChecked: !subcategoryToUpdate.isChecked,
      };

      updatedFilterOptions[index] = {
        ...categoryToUpdate,
        subcategories: subcategoriesUpdated,
      };

      setFilterOptions(updatedFilterOptions);
    }
  };

  useEffect(() => {
    if (isCategory) {
      const currentCategory: FilterCategory[] = filterOptions.filter(
        (item) => item.category === value,
      );
      const areThereOptionsSelected = currentCategory[0]?.subcategories.some((subCat) => (
        subCat.isChecked === true));

      setIsSomeSelected(areThereOptionsSelected);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  return (
    <TouchableOpacity
      onPress={handleCheck}
      disabled={disabled}
      style={[styles.mainContainer, { opacity: disabled ? 0.5 : 1 }]}
    >
      {isCategory ? (
        isSomeSelected && (
          <View style={styles.checkbox}>{isChecked && minusIcon}</View>
        )
      ) : (
        <View style={styles.checkbox}>
          {isChecked && (
            web ? (
              <img src={Checked} alt="checked" width={13} height={13} />
            ) : (
              <Checked width={13} height={13} />
            ))}
        </View>
      )}
      <Text style={styles.text}>{value}</Text>
    </TouchableOpacity>
  );
};

FilterCheckBox.defaultProps = defaultProps;

export default FilterCheckBox;
