import React from 'react';
import { View, Text, Platform } from 'react-native';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Spinner from '../../../assets/svg/spinner.svg';
import Button from '../../atoms/Button';
import OnlyTextInput from '../../atoms/OnlyTextInput';
import { Base } from '../../../types/base';
import webStyles from './forgot-password-form.style';

interface ForgotPasswordFormProps extends Base {
  sendData: (param: object) => void;
  isLoading: boolean;
  success: boolean;
  errorMessage: string;
}

const ForgotPasswordForm = ({
  web,
  sendData,
  isLoading,
  success,
  errorMessage,
}: ForgotPasswordFormProps) => {
  const device = Platform.OS;
  const validationSchema = yup.object({
    email: yup
      .string()
      .email('Please enter a valid email')
      .required('Email is required'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema,
    onSubmit: (values) => {
      sendData({ ...values, device });
    },
  });

  return (
    <View style={webStyles.formContainer}>
      <Text style={webStyles.text}>
        Enter your registered email below and we will send instructions to
        change your password.
      </Text>
      <View style={webStyles.inputContainer}>
        <OnlyTextInput
          placeHolder="Email"
          label="Email"
          value={formik.values.email}
          onChange={formik.handleChange('email')}
        />
        {formik.touched.email && formik.errors.email && (
          <Text style={webStyles.textError}>{formik.errors.email}</Text>
        )}
      </View>
      <View style={webStyles.buttonContainer}>
        {!isLoading ? (
          <Button disabled={false} onPress={formik.handleSubmit}>
            send
          </Button>
        ) : (
          <View style={webStyles.spinnerContainer}>
            {web ? (
              <img src={Spinner} alt="spinner" width={35} height={35} />
            ) : (
              <Spinner width={35} height={35} />
            )}
          </View>
        )}
      </View>
      {!success && errorMessage && (
        <Text style={webStyles.apiCallErrorText}>{errorMessage}</Text>
      )}
    </View>
  );
};

export default ForgotPasswordForm;
