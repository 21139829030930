import React, { useState, FC } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { SvgProps } from 'react-native-svg';
import { Base } from '../../../types/base';
import styles from './unassigned-issues';
import DashboardCard from '../../atoms/DashboardCard';
import IssueUnassigned from '../../molecules/IssueUnassigned';
import { IssueProps } from '../../molecules/Issue/Issue';
import Arrows from '../../../assets/svg/pagination-item-ellipsis.svg';

interface IconComponentProps extends Base {
  Icon: string | FC<SvgProps>;
  size: number;
  fill?: string;
}

export interface PRFormatted {
  prName: string;
  devName: string;
  devIcon: string;
  branch1: string;
  branch2: string;
  hoursAgo: number;
  link: string;
}

interface IssueUnassignedProps extends IssueProps {
  type: string;
}

interface UnassignedIssuesProps extends Base {
  items: Array<IssueUnassignedProps>;
}

const IconComponent: React.FC<IconComponentProps> = ({
  web, Icon, size, fill,
}) => (
  <View>
    {web ? (
      <img src={Icon as string} alt="icon" width={size} height={size} />
    ) : (
      <Icon width={size} height={size} fill={fill} />
    )}
  </View>
);

const UnassignedIssuesCard = ({ items, web }: UnassignedIssuesProps) => {
  const [localIssues] = useState<IssueUnassignedProps[]>(items);
  const [areAllItemsDisplayed, setAreAllItemsDisplayed] = useState(false);
  const [sliceParam, setSliceParam] = useState(3);

  // HANDLE IF DISPLAY ALL ITEMS OR ONLY 3...
  const handleDisplayMoreItems = () => {
    setSliceParam(localIssues?.length);
    setAreAllItemsDisplayed(true);
  };
  const handleHideItems = () => {
    setSliceParam(3);
    setAreAllItemsDisplayed(false);
  };

  return (
    <DashboardCard
      title={`UNASSIGNED ISSUES ${localIssues?.length || ''}`}
      height="auto"
      web={web}
    >
      <View style={styles.labelsContainer}>
        <View style={{ width: web ? '5%' : '10%' }} />
        <View style={{ width: web ? '15%' : '30%' }}>
          <Text style={styles.label}>ISSUE</Text>
        </View>
        <View style={{ width: web ? '40%' : '30%' }}>
          <Text style={styles.label}>NAME</Text>
        </View>
        { web && (
        <>
          <View style={[styles.labelcontainer3, { width: web ? '20%' : '35%' }]}>
            <Text style={styles.label}>PROJECT</Text>
          </View>
          <View style={[styles.labelContainer2, { width: web ? '20%' : '35%' }]}>
            <Text style={styles.label}>PRIORITY</Text>
          </View>
        </>
        ) }
      </View>
      {/* Issue Items */}
      <View>
        {
          localIssues?.length > 0 ? (
            localIssues.slice(0, sliceParam).map((item, index) => (
              <IssueUnassigned
                key={index}
                web={web}
                status={item.status}
                priority={item.priority}
                backgroundColor="#262626"
                isForTeamLeadDashboard
                type={item.type}
                projectName={item.projectName}
                issueName={item.issueName}
                projectCode={item.projectCode}
                projectIcon={item.projectIcon}
              />
            ))
          ) : (
            <Text style={{ color: 'white' }}>
              {items?.length === 0 ? 'No items available' : 'So empty, maybe changing filters?'}
            </Text>
          )
        }
      </View>
      {localIssues?.length > 3 && (
        <View
          style={[
            styles.arrowsContainer,
            {
              transform: [{ rotate: areAllItemsDisplayed ? '180deg' : '0deg' }],
            },
          ]}
        >
          <TouchableOpacity
            onPress={areAllItemsDisplayed ? handleHideItems : handleDisplayMoreItems}
          >
            <IconComponent
              web={web}
              Icon={Arrows}
              size={35}
              fill={areAllItemsDisplayed ? '#ffffff' : 'none'}
            />
          </TouchableOpacity>
        </View>
      )}
    </DashboardCard>
  );
};

IconComponent.defaultProps = {
  fill: undefined,
};

export default UnassignedIssuesCard;
