import React, { SVGProps } from 'react';

const Low: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="11"
    height="6"
    viewBox="0 0 11 6"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10 1L5.93085 4L1.8617 1"
      stroke="#FAFAFA"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Low;
