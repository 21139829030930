import React, { Dispatch, SetStateAction } from 'react';
import { Text, View, TouchableOpacity } from 'react-native';
import StatusReportRow from '../../molecules/StatusReportRow';
import styles from './status-report-list.style';

export interface StatusReportListProps {
  period: string;
  sowsId: string;
  startDate: string;
  endDate: string;
  timeSpent: number; // hour
  timeEstimate: number; // hour
  currentWeekStatus: {
    schedule: string;
    budget: string;
    scope: string;
  };
}

interface Props {
  web: boolean | undefined;
  reports: StatusReportListProps[];
  onClickItem: Dispatch<SetStateAction<StatusReportListProps>>;
}

const StatusReportList = ({ reports, onClickItem, web }: Props) => (
  <View style={{ width: '100%' }}>
    <View style={styles.row}>
      <View style={styles.col1}>
        <Text style={styles.label}>PERIOD</Text>
      </View>
      <View style={web ? styles.col2 : styles.col1}>
        <Text style={styles.label}>SoWS ID</Text>
      </View>
      {web ? (
        <>
          <View style={styles.col1}>
            <Text style={styles.label}>START DATE</Text>
          </View>
          <View style={styles.col1}>
            <Text style={styles.label}>END DATE</Text>
          </View>
          <View style={styles.col1}>
            <Text style={styles.label}>TIME</Text>
          </View>
          <View style={[styles.col1, styles.paddingRight10]}>
            <Text style={styles.label}>SCHEDULE</Text>
          </View>
          <View style={[styles.col1, styles.paddingRight10]}>
            <Text style={styles.label}>BUDGET</Text>
          </View>
          <View style={styles.col1}>
            <Text style={styles.label}>SCOPE</Text>
          </View>
        </>
      ) : null}
    </View>
    {reports.map((rp) => (
      <TouchableOpacity key={rp.sowsId} activeOpacity={0.8} onPress={() => onClickItem(rp)}>
        <StatusReportRow
          web={web}
          period={rp.period}
          sowsId={rp.sowsId}
          startDate={rp.startDate}
          endDate={rp.endDate}
          time={`${rp.timeSpent}h/${rp.timeEstimate}h`}
          currentWeekStatus={rp.currentWeekStatus}
        />
      </TouchableOpacity>
    ))}
  </View>
);

export default StatusReportList;
