export const buttonTheme = (disabled: boolean, type: string, variant: string) => {
  let theme = {
    textColor: '',
    fontWeight: '600',
    letterSpacing: 1,
    buttonTheme: {
      backgroundColor: '',
      borderColor: '',
      borderWidth: 0,
    },
    underlayColor: '',
    activeOpacity: 0.6,
  };
  const primaryColor = () => (type === 'primary' ? '#0ABAB5' : 'white');
  const primaryColor2 = () => (type === 'primary' ? 'white' : 'black');

  if (variant === 'text') {
    theme = {
      textColor: disabled ? '#D8D8D8' : primaryColor(),
      fontWeight: '600',
      letterSpacing: 1,
      buttonTheme: { backgroundColor: 'transparent', borderColor: 'transparent', borderWidth: 0 },
      underlayColor: 'transparent',
      activeOpacity: 0.6,
    };
  } else if (variant === 'outlined') {
    theme = {
      buttonTheme: {
        backgroundColor: 'transparent',
        borderColor: disabled ? '#D8D8D8' : primaryColor(),
        borderWidth: 2,
      },
      textColor: disabled ? '#D8D8D8' : primaryColor(),
      fontWeight: '600',
      letterSpacing: 1,
      underlayColor: type === 'light' ? 'white' : '#0ABAB5',
      activeOpacity: 0.9,
    };
  } else {
    theme = {
      buttonTheme: {
        backgroundColor: disabled ? '#D8D8D8' : primaryColor(),
        borderColor: 'transparent',
        borderWidth: 0,
      },
      textColor: disabled ? 'white' : primaryColor2(),
      fontWeight: '600',
      letterSpacing: 1.5,
      underlayColor: type === 'light' ? 'white' : '#0ABAB5',
      activeOpacity: 0.6,
    };
  }
  return theme;
};
