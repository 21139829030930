import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const styles = StyleSheet.create({
  fontFamily,
  container: {
    width: '100%',
    display: 'flex',
  },
  progressBarContainer: {
    width: '100%',
    maxWidth: '100%',
    borderRadius: 40,
  },
  progressBar: {
    width: '100%',
    maxWidth: '100%',
    height: 7,
    borderRadius: 40,
    backgroundColor: '#00000036',
  },
  progressPercentage: {
    height: 7,
    position: 'absolute',
    borderRadius: 40,
    maxWidth: '100%',
  },
  estimatedHoursContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  text: {
    color: '#BFBFBF',
    fontWeight: '400',
    marginLeft: 3,
    lineHeight: 24,
    fontSize: 13,
    fontFamily: 'Roboto',
  },
  text2: {
    color: '#BFBFBF',
    fontSize: 35,
    fontWeight: '300',
    fontFamily: 'Roboto',
  },
  hoursContainer: {
    padding: 5,
    alignSelf: 'flex-end',
    backgroundColor: '#FFFFFF',
    borderRadius: 1,
    position: 'absolute',
    bottom: 13,
    shadowColor: '#000000',
    shadowOffset: { width: -3, height: 4 },
    shadowOpacity: 0.3,
    shadowRadius: 12,
    justifyContent: 'center',
    alignItems: 'center',
  },
  hoursContainerArrow: {
    position: 'absolute',
    width: 0,
    height: 0,
    borderTopWidth: 10,
    borderTopColor: '#FFFFFF',
    borderRightWidth: 9,
    borderRightColor: 'transparent',
    borderLeftWidth: 10,
    borderLeftColor: 'transparent',
    top: 17,
  },
  hidden: {
    backgroundColor: 'transparent',
    color: 'transparent',
  },
  hours: {
    color: '#000000',
    fontSize: 12,
  },
  hoursHidden: {
    color: 'transparent',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  workedHoursApp: {
    color: '#BFBFBF',
    fontSize: 18,
    fontWeight: '700',
  },
  barContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  workedHoursContainerClientDashboard: {
    width: '20%',
    flexDirection: 'row',
    fontFamily: 'Roboto',
    alignItems: 'center',
  },
  estimatedHoursContaienrClientDashboard: {
    width: '15%',
    alignItems: 'flex-end',
  },
});

export default styles;
