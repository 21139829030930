import React from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import { Base } from '../../../types/base';
import Figma from '../../../assets/svg/figma_logo.svg';
import Pdf from '../../../assets/svg/pdf.svg';
import Url from '../../../assets/svg/link.svg';
import styles from './attachment.style';

// I'm not sure what types should be used, please be sure to update the types
interface AttachmentProps extends Base {
  attachmentName: string;
  onClick?: () => void;
  type: 'pdf' | 'url' | 'figma'
}

const defaultProps = {
  onClick: undefined,
};

const Attachment = ({
  attachmentName,
  type,
  onClick,
  web,
}: AttachmentProps) => {
  const getIcon = (iconType: string) => {
    switch (iconType) {
      case 'pdf':
        return web ? <img src={Pdf} alt="pdf" width={15} height={15} /> : <Pdf width={15} height={15} />;
      case 'url':
        return web ? <img src={Url} alt="pdf" width={15} height={15} /> : <Url width={15} height={15} />;
      case 'figma':
        return web ? <img src={Figma} alt="figma" width={15} height={15} /> : <Figma width={15} height={15} />;
      default:
        return web ? <img src={Pdf} alt="pdf" width={15} height={15} /> : <Pdf width={15} height={15} />;
    }
  };

  return (
    <TouchableOpacity onPress={onClick}>
      <View style={styles.attachmentContainer}>
        {getIcon(type)}
        <Text style={styles.attachmentText}>{attachmentName}</Text>
      </View>
    </TouchableOpacity>
  );
};

Attachment.defaultProps = defaultProps;

export default Attachment;
