import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  titleContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
  },
  title: {
    color: '#D9D9D9',
    fontSize: 20,
    fontWeight: '700',
    lineHeight: 22,
    letterSpacing: 1,
    paddingRight: 20,
    fontFamily: 'Roboto Mono',
  },
  titleApp: {
    color: '#D9D9D9',
    fontSize: 20,
    fontWeight: '700',
    lineHeight: 22,
    letterSpacing: 1,
    fontFamily: 'Roboto Mono',
  },
  labelsContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 15,
  },
  projectContainer: {
    width: '30%',
  },
  codeContainer: {
    alignItems: 'center',
  },
  priorityContainer: {
    width: '20%',
    alignItems: 'center',
  },
  iconContainer: {
    width: '15%',
    alignItems: 'center',
  },
  label: {
    color: '#8C8C8C',
    fontSize: 12,
    fontWeight: '700',
    textTransform: 'uppercase',
    letterSpacing: 1.5,
  },
  blockedIssuesLabel: {
    color: '#F5F5F5',
    fontSize: 12,
    fontWeight: '700',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: 'grey',

  },
  issuesAmount: {
    color: '#D9D9D9',
    fontSize: 24,
    fontWeight: '700',
  },
  itemsContainer: {
    width: '100%',
  },
  soEmptyMessage: {
    color: '#FFFFFF',
    alignSelf: 'center',
    marginTop: 20,
  },
  weekIconContainer: {
    paddingRight: 0,
    display: 'flex',
    flexDirection: 'row',
  },
  arrowsContainer: {
    width: '100%',
    alignItems: 'center',
    top: 10,
  },
  width70: {
    width: '70%',
  },
  width30: {
    width: '30%',
  },
  justifyContentEnd: {
    justifyContent: 'flex-end',
  },
  marginLeft10: {
    marginLeft: 10,
  },
});

export default styles;
