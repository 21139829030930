import React from 'react';
import { View } from 'react-native';
import Header from '../../molecules/Header';
import ResetPasswordForm from '../../organisms/ResetPasswordForm';
import { Base } from '../../../types/base';
import webStyles from './reset-password.style';

interface NewPasswordProps extends Base {
  resetPasssword: (param: string) => void;
  isLoading: boolean;
  success: boolean;
  errorMessage: string;
}

const ResetPasswordTemplate = ({
  web,
  resetPasssword,
  isLoading,
  success,
  errorMessage,
}: NewPasswordProps) => (
  <View style={webStyles.container}>
    <Header title="New Password" />
    <ResetPasswordForm
      web={web}
      resetPasssword={resetPasssword}
      isLoading={isLoading}
      success={success}
      errorMessage={errorMessage}
    />
  </View>
);

export default ResetPasswordTemplate;
