import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const styles = StyleSheet.create({
  fontFamily,
  contentContainer: {
    position: 'relative',
  },
  container: {
    minWidth: 50,
    padding: 10,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    position: 'absolute',
    bottom: 28,
    borderRadius: 2,
  },
  childrenContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
  },
  tooltipText: {
    fontSize: 10,
    lineHeight: 13,
  },
  beakContainer: {
    position: 'absolute',
    bottom: 0,
    transform: [{ translateY: 12 }],
  },
  justifyCenter: {
    justifyContent: 'center',
    alignItems: 'center',
  },
  marginBottom10: {
    marginBottom: 10,
  },
});

export default styles;
