const sizes = [
  // {
  //   title: 'Select',
  //   itemValue: '',
  //   id: '0',
  // },
  {
    title: '1 to 4',
    itemValue: 'FROM_1_TO_4',
    id: '1',
  },
  {
    title: '5 to 9',
    itemValue: 'FROM_5_TO_9',
    id: '2',
  },
  {
    title: '10 to 19',
    itemValue: 'FROM_10_TO_19',
    id: '3',
  },
  {
    title: '20 to 49',
    itemValue: 'FROM_20_TO_49',
    id: '4',
  },
  {
    title: '50 to 99',
    itemValue: 'FROM_50_TO_99',
    id: '5',
  },
  {
    title: '100 to 249',
    itemValue: 'FROM_100_TO_249',
    id: '6',
  },
  {
    title: '250 to 499',
    itemValue: 'FROM_250_TO_499',
    id: '7',
  },
  {
    title: '500 to 999',
    itemValue: 'FROM_500_TO_999',
    id: '8',
  },
  {
    title: '1000 or more',
    itemValue: 'FROM_1000_TO_MORE',
    id: '9',
  },
];

const entities = [
  // {
  //   title: 'Select',
  //   itemValue: '',
  //   id: '0',
  // },
  {
    title: 'Sole proprietorship',
    itemValue: 'SOLE_PROPRIETORSHIP',
    id: '1',
  },
  {
    title: 'Partnership',
    itemValue: 'PARTNERSHIP',
    id: '2',
  },
  {
    title: 'Corporation',
    itemValue: 'CORPORATION',
    id: '3',
  },
  {
    title: 'S Corporation',
    itemValue: 'S_CORPORATION',
    id: '4',
    time: 'select',
  },
  {
    title: 'LLC',
    itemValue: 'LLC',
    id: '5',
  },
  {
    title: 'Other',
    itemValue: 'OTHER',
    id: '6',
  },
];

const industries = [
  // {
  //   title: 'Select',
  //   itemValue: '',
  //   id: '0',
  // },
  {
    title: 'Accounting',
    itemValue: 'ACCOUNTING',
    id: '1',
  },
  {
    title: 'Administration and office support',
    itemValue: 'ADMINISTRATION_AND_OFFICE_SUPPORT',
    id: '2',
  },
  {
    title: 'Advertising',
    itemValue: 'ADVERTISING',
    id: '3',
  },
  {
    title: 'Artis and media',
    itemValue: 'ARTS_AND_MEDIA',
    id: '4',
  },
  {
    title: 'Banking and financial services',
    itemValue: 'BANKING_AND_FINANCIAL_SERVICES',
    id: '5',
  },
  {
    title: 'Call center and customer service',
    itemValue: 'CALL_CENTRE_AND_CUSTOMER_SERVICE',
    id: '6',
  },
  {
    title: 'Community services and development',
    itemValue: 'COMMUNITY_SERVICES_AND_DEVELOPMENT',
    id: '7',
  },
  {
    title: 'Construction',
    itemValue: 'CONSTRUCTION',
    id: '8',
  },
  {
    title: 'Consulting and strategy',
    itemValue: 'CONSULTING_AND_STRATEGY',
    id: '9',
  },
  {
    title: 'Design and architecture',
    itemValue: 'DESIGN_AND_ARCHITECTURE',
    id: '10',
  },
  {
    title: 'Education and training',
    itemValue: 'EDUCATION_AND_TRAINING',
    id: '11',
  },
  {
    title: 'Engineering',
    itemValue: 'ENGINEERING',
    id: '12',
  },
  {
    title: 'Farming',
    itemValue: 'FARMING',
    id: '13',
  },
  {
    title: 'Animals and conservation',
    itemValue: 'ANIMALS_AND_CONSERVATION',
    id: '14',
  },
  {
    title: 'Government and defence',
    itemValue: 'GOVERNMENT_AND_DEFENCE',
    id: '15',
  },
  {
    title: 'Healthcare and medical',
    itemValue: 'HEALTHCARE_AND_MEDICAL',
    id: '16',
  },
  {
    title: 'Hospitality and tourism',
    itemValue: 'HOSPITALITY_AND_TOURISM',
    id: '17',
  },
  {
    title: 'Human resources and recruitment',
    itemValue: 'HUMAN_RESOURCES_AND_RECRUITMENT',
    id: '18',
  },
  {
    title: 'Information and communication technology',
    itemValue: 'INFORMATION_AND_COMMUNICATION_TECHNOLOGY',
    id: '19',
  },
  {
    title: 'Insurance and superannuation',
    itemValue: 'INSURANCE_AND_SUPERANNUATION',
    id: '20',
  },
  {
    title: 'Legal',
    itemValue: 'LEGAL',
    id: '21',
  },
  {
    title: 'Manufacturing',
    itemValue: 'MANUFACTURING',
    id: '22',
  },
  {
    title: 'Transport and logistics',
    itemValue: 'TRANSPORT_AND_LOGISTICS',
    id: '23',
  },
  {
    title: 'Mining',
    itemValue: 'MINING',
    id: '24',
  },
  {
    title: 'Resources and energy',
    itemValue: 'RESOURCES_AND_ENERGY',
    id: '25',
  },
  {
    title: 'Real estate and property',
    itemValue: 'REAL_ESTATE_AND_PROPERTY',
    id: '26',
  },
  {
    title: 'Sales',
    itemValue: 'SALES',
    id: '27',
  },
  {
    title: 'Science and technology',
    itemValue: 'SCIENCE_AND_TECHNOLOGY',
    id: '28',
  },
  {
    title: 'Sport and recreation',
    itemValue: 'SPORT_AND_RECREATION',
    id: '29',
  },
  {
    title: 'Trades and services',
    itemValue: 'TRADES_AND_SERVICES',
    id: '30',
  },
  {
    title: 'Other',
    itemValue: 'OTHER',
    id: '31',
  },
];

export { sizes, entities, industries };
