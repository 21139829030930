import React from 'react';
import { View, Text } from 'react-native';
import { Base } from '../../../types/base';
import Calendar from './Calendar';
import responsiveStyles from './calendar-day-selection.style';

interface CalendarDaySelectionProps extends Base {
  onChange: (e: string) => void;
}

const CalendarDaySelection = ({ web, onChange }: CalendarDaySelectionProps) => {
  const styles = responsiveStyles();

  return (
    <View style={styles.container}>
      <Text style={styles.title}>Select day</Text>
      <Calendar web={web} onChange={onChange} />
    </View>
  );
};

export default CalendarDaySelection;
