import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  bannersContainer: {
    marginBottom: 20,
    marginTop: 20,
  },
  subText: {
    textAlign: 'left',
    paddingTop: 20,
    fontSize: 16,
    color: '#FFFFFF',
  },
  spinnerContainer: {
    width: '100%',
    alignItems: 'center',
  },
});

export default styles;
