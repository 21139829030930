import React, { SVGProps } from 'react';

const OnTrack: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg {...props} xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
    <path d="M6 0C2.68661 0 0 2.68661 0 6C0 9.31339 2.68661 12 6 12C9.31339 12 12 9.31339 12 6C12 2.68661 9.31339 0 6 0ZM6 10.9821C3.24911 10.9821 1.01786 8.75089 1.01786 6C1.01786 3.24911 3.24911 1.01786 6 1.01786C8.75089 1.01786 10.9821 3.24911 10.9821 6C10.9821 8.75089 8.75089 10.9821 6 10.9821Z" fill="#21EBE5" />
    <path d="M9.68753 3.87012H9.05941C8.9228 3.87012 8.79289 3.93574 8.71253 4.04824L6.60717 6.96789L5.6536 5.64467C5.57324 5.53351 5.44467 5.46655 5.30673 5.46655H4.6786C4.59155 5.46655 4.54066 5.56565 4.59155 5.63664L6.2603 7.95092C6.29972 8.00594 6.35169 8.05078 6.4119 8.08171C6.4721 8.11264 6.53882 8.12877 6.6065 8.12877C6.67419 8.12877 6.7409 8.11264 6.80111 8.08171C6.86132 8.05078 6.91329 8.00594 6.95271 7.95092L9.77325 4.04021C9.82548 3.96922 9.77458 3.87012 9.68753 3.87012Z" fill="#21EBE5" />
    <path d="M7.78323 3.87012H7.15511C7.0185 3.87012 6.88859 3.93574 6.80823 4.04824L4.70288 6.96789L3.7493 5.64467C3.66895 5.53351 3.54038 5.46655 3.40243 5.46655H2.7743C2.68725 5.46655 2.63636 5.56565 2.68725 5.63664L4.356 7.95092C4.39542 8.00594 4.44739 8.05078 4.5076 8.08171C4.56781 8.11264 4.63452 8.12877 4.70221 8.12877C4.76989 8.12877 4.83661 8.11264 4.89681 8.08171C4.95702 8.05078 5.00899 8.00594 5.04841 7.95092L7.86895 4.04021C7.92118 3.96922 7.87029 3.87012 7.78323 3.87012Z" fill="#21EBE5" />
  </svg>
);

export default OnTrack;
