import { toast } from 'react-hot-toast';

// singleton class
export class Alert {
  constructor() {
    this.canNotify = true;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new Alert();
    }
    return this.instance;
  }

  notify(message) {
    if (this.canNotify) {
      this.canNotify = false;
      toast(message, {
        icon: '✅',
        style: {
          fontFamily: 'Oxygen',
          borderRadius: '10px',
          background: '#333',
          color: '#fff',
        },
      });
      setTimeout(() => {
        this.canNotify = true;
      }, 10 * 1000); // updates on 10 seconds
    }
  }
}
