import React from 'react';
import { View, Text, ImageBackground } from 'react-native';
import styles from './avatar.style';

interface AvatarProps {
  description?: string;
  size: 'xs' | 'small' | 'medium' | 'large' | 'xLarge';
  color?: string;
  imgUrl?: string;
}

const defaultProps = {
  description: '',
  color: '',
  imgUrl: undefined,
};

const Avatar = ({
  description, size, color, imgUrl,
}: AvatarProps) => (
  <View>
    {imgUrl ? (
      <ImageBackground
        source={{ uri: imgUrl }}
        resizeMode="cover"
        style={styles[size]}
        imageStyle={styles.avatar}
      />
    ) : (
      <View style={{ ...styles.avatar, ...styles[size], backgroundColor: color }}>
        <Text style={{ fontSize: styles[size].fontSize, color: styles.avatar.color }}>
          {description}
        </Text>
      </View>
    )}
  </View>
);

Avatar.defaultProps = defaultProps;

export default Avatar;
