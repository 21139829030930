import React, { useState } from 'react';
import { View } from 'react-native';
import { useSelector } from 'react-redux';
import { Toaster } from 'react-hot-toast';
import Spinner from '../../../shared/assets/svg/spinner.svg';
import DashboardTemplate from '../../../shared/components/templates/Dashboard';
import { eventItems } from './dummyData';
import styles from './dashboard.style';
import { RootState } from '../../redux/store';
import { Role } from '../../../shared/types/enums';
import TeamLeadDashboardWeb from '../../../shared/components/templates/TeamLeadDashboardWeb';
import ClientDashboardTemplate from '../../../shared/components/templates/ClientDashboard';
import { useDashboard } from '../../../shared/hooks/useDashboard';
import { OperationsState } from '../../../shared/types/TeamLeadDashboard';
import { Alert } from '../../helpers/Alert.js';
import { ClientsDashboardDto } from '../../../shared/types/ClientDashboardDto';
import PDFWebViewer from '../../components/PDFWebViewer';

const onUpdate = () => Alert.getInstance().notify('Dashboard Updated!');

const Dashboard = () => {
  const { user } = useSelector((state: RootState) => state.session);
  const [pdf, setPdf] = useState<string>('');
  const [reload, setReload] = useState<number>(0);

  const { dashboard, isLoading } = useDashboard(user.role, onUpdate);

  const handleReload = () => {
    setReload(reload + 1);
  };

  return isLoading || !dashboard ? (
    <View style={[styles.container, styles.centerContent]}>
      <img src={Spinner} width={30} height={30} alt="spinner" />
    </View>
  ) : (
    <View style={styles.container}>
      {user.role === Role.CLIENT && dashboard && (
        <ClientDashboardTemplate
          web
          dashboard={dashboard as ClientsDashboardDto}
          pdfViewerComponent={<PDFWebViewer source={pdf} onError={(error) => console.log(error)} />}
          setPdf={setPdf}
        />
      )}
      {user.role === Role.TEAM_LEAD && dashboard && (
        <TeamLeadDashboardWeb
          eventsItems={eventItems}
          props={dashboard}
          userName={user.firstName}
          web
        />
      )}
      {/* {user.role === Role.DEVELOPER && (
        <DashboardTemplate
          eventsItems={eventItems}
          issuesItems={issuesItems}
          projectsItems={projectsItems}
          issueSummaryItems={issueSummaryItems}
          blockedIssuesItems={blockedIssuesItems}
          web
        />
      )} */}
      {user.role === Role.PROJECT_MANAGEMENT && (
        <DashboardTemplate
          eventsItems={(dashboard as OperationsState)?.eventItems}
          projectsItems={(dashboard as OperationsState)?.projectItems}
          issuesItems={(dashboard as OperationsState)?.issuesDueToday}
          issueSummaryItems={(dashboard as OperationsState)?.issueSummaryItems}
          blockedIssuesItems={(dashboard as OperationsState)?.blockedIssues}
          pastIssuesDueTodayItems={(dashboard as OperationsState)?.pastIssuesDueToday}
          upcomingProjects={(dashboard as OperationsState)?.upcomingProjects}
          onReload={handleReload}
          reload={reload}
          timeout={Date.now() + 1000 * 60 * 5}
          web
        />
      )}
      {/* {user.role === Role.ADMIN && (
        <DashboardTemplate
          eventsItems={eventItems}
          issuesItems={issuesItems}
          projectsItems={projectsItems}
          issueSummaryItems={issueSummaryItems}
          blockedIssuesItems={blockedIssuesItems}
          web
        />
      )} */}
      <Toaster position="top-center" reverseOrder={false} />
    </View>
  );
};

export default Dashboard;
