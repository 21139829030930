import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  fullWidth: {
    width: '100%',
  },
  row: {
    flexDirection: 'row',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  alignSpaceBetween: {
    justifyContent: 'space-between',
  },
  titleContainer: {
    borderBottomColor: '#615f5e',
    borderBottomWidth: 1,
  },
  title: {
    color: '#D9D9D9',
    fontSize: 20,
    fontFamily: 'Roboto Mono',
    fontWeight: '700',
    lineHeight: 22,
    letterSpacing: 1.2,
  },
  labelsContainer: {
    paddingHorizontal: 15,
    paddingTop: 20,
    paddingBottom: 15,
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: '700',
    color: '#AAAAAA',
    lineHeight: 22,
    letterSpacing: 1.8,
    textTransform: 'uppercase',
  },
  rowItem: {
    marginVertical: 5,
    borderRadius: 4,
    alignItems: 'center',
  },
  noItemsMessage: {
    color: '#FFFFFF',
    alignSelf: 'center',
    marginVertical: 20,
  },
  item: {
    fontSize: 14,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: '#D9D9D9',
  },
  hasBackgroundStyle: {
    padding: 20,
    borderRadius: 20,
    backgroundColor: '#1F1F1F',
  },
  childrenContainer: {
    marginTop: 20,
    marginBottom: 10,
  },
  paddingRight: {
    paddingRight: 20,
  },
  marginVertical10: {
    marginVertical: 10,
  },
  paddingVertical10: {
    paddingVertical: 10,
  },
  noItemsText: {
    alignSelf: 'center',
    color: '#D9D9D9',
  },
  alignTextCenter: {
    textAlign: 'center',
  },
});

export default styles;
