import React, { SVGProps } from 'react';

const Beak: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="12"
    height="14"
    viewBox="0 0 12 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M9.0875 2.28027L9.09375 2.27715H9.09688L6 0.499023L2.90469 2.27715H2.90156L2.9125 2.28184L0.367188 3.74902V10.249L6 13.499L11.6328 10.249V3.74902L9.0875 2.28027ZM5.54219 12.1803L3.30625 10.8896V8.7084L1.28125 7.5584V4.82559L5.54219 7.2834V12.1803ZM1.72188 4.02246L3.82656 2.80684L5.99688 4.05215L8.17031 2.80371L10.2828 4.02246L6.00313 6.49121L1.72188 4.02246ZM10.7188 7.56621L8.70625 8.7084V10.8818L6.45781 12.1787V7.28652L10.7188 4.82871V7.56621Z" fill="#959595" />
  </svg>
);

export default Beak;
