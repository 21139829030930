import React from 'react';
import { View, Text, useWindowDimensions } from 'react-native';
import DB from '../../../assets/icons/DBIcon';
import WebApp from '../../../assets/icons/WebApp';
import UI from '../../../assets/icons/UI';
import DBMobile from '../../../assets/svg/DB.svg';
import WebAppMobile from '../../../assets/svg/WebApp.svg';
import UIMobile from '../../../assets/svg/UI.svg';
import ProjectLabel from '../../atoms/ProjectLabel';
import { Base } from '../../../types/base';
import Button from '../../atoms/Button';
import Dropdown from '../../atoms/Dropdown';
import SoWSLabel from '../../atoms/SoWSLabel';
import ProjectPerformanceCards from '../../molecules/ProjectPerformanceCards';
import { StatusType } from '../../atoms/SoWSLabel/SoWSLabel';
import styles from './SoWSCard.style';

export interface SoWSCardProps extends Base {
  id: string;
  issueNumber?: number | string;
  startDay: string;
  deliveryDay: string;
  projectType?: string;
  currentWeekStatus: {
    schedule: string;
    budget: string;
    scope: string;
  };
  status?: StatusType;
  period?: string;
  isForSoWS?: boolean;
  navigateStatusReportsFunc?: () => void;
}

const defaultProps = {
  web: false,
  issueNumber: '',
  projectType: '',
  status: '',
  period: '',
  isForSoWS: false,
  navigateStatusReportsFunc: undefined,
};

const SoWSCard: React.FC<SoWSCardProps> = ({
  id,
  issueNumber,
  startDay,
  deliveryDay,
  projectType,
  currentWeekStatus,
  web,
  status,
  period,
  isForSoWS,
  navigateStatusReportsFunc,
}) => {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;

  const typesProject = [
    {
      id: '1',
      title: 'Web App',
      ItemIcon: web ? <WebApp /> : <WebAppMobile />,
    },
    {
      id: '2',
      title: 'UI',
      ItemIcon: web ? <UI /> : <UIMobile />,
    },
    {
      id: '3',
      title: 'DB',
      ItemIcon: web ? <DB /> : <DBMobile />,
    },
  ];

  return (
    <View style={styles.container}>
      <View style={[!isMobile && styles.row, { zIndex: 999 }]}>
        <View style={[styles.flex3, isMobile && styles.bottomLine, { zIndex: 90 }]}>
          <View>
            <Text style={styles.title}>SoWS ID</Text>
            <View style={!isMobile && styles.row}>
              <View style={styles.flex2}>
                <Text style={[styles.id, isMobile ? styles.marginTop10 : styles.marginTop20]}>
                  {`[${id}]`}
                </Text>
              </View>
              {isForSoWS && (
                <View
                  style={[
                    styles.flex1,
                    !isMobile && styles.alignContentBottom,
                    !isMobile && styles.marginTop10,
                  ]}
                >
                  <Dropdown
                    type="secondary"
                    label=""
                    items={typesProject}
                    placeholder="Type Issue"
                    iconColor="#D9D9D9"
                    placeHolderColor="#D9D9D9"
                    isLeftAligned
                    web={web}
                  />
                </View>
              )}
            </View>
          </View>
        </View>
        <View
          style={[
            styles.flex1,
            isMobile && styles.marginTop20,
            isMobile && styles.bottomLine,
            web && isMobile && styles.paddingBottom15,
            { zIndex: 89 },
          ]}
        >
          <Text style={styles.title}>{isForSoWS ? '#ISSUE' : 'PERIOD'}</Text>
          <Text style={[styles.issueNumber, isMobile ? styles.marginTop10 : styles.marginTop20]}>
            {isForSoWS ? issueNumber : period}
          </Text>
        </View>
        <View
          style={[
            styles.flex1,
            isMobile && styles.marginTop20,
            isMobile && styles.bottomLine,
            web && isMobile && styles.paddingBottom15,
            { zIndex: 89 },
          ]}
        >
          <Text style={styles.title}>START DAY</Text>
          <Text style={[styles.lightText, isMobile ? styles.marginTop10 : styles.marginTop20]}>
            {startDay}
          </Text>
        </View>
        <View
          style={[
            styles.flex1,
            isMobile ? styles.marginTop20 : styles.paddingRight,
            isMobile && styles.bottomLine,
            web && isMobile && styles.paddingBottom15,
            { zIndex: 89 },
          ]}
        >
          <Text style={styles.title}>DELIVERY DAY</Text>
          <Text style={[styles.lightText, isMobile ? styles.marginTop10 : styles.marginTop20]}>
            {deliveryDay}
          </Text>
        </View>
        <View
          style={[
            styles.flex1,
            isMobile && styles.marginTop20,
            isMobile && styles.bottomLine,
            web && isMobile && styles.paddingBottom15,
            { zIndex: 89 },
          ]}
        >
          <Text style={styles.title}>STATUS</Text>
          <View style={isMobile ? styles.marginTop10 : styles.marginTop20}>
            <SoWSLabel status={status} web={web} />
          </View>
        </View>
      </View>

      <View
        style={[
          !isMobile && styles.row,
          isMobile ? styles.marginTop20 : styles.bottomRow,
          { zIndex: 990 },
        ]}
      >
        {isForSoWS ? (
          <>
            <View style={[styles.flex3, isMobile && styles.bottomLine]}>
              <Text style={styles.title}>DEFINITION OF DONE</Text>
              <Text
                style={[styles.typeDescription, isMobile ? styles.marginTop10 : styles.marginTop20]}
              >
                {projectType}
              </Text>
            </View>
            <View
              style={[
                styles.flex3,
                isMobile ? styles.marginTop20 : styles.paddingRight,
                isMobile && styles.bottomLine,
              ]}
            >
              <Text style={styles.title}>CURRENT WEEK STATUS</Text>
              <View
                style={[styles.spaceBetween, isMobile ? styles.marginTop10 : styles.marginTop20]}
              >
                <ProjectLabel
                  label="Schedule"
                  isForSchedule
                  isDanger={false}
                  data={currentWeekStatus.schedule}
                  web={web}
                />
                <ProjectLabel
                  label="Budget"
                  isForBudget
                  isDanger={false}
                  data={currentWeekStatus.budget}
                  web={web}
                />
                <ProjectLabel
                  label="Scope"
                  isForScope
                  isDanger={false}
                  data={currentWeekStatus.scope}
                  web={web}
                />
              </View>
            </View>
            <View style={[styles.flex1, styles.alignContentBottom, isMobile && styles.marginTop20]}>
              <Button
                onPress={navigateStatusReportsFunc}
                style={styles.statusReportButton}
                small
                uppercase={false}
              >
                See Status Reports
              </Button>
            </View>
          </>
        ) : (
          <>
            <View style={styles.flex2} />
            <View style={[styles.flex3, !isMobile && styles.paddingLeft]}>
              <ProjectPerformanceCards
                schedulePercentage={12}
                budgetPercentage={2}
                scope={32}
                web={web}
                dark
              />
            </View>
          </>
        )}
      </View>
    </View>
  );
};

SoWSCard.defaultProps = defaultProps;

export { SoWSCard };
