import React, { FC, useState } from 'react';
import { Text, View, TextInput } from 'react-native';
import { InputBase } from '../../../types/inputs';
import styles from '../../../styles/inputs.style';

export interface OnlyTextInputProps extends InputBase {
  placeHolder?: string;

  value?: string;

  label?: string;

  onChange?: (val: string) => void;

  require?: boolean;
}

const defaultProps = {
  web: false,
  placeHolder: '',
  value: '',
  label: '',
  onChange: undefined,
  require: false,
};

const OnlyTextInput: FC<OnlyTextInputProps> = ({
  placeHolder,
  value,
  label,
  onChange,
  require = true,
}) => {
  const [val, setVal] = useState(value);
  const [inputStyle, setInputStyle] = useState(false);

  const onChangeLocal = (str: string) => {
    if (onChange) {
      onChange(str);
    }
    setVal(str);
  };

  const onBlurLocal = () => {
    setInputStyle(false);
  };

  const inputContainerStyle = () => (val !== '' ? styles.inputContainer : styles.inputContainerFilled);

  return (
    <View
      style={inputStyle ? styles.inputContainerFocus : inputContainerStyle()}
    >
      {label && val !== '' && (
        <Text style={inputStyle ? styles.labelFocus : styles.label}>
          {label}
          {require}
        </Text>
      )}
      <View style={styles.iconContainer}>
        <TextInput
          style={styles.input}
          value={val}
          onChangeText={(e) => onChangeLocal(e)}
          onBlur={onBlurLocal}
          onFocus={() => setInputStyle(true)}
          placeholderTextColor="#ffffffa6"
          placeholder={require ? placeHolder : placeHolder}
        />
      </View>
    </View>
  );
};

OnlyTextInput.defaultProps = defaultProps;

export default OnlyTextInput;
