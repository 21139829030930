import React, { SVGProps } from 'react';

const Close: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="14px"
    height="14px"
    viewBox="0 0 14 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    {...props}
  >
    <g id="Icons" stroke="none" fill="none" fillRule="evenodd">
      <g id="Rounded" transform="translate(-281.000000, -3481.000000)">
        <g id="Navigation" transform="translate(100.000000, 3378.000000)">
          <g id="-Round-/-Navigation-/-close" transform="translate(176.000000, 98.000000)">
            <g>
              <polygon id="Path" points="0 0 24 0 24 24 0 24" />
              <path
                d="M18.3,5.71 C17.91,5.32 17.28,5.32 16.89,5.71 L12,10.59 L7.11,5.7 C6.72,5.31 6.09,5.31 5.7,5.7 C5.31,6.09 5.31,6.72 5.7,7.11 L10.59,12 L5.7,16.89 C5.31,17.28 5.31,17.91 5.7,18.3 C6.09,18.69 6.72,18.69 7.11,18.3 L12,13.41 L16.89,18.3 C17.28,18.69 17.91,18.69 18.3,18.3 C18.69,17.91 18.69,17.28 18.3,16.89 L13.41,12 L18.3,7.11 C18.68,6.73 18.68,6.09 18.3,5.71 Z"
                id="🔹-Icon-Color"
                fill="#555555"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default Close;
