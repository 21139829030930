import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    borderRadius: 4,
    paddingVertical: 10,
    paddingHorizontal: 15,
    marginTop: 5,
  },
  fullWidth: {
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  row2: {
    height: 45,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  alignItems: {
    alignItems: 'center',
  },
  priorityContainer: {
    width: '20%',
    alignItems: 'center',
  },
  iconContainer: {
    width: '15%',
  },
  descriptionContainerApp: {
    marginVertical: 15,
  },
  issueCode: {
    color: '#1890FF',
    fontSize: 15,
    fontWeight: '400',
    textDecorationLine: 'underline',
    fontFamily: 'Roboto',
  },
  issueDescription: {
    color: '#BFBFBF',
    fontSize: 14,
    flex: 1,
  },
  devAvatar: {
    width: 30,
    height: 30,
    borderRadius: 100,
    overflow: 'hidden',
    alignItems: 'center',
    justifyContent: 'center',
  },
  label: {
    color: '#8C8C8C',
    fontSize: 12,
    fontWeight: '700',
    textTransform: 'uppercase',
  },
  marginTop15: {
    marginTop: 15,
  },
  bottomLine: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#1f1f1f',
  },
  overflowHidden: {
    overflow: 'hidden',
    borderRightColor: 'transparent',
  },
});

export default styles;
