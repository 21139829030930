import React from 'react';
import {
  VictoryChart,
  VictoryBar,
  VictoryAxis,
  VictoryLabel,
  VictoryTooltip,
  VictoryTheme,
} from 'victory';
import DashboardCard from '../../atoms/DashboardCard';
import { axis, colors } from '../../../styles/victory-theme';
import { dayOfWeek } from '../../../types/ClientDashboardDto';

const lightAxis = [68, 101.5, 135.5, 170.3, 204.5, 236.5];
const verticalAxis = [152, 259, 366, 475];

const CustomTooltip = (props) => (
  <g>
    <VictoryTooltip active {...props} {...axis.tooltip} flyoutWidth={60} renderInPortal={false} />
  </g>
);

const translate = (datum, index) => {
  if (datum.y < 1 && index === 0) {
    return 'translate(3px, 2px)';
  }

  if (datum.y < 1) {
    return 'translate(0, 0px)';
  }

  if (index === 0) {
    return 'translate(3px, 0)';
  }

  if (index === 4) {
    return 'translate(-2px, 0)';
  }

  return null;
};

const calcMax = (value: number, length: number = null) => {
  if (value <= 45) { return 45; }

  if (value <= 90) { return 90; }

  if (value <= 225) { return 225; }

  let nextNum = parseInt('45'.padEnd(length ?? value.toString().length, '0'), 10);

  if (value <= nextNum) { return nextNum; }

  nextNum = parseInt('90'.padEnd(length ?? value.toString().length, '0'), 10);

  if (value <= nextNum) { return nextNum; }

  nextNum = parseInt('225'.padEnd(length ?? value.toString().length, '0'), 10);

  if (value <= nextNum) { return nextNum; }

  return calcMax(value, value.toString().length + 1);
};

const calcHeight = (value: number) => {
  const millares = ((value % 10000) - (value % 1000)) / 1000;
  if (millares > 0) { return 87; }

  const centenas = ((value % 1000) - (value % 100)) / 100;
  if (centenas > 0) { return value >= 450 ? 8 : 4.4; }

  const decenas = ((value % 100) - (value % 10)) / 10;
  if (decenas > 0) { return value >= 90 ? 1.5 : 0.8; }

  return 0.8;
};

interface Props {
  weeklyStatus: Record<dayOfWeek, number>;
}

const WeeklyStatusReport = ({ weeklyStatus }: Props) => {
  let max = Math.max(...Object.values(weeklyStatus));
  max = calcMax(max < 45 ? 45 : max);

  const data = Object.keys(weeklyStatus).map((key) => ({
    x: key === 'Thursday' ? 'Thur' : key.slice(0, 3),
    y: weeklyStatus[key] < 1 ? 1 : weeklyStatus[key],
    label: `${Math.round(weeklyStatus[key])} hours`,
  }));

  data.push({ x: '', y: 50, label: '' }); // this makes the chart look like the figma design ¬¬

  return (
    <DashboardCard
      web
      title="WEEKLY STATUS REPORT"
      iconType="Clock"
      tooltipContent="Measured by Week"
    >
      <VictoryChart
        domainPadding={48}
        width={625}
        height={300}
        theme={VictoryTheme.grayscale}
        domain={{ y: [0, max] }}
      >
        <VictoryAxis
          style={{
            ...axis,
            tickLabels: { ...axis.tickLabels, fontSize: 14 },
            grid: {},
          }}
          tickLabelComponent={<VictoryLabel />}
        />
        <VictoryAxis
          minDomain={0}
          axisValue={0}
          style={{
            ...axis,
            grid: {
              stroke: '#575757',
              strokeWidth: 1,
            },
          }}
          dependentAxis
        />
        {lightAxis.map((item) => (
          <VictoryAxis
            key={item}
            crossAxis
            width={400}
            height={400}
            offsetY={item}
            style={{
              tickLabels: {
                fill: 'transparent',
              },
            }}
          />
        ))}
        {verticalAxis.map((item) => (
          <VictoryAxis
            key={item}
            dependentAxis
            width={400}
            height={400}
            offsetX={item}
            style={{
              tickLabels: {
                fill: 'transparent',
              },
            }}
          />
        ))}
        <VictoryBar
          labelComponent={<CustomTooltip />}
          data={data}
          y={(d) => d.y - calcHeight(max)}
          y0={(d) => d.y + calcHeight(max)}
          style={{
            data: {
              fill: ({ index }) => colors[index as number],
              width: ({ index }) => (index === 0 ? 100 : 106),
              transform: ({ datum, index }) => translate(datum, index),
            },
          }}
        />
      </VictoryChart>
    </DashboardCard>
  );
};
export default WeeklyStatusReport;
