/* eslint-disable no-shadow */
enum Role {
  GUEST = 'GUEST',
  CLIENT = 'CLIENT',
  ADMIN = 'ADMIN',
  PROJECT_MANAGEMENT = 'PROJECT_MANAGEMENT',
  HR_MANAGER = 'HR_MANAGER',
  DEVELOPER = 'DEVELOPER',
  TEAM_LEAD = 'TEAM_LEAD',
  SYSTEM = 'SYSTEM',
  UNASSIGNED = 'UNASSIGNED',
}

enum Language {
  EN = 'EN',
  ES = 'ES',
}

enum OrganizationSize {
  FROM_1_TO_4 = 'FROM_1_TO_4',
  FROM_5_TO_9 = 'FROM_5_TO_9',
  FROM_10_TO_19 = 'FROM_10_TO_19',
  FROM_20_TO_49 = 'FROM_20_TO_49',
  FROM_50_TO_99 = 'FROM_50_TO_99',
  FROM_100_TO_249 = 'FROM_100_TO_249',
  FROM_250_TO_499 = 'FROM_250_TO_499',
  FROM_500_TO_999 = 'FROM_500_TO_999',
  FROM_1000_TO_MORE = 'FROM_1000_TO_MORE',
}

enum OrganizationIndustry {
  ACCOUNTING = 'ACCOUNTING',
  ADMINISTRATION_AND_OFFICE_SUPPORT = 'ADMINISTRATION_AND_OFFICE_SUPPORT',
  ADVERTISING = 'ADVERTISING',
  ARTS_AND_MEDIA = 'ARTS_AND_MEDIA',
  BANKING_AND_FINANCIAL_SERVICES = 'BANKING_AND_FINANCIAL_SERVICES',
  CALL_CENTRE_AND_CUSTOMER_SERVICE = 'CALL_CENTRE_AND_CUSTOMER_SERVICE',
  COMMUNITY_SERVICES_AND_DEVELOPMENT = 'COMMUNITY_SERVICES_AND_DEVELOPMENT',
  CONSTRUCTION = 'CONSTRUCTION',
  CONSULTING_AND_STRATEGY = 'CONSULTING_AND_STRATEGY',
  DESIGN_AND_ARCHITECTURE = 'DESIGN_AND_ARCHITECTURE',
  EDUCATION_AND_TRAINING = 'EDUCATION_AND_TRAINING',
  ENGINEERING = 'ENGINEERING',
  FARMING = 'FARMING',
  ANIMALS_AND_CONSERVATION = 'ANIMALS_AND_CONSERVATION',
  GOVERNMENT_AND_DEFENCE = 'GOVERNMENT_AND_DEFENCE',
  HEALTHCARE_AND_MEDICAL = 'HEALTHCARE_AND_MEDICAL',
  HOSPITALITY_AND_TOURISM = 'HOSPITALITY_AND_TOURISM',
  HUMAN_RESOURCES_AND_RECRUITMENT = 'HUMAN_RESOURCES_AND_RECRUITMENT',
  INFORMATION_AND_COMMUNICATION_TECHNOLOGY = 'INFORMATION_AND_COMMUNICATION_TECHNOLOGY',
  INSURANCE_AND_SUPERANNUATION = 'INSURANCE_AND_SUPERANNUATION',
  LEGAL = 'LEGAL',
  MANUFACTURING = 'MANUFACTURING',
  TRANSPORT_AND_LOGISTICS = 'TRANSPORT_AND_LOGISTICS',
  MARKETING_AND_COMMUNICATIONS = 'MARKETING_AND_COMMUNICATIONS',
  MINING = 'MINING',
  RESOURCES_AND_ENERGY = 'RESOURCES_AND_ENERGY',
  REAL_ESTATE_AND_PROPERTY = 'REAL_ESTATE_AND_PROPERTY',
  RETAIL_AND_CONSUMER_PRODUCTS = 'RETAIL_AND_CONSUMER_PRODUCTS',
  SALES = 'SALES',
  SCIENCE_AND_TECHNOLOGY = 'SCIENCE_AND_TECHNOLOGY',
  SPORT_AND_RECREATION = 'SPORT_AND_RECREATION',
  TRADES_AND_SERVICES = 'TRADES_AND_SERVICES',
  OTHER = 'OTHER',
}

enum OrganizationEntityType {
  SOLE_PROPRIETORSHIP = 'SOLE_PROPRIETORSHIP',
  PARTNERSHIP = 'PARTNERSHIP',
  CORPORATION = 'CORPORATION',
  S_CORPORATION = 'S_CORPORATION',
  LLC = 'LLC',
  OTHER = 'OTHER',
}

enum ProjectStatus {
  ACTIVE,
  INACTIVE,
  ON_HOLD,
  DELINQUENT,
  REVIEW,
}

export {
  Role,
  Language,
  OrganizationSize,
  OrganizationIndustry,
  OrganizationEntityType,
  ProjectStatus,
};
