import React from 'react';
import { View, Text } from 'react-native';
import Calendar from '../../../assets/icons/Calendar';
import Scope from '../../../assets/icons/Scope';
import Clock from '../../../assets/icons/Clock';

import { Base } from '../../../types/base';
import styles from './project-label.style';
import {
  handleScheduleBudgetPercentageColor,
  handleScopeColor,
  IconAppScope,
  IconBudgetApp,
  IconScheduleApp,
} from './colorGetter';

interface ProjectLabelprops extends Base {
  label: string;
  isForHoursLeft?: boolean;
  isForSchedule?: boolean;
  isForBudget?: boolean;
  isForScope?: boolean;
  isDanger: boolean;
  data?: string | number;
}

const ProjectLabel = ({
  label,
  isForHoursLeft,
  isForSchedule,
  isForBudget,
  isForScope,
  isDanger,
  data,
  web,
}: ProjectLabelprops) => {
  const IconClock = web ? Clock : IconBudgetApp(data);
  const IconCalendar = web ? Calendar : IconScheduleApp(data);
  const IconScope = web ? Scope : IconAppScope(data);

  return (
    <View
      style={[
        styles.container,
        { backgroundColor: isDanger ? '#450716' : '#1F1F1F', marginTop: web ? 0 : 5 },
      ]}
    >
      <View style={styles.iconContainer}>
        {isForHoursLeft && <IconClock stroke="#D9D9D9" width={14} height={14} />}
        {isForSchedule && (
          <>
            <IconCalendar
              stroke={handleScheduleBudgetPercentageColor(data)}
              width={14}
              height={14}
            />
            <Text style={[styles.info, { color: handleScheduleBudgetPercentageColor(data) }]}>
              {`${data}%`}
            </Text>
          </>
        )}
        {isForBudget && (
          <>
            <IconClock stroke={handleScheduleBudgetPercentageColor(data)} width={14} height={14} />
            <Text style={[styles.info, { color: handleScheduleBudgetPercentageColor(data) }]}>
              {`${data}%`}
            </Text>
          </>
        )}
        {isForScope && (
          <>
            <IconScope stroke={handleScopeColor(data)} width={14} height={14} />
            <Text style={[styles.info, { color: handleScopeColor(data) }]}>{data}</Text>
          </>
        )}
      </View>
      <Text style={[styles.text, { fontSize: isForHoursLeft ? 12 : 10 }]}>{label}</Text>
    </View>
  );
};

ProjectLabel.defaultProps = {
  isForHoursLeft: false,
  isForSchedule: false,
  isForBudget: false,
  isForScope: false,
  data: 0,
};

export default ProjectLabel;
