import React, { useState, useEffect } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
} from 'react-native';
import { datesGenerator } from 'dates-generator';
import LeftArrow from '../../../../assets/svg/leftArrow.svg';
import RightArrow from '../../../../assets/svg/rightArrow.svg';
import { Base } from '../../../../types/base';
import styles from './calendar.style';

interface CalendarProps extends Base {
  onChange: (date: string) => void;
}

const Calendar = ({ web, onChange }: CalendarProps) => {
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [datesofTheMonth, setDatesOfTheMonth] = useState([]);
  const [calendar, setCalendar] = useState({
    month: selectedDate.getMonth(),
    year: selectedDate.getFullYear(),
    nextMonth: 0,
    previousMonth: 0,
  });
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const onClickNext = () => {
    const body = { month: calendar.nextMonth, year: calendar.nextYear };
    const {
      dates,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    } = datesGenerator(body);

    setDatesOfTheMonth([...dates]);
    setCalendar({
      ...calendar,
      month: calendar.nextMonth,
      year: calendar.nextYear,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    });
  };

  const onClickPrevious = () => {
    const body = { month: calendar.previousMonth, year: calendar.previousYear };
    const {
      dates, nextMonth, nextYear, previousMonth, previousYear,
    } = datesGenerator(body);

    setDatesOfTheMonth([...dates]);
    setCalendar({
      ...calendar,
      month: calendar.previousMonth,
      year: calendar.previousYear,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    });
  };

  const onSelectDate = (date) => {
    setSelectedDate(new Date(date.year, date.month, date.date));
    onChange(
      `${new Date(date.year, date.month, date.date).toLocaleDateString(
        'en-US',
        {
          weekday: 'long',
          day: '2-digit',
          month: 'short',
          year: 'numeric',
        },
      )}`,
    );
  };

  useEffect(() => {
    const body = {
      month: calendar.month,
      year: calendar.year,
    };
    const {
      dates, nextMonth, nextYear, previousMonth, previousYear,
    } = datesGenerator(body);

    setDatesOfTheMonth([...dates]);
    setCalendar({
      ...calendar,
      nextMonth,
      nextYear,
      previousMonth,
      previousYear,
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.navContainer}>
        <TouchableOpacity onPress={onClickPrevious}>
          {web ? (
            <img src={LeftArrow} alt="prev" width={30} height={30} />
          ) : (
            <LeftArrow width={30} height={30} />
          )}
        </TouchableOpacity>
        <View style={styles.yearMonthContainer}>
          <Text style={styles.month}>{months[calendar.month]}</Text>
          <Text style={styles.month}>{calendar.year}</Text>
        </View>
        <TouchableOpacity onPress={onClickNext}>
          {web ? (
            <img src={RightArrow} alt="prev" width={30} height={30} />
          ) : (
            <RightArrow width={30} height={30} />
          )}
        </TouchableOpacity>
      </View>
      <View>
        <View style={styles.daysOfTheWeekContainer}>
          {days.map((day) => (
            <View key={day} style={styles.dayContainer}>
              <Text style={styles.day}>{day}</Text>
            </View>
          ))}
        </View>
        <View style={styles.daysContainer}>
          {datesofTheMonth
            && datesofTheMonth.map((week, index) => (
              <View key={index} style={styles.daysOfTheWeekContainer}>
                {week.map((item, i) => (
                  <View
                    key={i}
                    style={
                      item.date === selectedDate.getDate()
                      && item.month === selectedDate.getMonth()
                      && item.year === selectedDate.getFullYear()
                        ? styles.selectedDayContainer
                        : styles.dayContainer
                    }
                  >
                    <TouchableOpacity onPress={() => onSelectDate(item)}>
                      <Text
                        style={
                          item.month === calendar.nextMonth
                          || item.month === calendar.previousMonth
                            ? styles.prevNextMonthDay
                            : styles.day
                        }
                      >
                        {item.date}
                      </Text>
                    </TouchableOpacity>
                  </View>
                ))}
              </View>
            ))}
        </View>
      </View>
    </View>
  );
};

export default Calendar;
