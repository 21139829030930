import React from 'react';
import NotFoundTemplate from '../../../shared/components/templates/NotFound';
import styles from '../../styles/page.style';

const NotFound = () => (
  <div style={styles.container}>
    <NotFoundTemplate web />
  </div>
);

export default NotFound;
