import React, { FC, useRef, useState } from 'react';
import { ScrollView, Animated, View } from 'react-native';
import { Base } from '../../../types/base';
import './scroll-component-web-style.module.css';

interface ScrollComponentProps extends Base {
  children: React.ReactNode;
}

const ScrollComponent: FC<ScrollComponentProps> = ({ children, web }) => {
  const [completeScrollBarHeight, setCompleteScrollBarHeight] = useState(1);
  const [scrollBarHeight, setscrollBarHeight] = useState(0);

  const scrollIndicator = useRef(new Animated.Value(0)).current;

  const scrollSize = completeScrollBarHeight > scrollBarHeight
    ? (scrollBarHeight * scrollBarHeight) / completeScrollBarHeight
    : scrollBarHeight;

  const difference = scrollBarHeight > scrollSize ? scrollBarHeight - scrollSize : 1;

  const scrollIndicatorPosition = Animated.multiply(
    scrollIndicator,
    scrollBarHeight / completeScrollBarHeight,
  ).interpolate({
    inputRange: [0, difference],
    outputRange: [0, difference],
    extrapolate: 'clamp',
  });

  return web ? (
    <div className="container">{children}</div>
  ) : (
    <View
      style={{
        flexDirection: 'row',
        flex: 1,
      }}
    >
      <ScrollView
        contentContainerStyle={{
          paddingRight: 14,
        }}
        showsVerticalScrollIndicator={false}
        scrollEventThrottle={16}
        onContentSizeChange={(_, height) => {
          setCompleteScrollBarHeight(height);
        }}
        onLayout={({
          nativeEvent: {
            layout: { height },
          },
        }) => {
          setscrollBarHeight(height);
        }}
        onScroll={Animated.event([{ nativeEvent: { contentOffset: { y: scrollIndicator } } }], {
          useNativeDriver: false,
        })}
      >
        {children}
      </ScrollView>
      <View
        style={{
          height: '100%',
          width: 5,
          backgroundColor: 'transparent',
          borderRadius: 8,
        }}
      >
        <Animated.View
          style={{
            width: 5,
            borderRadius: 8,
            backgroundColor: '#606060',
            height: scrollSize,
            transform: [{ translateY: scrollIndicatorPosition }],
          }}
        />
      </View>
    </View>
  );
};
export default ScrollComponent;
