import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 15,
    backgroundColor: '#141414',
    borderRadius: 4,
    marginBottom: 5,
    cursor: 'pointer',
  },
  iconContainer: {
    marginRight: 15,
  },
  type: {
    color: '#FAFAFA',
    fontSize: 16,
    fontWeight: '500',
  },
  account: {
    color: '#8C8C8C',
    fontSize: 14,
    fontWeight: '400',
  },
});

export default styles;
