import React, { SVGProps } from 'react';

const Lowest: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="12"
    height="11"
    viewBox="0 0 12 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.0859 1.98656L5.54293 4.6167L0.999974 1.98656"
      stroke="#FAFAFA"
      strokeOpacity="0.7"
      strokeWidth="2"
      strokeLinecap="round"
    />
    <path
      d="M10.0859 6.36986L5.54293 9L0.999974 6.36986"
      stroke="#FAFAFA"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);

export default Lowest;
