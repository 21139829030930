import { StyleSheet, useWindowDimensions } from 'react-native';

const fontFamily = {
  fontFamily: 'Oxygen-Regular',
};

const useResponsiveStyles = () => {
  const { width } = useWindowDimensions();
  const containerWidth = width < 570 ? '100%' : 400;
  const borderLeftWidth = width < 1200 ? 0 : 1;

  const styles = StyleSheet.create({
    fontFamily,
    container: {
      width: containerWidth,
      alignItems: 'center',
      justifyContent: 'center',
      paddingHorizontal: 20,
      borderLeftWidth,
      borderLeftColor: 'rgba(255, 255, 255, 0.15)',
    },
    title: {
      fontSize: 14,
      color: '#ffffffa6',
      marginBottom: 20,
    },
  });

  return styles;
};

export default useResponsiveStyles;
