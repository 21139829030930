import React from 'react';
import { useWindowDimensions, View } from 'react-native';
import { Base } from '../../../types/base';
import NDAConfirmation from '../../organisms/NDAConfirmation';
import webStyles from './nda-confirmation.style';

interface NDAConfirmationTemplateProps extends Base {
  action: () => void;
  isLoading: boolean;
}

const NDAConfirmationTemplate = ({ web, action, isLoading }: NDAConfirmationTemplateProps) => {
  const { width } = useWindowDimensions();
  const withContainer = width <= 800 ? '80%' : webStyles.container.width;

  return (
    <View style={web ? { width: withContainer } : webStyles.containerApp}>
      <NDAConfirmation web={web} action={action} isLoading={isLoading} />
    </View>
  );
};

export default NDAConfirmationTemplate;
