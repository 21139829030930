import React, { SVGProps } from 'react';

const Scope: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const { stroke } = props;

  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="5.5" cy="5.5" r="2" stroke={stroke} />
      <circle cx="5.5" cy="5.5" r="5" stroke={stroke} />
    </svg>
  );
};

export default Scope;
