import React, { FC, SVGProps } from 'react';
import { SvgProps } from 'react-native-svg';
import { View, Text, useWindowDimensions } from 'react-native';
import { Calendar, Scope, Clock } from '../../../assets/icons';
import styles from './project-performance-cards.style';
import { Base } from '../../../types/base';
import {
  handleScheduleBudgetPercentageColor,
  handleScopeColor,
  IconAppScope,
  IconBudgetApp,
  IconScheduleApp,
} from '../../atoms/ProjectLabel/colorGetter';

interface ProjectPerformanceCardsProps extends Base {
  scope?: number;
  schedulePercentage?: number;
  budgetPercentage?: number;
  dark?: boolean;
}

interface CardItemProps extends Base {
  value: number;
  label: string;
  Icon: FC<SVGProps<SVGSVGElement>> | FC<SvgProps>;
  percentage?: boolean;
  margin?: number;
  colorFunction: (value: string | number) => string;
  isMobil?: boolean;
  dark?: boolean;
}

const CardItem: FC<CardItemProps> = ({
  value,
  label,
  Icon,
  percentage = false,
  web,
  margin,
  colorFunction,
  isMobil,
  dark,
}) => (
  <View
    style={[
      styles.container,
      isMobil ? { marginVertical: margin } : { marginHorizontal: margin },
      { backgroundColor: dark ? '#141414' : '#262626', padding: web ? 20 : 10 },
    ]}
  >
    <View style={[styles.header, { marginBottom: web ? 20 : 10 }]}>
      <Icon stroke={colorFunction(value || 0)} width={web ? 24 : 14} height={web ? 24 : 14} />
      <Text style={web ? styles.title : styles.titleApp}>{label}</Text>
    </View>
    <View style={[styles.content, isMobil && { justifyContent: 'center' }]}>
      <Text
        style={[
          web ? styles.label : styles.labelApp,
          {
            fontFamily: web ? 'Roboto, sans-serif' : 'Roboto',
            color: colorFunction(value || 0),
          },
        ]}
      >
        {value}
      </Text>
      {percentage && (
        <Text
          style={[
            web ? styles.percent : styles.percentApp,
            {
              fontFamily: web ? 'Roboto, sans-serif' : 'Roboto',
              color: colorFunction(value || 0),
            },
          ]}
        >
          %
        </Text>
      )}
    </View>
  </View>
);

const ProjectPerformanceCards: FC<ProjectPerformanceCardsProps> = ({
  scope,
  schedulePercentage,
  budgetPercentage,
  dark,
  web,
}) => {
  const { width } = useWindowDimensions();
  const IconClock = web ? Clock : IconBudgetApp(budgetPercentage || 0);
  const IconCalendar = web ? Calendar : IconScheduleApp(schedulePercentage || 0);
  const IconScope = web ? Scope : IconAppScope(scope || 0);

  const isMobil = () => width <= 700;

  return (
    <View style={styles.cardsContainer}>
      <CardItem
        value={schedulePercentage || 0}
        label="Schedule"
        Icon={IconCalendar}
        web={web}
        colorFunction={handleScheduleBudgetPercentageColor}
        isMobil={isMobil()}
        dark={dark}
      />
      <CardItem
        value={budgetPercentage || 0}
        label="Budget"
        Icon={IconClock}
        percentage
        web={web}
        margin={web ? 20 : 10}
        colorFunction={handleScheduleBudgetPercentageColor}
        isMobil={isMobil()}
        dark={dark}
      />
      <CardItem
        value={scope || 0}
        label="Scope"
        Icon={IconScope}
        percentage
        web={web}
        colorFunction={handleScopeColor}
        isMobil={isMobil()}
        dark={dark}
      />
    </View>
  );
};

ProjectPerformanceCards.defaultProps = {
  scope: 0,
  schedulePercentage: 0,
  budgetPercentage: 0,
  dark: false,
};

CardItem.defaultProps = {
  margin: 0,
  percentage: false,
  isMobil: false,
  dark: false,
};

export default ProjectPerformanceCards;
