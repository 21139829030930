import React, { SVGProps } from 'react';
import { View, Text } from 'react-native';
import { Base } from '../../../types/base';
import styles from './status-label.style';
import labelData, { colors, statusType, icons } from './defaultTemplates';

export type statusLabelText =
  | 'queued'
  | 'success'
  | 'inProgress'
  | 'failed'
  | 'done'
  | 'overdue'
  | 'reopened'
  | 'NA';

export type labelDataType = {
  [index: statusLabelText | string]: {
    color: string;
    Icon: {
      web: React.FC<SVGProps<SVGSVGElement>> | null;
      app: React.FC<SVGProps<SVGSVGElement>> | null;
    };
    label: string;
  };
};

interface BannerIconprops extends Base {
  label: statusLabelText | string;
  IconApp?: React.FC<SVGProps<SVGSVGElement>> | null;
  IconWeb?: React.FC<SVGProps<SVGSVGElement>> | null;
  filled?: boolean;
  status: statusType;
}

const StatusLabel = ({
  IconApp,
  IconWeb,
  web = false,
  label,
  filled = false,
  status,
}: BannerIconprops) => {
  const themeColor = colors[status || label] || labelData[label]?.color || 'white';
  const ThemeIcon = web
    ? IconWeb || icons[status] || labelData[label]?.Icon?.web
    : IconApp || labelData[label]?.Icon?.app;
  const localLabel = labelData[label] ? labelData[label].label : label || 'Default';
  const defaultTextColor = themeColor === 'white' ? 'black' : 'white';

  return (
    <View
      style={[
        styles.labelContainer,
        {
          borderColor: themeColor,
          backgroundColor: filled ? themeColor : 'transparent',
        },
      ]}
    >
      {ThemeIcon && (
        <View style={styles.iconContainer}>
          <ThemeIcon width={15} height={15} />
        </View>
      )}
      <Text
        style={{
          color: filled ? defaultTextColor : themeColor,
          fontWeight: filled ? 'bold' : 'normal',
        }}
        numberOfLines={1}
      >
        {localLabel}
      </Text>
    </View>
  );
};

export default StatusLabel;

StatusLabel.defaultProps = {
  color: undefined,
  IconApp: null,
  IconWeb: null,
  filled: false,
};
