import React from 'react';
import { View, Text } from 'react-native';
import styles from './header-projects-amount.style';

interface HeaderProjectsAmountProps {
  projectsAmount: string | number;
  isForUpcoming?: boolean;
}

const defaultProps = {
  isForUpcoming: false,
};

const HeaderProjectsAmount = ({
  projectsAmount,
  isForUpcoming,
}: HeaderProjectsAmountProps) => (
  <View
    style={[
      styles.container,
      {
        backgroundColor: isForUpcoming ? '#B2F1E8' : '#262626',
        marginLeft: isForUpcoming ? 30 : 0,
        paddingTop: 20,
        paddingBottom: 10,
      },
    ]}
  >
    <Text
      style={[styles.amount, { color: isForUpcoming ? '#000000' : '#FFFFFF' }]}
    >
      {projectsAmount}
    </Text>
    <Text
      style={[styles.text, { color: isForUpcoming ? '#000000' : '#BFBFBF' }]}
    >
      {isForUpcoming ? 'Upcoming' : 'Projects'}
    </Text>
  </View>
);

HeaderProjectsAmount.defaultProps = defaultProps;

export default HeaderProjectsAmount;
