import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  avatar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 100,
  },
  xs: {
    width: 16,
    height: 16,
    fontSize: 10,
  },
  small: {
    width: 32,
    height: 32,
    fontSize: 14,
  },
  medium: {
    width: 50,
    height: 50,
    fontSize: 22,
  },
  large: {
    width: 75,
    height: 75,
    fontSize: 28,
  },
  xLarge: {
    width: 100,
    height: 100,
    fontSize: 35,
  },
});

export default styles;
