import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    minHeight: '100%',
    maxHeight: '100%',
    backgroundColor: '#262626',
    padding: 20,
  },
  title: {
    color: '#D9D9D9',
    fontSize: 16,
  },
  borderBottom: {
    borderBottomWidth: 1,
    borderBottomColor: 'gray',
    padding: 5,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  searchContainer: {
    marginVertical: 25,
    marginHorizontal: 10,
  },
  linkButton: {
    color: '#1890FF',
    paddingHorizontal: 5,
    paddingVertical: 10,
  },
  mainContainer: {
    width: 200,
    padding: 15,
    paddingRight: 20,
    backgroundColor: '#141414',
    position: 'absolute',
    top: 25,
    shadowColor: '#000000',
    shadowOffset: { width: -5, height: 5 },
    shadowOpacity: 0.4,
    shadowRadius: 12,
    borderRadius: 2,
  },
  text: {
    color: '#A8A8A8',
    fontSize: 12,
    fontWeight: '400',
  },
  optionscontainer: {
    width: '100%',
    marginTop: 20,
  },
});

export default styles;
