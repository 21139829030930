import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
  },
  title: {
    color: '#D9D9D9',
    fontWeight: '700',
    lineHeight: 22,
    letterSpacing: 1.2,
  },
  titleContainer: {
    width: '100%',
    borderColor: '#615f5e',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

export default styles;
