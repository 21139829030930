import { assign } from 'lodash';

export const tooltipFill = '#434343';
const outsideAxis = '#595959';
const labelColor = '#e5e5e5';

export const colors = ['#0ABAB5', '#F93C6F', '#1890FF', '#948BF4', '#F0CC28'];

// Typography
const sansSerif = 'Roboto Mono';
const letterSpacing = 'normal';
const fontSize = 14;

// Layout
const padding = 8;

// * Labels
const baseLabelStyles = {
  fontFamily: sansSerif,
  fontSize,
  fontWeight: 400,
  letterSpacing,
  lineHeight: 20,
  padding,
  fill: '#e5e5e5',
  stroke: 'transparent',
  strokeWidth: 0,
};

// * Tooltip
const baseTooltipStyles = {
  fontFamily: 'Roboto, sans-serif',
  fontSize: 8,
  letterSpacing,
  padding,
  fill: '#BABABA',
};

const centeredLabelStyles = assign({ textAnchor: 'middle' }, baseLabelStyles);

// Strokes
const strokeLinecap = 'round';
const strokeLinejoin = 'round';

export const axis = {
  axis: {
    fill: 'transparent',
    stroke: outsideAxis,
    strokeWidth: 2,
    strokeLinecap,
    strokeLinejoin,
  },
  axisLabel: assign({}, centeredLabelStyles, {
    padding,
    stroke: 'transparent',
  }),
  grid: {
    fill: 'none',
    stroke: '#232323',
    strokeLinecap,
    strokeLinejoin,
    pointerEvents: 'painted',
  },
  ticks: {
    fill: 'transparent',
    size: 5,
    stroke: outsideAxis,
    strokeWidth: 1,
    strokeLinecap: '',
    strokeLinejoin,
  },
  tickLabels: assign({}, baseLabelStyles, {
    fill: labelColor,
  }),
  tooltip: {
    flyoutStyle: {
      stroke: tooltipFill,
      strokeWidth: 1,
      fill: tooltipFill,
    },
    style: baseTooltipStyles,
    flyoutPadding: 5,
    cornerRadius: 1,
    pointerLength: 3,
  },
};
