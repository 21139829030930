import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  row: {
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: 4,
    padding: 5,
    marginBottom: 5,
    backgroundColor: '#262626',
    position: 'relative',
  },
  row2: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flex3: {
    flex: 3,
  },
  arrowButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  rowItem: {
    padding: 10,
    flex: 1,
  },
  nameContainer: {
    flexDirection: 'row',
    padding: 10,
  },
  issueTypeIcon: {
    marginRight: 10,
  },
  epicBadge: {
    alignSelf: 'center',
    borderRadius: 2,
    paddingHorizontal: 6,
    paddingVertical: 3,
    textAlign: 'center',
    alignItems: 'center',
    width: '100%',
  },
  epicName: {
    paddingBottom: 3,
    textAlign: 'center',
  },
  epicText: {
    color: '#121212',
  },
  nameText: {
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 16,
    color: '#BFBFBF',
    flexShrink: 1,
  },
  textWhite: {
    color: 'white',
  },
  iconWebStyles: {
    position: 'absolute',
    right: 15,
    top: 15,
    transform: [{ translateY: 10 }],
  },
  iconWebStylesLeft: {
    position: 'absolute',
    left: 15,
    top: 15,
    transform: [{ translateY: 10 }],
  },
  iconAppStyle: {
    width: '100%',
    alignItems: 'center',
  },
  hoursText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 20,
    color: '#BFBFBF',
  },
});

export default styles;
