import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    position: 'relative',
  },
  iconContainer: {
    width: 86,
    display: 'flex',
    alignItems: 'center',
    paddingHorizontal: 5,
  },
  paddingVertical: {
    paddingVertical: 16,
  },
  titleContainer: {
    minWidth: 'max-content',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: 'gray',
    textTransform: 'capitalize',
  },
  subtitle: {
    color: '#FFFFFF',
  },
  rotateIcon: {
    transform: [{ rotate: '0deg' }],
  },
  rotateIcon2: {
    transform: [{ rotate: '180deg' }],
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  listCollapsedContainer: {
    backgroundColor: '#424242',
    width: 'max-content',
    padding: 10,
    borderRadius: 10,
  },
  absoluteList: {
    position: 'absolute',
    top: -20,
    left: 88,
    paddingLeft: 5,
    zIndex: 99999,
  },
  selectedItem: {
    backgroundColor: '#131313',
    borderRightWidth: 7,
    borderRightColor: '#0ABAB5',
    cursor: 'pointer',
  },
  selectedCollapsedItem: {
    backgroundColor: '#424242',
    borderRadius: 10,
    cursor: 'pointer',
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  paddingTop: {
    paddingTop: 24,
  },
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    width: '100%',
    paddingVertical: 15,
    flex: 1,
  },
  itemTitle: {
    color: '#FFFFFF',
    paddingLeft: 100,
  },
});

export default styles;
