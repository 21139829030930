import React, { SVGProps } from 'react';

const Arrows: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M16.3522 16.3787L22.1772 11.8198C22.2468 11.7662 22.2861 11.6823 22.2861 11.5948L22.2861 10.2144C22.2861 10.0948 22.1486 10.0287 22.0558 10.1019L16.0004 14.8412L9.94506 10.1019C9.92401 10.0854 9.89874 10.0752 9.87214 10.0724C9.84555 10.0696 9.8187 10.0743 9.79467 10.0861C9.77064 10.0978 9.7504 10.1161 9.73625 10.1388C9.72211 10.1615 9.71464 10.1877 9.7147 10.2144L9.7147 11.5948C9.7147 11.6823 9.75577 11.7662 9.82363 11.8198L15.6468 16.3787C15.8558 16.5412 16.1451 16.5412 16.3522 16.3787ZM16.3522 21.8073L22.1772 17.2484C22.2468 17.1948 22.2861 17.1109 22.2861 17.0234L22.2861 15.643C22.2861 15.5234 22.1486 15.4573 22.0558 15.5305L16.0004 20.2698L9.94506 15.5305C9.92401 15.514 9.89873 15.5038 9.87214 15.501C9.84555 15.4982 9.8187 15.5029 9.79467 15.5147C9.77064 15.5264 9.7504 15.5446 9.73625 15.5673C9.72211 15.59 9.71464 15.6163 9.7147 15.643L9.7147 17.0234C9.7147 17.1109 9.75577 17.1948 9.82363 17.2484L15.6468 21.8073C15.8558 21.9698 16.1451 21.9698 16.3522 21.8073Z"
      fill="#555555"
    />
  </svg>
);

export default Arrows;
