import React from 'react';
import { View, Text } from 'react-native';
import { Base } from '../../../types/base';
import ProjectProgressBar from '../../atoms/ProjectProgressBar';
import DashboardCard from '../../atoms/DashboardCard';
import styles from './allocated-hours.style';

interface AllocatedHoursProps extends Base {
  title: string;
  progressHours: number | string;
  backgroundColor?: boolean;
  allocatedHours: number | string;
  isForProject?: boolean;
  isDanger?: boolean;
  height?: string;
}

const defaultProps = {
  isDanger: false,
  isForProject: false,
  height: '45%',
  backgroundColor: true,
};

const AllocatedHours = ({
  title,
  progressHours,
  allocatedHours,
  isForProject,
  backgroundColor,
  isDanger,
  height,
  web,
}: AllocatedHoursProps) => {
  const handlePercentage = () => {
    const percentage = +progressHours < 1 ? 0 : (+progressHours / +allocatedHours) * 100;
    return Math.round(percentage);
  };

  return (
    <DashboardCard
      backgroundColor={backgroundColor}
      height={height}
      title={title}
      iconType="Timer"
      web={web}
      isDanger={isDanger}
      isForProject={isForProject}
      isForAllocatedHoursCard
      tooltipContent="Measured by Sprint"
    >
      <View style={styles.container}>
        <View style={[styles.textContainer]}>
          <Text
            style={[
              styles.text,
              { color: isDanger ? '#F5F5F5' : '#BFBFBF', fontSize: isForProject ? 20 : 35 },
            ]}
          >
            {Math.round(+progressHours)}
          </Text>
          <Text style={[styles.text2, { color: isDanger ? '#F5F5F5' : '#BFBFBF' }]}>h</Text>
        </View>
        <View style={{ flex: 1, marginLeft: 10 }}>
          <ProjectProgressBar
            workedHours={progressHours || 0}
            estimatedHours={allocatedHours}
            percentage={allocatedHours > 0 ? handlePercentage() : 100}
            isForTLDashboardCard
            isDanger={isDanger}
            web={web}
          />
        </View>
      </View>
    </DashboardCard>
  );
};

AllocatedHours.defaultProps = defaultProps;

export default AllocatedHours;
