import React from 'react';
import {
  View, Text, TouchableHighlight, Image,
} from 'react-native';
import { SvgProps } from 'react-native-svg';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../src/redux/store';
import { Base } from '../../../types/base';
import styles from './hover-item.style';

interface MenuItemProps extends Base {
  title: string;
  subtitle?: string;
  link?: string;
  icon?: React.Component;
  IconApp?: React.FC<SvgProps>;
  suffixIcon?: React.ReactNode;
  isItemsCollapsed?: boolean;
  img?: string;
  isOpen?: boolean;
  onPress?: () => void;
  type?: 'icon' | 'image';
}

const defaultProps = {
  link: 'example',
  isItemsCollapsed: false,
  suffixIcon: undefined,
};

const HoverItem = ({
  title,
  // link,
  web,
  icon,
  isOpen,
  onPress,
  subtitle,
  IconApp,
  suffixIcon,
  isItemsCollapsed,
  img,
  type = 'icon',
}: MenuItemProps) => {
  const {
    user: { lastName, firstName },
  } = useSelector((state: RootState) => state.session);

  const userAcronym = () => {
    const first = firstName.charAt(0);
    const last = lastName.charAt(0);

    return `${first}${last}`;
  };

  const renderSectionIcon = () => (web ? <img src={icon} alt="icon" width={25} height={25} /> : <View>{IconApp}</View>);

  const renderSectionImage = () => (!img ? (
    <View style={styles.userAcronymContainer}>
      <Text style={styles.userAcronym}>{userAcronym()}</Text>
    </View>
  ) : (
    <View style={styles.userImageContainer}>
      {web ? <img src={img} alt="user" /> : <Image source={{ uri: img }} />}
    </View>
  ));

  return React.cloneElement(
    React.Children.only(
      <TouchableHighlight onPress={onPress}>
        <View style={styles.itemContainer}>
          <View style={styles.titleContainer}>
            {React.cloneElement(
              React.Children.only(
                <View style={styles.iconContainer}>
                  <View style={styles.paddingVertical}>
                    {type === 'icon' ? renderSectionIcon() : renderSectionImage()}
                  </View>
                </View>,
              ),
            )}
            {isOpen && (
              <Text>
                <Text style={styles.itemTitle}>{title}</Text>
                {subtitle && <Text style={styles.userRole}>{subtitle}</Text>}
              </Text>
            )}
          </View>
          {isOpen && suffixIcon && (
            <View
              style={[
                { transform: [{ rotate: isItemsCollapsed ? '0deg' : '180deg' }] },
                styles.suffixIconContainer,
              ]}
            >
              {suffixIcon}
            </View>
          )}
        </View>
      </TouchableHighlight>,
    ),
  );
};

HoverItem.defaultProps = defaultProps;

export default HoverItem;
