import React, { ReactNode } from 'react';
import {
  View, Text, ScrollView, ImageBackground,
} from 'react-native';
import { Base } from '../../../types/base';
import Issues from '../../organisms/Issues';
import UnassignedIssuesCard from '../../organisms/UnassignedIssuesCard';
import styles from './team-lead-dashboard.style';
import ExpandableProjectsContainer from '../../organisms/ExpandableProjectsContainer';
import image from '../../../assets/images/header-team-leader-asset.png';
import { DashboardTeamLeadPayload, EventItemsEntity } from '../../../types/TeamLeadDashboard';
import DashboardLayout from '../dashboardLayout/DashboardLayout';

interface DashboardProps extends Base {
  eventsItems: Array<EventItemsEntity>;
  props: DashboardTeamLeadPayload;
  userName: string;
}

export const Wrapper = (children: ReactNode, web?: boolean) => (web ? (
  <View style={styles.mainContainer}>{children}</View>
) : (
  <ScrollView alwaysBounceVertical automaticallyAdjustContentInsets indicatorStyle="white">
    {children}
  </ScrollView>
));

const TeamLeadDashboardWeb = ({
  web, eventsItems, props, userName,
}: DashboardProps) => (
  <DashboardLayout web={web} eventsItems={eventsItems}>
    <ImageBackground
      resizeMode="cover"
      style={{
        width: '100%',
        height: '25vh',
        marginBottom: 30,
        paddingLeft: 50,
        paddingBottom: 30,
        justifyContent: 'flex-end',
      }}
      imageStyle={{ borderRadius: 9 }}
      source={image}
    >
      <Text style={styles.headerText}>
        {`<Hi, ${userName[0].toUpperCase() + userName.substring(1)}!`}
      </Text>
      <Text style={styles.headerText}>Inspiring people to unlock</Text>
      <Text style={styles.headerText}>{'their full potencial>'}</Text>
    </ImageBackground>
    <>
      <View style={styles.unassignedContainer}>
        <UnassignedIssuesCard web items={props['Unassigned Issues']} />
      </View>

      <View style={web ? styles.cardContainer : styles.bottomAppContainer}>
        <ExpandableProjectsContainer title="WORKLOAD" items={props.Workload} web={web} />
      </View>
      <View style={web ? [styles.cardContainer, { zIndex: 5 }] : styles.bottomAppContainer}>
        {/* <Issues
          title="READY FOR RELEASE"
          web={web}
          isForTeamLeadDashboard
          statusFilter
          items={props['Ready For Release'] ?? []}
        /> */}
      </View>
      <View style={web ? [styles.cardContainer, { zIndex: 5 }] : styles.bottomAppContainer}>
        <Issues
          title="REOPENED ISSUES"
          items={props['Reopened Issues']}
          web={web}
          isForTeamLeadDashboard
          statusFilter
        />
      </View>
      <View style={web ? [styles.cardContainer, { zIndex: 4 }] : styles.bottomAppContainer}>
        <Issues
          title="OVERDUE ISSUES"
          items={props['Overdue Issues']}
          web={web}
          isForTeamLeadDashboard
          isForOverdue
        />
      </View>
      <View style={web ? [styles.cardContainer, { zIndex: 3 }] : styles.bottomAppContainer}>
        <Issues
          title="ISSUES DUE TODAY"
          items={props['Issues due Today']}
          web={web}
          isForTeamLeadDashboard
        />
      </View>
      <View style={web ? [styles.cardContainer, { zIndex: 2 }] : styles.bottomAppContainer}>
        <Issues
          title="ISSUES IN CODE REVIEW"
          items={props['Issues in Code Review']}
          web={web}
          isForTeamLeadDashboard
          statusFilter
        />
      </View>
      <View style={web ? [styles.cardContainer, { zIndex: 1 }] : styles.bottomAppContainer}>
        <Issues
          title="LONGEVITY ISSUES"
          items={props['Longevity Issues']}
          web={web}
          isForTeamLeadDashboard
          statusFilter
        />
      </View>
    </>
  </DashboardLayout>
);

export default TeamLeadDashboardWeb;
