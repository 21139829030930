/* eslint-disable no-nested-ternary */
import React from 'react';
import { View, Text } from 'react-native';
import { Pressable } from 'react-native-web-hover';
import { Base } from '../../../types/base';
import styles from './project-progress-bar.style';

interface ProjectProgressBarProps extends Base {
  workedHours: number | string;
  estimatedHours: number | string;
  percentage: number | string;
  isForTLDashboardCard?: boolean;
  isForClientDashboardCard?: boolean;
  isForSoWS?: boolean;
  isDanger?: boolean;
}

const defaultProps = {
  isForTLDashboardCard: false,
  isForClientDashboardCard: false,
  isForSoWS: false,
  isDanger: false,
};

const ProjectProgressBar = ({
  workedHours,
  estimatedHours,
  percentage,
  isDanger,
  isForTLDashboardCard,
  isForClientDashboardCard,
  isForSoWS,
  web,
}: ProjectProgressBarProps) => (
  <View style={styles.container}>
    <View
      style={[
        styles.estimatedHoursContainer,
        {
          justifyContent: isForTLDashboardCard ? 'flex-end' : 'space-between',
          marginBottom: isForSoWS ? 0 : 10,
        },
      ]}
    >
      {!isForTLDashboardCard && !isForClientDashboardCard && web && (
        <Text style={styles.text}>Progress</Text>
      )}
      {!isForTLDashboardCard && !isForClientDashboardCard && !web && (
        <View style={styles.row}>
          <Text style={styles.workedHoursApp}>{Math.round(+workedHours)}</Text>
          <Text style={styles.text}>h</Text>
        </View>
      )}
      {!isForClientDashboardCard && (
        <Text
          style={[styles.text, { color: isDanger ? '#F5F5F5' : '#BFBFBF' }]}
        >
          {`${Math.round(+estimatedHours)}h`}
        </Text>
      )}
    </View>
    <View style={styles.barContainer}>
      {isForClientDashboardCard && !isForSoWS && (
        <View style={styles.workedHoursContainerClientDashboard}>
          <Text
            style={[styles.text2, { color: isDanger ? '#F5F5F5' : '#BFBFBF' }]}
          >
            {`${Math.round(+workedHours)}`}
          </Text>
          <Text
            style={[styles.text, { color: isDanger ? '#F5F5F5' : '#BFBFBF' }]}
          >
            h
          </Text>
        </View>
      )}
      <View
        style={[
          styles.progressBarContainer,
          {
            overflow: web ? 'visible' : 'hidden',
            width: isForClientDashboardCard ? isForSoWS ? '80%' : '65%' : '100%',
          },
        ]}
      >
        <View style={styles.progressBar}>
          {percentage > 0 && (
            <Pressable>
              {({ hovered }) => (
                <View
                  style={[
                    styles.progressPercentage,
                    {
                      width: `${percentage}%`,
                      backgroundColor: isDanger ? '#F93C6F' : '#0ABAB5',
                    },
                  ]}
                >
                  <View style={hovered ? styles.hoursContainer : styles.hidden}>
                    <Text style={hovered ? styles.hours : styles.hoursHidden} numberOfLines={1}>
                      {`${Number(workedHours).toFixed(1)} hours`}
                    </Text>
                    <View
                      style={
                        hovered ? styles.hoursContainerArrow : styles.hidden
                      }
                    />
                  </View>
                </View>
              )}
            </Pressable>
          )}
        </View>
      </View>
      {isForClientDashboardCard && (
        <View style={styles.estimatedHoursContaienrClientDashboard}>
          <Text
            style={[
              styles.text,
              {
                color: isDanger ? '#F5F5F5' : '#BFBFBF',
                fontSize: isForSoWS ? 12 : 16,
              },
            ]}
          >
            {`${Math.round(+estimatedHours)}h`}
          </Text>
        </View>
      )}
    </View>
  </View>
);

ProjectProgressBar.defaultProps = defaultProps;

export default ProjectProgressBar;
