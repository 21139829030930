/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  View, Text, Image, ImageSourcePropType,
} from 'react-native';
import { Base } from '../../../types/base';
import styles from './icon-with-text.style';

interface IssueProps extends Base {
  text?: string;
  Icon: string | React.ElementType | ImageSourcePropType;
  isForTlDashboard?: boolean;
  isIcon?: boolean;
}

const defaultProps = {
  text: '',
  isForTlDashboard: false,
  isIcon: false,
};

const IconWithText = ({
  text, Icon, isForTlDashboard, web, isIcon,
}: IssueProps) => {
  // NAME INITIALS
  const userAcronym = () => {
    const splittedName = text?.split(' ');
    const acro = splittedName?.map((item) => item.split('')[0]).join('');
    return acro;
  };

  const renderSectionImage = () => (
    Icon === '' ? (
      <View style={styles.userAcronymContainer}>
        <Text style={styles.userAcronym}>{userAcronym()}</Text>
      </View>
    ) : (
      web ? (
        <View style={styles.userImageContainer}>
          <img src={Icon as string} alt="user" />
        </View>
      ) : (
        <View>
          {typeof Icon === 'string' ? (
            <Image
              source={{ uri: Icon.toString().trim() }}
              style={{ borderRadius: 100, width: 25, height: 25 }}
            />
          ) : (
            <Image
              source={Icon as ImageSourcePropType}
              style={{ borderRadius: 100, width: 30, height: 30 }}
            />
          )}
        </View>
      )
    )
  );

  return (
    <View style={styles.container}>
      {isIcon ? (
        <View>
          {web ? <img src={Icon as string} alt="user" /> : <Icon width={30} height={30} />}
        </View>
      ) : (
        renderSectionImage()
      )}
      {text ? (
        <Text
          style={[
            styles.text,
            {
              fontSize: isForTlDashboard ? 12 : 15,
              marginLeft: isForTlDashboard ? 5 : 10,
            },
          ]}
          numberOfLines={1}
        >
          {text}
        </Text>
      ) : null}
    </View>
  );
};

IconWithText.defaultProps = defaultProps;

export default IconWithText;
