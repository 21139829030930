import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
  },
  moreProjectsButtonContainer: {
    width: '100%',
    alignItems: 'flex-end',
  },
  moreProjectsButton: {
    color: '#595959',
    fontSize: 13,
    fontFamily: 'Roboto',
    fontWeight: '400',
  },
  projectsContainer: {
    width: '100%',
    flexWrap: 'wrap',
  },
  projectContainer: {
    marginBottom: 20,
  },
  arrowsContainer: {
    width: '100%',
    alignItems: 'center',
  },
});

export default styles;
