import React, { SVGProps } from 'react';

const Stripe: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <rect width="34" height="34" rx="6" fill="#D9D9D9" />
    <path
      d="M15.9764 12.956C15.9764 12.0989 16.7323 11.7692 17.9843 11.7692C19.7795 11.7692 22.0472 12.2747 23.8425 13.1758V8.01099C21.8819 7.28571 19.9449 7 17.9843 7C13.189 7 10 9.32967 10 13.2198C10 19.2857 18.9764 18.3187 18.9764 20.9341C18.9764 21.9451 18.0315 22.2747 16.7087 22.2747C14.748 22.2747 12.2441 21.5275 10.2598 20.5165V25.7473C12.4567 26.6264 14.6772 27 16.7087 27C21.622 27 25 24.7363 25 20.8022C24.9764 14.2527 15.9764 15.4176 15.9764 12.956Z"
      fill="#141414"
    />
  </svg>
);

export default Stripe;
