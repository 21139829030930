import React from 'react';
import { View, Text } from 'react-native';
import { Base } from '../../../types/base';
import Delayed from '../../../assets/icons/Delayed';
import OnTrack from '../../../assets/icons/OnTrack';
import DelayedSVG from '../../../assets/svg/delayed.svg';
import OnTrackSVG from '../../../assets/svg/onTrack.svg';
import styles from './SoWSLabel.style';

export type LabelType = 'default' | 'delivery';
export type DefaultStatus = 'pending' | 'inProgress' | 'draft' | 'closed' | 'na';
export type DeliveryStatus = 'onTrack' | 'delayed' | 'delivered' | 'ready' | 'sent';
export type StatusType = DefaultStatus | DeliveryStatus;

export interface SoWSLabelProps extends Base {
  type?: LabelType;
  status: StatusType;
}

const defaultProps = {
  type: 'default' as LabelType,
};

const SoWSLabel: React.FC<SoWSLabelProps> = ({ type = 'default', status, web }) => {
  const getLabelText = (labelStatus: StatusType): string => {
    switch (labelStatus) {
      case 'pending':
        return 'Approval Pending';
      case 'draft':
        return 'Draft';
      case 'inProgress':
        return 'In Progress';
      case 'closed':
        return 'Closed';
      case 'onTrack':
        return 'On Track';
      case 'delayed':
        return 'Delayed';
      case 'na':
        return 'NA';
      default:
        return '';
    }
  };

  const getIcon = (isWeb: boolean, statusType: DeliveryStatus) => {
    if (statusType === 'delayed') {
      return isWeb ? <Delayed /> : <DelayedSVG />;
    }
    if (statusType === 'ready') {
      return isWeb ? <OnTrack /> : <OnTrackSVG />;
    }
    return isWeb ? <OnTrack /> : <OnTrackSVG />;
  };

  return (
    <View
      style={[
        styles.label,
        styles[`${type}Label`],
        type === 'delivery' ? styles[`${status as DeliveryStatus}Label`] : {},
      ]}
    >
      {type === 'delivery' && getIcon(web, status as DeliveryStatus)}
      <Text
        style={[
          styles.labelText,
          styles[`${type}Text`],
          type === 'delivery'
            ? styles[`${status as DeliveryStatus}Text`]
            : styles[status as DefaultStatus],
        ]}
      >
        {getLabelText(status)}
      </Text>
    </View>
  );
};

SoWSLabel.defaultProps = defaultProps;
export { SoWSLabel };
