const formatHours = (estimate: number) => {
  const estimateString = estimate.toString();
  let hours;
  let minutes;

  if (estimateString.includes('.')) {
    const estimateArr = estimateString.split('.');
    hours = estimateArr[0]
      .slice(0, 2)
      .padStart(2, '0');
    minutes = (60 / (100 / +estimateArr[1]))
      .toString()
      .slice(0, 2)
      .padStart(2, '0');
  } else {
    hours = estimateString
      .slice(0, 2)
      .padStart(2, '0');
    minutes = '00';
  }

  return `${hours}:${minutes} hr`;
};

export default formatHours;
