/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import {
  View,
  Text,
  TouchableOpacity,
  useWindowDimensions,
} from 'react-native';
import { IssueRowComponent } from './IssueRow.types';
import IssuePriority from '../IssuePriority';
import styles from './IssueRow.style';
import IssueLink from '../IssueLink';
import { DownIcon } from '../../../assets/icons';
import DonwIconApp from '../../../assets/svg/dropDownIcon.svg';
import SmallArrow from '../../../assets/icons/SmallArrow';
import SmallArrowApp from '../../../assets/svg/SmallArrow.svg';
import IssueIcon from '../IssueIcon';

export const IssueRow: IssueRowComponent = ({
  type,
  priority,
  name,
  epic,
  epicName = undefined,
  epicColor = '#C3FDFB',
  issue,
  link,
  onCollapse,
  isAccordionOpen = false,
  isRowSelected,
  estimate = null,
  hasChildren = false,
  web,
}): JSX.Element => {
  const { width } = useWindowDimensions();
  const isMobile = width < 900;
  const [open, setOpen] = useState(false);
  const [epicNameFull, setEpicNameFull] = useState(false);
  const smallArrowIcon = web ? <SmallArrow /> : <SmallArrowApp width={15} height={15} fill="#D9D9D9" />;
  const arrowIcon = web ? <DownIcon /> : <DonwIconApp width={20} height={20} />;

  const handleCollapse = () => {
    setOpen(!open);
    if (onCollapse) { onCollapse(); }
  };

  const handleCollapseTitle = () => setEpicNameFull(!epicNameFull);

  return (
    <View
      style={[
        styles.row,
        {
          paddingRight: !isMobile && !estimate ? 50 : 5,
          paddingLeft: !isMobile && estimate ? 30 : 5,
          flexDirection: isAccordionOpen || isMobile ? 'column' : 'row',
          paddingBottom: isMobile ? 15 : 0,
        },
      ]}
    >
      {estimate && !isMobile ? (
        <View style={!isMobile && styles.iconWebStylesLeft}>
          <TouchableOpacity
            style={[
              styles.arrowButton,
              {
                transform: [{ rotate: isRowSelected ? '-90deg' : '0deg' }],
              },
            ]}
            onPress={handleCollapse}
          >
            {smallArrowIcon}
          </TouchableOpacity>
        </View>
      ) : null}
      <View
        style={[
          styles.nameContainer,
          { width: isAccordionOpen ? '100%' : estimate ? '50%' : '60%' },
        ]}
      >
        <View style={[styles.row2, styles.flex3]}>
          <View style={styles.issueTypeIcon}>
            <IssueIcon type={type} web={web} />
          </View>
          <Text style={styles.nameText} numberOfLines={1} ellipsizeMode="tail">
            {name}
          </Text>
        </View>
        {estimate && isMobile && (
          <View style={styles.rowItem}>
            <Text style={styles.hoursText}>{estimate}</Text>
          </View>
        )}
      </View>
      <View
        style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          width: isAccordionOpen ? '100%' : estimate ? '50%' : '40%',
        }}
      >
        <View style={styles.rowItem}>
          <TouchableOpacity onPress={handleCollapseTitle}>
            <View style={[styles.epicBadge, { backgroundColor: epicColor }]}>
              <Text style={styles.epicName} numberOfLines={epicNameFull ? 0 : 2}>
                {epicName || 'NA'}
              </Text>
              <Text style={styles.epicText}>
                {epic}
              </Text>
            </View>
          </TouchableOpacity>
        </View>
        <View />
        <View style={styles.rowItem}>
          <IssueLink title={issue} url={link} />
        </View>
        <View style={styles.rowItem}>
          <IssuePriority web={web} priority={priority} />
        </View>
        {estimate && !isMobile && (
          <View style={styles.rowItem}>
            <Text style={styles.hoursText}>{estimate}</Text>
          </View>
        )}
      </View>
      {!estimate ? (
        <View style={!isMobile && styles.iconWebStyles}>
          <TouchableOpacity
            style={[
              styles.arrowButton,
              {
                transform: [{ rotate: isRowSelected ? isMobile ? '180deg' : '-90deg' : '0deg' }],
              },
            ]}
            onPress={handleCollapse}
          >
            {arrowIcon}
          </TouchableOpacity>
        </View>
      ) : (
        isMobile && (
          <View style={!isMobile && styles.iconWebStyles}>
            <TouchableOpacity style={[styles.arrowButton]} onPress={handleCollapse}>
              {hasChildren
                ? smallArrowIcon
                : <Text style={styles.textWhite}>More Info</Text>}
            </TouchableOpacity>
          </View>
        )
      )}
    </View>
  );
};
