import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const styles = StyleSheet.create({
  fontFamily,
  fullWidth: {
    width: '100%',
  },
  container: {
    width: '100%',
    alignItems: 'center',
    flexDirection: 'row',
  },
  subtaskStyles: {
    backgroundColor: '#222222',
    borderRadius: 5,
    marginTop: 10,
    padding: 10,
  },
  issueCode: {
    color: '#1890FF',
    fontSize: 14,
    fontWeight: '500',
    textDecorationLine: 'underline',
  },
  text: {
    fontSize: 12,
    color: '#ffffff',
  },
  descriptionContainer: {
    marginTop: 15,
    marginBottom: 20,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default styles;
