import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const textFont = {
  fontFamily: 'Oxygen-Bold',
};

const styles = StyleSheet.create({
  fontFamily,
  container: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: 20,
  },
  linkContainer: {
    marginTop: 20,
  },
  textError: {
    textFont,
    fontSize: 14,
    color: 'red',
    marginTop: 20,
  },
});

export default styles;
