import React from 'react';
import { View } from 'react-native';
import { Base } from '../../../types/base';

interface BannerIconprops extends Base {
  Icon: React.ReactNode;
}

const BannerIcon = ({ Icon, web }: BannerIconprops) => (
  <View>
    {web ? (
      <img src={Icon} alt="icon" width={35} height={35} />
    ) : (
      Icon
    )}
  </View>
);

export default BannerIcon;
