import { StyleSheet } from 'react-native';

const textFont = {
  fontFamily: 'Oxygen-Bold',
};

const styles = StyleSheet.create({
  btn: {
    color: '#0ABAB5',
    textFont,
    fontSize: 14,
    fontWeight: '700',
    lineHeight: 17.68,
    textAlign: 'center',
    backgroundColor: 'transparent',
  },
});

export default styles;
