import React from 'react';
import {
  View, Text, ImageBackground, Image, useWindowDimensions,
} from 'react-native';
import background from '../../../assets/images/BitmapCopy7.jpg';
import figures from '../../../assets/images/blackstone-shapes.png';
import figuresMobile from '../../../assets/images/blackstone-shapes-mobile.png';
import AppleStoreSvg from '../../../assets/svg/appleStore.svg';
import PlayStoreSvg from '../../../assets/svg/playStore.svg';
import TextLink from '../../atoms/TextLink';
import Header from '../../molecules/Header';
import { Base } from '../../../types/base';
import useResponsiveStyles from './not-found.style';
import appStyles from './not-found-app.style';

interface NotFoundProps extends Base {
  web: boolean;
}

const NotFoundTemplate = ({ web }: NotFoundProps) => {
  const { width } = useWindowDimensions();
  const webStyles = useResponsiveStyles();
  const styles = web ? webStyles : appStyles;
  const images = width < 900 ? figuresMobile : figures;

  return (
    <ImageBackground
      resizeMode="cover"
      source={background}
      style={webStyles.backgroundImageContainer}
    >
      <View style={webStyles.mainContainer}>
        <Header title="" />
        <View style={styles.imagesContainer}>
          {web ? (
            <img src={images} alt="blks-shapes" style={{ width: '100%' }} />
          ) : (
            <Image source={images} style={{ width: '100%' }} />
          )}
        </View>
        <Text style={styles.title}>There&apos;s nothing to show you yet :(</Text>
        <Text style={styles.text}>Lorem ipsum description.</Text>
        <View style={styles.storesContainer}>
          <View style={styles.appleIcon}>
            {web ? (
              <img width="100" src={AppleStoreSvg} alt="appleStore" />
            ) : (
              <AppleStoreSvg width={100} />
            )}
          </View>
          {web ? (
            <img width="100" src={PlayStoreSvg} alt="playStore" />
          ) : (
            <PlayStoreSvg width={100} />
          )}
        </View>
        <TextLink>
          <Text>Contact us</Text>
        </TextLink>
      </View>
    </ImageBackground>
  );
};

export default NotFoundTemplate;
