import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
  },
  header: {
    width: '100%',
    height: 42,
    backgroundColor: 'transparent',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottomWidth: 1,
    borderBottomColor: '#555555',
    borderTopWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  },
  paddingApp: {
    paddingHorizontal: 20,
  },
  paddingWeb: {
    paddingLeft: 45,
    paddingRight: 20,
  },
  title: {
    fontSize: 12,
    fontWeight: '700',
    color: '#8C8C8C',
    textTransform: 'uppercase',
  },
  containerChild: {
    paddingLeft: 45,
    paddingRight: 20,
    paddingVertical: 30,
    color: 'white',
    overflow: 'hidden',
  },
  containerChildApp: {
    paddingHorizontal: 20,
    paddingVertical: 10,
    color: 'white',
    overflow: 'hidden',
  },
});
export default styles;
