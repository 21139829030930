export const formatValue = (value: string) => {
  let phone = '';
  if (value && !value.includes('+')) {
    const arrayValue = Array.from(value);
    if (arrayValue.length >= 2) {
      phone = `+${arrayValue.slice(0, 2).join('')} ${arrayValue.slice(2, 12).join('')}`;
    }
  }
  return phone || value;
};
