import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    backgroundColor: '#262626',
    display: 'flex',
    borderRadius: 4,
    paddingHorizontal: 25,
    paddingTop: 29,
    paddingBottom: 24,
  },
  row: {
    width: '100%',
    flexDirection: 'row',
  },
  flex1: {
    flex: 1,
  },
  flex2: {
    flex: 2,
  },
  flex3: {
    flex: 3,
  },
  bottomLine: {
    width: '100%',
    borderBottomWidth: 1,
    borderBottomColor: '#373737',
    paddingBottom: 5,
  },
  alignContentBottom: {
    justifyContent: 'flex-end',
  },
  alignItemsCenter: {
    alignItems: 'center',
  },
  spaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  contentEnd: {
    alignItems: 'flex-end',
  },
  bottomRow: {
    paddingTop: 48,
    justifyContent: 'flex-end',
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop10: {
    marginTop: 10,
  },
  paddingRight: {
    paddingRight: 25,
  },
  paddingLeft: {
    paddingLeft: 25,
  },
  paddingBottom15: {
    paddingBottom: 15,
  },
  title: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 12,
    lineHeight: 22,
    letterSpacing: 1.8,
    color: '#AAAAAA',
  },
  id: {
    fontFamily: 'Roboto Mono',
    fontStyle: 'normal',
    fontWeight: '700',
    fontSize: 24,
    lineHeight: 32,
    letterSpacing: -0.01,
    color: '#BFBFBF',
  },
  issueNumber: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 18,
    lineHeight: 21,
    letterSpacing: -0.01,
    color: '#BFBFBF',
  },
  lightText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '300',
    fontSize: 12,
    lineHeight: 20,
    letterSpacing: 0.05,
    color: '#D9D9D9',
  },
  typeDescription: {
    fontFamily: 'Oxygen',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 14,
    lineHeight: 21,
    textAlign: 'justify',
    color: '#EAEBEB',
  },
  status: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: 12,
    lineHeight: 14,
    textAlign: 'center',
    color: '#0ABAB5',
  },
  statusReportButton: {
    // marginTop: 46,
    paddingVertical: 3,
    paddingHorizontal: 7,
    backgroundColor: '#079995',
    borderRadius: 2,
    alignSelf: 'flex-end',
    fontSize: 12,
  },
});

export default styles;
