import React from 'react';
import { Text, View } from 'react-native';
import { Base } from '../../../types/base';
// import Spinner from '../../../assets/svg/spinner.svg';
import Button from '../../atoms/Button';
import TextLink from '../../atoms/TextLink';
import CalendarContactForm from '../../organisms/CalendarContactForm';
import CalendarCallInfo from '../../organisms/CalendarCallInfo';
import CalendarConfirmation from '../../organisms/CalendarConfirmation';
import useResponsiveStyles from './calendar-call-info.style';

interface CallendarCallInfoTemplateProps extends Base {
  image: string;
  name: string;
  values: {
    callType: string;
    date: string;
    time: string;
    customerName: string;
    email: string;
    notes: string;
  };
  onChangeName: (e: string) => void;
  onChangeEmail: (e: string) => void;
  onChangeNotes: (e: string) => void;
  onSubmit: () => void;
  // isLoading: boolean;
  onSuccess: boolean;
  onReturn: () => void;
}

const CallendarCallInfoTemplate = ({
  web,
  name,
  image,
  values,
  onChangeName,
  onChangeEmail,
  onChangeNotes,
  onSubmit,
  // isLoading,
  onSuccess,
  onReturn,
}: CallendarCallInfoTemplateProps) => {
  const styles = useResponsiveStyles();
  const {
    callType, date, time, customerName, email, notes,
  } = values;

  return (
    <View style={styles.mainContainer}>
      {!onSuccess ? (
        <>
          <View style={styles.subContainer}>
            <CalendarCallInfo
              web={web}
              name={name}
              image={image}
              callType={callType}
              day={date}
              hour={time}
            />
            <CalendarContactForm
              customerName={customerName}
              email={email}
              notes={notes}
              onChangeName={onChangeName}
              onChangeEmail={onChangeEmail}
              onChangeNotes={onChangeNotes}
            />
          </View>
          <View style={styles.buttonsContainer}>
            <TextLink onPress={onReturn}>
              <Text>Back</Text>
            </TextLink>
            <View style={styles.buttonContainer}>
              <Button disabled={false} onPress={onSubmit}>
                <Text>confirm</Text>
              </Button>
            </View>
          </View>
        </>
      ) : (
        <CalendarConfirmation
          web={web}
          name={name}
          callType={callType}
          day={date}
          hour={time}
          customerName={customerName}
        />
      )}
    </View>
  );
};

export default CallendarCallInfoTemplate;
