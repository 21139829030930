import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: {
    backgroundColor: '#141414',
    maxHeight: 650,
    borderRadius: 10,
    padding: 20,
    shadowColor: '#000000',
    shadowOffset: { width: -5, height: 5 },
    shadowOpacity: 0.8,
    shadowRadius: 15,
    borderWidth: 1,
    borderColor: '#373737b1',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  overlay: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
  headerContainer: {
    marginBottom: 30,
    paddingBottom: 20,
    borderBottomColor: '#373737',
    borderBottomWidth: 1,
  },
  header: {
    color: '#fff',
    fontFamily: 'Roboto',
    fontSize: 18,
    fontWeight: '700',
    alignSelf: 'center',
  },
  closeIconContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 20,
  },
});

export default styles;
