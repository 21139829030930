import React, { useState, useEffect } from 'react';
import {
  View, Text, TouchableOpacity, useWindowDimensions,
} from 'react-native';
import { useDispatch, useSelector } from 'react-redux';
import { resetSession } from '../../../../src/redux/slices/sessionSlice';
import { RootState } from '../../../../src/redux/store';
import { Base } from '../../../types/base';
import Notification from '../../../assets/svg/notification.svg';
import Settings from '../../../assets/svg/settings.svg';
import Help from '../../../assets/svg/Help.svg';
import styles from './account.style';
import HoverItem from '../../atoms/HoverItem';
import HoverableItem from '../HoverableItem';
import { Role } from '../../../types/enums';
import Logout from '../../../assets/icons/Logout';

interface AccountProps extends Base {
  notifications?: string | number;
  isOpen?: boolean;
  onLogout?: () => void;
}

const Account = ({
  web, notifications, isOpen, onLogout,
}: AccountProps) => {
  const { width } = useWindowDimensions();
  const dispatch = useDispatch();
  const [displayUserOptions, setDisplayUserOptions] = useState(false);
  const [localOpen, setLocalOpen] = useState(false);
  const { user } = useSelector((state: RootState) => state.session);
  const areThereNotifications = notifications && +notifications > 0 ? styles.nCircleOnClosed : null;

  const renderuserOptions = () => setDisplayUserOptions(!displayUserOptions);

  const mouseEnter = () => {
    if (!localOpen && width > 576) {
      renderuserOptions();
    }
  };

  const mouseLeave = () => {
    if (!localOpen && width > 576) {
      renderuserOptions();
    }
  };

  const handleLogout = () => {
    dispatch(resetSession());
    if (onLogout) {
      onLogout();
    }
  };

  useEffect(() => {
    if (isOpen) {
      setLocalOpen(isOpen);
      setDisplayUserOptions(false);
    }
  }, [isOpen]);

  return (
    <View>
      <View style={styles.notificationsContainer}>
        <HoverItem icon={Notification} isOpen={isOpen} web={web} title="Notifications" />
        <View style={!isOpen ? areThereNotifications : styles.notificationsCircle}>
          {isOpen && <Text style={styles.notifications}>{notifications}</Text>}
        </View>
      </View>
      <HoverItem icon={Settings} isOpen={isOpen} web={web} title="Settings" />
      <HoverItem icon={Help} isOpen={isOpen} web={web} title="Help" />
      <HoverableItem onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
        <View style={styles.container}>
          <HoverItem
            subtitle={user.role === Role.CLIENT ? user?.organization?.name : user.role}
            isOpen={isOpen}
            web={web}
            title={`${user.firstName || ''} ${user.lastName ?? ''}`}
            type="image"
            img={user.avatar}
          />
          {displayUserOptions && (
            <View style={!localOpen && styles.absoluteContainer}>
              <View style={[styles.collapsedLogoutContainer, !localOpen && styles.collapsedLogout]}>
                <TouchableOpacity onPress={handleLogout}>
                  <Text style={styles.text}>Logout</Text>
                </TouchableOpacity>
              </View>
            </View>
          )}
        </View>
      </HoverableItem>
      <View style={styles.logoutContainer}>
        <View style={styles.logoutIconWrapper}>
          <Logout />
        </View>
        {isOpen && (
          <TouchableOpacity onPress={handleLogout}>
            <Text style={styles.text}>Logout</Text>
          </TouchableOpacity>
        )}
      </View>
    </View>
  );
};

Account.defaultProps = {
  notifications: 1,
  isOpen: false,
  onLogout: undefined,
};

export default Account;
