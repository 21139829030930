import React from 'react';
import { View, Text } from 'react-native';
import TimeOptions from '../../molecules/TimeOptions';
import Button from '../../atoms/Button';
import responsiveStyles from './calendar-time-selection.style';

interface CalendarTimeSelectionProps {
  value: string;
  items: Array<{ hour: string }>;
  selectedValues: {
    timezone: string;
    callType: string;
    date: string;
    time: string;
  };
  onChange: (e: string) => void;
  handleConfirm: () => void;
}

const CalendarTimeSelection = ({
  onChange,
  items,
  value,
  selectedValues,
  handleConfirm,
}: CalendarTimeSelectionProps) => {
  const styles = responsiveStyles();
  const {
    timezone, date, callType, time,
  } = selectedValues;

  return (
    <View style={styles.container}>
      <View style={styles.subContainer}>
        <Text style={styles.title}>Select time</Text>
        <Text style={styles.date}>{date}</Text>
        <TimeOptions items={items} onChange={onChange} value={value} />
      </View>
      {timezone && date && callType && time && (
        <View style={{ width: '100%' }}>
          <Button disabled={false} onPress={handleConfirm}>
            <Text>confirm</Text>
          </Button>
        </View>
      )}
    </View>
  );
};
export default CalendarTimeSelection;
