/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import Minus from '../../../assets/svg/minus.svg';
import Plus from '../../../assets/svg/plus.svg';
import WorkedEstimatedHours from '../../atoms/WorkedEstimatedHours';
import IconWithText from '../../atoms/IconWithText';
import WeekTasks from '../WeekTasks';
import { Base } from '../../../types/base';
import styles from './expandable-project-card.style';
import CISubProjectsStatus from '../CISubProjectsStatus';
import StatusLabel from '../../atoms/StatusLabel';
import { Workload } from '../../../types/TeamLeadDashboard';
import { statusType } from '../../atoms/StatusLabel/defaultTemplates';

interface ExpandableProjectCardProps extends Base {
  isForDeviationPercentage?: boolean;
  title: string;
  cardInfo?: Workload;
  ciStatus?: boolean;
  ciStatusInfo?: {
    projectName: string;
    status: statusType;
    icon?: string | null;
    items?: Array<{
      projectName: string;
      status: statusType;
      icon?: string | null;
      url: string;
    }> | null;
  };
}

const defaultProps = {
  isForDeviationPercentage: false,
  ciStatus: false,
  ciStatusInfo: {},
  cardInfo: {},
};

const ExpandableProjectCard = ({
  isForDeviationPercentage,
  ciStatus,
  ciStatusInfo,
  cardInfo,
  title,
  web,
}: ExpandableProjectCardProps) => {
  const [isOpen, setIsopen] = useState(false);
  const minusIcon = web ? (
    <img src={Minus} width={15} height={15} alt="minus" />
  ) : (
    <Minus width={15} height={15} />
  );
  const plusIcon = web ? (
    <img src={Plus} width={15} height={15} alt="minus" />
  ) : (
    <Plus width={15} height={15} />
  );

  const handleOpen = () => setIsopen(!isOpen);

  const currentStatus = ciStatusInfo?.status || 'Success';

  const projectAvatar = cardInfo?.storys ? cardInfo?.storys[Object.keys(cardInfo.storys)[0]].story.avatar.replace(' ', '')
    : '';

  return (
    <View style={[styles.container, !ciStatus && { backgroundColor: 'transparent', padding: 0 }]}>
      <View
        style={[
          styles.header,
          !ciStatus && {
            backgroundColor: '#222222',
            padding: 10,
            borderRadius: 5,
          },
        ]}
      >
        <View style={[styles.titleContainer, { width: web ? '70%' : '33.333%' }]}>
          <TouchableOpacity onPress={handleOpen} style={styles.icon}>
            {isOpen ? minusIcon : plusIcon}
          </TouchableOpacity>
          {ciStatus ? (
            <IconWithText text={ciStatusInfo.projectName} Icon={ciStatusInfo.icon} web={web} />
          ) : web ? (
            <IconWithText
              text={title}
              Icon={projectAvatar}
              web={web}
            />
          ) : (
            <Image
              source={{ uri: projectAvatar }}
              style={styles.image}
            />

          )}
        </View>
        {ciStatus ? (
          <StatusLabel label={currentStatus} web={web} status={currentStatus} />
        ) : (
          <View
            style={[
              styles.row,
              { width: web ? '20%' : '66.666%', justifyContent: 'space-between' },
            ]}
          >
            <WorkedEstimatedHours
              estimatedHours={
                isForDeviationPercentage
                  ? cardInfo.totalTimeEstimate / 3600
                  : (cardInfo.totalTimeEstimate / 3600).toFixed(0)
              }
              workedhours={
                isForDeviationPercentage
                  ? cardInfo.totalTimespent / 3600
                  : (cardInfo.totalTimespent / 3600).toFixed(0)
              }
              isForDeviationPercentage={isForDeviationPercentage}
              web={web}
            />
            <WorkedEstimatedHours
              estimatedHours={
                isForDeviationPercentage
                  ? cardInfo.totalTimeEstimate / 3600
                  : (cardInfo.totalTimeEstimate / 3600).toFixed(0)
              }
              workedhours={
                isForDeviationPercentage
                  ? cardInfo.totalTimespent / 3600
                  : (cardInfo.totalTimespent / 3600).toFixed(0)
              }
              isForDeviationPercentage
              web={web}
            />
          </View>
        )}
      </View>
      {isOpen
        && (ciStatus ? (
          <CISubProjectsStatus items={ciStatusInfo.items} web={web} />
        ) : (
          <WeekTasks items={cardInfo.storys} web={web} />
        ))}
    </View>
  );
};

ExpandableProjectCard.defaultProps = defaultProps;

export default ExpandableProjectCard;
