import React, { SVGProps } from 'react';

const Clock: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const { stroke } = props;

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="5.5" cy="5.5" r="5" stroke={stroke} />
      <path d="M5.5 0.916748V5.50008H10.0833" stroke={stroke} />
    </svg>
  );
};

export default Clock;
