import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const styles = StyleSheet.create({
  fontFamily,
  percentage: {
    fontSize: 12,
    marginLeft: 10,
    overflow: 'hidden',
    maxWidth: '100%',
  },
  text: {
    fontSize: 15,
    color: '#ffffff',
  },
  subContainer: {
    backgroundColor: '#0d0d0d52',
    width: 'auto',
    maxWidth: '100%',
    borderRadius: 50,
    flexDirection: 'row',
    paddingVertical: 5,
    alignItems: 'center',
    justifyContent: 'space-evenly',
    paddingHorizontal: 10,
  },
});

export default styles;
