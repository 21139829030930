import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  itemContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  titleContainer: {
    minWidth: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 32,
  },
  suffixIconContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginRight: 29,
  },
  paddingVertical: {
    paddingVertical: 16,
  },
  itemTitle: {
    color: '#FFFFFF',
    paddingLeft: 24,
  },
  userImageContainer: {
    width: 25,
    height: 25,
    borderRadius: 40,
    overflow: 'hidden',
  },
  userAcronymContainer: {
    width: 25,
    height: 25,
    borderRadius: 40,
    borderWidth: 1,
    borderColor: 'gray',
    justifyContent: 'center',
    alignItems: 'center',
  },
  userAcronym: {
    color: '#0ABAB5',
    fontSize: 12,
  },
  userRole: {
    color: 'gray',
    fontWeight: '400',
    fontSize: 12,
    marginLeft: 24,
    lineHeight: 22,
  },
});

export default styles;
