import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  title: {
    color: 'gray',
    marginBottom: 20,
    marginLeft: 24,
  },
  notificationsContainer: {
    flex: 1,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingRight: 24,
    position: 'relative',
  },
  notificationsCircle: {
    width: 32,
    height: 17.63,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#F0000E',
    borderRadius: 8,
  },
  nCircleOnClosed: {
    position: 'absolute',
    left: 50,
    top: 15,
    width: 5,
    height: 5,
    borderRadius: 40,
    border: 'red',
    backgroundColor: 'red',
  },
  notifications: {
    color: '#FAFAFA',
    fontSize: 12,
    fontWeight: '700',
    lineHeight: 16,
  },
  accountInfoContainer: {
    flex: 1,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  userInfoContainer: {
    width: 86,
    display: 'flex',
    alignItems: 'center',
  },
  userImageContainer: {
    width: 35,
    height: 35,
    borderRadius: 40,
    overflow: 'hidden',
  },
  text: {
    color: '#FAFAFA',
  },
  closeButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingRight: 25,
  },
  closeButton: {
    width: 86,
    display: 'flex',
    alignItems: 'center',
    paddingVertical: 24,
  },
  menuIconContainer: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'flex-end',
  },
  container: {
    position: 'relative',
  },
  absoluteContainer: {
    position: 'absolute',
    top: -20,
    left: 88,
    paddingLeft: 5,
    zIndex: 99999,
  },
  collapsedLogoutContainer: {
    marginVertical: 20,
  },
  collapsedLogout: {
    backgroundColor: '#424242',
    padding: 20,
    borderRadius: 10,
  },
  logoutContainer: {
    marginTop: 45.62,
    marginLeft: 35,
    marginBottom: 24,
    paddingBottom: 5,
    display: 'flex',
    flexDirection: 'row',
  },
  logoutIconWrapper: {
    paddingRight: 24,
  },
});

export default styles;
