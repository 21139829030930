import { IssueProps } from '../../molecules/Issue/Issue';

const priorityLabels = ['Highest', 'High', 'Medium', 'Low', 'Lowest'];
const statusLabelText = [
  'To Do',
  'In progress',
  'Done',
  'Reopened',
  'Code Review',
  'Blocked',
  'Ready for QA',
  'Ready for Release',
];

const getSubcategories = (
  type: 'status' | 'label' | 'other',
  items: IssueProps[],
  param?: keyof IssueProps,
) => {
  const sub: Array<{
    label?: string | boolean | React.ElementType;
    isChecked: boolean;
  }> = [];

  if (type === 'status') {
    statusLabelText?.forEach((item) => {
      sub.push({
        label: item,
        isChecked: true,
      });
    });
  }

  if (type === 'label') {
    priorityLabels?.forEach((item) => {
      sub.push({
        label: item,
        isChecked: true,
      });
    });
  }

  if (type === 'other' && param) {
    items?.forEach((item) => {
      const obj = {
        label: param === 'devName' && item[param] === '' ? 'Unassigned' : item[param],
        isChecked: true,
      };

      let includes = false;
      sub?.forEach((s) => {
        if (s.label === item[param]) {
          includes = true;
        }
      });

      if (!includes) {
        sub.push(obj);
      }
    });
  }

  return sub;
};

export const filterOptions = (
  // isForTeamLeadDashboard: boolean,
  isForBlockedIssues: boolean,
  statusFilter: boolean,
  items: IssueProps[],
) => {
  if (isForBlockedIssues || statusFilter) {
    return [
      {
        category: 'Project',
        areAllItemsChecked: true,
        subcategories: getSubcategories('other', items, 'projectName'),
      },
      {
        category: 'Assigned',
        areAllItemsChecked: true,
        subcategories: getSubcategories('other', items, 'devName'),
      },
    ];
  }

  if (!statusFilter) {
    return [
      {
        category: 'Project',
        areAllItemsChecked: true,
        subcategories: getSubcategories('other', items, 'projectName'),
      },
      {
        category: 'Assigned',
        areAllItemsChecked: true,
        subcategories: getSubcategories('other', items, 'devName'),
      },
      {
        category: 'Priority',
        areAllItemsChecked: true,
        subcategories: getSubcategories('label', items, 'priority'),
      },
    ];
  }

  return [
    {
      category: 'Project',
      areAllItemsChecked: true,
      subcategories: getSubcategories('other', items, 'projectName'),
    },
    {
      category: 'Assigned',
      areAllItemsChecked: true,
      subcategories: getSubcategories('other', items, 'devName'),
    },
    {
      category: 'Status',
      areAllItemsChecked: true,
      subcategories: statusLabelText.map((item) => ({
        label: item,
        isChecked: true,
      })),
    },
  ];
};
