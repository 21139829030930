import React from 'react';
import {
  TouchableHighlight, Text, View, StyleProp, ViewStyle,
} from 'react-native';
import { buttonTheme } from './buttonTheme';
import styles from './button.style';
import { Base } from '../../../types/base';

interface ButtonProps extends Base {
  onPress?: () => void;
  children?: React.ReactNode;
  variant?: 'basic' | 'text' | 'outlined';
  type?: 'primary' | 'light';
  disabled?: boolean;
  Icon?: React.ReactNode;
  uppercase?: boolean;
  style?: StyleProp<ViewStyle>;
  small?: boolean;
}

const defaultProps: ButtonProps = {
  children: null,
  onPress: () => undefined,
  variant: 'basic',
  type: 'primary',
  disabled: true,
  Icon: null,
  uppercase: true,
  web: false,
  style: {},
  small: false,
};

const Button: React.FC<ButtonProps> = ({
  onPress,
  children,
  disabled = true,
  variant = 'basic',
  type = 'primary',
  Icon,
  uppercase,
  style,
  web,
  small,
}) => {
  const theme = buttonTheme(disabled, type, variant);
  const [outlinedActive, setOutlinedActive] = React.useState(theme.textColor);
  const textTransform = uppercase ? 'uppercase' : 'capitalize';

  return (
    <TouchableHighlight
      underlayColor={theme.underlayColor}
      activeOpacity={theme.activeOpacity}
      onPressIn={() => setOutlinedActive(type === 'primary' ? 'white' : 'black')}
      onPressOut={() => setOutlinedActive(theme.textColor)}
      style={[styles.buttonBase, theme.buttonTheme, style]}
      onPress={onPress}
    >
      <View style={styles.iconView}>
        <View style={styles.textView}>
          {Icon ? (
            <View style={styles.marginRight10}>{web ? <img width="15" height="15" src={Icon} alt="icon" /> : Icon}</View>
          ) : null}

          <Text
            style={{
              ...styles.fontFamily,
              color: variant === 'outlined' ? outlinedActive : theme.textColor,
              fontWeight: theme.fontWeight,
              letterSpacing: theme.letterSpacing,
              fontSize: small ? 12 : 14,
              textTransform,
            }}
          >
            {children}
          </Text>
        </View>
      </View>
    </TouchableHighlight>
  );
};

Button.defaultProps = defaultProps;

export default Button;
