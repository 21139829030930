import React, { useMemo } from 'react';
import { View } from 'react-native';
import { Story, Subtask, Hotfix } from '../../../assets/icons';
import HotfixApp from '../../../assets/svg/ClientBacklogIssue/bug.svg';
import StoryApp from '../../../assets/svg/ClientBacklogIssue/story.svg';
import SubtaskApp from '../../../assets/svg/ClientBacklogIssue/subtask.svg';
import { IssueIconComponent } from './IssueIcon.types';

export const IssueIcon: IssueIconComponent = ({ type, web }): JSX.Element => {
  const memoizedIcon = useMemo(() => {
    if (type === 'Story') {
      return web ? <Story width={20} /> : <StoryApp />;
    }
    if (type === 'Sub-task') {
      return web ? <Subtask width={20} /> : <SubtaskApp />;
    }
    if (type === 'Bug') {
      return web ? <Hotfix width={20} /> : <HotfixApp />;
    }
    return <View />;
  }, [type, web]);

  return memoizedIcon;
};
