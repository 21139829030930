import React from 'react';
import { View, Text } from 'react-native';
import { FormikErrors, FormikTouched } from 'formik';
import LinkText from '../../atoms/TextLink';
import Header from '../../molecules/Header';
import LoginForm from '../../organisms/LoginForm';
import styles from './login.style';
import { Base } from '../../../types/base';

interface LoginTemplateProps extends Base {
  signIn: () => void;
  signUpRedirect: () => void;
  forgotPasswordRedirect: () => void;
  errormessage?: string;
  values: {
    username: string;
    password: string;
  };
  handleChangeEmail: (val: string) => void;
  handleChangePassword: (val: string) => void;
  handleSubmit: () => void;
  formikErrors: FormikErrors<{ username: string; password: string }>;
  formikTouched: FormikTouched<{ username: string; password: string }>;
  isLoading: boolean;
}

const defaultProps = {
  errormessage: undefined,
};

const LoginTemplate = ({
  web,
  signIn,
  errormessage,
  signUpRedirect,
  forgotPasswordRedirect,
  values,
  handleChangeEmail,
  handleChangePassword,
  handleSubmit,
  formikErrors,
  formikTouched,
  isLoading,
}: LoginTemplateProps) => (
  <View style={styles.container}>
    <Header title="Log In" />
    <LoginForm
      web={web}
      signIn={signIn}
      redirect={forgotPasswordRedirect}
      values={values}
      handleChangeEmail={handleChangeEmail}
      handleChangePassword={handleChangePassword}
      handleSubmit={handleSubmit}
      formikErrors={formikErrors}
      formikTouched={formikTouched}
      isLoading={isLoading}
    />
    <View style={styles.linkContainer}>
      <LinkText onPress={signUpRedirect}>
        <Text>Not registered yet? Click here</Text>
      </LinkText>
    </View>
    {errormessage && <Text style={styles.textError}>{errormessage}</Text>}
  </View>
);

LoginTemplate.defaultProps = defaultProps;

export default LoginTemplate;
