import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  issueView: {
    textAlign: 'center',
  },
  issueText: {
    textTransform: 'uppercase',
    fontSize: 14,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: '#D9D9D9',
  },
  issueLink: {
    color: '#1890FF',
    fontSize: 15,
    textTransform: 'uppercase',
    fontWeight: '400',
    letterSpacing: 0.025,
    lineHeight: 18,
    textDecorationLine: 'underline',
    textDecorationStyle: 'solid',
    textDecorationColor: 'rgba(24, 144, 255, 1)',
  },
});

export default styles;
