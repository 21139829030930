import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    backgroundColor: '#262626',
    paddingVertical: 20,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'visible',
    borderRadius: 4,
  },
  containerIssue: {
    flexDirection: 'row',
    zIndex: 1,
  },
  col2: {
    width: '100%',
    height: '100%',
  },
  contentName: {
    marginVertical: 24,
    paddingLeft: 45,
    paddingRight: 20,
  },
  contentNameApp: {
    paddingHorizontal: 20,
    marginVertical: 10,
  },
  typeIssue: {
    zIndex: 1,
    marginRight: 15,
  },
  epicText: {
    color: '#121212',
  },
  nameContainer: {
    display: 'flex',
    flex: 1,
    paddingRight: 15,
  },
  name: {
    flex: 1,
    fontFamily: 'Roboto',
    fontWeight: '400',
    lineHeight: 31,
    letterSpacing: -0.01,
    color: 'rgba(191, 191, 191, 1)',
  },
  namesApp: {
    flex: 1,
    fontFamily: 'Roboto',
    fontWeight: '400',
    fontSize: 16,
    lineHeight: 20,
    textAlign: 'justify',
    color: 'rgba(191, 191, 191, 1)',
  },
  collapse: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  cardFirstRow: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingLeft: 15,
    paddingRight: 15,
  },
  cardSecondRow: {
    width: '100%',
    justifyContent: 'space-between',
    marginBottom: 24,
    paddingLeft: 45,
    paddingRight: 20,
  },
  cardSecondRowApp: {
    width: '100%',
    justifyContent: 'space-between',
    paddingHorizontal: 20,
    paddingTop: 20,
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  codeAndPriorityRow: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  code: {
    flexDirection: 'row',
    marginRight: 25,
  },
  issueIconContainer: {
    paddingRight: 10,
  },
  issueInfo: {
    width: '100%',
    paddingLeft: 45,
    paddingTop: 20,
  },
  issueInfoApp: {
    width: '100%',
    paddingLeft: 20,
    paddingTop: 10,
  },
  flexDirectionRow: {
    flexDirection: 'row',
  },
  infoText: {
    color: '#8C8C8C',
    fontWeight: '700',
    fontSize: 12,
  },
  infoText2: {
    color: '#8C8C8C',
    fontWeight: '400',
    fontSize: 12,
    marginLeft: 10,
  },
  dropdownContainer: {
    width: '49%',
  },
});

export default styles;
