import React from 'react';
import { View, Text } from 'react-native';
import ClientProjectCard from '../../molecules/ClientProjectCard';
import { Base } from '../../../types/base';
import styles from './client-project-container.style';
import { ClientIssue } from '../../../types/ClientDashboardDto';

interface IssuesProps extends Base {
  items: Array<ClientIssue>;
  title: string;
}
const ClientProjectContainer = ({ items, title, web }: IssuesProps) => {
  const fontFamily = web ? 'RobotoMono-Regular, monospace' : 'RobotoMono-Medium';

  return (
    <View
      style={[
        styles.container,
        {
          marginBottom: !web ? 20 : 0,
        },
      ]}
    >
      <View
        style={[
          styles.titleContainer,
          { marginBottom: web ? 20 : 12, paddingBottom: web ? 20 : 5 },
        ]}
      >
        <View style={styles.row}>
          <Text
            style={[
              styles.title,
              {
                fontFamily,
              },
            ]}
          >
            {title}
          </Text>
          <Text
            style={[
              styles.issuesAmount,
              {
                fontFamily,
              },
            ]}
          >
            {items.length}
          </Text>
        </View>
      </View>
      {items.length ? (
        items.map((item, index) => (
          <ClientProjectCard
            key={index}
            issueName={`${item.projectCode} - ${item.issueName}`}
            description={item.description || 'No description available'}
            priority={item.priority}
            hours={item.hours}
            web={web}
          />
        ))
      ) : (
        <Text style={styles.noItemsText}>No items available</Text>
      )}
    </View>
  );
};

export default ClientProjectContainer;
