import { Platform, StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  fontFamily: {
    fontFamily: 'Roboto',
    fontWeight: '600',
    lineHeight: 18,
    letterSpacing: 1,
  },
  buttonBase: {
    borderRadius: 5,
    padding: 15,
    alignItems: 'center',
    ...Platform.select({
      ios: {
        shadowRadius: 2,
        shadowColor: 'black',
        shadowOffset: { width: 2, height: 2 },
        shadowOpacity: 0.2,
      },
      android: {
        elevation: 2,
      },
    }),
  },
  iconView: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    textAlign: 'center',
  },
  textView: {
    flexDirection: 'row',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  marginRight10: {
    marginRight: 10,
  },
});

export default styles;
