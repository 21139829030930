const callTimeOptions = [
  {
    id: '1',
    title: 'Silver Call',
    time: '30m',
  },
  {
    id: '2',
    title: 'Golden Call',
    time: '60m',
  },
];

const timezonesExample = [
  {
    id: '1',
    title: 'Timezone 1',
  },
  {
    id: '2',
    title: 'Timezone 2',
  },
  {
    id: '3',
    title: 'Timezone 3',
  },
];

export { callTimeOptions, timezonesExample };
