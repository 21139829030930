import React, { useState } from 'react';
import { View, TouchableOpacity, TextInput } from 'react-native';
import { Base } from '../../../types/base';
import { Search, Close } from '../../../assets/icons';
import CloseSvg from '../../../assets/svg/close.svg';
import SearchSvg from '../../../assets/svg/search.svg';
import styles from './searchbar.style';

interface SearchBarProps extends Base {
  onSearchPress: () => void;
  onErasePress: () => void;
  onChange: (value: string) => void;
  search: string;
  mini?: boolean;
}

const defaultProps = {
  mini: false,
};

const SearchBar = ({
  onSearchPress,
  onErasePress,
  onChange,
  search,
  mini,
  web,
}: SearchBarProps) => {
  const [clicked, setClicked] = useState<boolean>(false);

  const handleBlur = () => setTimeout(() => {
    setClicked(false);
  }, 20);

  return (
    <View style={styles.container}>
      <View style={mini ? styles.searchMini : styles.search}>
        <TextInput
          style={styles.input}
          placeholder="Search"
          value={search}
          onChangeText={(e) => onChange(e)}
          onBlur={() => handleBlur()}
          onFocus={() => setClicked(true)}
          placeholderTextColor="#8C8C8C"
          returnKeyType="search"
          onSubmitEditing={onSearchPress}
        />
        {clicked && (
          <TouchableOpacity style={styles.erasePress} onPress={onErasePress}>
            {web ? <Close width={15} height={15} /> : <CloseSvg width={15} height={15} />}
          </TouchableOpacity>
        )}
        <TouchableOpacity onPress={onSearchPress}>
          {web ? <Search width={15} height={15} /> : <SearchSvg width={15} height={15} />}
        </TouchableOpacity>
      </View>
    </View>
  );
};

SearchBar.defaultProps = defaultProps;

export default SearchBar;
