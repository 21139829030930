import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
  },
  labelsContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
    paddingHorizontal: 20,
  },
  flex1: {
    flex: 1,
    paddingRight: 15,
    alignItems: 'center',
  },
  flex2: {
    flex: 2,
    paddingRight: 15,
    alignItems: 'center',
  },
  flex3: {
    flex: 3,
    paddingRight: 15,
  },
  label: {
    fontFamily: 'Roboto',
    fontSize: 12,
    lineHeight: 22,
    letterSpacing: 1.8,
    fontWeight: '700',
    color: '#AAAAAA',
  },
  noItemsMessage: {
    color: '#FFFFFF',
    alignSelf: 'center',
    marginVertical: 20,
  },
});

export default styles;
