import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto-Mono',
};

const styles = StyleSheet.create({
  fontFamily,
  subContainer2: {
    width: '20%',
    height: '100%',
  },
  slideShowProjects: {
    position: 'absolute',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    right: 0,
    backgroundColor: '#262626',
  },
  avatarContainer: {
    position: 'relative',
    padding: 30,
    transform: [{ translateY: -100 }],
  },
  imageBg: {
    background: 'linear-gradient(92.93deg, #0ABAB5 -0.34%, #000000 77.39%)',
    width: '100%',
    height: '25vh',
    marginBottom: 30,
    paddingLeft: 50,
    paddingBottom: 30,
    justifyContent: 'flex-end',
  },
  envContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    maxWidth: '100%',
    flexWrap: 'wrap',
  },
  envContainerApp: {
    display: 'flex',
  },
  mainContainer: {
    width: '100%',
    height: '100vh',
    flexDirection: 'row',
    backgroundColor: '#121212',
  },
  subContainer1: {
    width: '80%',
    height: '100%',
    paddingLeft: 30,
  },
  subContainer2ComponentsContainer: {
    width: '100%',
    paddingRight: 50,
  },
  leftRowItem: {
    width: '38%',
  },
  rightRowItem: {
    width: '58%',
  },
  fullWidth: {
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 30,
  },
  loaderContainer: {
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  paddingTop20: {
    paddingTop: 20,
  },
  msg: {
    backgroundColor: '#333',
    padding: 15,
    color: 'white',
    margin: 'auto',
    borderRadius: 5,
  },
});

export default styles;
