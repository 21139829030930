import React from 'react';
import { View, Text } from 'react-native';
import BSCircleIcon from '../../../assets/svg/BSCircleIcon.svg';
import { Base } from '../../../types/base';
import BannerIcon from '../../atoms/BannerIcon';
import styles from './banner.style';

interface BannerProps extends Base {
  Icon: React.ReactNode | string;
}

const Banner = ({ Icon, web }: BannerProps) => (
  <View style={styles.container}>
    <View style={styles.iconContainer}>
      <BannerIcon Icon={BSCircleIcon} web={web} />
    </View>
    <View style={styles.userNamesContainer}>
      <Text style={styles.text1}>@Blackstone Studio</Text>
      <Text style={styles.text2}>@blackstonestudio</Text>
    </View>
    <View style={styles.iconContainer}>
      <BannerIcon Icon={Icon} web={web} />
    </View>
  </View>
);

export default Banner;
