import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const textFontFamily = {
  fontFamily: 'Oxygen-Regular',
};

const styles = StyleSheet.create({
  fontFamily,
  container: {
    width: '100%',
  },
  callType: {
    textAlign: 'center',
    fontSize: 40,
    color: '#0ABAB5',
  },
  lengthContainer: {
    flexDirection: 'row',
    justifyContent: 'center',
    marginTop: 5,
  },
  length: {
    textFontFamily,
    fontSize: 16,
    fontWeight: '300',
    color: '#ffffffa6',
    marginLeft: 5,
  },
});

export default styles;
