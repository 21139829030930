import React, { FC, useEffect } from 'react';
import {
  Text, View, TouchableHighlight, ScrollView,
} from 'react-native';
import SmallArrow from '../../../assets/icons/SmallArrow';
import SmallArrowApp from '../../../assets/svg/SmallArrow.svg';
import styles from './dropdown.style';
import Call from './Call';
import BaseItem from './BaseItem';

const light = 'rgba(255, 255, 255, 0.65)';
const dark = 'rgba(0, 0, 0, 0.65)';

export type DropdownItem = {
  active?: string;
  title: string;
  itemValue?: string;
  id: string;
  time?: string;
  ItemIcon?: React.ReactNode;
};

interface DropdownProps {
  onSelected?: (selected: DropdownItem) => void;
  onChange?: (val: string) => void;
  placeholder?: string;
  placeHolderColor?: string;
  iconColor?: string;
  label?: string;
  items?: Array<DropdownItem>;
  value?: string | number;
  type?: 'basic' | 'secondary' | 'call';
  variant?: 'static' | 'dynamic';
  Icon?: React.ReactNode;
  isLeftAligned?: boolean;
  web?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  onSelected,
  onChange,
  placeholder,
  placeHolderColor,
  iconColor,
  label,
  items,
  value,
  type,
  variant,
  Icon,
  isLeftAligned,
  web,
}) => {
  const [val, setVal] = React.useState(value);
  const [activeIcon, setActiveIcon] = React.useState<React.ReactNode | null>();
  const [show, setShow] = React.useState(false);
  const [itemActive, setItemActive] = React.useState(
    items && items.map((obj) => ({ ...obj, active: light })),
  );

  const renderDropDownIcon = (color: string) => (web ? <SmallArrow width={15} color={color} />
    : <SmallArrowApp width={15} fill={color} />);

  const renderItem: FC<DropdownItem> = ({
    id, title, time, active, itemValue, ItemIcon,
  }) => {
    const setValue = (item: DropdownItem) => {
      onChange(item.itemValue || item.title);
      setShow(false);
      if (item) {
        delete item.active;
        setVal(item.title);
        onSelected(item);

        if (item.ItemIcon) {
          setActiveIcon(item.ItemIcon);
        }
      }
    };

    const setActiveItem = (itemId: string) => {
      if (itemActive) {
        const newItems = itemActive.map((obj) => ({
          ...obj,
          active: itemId === obj.id ? dark : light,
        }));
        setItemActive(newItems);
      }
    };

    if (type === 'call') {
      return (
        <Call
          key={id}
          id={id}
          title={title}
          time={time}
          active={active}
          onShowUnderlay={() => setActiveItem(id)}
          onHideUnderlay={() => setActiveItem('')}
          web={web}
          setValue={(item) => setValue(item)}
        />
      );
    }

    return (
      <BaseItem
        key={id}
        id={id}
        title={title}
        itemValue={itemValue}
        onShowUnderlay={() => setActiveItem(id)}
        onHideUnderlay={() => setActiveItem('')}
        setValue={(item) => setValue(item)}
        active={active}
        type={type}
        ItemIcon={ItemIcon || null}
      />
    );
  };

  const containerStyle = val ? styles.containerVal : styles.container;
  const textStyle = val ? styles.textBasicWhite : styles.textBasic;

  useEffect(() => {
    const selected = itemActive.find((item) => item.itemValue === val);
    if (selected) {
      setVal(selected.title);
    }
  }, []);

  return (
    <View style={{ position: 'relative' }}>
      {label && val !== '' ? <Text style={styles.label}>{label}</Text> : null}
      <View
        style={
          type === 'basic'
            ? containerStyle
            : [styles.container2, { alignItems: isLeftAligned ? 'flex-start' : 'center' }]
        }
      >
        <TouchableHighlight onPress={() => setShow(!show)} underlayColor="transparent">
          <View style={styles.dropdownContainer}>
            {Icon && !activeIcon && <View style={styles.icon}>{Icon}</View>}
            {activeIcon && <View style={styles.icon}>{activeIcon}</View>}
            <Text
              style={[
                type === 'basic' ? textStyle : styles.dropdownText2,
                { color: items.find((item) => item.title === val) ? 'white' : placeHolderColor },
              ]}
            >
              {(variant === 'dynamic' || type === 'basic') ? (val || placeholder) : placeholder}
            </Text>
            <View style={{ transform: [{ rotate: show ? '180deg' : '0deg' }] }}>
              {renderDropDownIcon(iconColor || '#D9D9D9')}
            </View>
          </View>
        </TouchableHighlight>
        <View
          style={[
            type === 'basic' ? styles.itemContainer : styles.itemContainer2,
            show ? styles.show : styles.hide,
          ]}
        >
          <ScrollView>{itemActive?.map((item: DropdownItem) => renderItem(item))}</ScrollView>
        </View>
      </View>
    </View>
  );
};

Dropdown.defaultProps = {
  onSelected: () => undefined,
  onChange: () => undefined,
  placeholder: 'Select',
  label: 'Select',
  items: undefined,
  value: undefined,
  type: 'basic',
  variant: 'dynamic',
  Icon: null,
  isLeftAligned: false,
  web: false,
  placeHolderColor: 'rgba(255, 255, 255, 0.65)',
  iconColor: '#BCBCBC',
};

export default Dropdown;
