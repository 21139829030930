import {
  Failed,
  // InProgress,
  Queued,
  Success,
} from '../../../assets/icons';
import QueuedApp from '../../../assets/svg/CIStatusIcons/queued.svg';
import FailedApp from '../../../assets/svg/CIStatusIcons/failed.svg';
// import InProgressApp from '../../../assets/svg/CIStatusIcons/inProgress.svg';
import SuccessApp from '../../../assets/svg/CIStatusIcons/success.svg';
import { labelDataType } from './StatusLabel';
import SuccessIcon from '../../../assets/icons/CIStatusIcons/Success';
import FailedIcon from '../../../assets/icons/CIStatusIcons/Failed';
import InProgressIcon from '../../../assets/icons/CIStatusIcons/InProgress';
import HoldIcon from '../../../assets/icons/CIStatusIcons/Hold';

export type statusType = 'Success' | 'Error' | 'Hold' | 'Running';

export type issueStatusType =
  | 'Reopened'
  | 'Blocked'
  | 'ToDo'
  | 'In progress'
  | 'Code Review'
  | 'Ready for QA'
  | 'Ready for Release'
  | 'Done';

// ciStatus
export const icons: Record<statusType, React.FC<React.SVGProps<SVGSVGElement>>> = {
  Success: SuccessIcon,
  Error: FailedIcon,
  Hold: HoldIcon,
  Running: InProgressIcon,
};

export const colors: Record<statusType & issueStatusType, string> = {
  Success: '#58D1C9',
  Error: '#F93C6F',
  Hold: '#948BF4',
  Running: '#29ABE2',
  Reopened: '#F93C6F',
  Blocked: '#F93C6F',
  ToDo: '#AAAAAA',
  'In progress': '#2151C5',
  'Code Review': '#2151C5',
  'Ready for QA': '#2151C5',
  'Ready for Release': '#079995',
  Done: '#17924F',
  Overdue: '#E87040',
  NA: '#F93C6F',
};

const labelData = {
  queued: {
    color: '#948BF4',
    Icon: {
      web: Queued,
      app: QueuedApp,
    },
    label: 'queued',
  },
  'Code Review': {
    color: '#948BF4',
    Icon: {
      web: null,
      app: null,
    },
    label: 'Code Review',
  },
  success: {
    color: '#58D1C9',
    Icon: {
      web: Success,
      app: SuccessApp,
    },
    label: 'success',
  },
  'On Time': {
    color: '#58D1C9',
    Icon: {
      web: null,
      app: null,
    },
    label: 'On Time',
  },
  'Ready for Release': {
    color: '#079995',
    Icon: {
      web: null,
      app: null,
    },
    label: 'Ready for Release',
  },
  'In progress': {
    color: '#29ABE2',
    label: 'In Progress',
    Icon: {
      web: null,
      app: null,
    },
  },
  failed: {
    color: '#F93C6F',
    Icon: {
      web: Failed,
      app: FailedApp,
    },
    label: 'failed',
  },
  Blocked: {
    color: '#F93C6F',
    Icon: {
      web: null,
      app: null,
    },
    label: 'Blocked',
  },
  Done: {
    color: '#AAAAAA',
    Icon: {
      web: null,
      app: null,
    },
    label: 'Done',
  },
  Overdue: {
    color: '#E87040',
    Icon: {
      web: null,
      app: null,
    },
    label: 'Overdue',
  },
  Reopened: {
    color: '#F93C6F',
    Icon: {
      web: null,
      app: null,
    },
    label: 'Reopened',
  },
  callReview: {
    color: '#2151C5',
    Icon: {
      web: null,
      app: null,
    },
    label: 'Call Review',
  },
  'Ready for QA': {
    color: '#F93C6F',
    Icon: {
      web: null,
      app: null,
    },
    label: 'Ready for QA',
  },
  ToDo: {
    color: '#AAAAAA',
    Icon: {
      web: null,
      app: null,
    },
    label: 'To Do',
  },
  NA: {
    color: '#F93C6F',
    Icon: {
      web: Failed,
      app: FailedApp,
    },
    label: 'NA',
  },
} as labelDataType;

export default labelData;
