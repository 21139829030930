import React, { SVGProps } from 'react';

const Plus: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="#ffffff"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    {...props}
  >
    <path d="M24 10h-10v-10h-4v10h-10v4h10v10h4v-10h10z" />
  </svg>
);

export default Plus;
