/* eslint-disable max-len */
import React, {
  Dispatch, SetStateAction, useState, useEffect,
} from 'react';
import { View, Text } from 'react-native';
import { Base } from '../../../types/base';
import { IssueProps } from '../../molecules/Issue/Issue';
import FilterCheckBox from '../../atoms/FilterCheckBox';
import styles from './issues-filter-app.style';

type FilterProps = {
  label?: string | boolean | React.ElementType;
  isChecked: boolean;
};

export interface FilterCategory {
  category: string;
  areAllItemsChecked: boolean;
  subcategories: Array<FilterProps>;
}

type ItemExtraProps = {
  totalTimespent: number;
  totalTimeEstimate: number;
  hours: string;
  avatar: string;
  name: string;
  link: string;
  status: string;
};

interface IssuesFilterProps extends Base {
  isOpen: boolean;
  setFilterOptions: Dispatch<SetStateAction<FilterCategory[]>> | null;
  filterOptions: Array<FilterCategory> | [];
  items: Array<IssueProps>;
  projectItems?: { [key: string]: ItemExtraProps };
  setFilteredProjects?: Dispatch<SetStateAction<unknown>> | null;
  setFilteredItems?: Dispatch<SetStateAction<IssueProps[] | []>> | null;
  // isForTeamLeadDashboard?: boolean;
  isForProjectItems?: boolean;
  // statusFilter?: boolean;
  // isForDueToday?: boolean;
}

const defaultProps = {
  isForBlockedIssues: false,
  // isForTeamLeadDashboard: false,
  projectItems: {},
  setFilteredProjects: null,
  isForProjectItems: false,
  setFilteredItems: null,
  // statusFilter: false,
  isForDueToday: false,
};

const IssuesFilterApp = ({
  isOpen,
  filterOptions,
  setFilterOptions,
  setFilteredItems,
  items,
  projectItems,
  setFilteredProjects,
  // isForTeamLeadDashboard,
  isForProjectItems,
  // statusFilter,
  web,
}: IssuesFilterProps) => {
  const [isAllSelected, setIsAllSelected] = useState(true);

  const filterValidation = (options: {
    projectName?: string;
    devName?: string;
    status?: string;
    priority?: string;
  }): boolean => {
    let statusValidation: Record<'Project' | 'Assigned' | 'Status' | 'Priority', boolean> = {
      Project: true,
      Assigned: true,
      Status: true,
      Priority: true,
    };

    filterOptions?.forEach((option: FilterCategory) => {
      statusValidation = {
        ...statusValidation,
        [option.category]: option.subcategories.every((item) => !item.isChecked),
      };
    });

    const result = Object.values(options).reduce(
      (acc, op) => acc
        || filterOptions.some((category: FilterCategory) => category.subcategories.some((subCat) => subCat.isChecked && subCat.label === op)),
      false,
    );

    return result;
  };

  const handleTimeTrackingProjects = () => {
    const itemsCopy = { ...projectItems };

    const filtered = Object.entries(itemsCopy).filter(([, value]) => filterOptions.some((category) => category.subcategories.some((subCat) => subCat.isChecked && subCat.label === value.status)));

    setFilteredProjects(Object.fromEntries(filtered));
  };

  const handleFilteredItems = () => {
    const itemsCopy = items ? [...items] : [];

    itemsCopy?.forEach((item, index) => {
      if (item.devName === '') {
        itemsCopy[index] = { ...item, devName: 'Unassigned' };
      }
    });

    const updatedItems = itemsCopy?.filter(({
      projectName, devName, status, priority,
    }) => filterValidation({
      projectName,
      devName,
      status,
      priority,
    }));
    setFilteredItems(updatedItems);
  };

  useEffect(() => {
    const areAllChecked = filterOptions.every((item) => item.subcategories.every((subCat) => subCat.isChecked === true));

    if (isForProjectItems) {
      handleTimeTrackingProjects();
    } else {
      handleFilteredItems();
    }

    setIsAllSelected(areAllChecked);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterOptions]);

  if (!items) {
    return null; // TODO: check this :p
  }

  return isOpen ? (
    <View
      style={styles.mainContainer}
    >
      <Text style={styles.text}>Filter</Text>
      <View style={styles.optionscontainer}>
        {filterOptions.map((item, index) => {
          const seen = new Set();
          const filteredArr = item.subcategories.filter((el: FilterProps) => {
            const duplicate = seen.has(el.label);
            seen.add(el.label);
            return !duplicate;
          });
          return (
            <View key={index} style={styles.categoryContainer}>
              <FilterCheckBox
                isCategory
                value={item.category}
                isChecked={item.areAllItemsChecked}
                filterOptions={filterOptions}
                setFilterOptions={setFilterOptions}
                web={web}
              />
              <View style={styles.subCategoriesContainer}>
                {filteredArr.map((subCategory, i) => (
                  <FilterCheckBox
                    key={i}
                    value={subCategory.label}
                    category={item.category}
                    isChecked={subCategory.isChecked}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    web={web}
                  />
                ))}
              </View>
            </View>
          );
        })}
      </View>
      {Object.keys(projectItems).length === 0 && (
        <FilterCheckBox
          value="All"
          isChecked={isAllSelected}
          setSelectAll={setIsAllSelected}
          isSelectAll
          filterOptions={filterOptions}
          setFilterOptions={setFilterOptions}
          web={web}
        />
      )}
    </View>
  ) : (
    <View />
  );
};

IssuesFilterApp.defaultProps = defaultProps;

export default IssuesFilterApp;
