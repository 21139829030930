import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  labelsContainer: {
    backgroundColor: 'transparent',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 10,
  },
  labelContainer2: {
    alignItems: 'flex-end',
    paddingRight: 50,
  },
  labelcontainer3: {
    width: '15%',
  },
  label: {
    fontSize: 12,
    fontWeight: '700',
    color: '#8C8C8C',
  },
  itemsContainer: {
    width: '100%',
  },
  item: {
    width: '100%',
    backgroundColor: '#222222',
    marginBottom: 10,
    padding: 10,
    borderRadius: 5,
  },
  arrowsContainer: {
    width: '100%',
    alignItems: 'center',
  },
});

export default styles;
