import React from 'react';
import { TouchableOpacity, Text } from 'react-native';
import styles from './text-link.style';

interface TextLinkProps {
  children: React.ReactElement;
  onPress?: () => void;
}

const defaultProps = {
  onPress: () => undefined,
};

const TextLink = ({ children, onPress }: TextLinkProps) => (
  <TouchableOpacity style={styles.btn} onPress={onPress}>
    <Text style={styles.btn}>{children}</Text>
  </TouchableOpacity>
);

TextLink.defaultProps = defaultProps;

export default TextLink;
