const styles = {
  container: {
    flex: 1,
    height: '100vh',
  },
  centerContent: {
    justifyContent: 'center',
    alignItems: 'center',
  },
};

export default styles;
