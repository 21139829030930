import React, { SVGProps, useEffect, useState } from 'react';

const ChartTooltip: React.FC<SVGProps<SVGSVGElement>> = (props) => {
  const { x, y, orientation } = props;
  const [newY, setNewY] = useState(0);

  useEffect(() => {
    if (y) {
      setNewY(
        orientation === 'bottom' ? parseInt(y.toString(), 10) - 35 : parseInt(y.toString(), 10) + 35,
      );
    }
  }, [orientation, y]);

  return (
    <g>
      <circle cx={x} cy={newY} r="20" stroke="tomato" fill="none" />
      <circle cx={x} cy={newY} r="25" stroke="orange" fill="none" />
      <circle cx={x} cy={newY} r="30" stroke="gold" fill="none" />
    </g>
  );
};

export default ChartTooltip;
