import React from 'react';
import { View, Text, useWindowDimensions } from 'react-native';
import Spinner from '../../../assets/svg/spinner.svg';
import Button from '../../atoms/Button';
import TextBox from '../../atoms/TextBox';
import TextLink from '../../atoms/TextLink';
import { Base } from '../../../types/base';
import webStyles from './text-box-with-button.style';

interface TextBoxWithButtonProps extends Base {
  children: React.ReactNode;
  buttonText: string;
  action: () => void;
  isLoading: boolean;
  handleGoBack: () => void;
  errorMessage: string;
}

const TextBoxWithButton = ({
  web,
  children,
  buttonText,
  action,
  isLoading,
  handleGoBack,
  errorMessage,
}: TextBoxWithButtonProps) => {
  const { width } = useWindowDimensions();
  const styleButtonXs = webStyles.buttonContainerXs;
  const isXs = width <= 800;
  const styleContainer = isXs ? webStyles.containerXs : webStyles.container;
  const styleButton = isXs ? styleButtonXs : webStyles.buttonContainer;

  return (
    <View
      style={web ? styleContainer : webStyles.containerApp}
    >
      <TextBox>{children}</TextBox>

      {errorMessage ? <Text style={webStyles.apiCallErrorText}>{errorMessage}</Text> : null}

      <View
        style={web ? styleButton : webStyles.buttonAppContainer}
      >
        {isLoading ? (
          <View style={webStyles.spinnerContainer}>
            {web ? (
              <img src={Spinner} alt="spinner" width={35} height={35} />
            ) : (
              <Spinner width={35} height={35} />
            )}
          </View>
        ) : (
          <Button disabled={false} onPress={action}>
            {buttonText}
          </Button>
        )}
      </View>
      <View style={{ marginBottom: 24, marginTop: 24 }}>
        <TextLink onPress={handleGoBack}>
          <Text>Back</Text>
        </TextLink>
      </View>
    </View>
  );
};

export default TextBoxWithButton;
