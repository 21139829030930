import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    marginTop: 10,
    flex: 1,
  },
  title: {
    color: '#8C8C8C',
    fontSize: 12,
    fontWeight: '700',
    paddingTop: 10,
    paddingBottom: 5,
  },
  itemContainer: {
    paddingTop: 10,
  },
});

export default styles;
