import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Oxygen-Regular',
};

const styles = StyleSheet.create({
  fontFamily,
  container: {
    flex: 1,
    flexDirection: 'row',
    padding: 5,
    marginTop: 10,
    marginBottom: 10,
  },
  userNamesContainer: {
    width: '80%',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingHorizontal: 10,
  },
  iconContainer: {
    width: '10%',
  },
  text1: {
    color: '#FFFFFF',
    fontSize: 16,
  },
  text2: {
    color: '#AEAEAE',
    fontSize: 14,
  },
});

export default styles;
