import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto-Mono',
};

const styles = StyleSheet.create({
  fontFamily,
  mainContainer: {
    width: '100%',
    height: 200,
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    overflow: 'hidden',
  },
  subContainer: {
    position: 'absolute',
    width: '100%',
    height: 200,
    padding: 30,
    paddingRight: 100,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  wormholeContainer: {
    width: '100%',
    height: 200,
    position: 'absolute',
  },
  linearGradient: {
    width: '100%',
    height: 200,
    overflow: 'hidden',
    position: 'absolute',
  },
  text: {
    color: '#ffffff',
    fontWeight: '500',
    fontSize: 28,
  },
  projectsContainer: {
    flexDirection: 'row',
  },
});

export default styles;
