import Home from '../../../assets/svg/home.svg';
import GitHub from '../../../assets/svg/gitHub.svg';
import Google from '../../../assets/svg/google.svg';
import Bitmap from '../../../assets/svg/Bitmap.svg';
import BSCircle from '../../../assets/svg/BSCircleIcon.svg';
import Project from '../../../assets/svg/Project.svg';
import Bag from '../../../assets/svg/Bag.svg';
import Dashboard from '../../../assets/svg/Dashboard.svg';

const items = [
  {
    icon: Home,
    sectionTitle: 'Home',
    link: '/{{ROUTE_PATH}}',
  },
  {
    icon: GitHub,
    sectionTitle: 'Menu 1',
    link: '/{{ROUTE_PATH}}',
    items: [
      {
        title: 'SubMenu 1-1',
        link: '/{{ROUTE_PATH}}',
        icon: Bitmap,
      },
      {
        title: 'SubMenu 1-2',
        link: '/{{ROUTE_PATH}}',
        icon: BSCircle,
      },
    ],
  },
  {
    icon: BSCircle,
    sectionTitle: 'Menu 2',
    link: '/{{ROUTE_PATH}}',
    items: [
      {
        title: 'SubMenu 3-1',
        link: '/{{ROUTE_PATH}}',
        icon: Google,
      },
      {
        title: 'SubMenu 3-2',
        link: '/{{ROUTE_PATH}}',
        icon: Home,
      },
    ],
  },
];

export const itemsClient = [
  {
    icon: Project,
    sectionTitle: 'Projects',
    link: '/{{ROUTE_PATH}}',
    items: [
      {
        title: 'Invasion',
        link: '/{{ROUTE_PATH}}',
      },
      {
        title: 'Extractable',
        link: '/{{ROUTE_PATH}}',
      },
    ],
  },
  {
    icon: Bag,
    sectionTitle: 'Billing',
    link: '/{{ROUTE_PATH}}',
  },
];

export const itemsTeamLead = [
  {
    icon: Dashboard,
    sectionTitle: 'Dashboard',
    link: '/dashboard',
  },
];

export const itemsProjectManager = [
  {
    icon: Dashboard,
    sectionTitle: 'Dashboard',
    link: '/dashboard',
  },
];

export default items;
