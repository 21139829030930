import React from 'react';
import { View, Text, useWindowDimensions } from 'react-native';
import { FormikErrors, FormikTouched } from 'formik';
import Spinner from '../../../assets/svg/spinner.svg';
// import googleSignIn from '../../../assets/images/btn_google_signin_light_normal_web.png';
import OnlyTextInput from '../../atoms/OnlyTextInput';
import PasswordInput from '../../atoms/PasswordInput';
import Button from '../../atoms/Button';
import TextLink from '../../atoms/TextLink';
import DismissKeyboard from '../../atoms/DismissKeyboard';
import { Base } from '../../../types/base';
import { webStyles, appStyles } from './login-form.style';
import GoogleLoginButton from '../../atoms/GoogleLoginButton';

interface LoginFormProps extends Base {
  signIn: () => void;
  redirect: () => void;
  values: {
    username: string;
    password: string;
  };
  handleChangeEmail: (val: string) => void;
  handleChangePassword: (val: string) => void;
  handleSubmit: () => void;
  formikErrors: FormikErrors<{ username: string; password: string }>;
  formikTouched: FormikTouched<{ username: string; password: string }>;
  isLoading: boolean;
}

const LoginForm: React.FC<LoginFormProps> = ({
  web,
  signIn,
  redirect,
  values,
  handleChangeEmail,
  handleChangePassword,
  handleSubmit,
  formikErrors,
  formikTouched,
  isLoading,
}) => {
  const { width } = useWindowDimensions();
  const isXs = width <= 800 && web;
  const styles = web ? webStyles : appStyles;

  return (
    <View style={isXs ? webStyles.formContainerXs : styles.formContainer}>
      <View style={isXs ? webStyles.column1Xs : styles.column1}>
        <DismissKeyboard web={web}>
          <View style={styles.inputContainer}>
            <OnlyTextInput
              placeHolder="Email"
              label="Email"
              value={values.username}
              onChange={handleChangeEmail}
            />
            {formikTouched.username && formikErrors.username && (
              <Text style={styles.textError}>{formikErrors.username}</Text>
            )}
          </View>
        </DismissKeyboard>
        <DismissKeyboard web={web}>
          <View style={styles.inputContainer}>
            <PasswordInput
              placeHolder="Password"
              label="Password"
              value={values.password}
              onChange={handleChangePassword}
              web={web}
            />
            {formikTouched.password && formikErrors.password && (
              <Text style={styles.textError}>{formikErrors.password}</Text>
            )}
          </View>
        </DismissKeyboard>
        <View style={styles.linkContainer}>
          <TextLink onPress={redirect}>
            <Text>Forgot password?</Text>
          </TextLink>
        </View>
        {isLoading ? (
          <View style={styles.spinnerContainer}>
            {web ? (
              <img src={Spinner} width={30} height={30} alt="spinner" />
            ) : (
              <Spinner width={30} height={30} />
            )}
          </View>
        ) : (
          <Button onPress={handleSubmit} disabled={false}>
            Login
          </Button>
        )}
      </View>
      <Text style={isXs ? webStyles.dividerTextXs : styles.dividerText}>Or</Text>
      <View style={isXs ? webStyles.column2Xs : styles.column2}>
        {/* <View>
          {web && (
            <View style={webStyles.buttonContainer}>
              <Button type="light" uppercase={false} disabled={false}>
                log in with apple
              </Button>
            </View>
          )}
        </View> */}
        <View style={webStyles.buttonContainer}>
          <GoogleLoginButton web={web} onPress={signIn} />
        </View>
      </View>
    </View>
  );
};

export default LoginForm;
