import React, { FC } from 'react';
import { View, Text } from 'react-native';
import styles from './client-project-card.style';
import { Base } from '../../../types/base';
import IssuePriority from '../../atoms/IssuePriority';

export type PriorityLabels = 'Highest' | 'High' | 'Medium' | 'Low' | 'Lowest';
export interface ClientProjectCardProps extends Base {
  issueName: string;
  hours: number;
  description: string;
  priority: PriorityLabels;
}

const ClientProjectCard: FC<ClientProjectCardProps> = ({
  issueName,
  hours,
  description,
  priority,
  web,
}) => (
  <View style={styles.container}>
    <Text style={styles.title}>{issueName}</Text>
    <Text style={styles.description}>{description}</Text>
    <View style={styles.priorityDiv}>
      <Text style={styles.hours}>{`${hours} hours`}</Text>
      <IssuePriority web={web} priority={priority} />
    </View>
  </View>
);
export default ClientProjectCard;
