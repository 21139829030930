import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  headerContainer: {
    width: '100%',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subContainer1: {
    width: '60%',
  },
  subContainer2: {
    alignItems: 'flex-end',
    marginLeft: 5,
  },
  progressBarContainer: {
    width: '100%',
    marginVertical: 20,
  },
  labelsContainer: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingVertical: 10,
  },
  labelsContainerApp: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default styles;
