import React from 'react';
import { View, Text } from 'react-native';
import Header from '../../molecules/Header';
import TextLink from '../../atoms/TextLink';
import { Base } from '../../../types/base';
import webStyles from './email-confirmation.style';

interface EmailConfirmationProps extends Base {
  redirect: () => void;
}

const EmailConfirmationTemplate = ({ redirect }: EmailConfirmationProps) => (
  <View style={webStyles.container}>
    <Header title="Forgot Password" />
    <View style={webStyles.textContainer}>
      <Text style={webStyles.subTitle}>
        An email has been sent to your inbox.
      </Text>
      <Text style={webStyles.text}>
        If you haven&apos;t received the confirmation email, be sure to check
        your spam and junk email folders first, in case the message is there, if
        it&apos;s not
        {' '}
        <TextLink onPress={redirect}>
          <Text>click here</Text>
        </TextLink>
        {' '}
        to resend.
      </Text>
    </View>
  </View>
);

export default EmailConfirmationTemplate;
