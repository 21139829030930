import React, { FC } from 'react';
import { Text, TextInput, View } from 'react-native';
import { formatValue } from '../../../utils/format';
import { InputBase } from '../../../types/inputs';
import styles from '../../../styles/inputs.style';

interface PhoneInputProps extends InputBase {
  placeHolder?: string;

  value?: string;

  label?: string;

  onChange?: (val: string) => void;

  require?: boolean;
}

const defaultProps = {
  web: false,
  placeHolder: '',
  value: '',
  label: '',
  onChange: undefined,
  require: false,
};

const PhoneInput: FC<PhoneInputProps> = ({
  placeHolder,
  value,
  label,
  onChange,
  require,
}) => {
  const [val, setVal] = React.useState(value ? formatValue(value) : '');
  const [inputStyle, setInputStyle] = React.useState(false);

  const onChangeLocal = (localValue: string) => {
    if (!Number.isNaN(localValue)) {
      if (onChange) {
        onChange(localValue);
      }
      setVal(localValue);
    }
  };

  const onBlurLocal = () => {
    if (val) {
      const formattedValue = formatValue(val);
      if (onChange) {
        onChange(formattedValue);
      }
      setVal(formattedValue);
    }
    setInputStyle(false);
  };

  const inputContainerStyle = () => (val !== '' ? styles.inputContainer : styles.inputContainerFilled);

  return (
    <View
      style={inputStyle ? styles.inputContainerFocus : inputContainerStyle()}
    >
      {label && val !== '' && (
        <Text style={inputStyle ? styles.labelFocus : styles.label}>
          {label}
          {require}
        </Text>
      )}
      <View style={styles.iconContainer}>
        <TextInput
          style={styles.input}
          value={val}
          onChangeText={onChangeLocal}
          onBlur={onBlurLocal}
          onFocus={() => {
            setInputStyle(true);
          }}
          placeholderTextColor="#ffffffa6"
          placeholder={require ? `${placeHolder} *` : placeHolder}
          keyboardType="numeric"
        />
      </View>
    </View>
  );
};

PhoneInput.defaultProps = defaultProps;

export default PhoneInput;
