import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: {
    marginTop: 20,
    marginBottom: 30,
  },
  fullWidth: {
    width: '100%',
  },
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  flex1: {
    flex: 1,
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  text: {
    fontFamily: 'Roboto',
    fontWeight: '700',
    fontSize: 16,
    color: '#AAAAAA',
  },
  text2: {
    fontFamily: 'Roboto Mono',
    fontWeight: '700',
    fontSize: 18,
    color: '#AAAAAA',
  },
  text3: {
    fontSize: 14,
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: '#D9D9D9',
  },
  backText: {
    color: '#959595',
    marginLeft: 5,
    fontSize: 14,
    fontWeight: '400',
  },
  marginTop40: {
    marginTop: 40,
  },
  marginTop30: {
    marginTop: 30,
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop10: {
    marginTop: 10,
  },
  downloadButton: {
    paddingVertical: 5,
    paddingHorizontal: 15,
    backgroundColor: '#262626',
    borderRadius: 4,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default styles;
