import React from 'react';
import { View } from 'react-native';
import LinearGradient from 'react-native-web-linear-gradient';
import Wormhole from '../../../assets/svg/wormhole.svg';
import Title from '../../../assets/svg/headerTitle.svg';
import HeaderProjectsAmount from '../../atoms/HeaderProjectsAmount';
import styles from './dashboard-header.style';

interface DashboardHeaderProps {
  projects: number | string;
  upcomingProjects: number | string;
}

const DashboardHeader = ({
  projects,
  upcomingProjects,
}: DashboardHeaderProps) => (
  <View style={styles.mainContainer}>
    <LinearGradient
      colors={['#0ABAB5', '#000000']}
      start={{ x: 0, y: 1 }}
      end={{ x: 1, y: 1 }}
      style={styles.linearGradient}
    />
    <View style={styles.wormholeContainer}>
      <img src={Wormhole} alt="wormhole" />
    </View>
    <View style={styles.subContainer}>
      <img src={Title} alt="title" />
      <View style={styles.projectsContainer}>
        <HeaderProjectsAmount projectsAmount={projects || 0} />
        <HeaderProjectsAmount
          projectsAmount={upcomingProjects || 0}
          isForUpcoming
        />
      </View>
    </View>
  </View>
);

export default DashboardHeader;
