import React from 'react';
import { View, Text } from 'react-native';
import styles from './circular-progress.style';

interface CircularProgressProps {
  totalHours: number | string;
  completedHours: number | string;
}

const handlePercent = (completedHours: string | number, totalHours: string | number) => {
  const percentage = (+completedHours / +totalHours) * 100;

  if (percentage > 100) { return 100; }

  return Math.round(percentage);
};

const CircularProgress = ({ totalHours, completedHours }: CircularProgressProps) => {
  const percent = handlePercent(completedHours, totalHours);

  const handleConvertPercentToDegrees = (per: number, baseDegrees: number) => {
    const rotateBy = baseDegrees + (per * 3.6);
    return {
      transform: [{ rotateZ: `${rotateBy}deg` }],
    };
  };

  const handleRenderThirdLayer = (per: number) => {
    if (per > 50) {
      return (
        <View
          style={[
            styles.secondProgress,
            handleConvertPercentToDegrees(per - 50, 45),
          ]}
        />
      );
    }

    return <View style={styles.offsetLayer} />;
  };

  const handleSetFirstProgressLayerStyle = (per: number) => {
    let firstProgressLayerStyle;

    if (per > 50) {
      firstProgressLayerStyle = handleConvertPercentToDegrees(50, -135);
    } else {
      firstProgressLayerStyle = handleConvertPercentToDegrees(per, -135);
    }

    return firstProgressLayerStyle;
  };

  return (
    <View style={styles.circleWrap}>
      <View style={[styles.progress, handleSetFirstProgressLayerStyle(percent)]} />
      {handleRenderThirdLayer(percent)}
      <View style={styles.row}>
        <Text style={styles.text}>{completedHours}</Text>
        <Text style={styles.text2}>h</Text>
      </View>
    </View>
  );
};

export default CircularProgress;
