import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '100%',
    padding: 10,
    marginTop: 10,
  },
  itemContainer: {
    paddingVertical: 5,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  item: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginLeft: '20%',
  },
  title: {
    color: '#1890ff',
    fontSize: 12,
    marginLeft: 10,
  },
});

export default styles;
