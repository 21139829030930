/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useState, useEffect, FC } from 'react';
import {
  View, Text, TouchableOpacity, TextStyle,
} from 'react-native';
import { SvgProps } from 'react-native-svg';
import Profile from '../../../assets/svg/profile.svg';
import { Base } from '../../../types/base';
import IssuesFilter from '../IssuesFilter';
import IssuesFilterApp from '../IssuesFilterApp';
import Arrows from '../../../assets/svg/pagination-item-ellipsis.svg';
import Day from '../../../assets/svg/Day.svg';
import ThreeDotsMenu from '../../../assets/svg/threeDotsMenu.svg';
import MeasuredWeek from '../../../assets/svg/measuredWeek.svg';
import DashboardCard from '../../atoms/DashboardCard';
import Issue from '../../molecules/Issue';
import { IssueProps } from '../../molecules/Issue/Issue';
import { FilterCategory } from '../IssuesFilter/IssuesFilter';
import Tooltip from '../../atoms/Tooltip';
import ModalApp from '../../atoms/ModalApp';
import styles from './issues.style';
import { filterOptions } from './filterOptions';

interface IconComponentProps extends Base {
  Icon: string | FC<SvgProps>;
  size: number;
  fill?: string;
}
interface IssuesProps extends Base {
  items: Array<IssueProps>;
  title: string;
  isForBlockedIssues?: boolean;
  isForTeamLeadDashboard?: boolean;
  // isForDueToday?: boolean;
  statusFilter?: boolean;
  isForOverdue?: boolean;
}

const Label = (label: string | number, web?: boolean, style?: TextStyle) => (
  <Text
    style={[
      {
        fontFamily: web ? 'Roboto' : 'RobotoMono-Medium',
      },
      style || styles.label,
    ]}
  >
    {label}
  </Text>
);

const IconComponent: React.FC<IconComponentProps> = ({
  web, Icon, size, fill,
}) => (
  <View>
    {web ? (
      <img src={Icon as string} alt="icon" width={size} height={size} />
    ) : (
      <Icon width={size} height={size} fill={fill} />
    )}
  </View>
);

const Issues = ({
  items = [],
  title,
  isForBlockedIssues,
  isForTeamLeadDashboard,
  isForOverdue,
  web,
  statusFilter,
}: IssuesProps) => {
  // const [areCategoriesSet, setAreCategoriesSet] = useState(false);
  const [localIssues, setLocalIssues] = useState<IssueProps[]>(items);
  const [areAllItemsDisplayed, setAreAllItemsDisplayed] = useState(false);
  const [sliceParam2, setSliceParam2] = useState(3);
  const [issuesFilterOptions, setIssuesFilterOptions] = useState<FilterCategory[]>(
    filterOptions(!!isForBlockedIssues, statusFilter!, items),
  );
  const [isIssuesFilterOpen, setIsIssuesFilterOpen] = useState(false);

  const setLabelWidth = (width: string) => {
    if (web) {
      if (isForOverdue) {
        return '16%';
      }
      return width;
    }
    return '50%';
  };

  // OPEN/CLOSE FILTER BAR...
  const handleShowIssuesFilter = () => setIsIssuesFilterOpen(!isIssuesFilterOpen);

  // HANDLE IF DISPLAY ALL ITEMS OR ONLY 3...
  const handleDisplayMoreItems = () => {
    setSliceParam2(localIssues?.length);
    setAreAllItemsDisplayed(true);
  };
  const handleHideItems = () => {
    setSliceParam2(3);
    setAreAllItemsDisplayed(false);
  };

  useEffect(() => {
    setLocalIssues([...items]);
  }, [items]);

  return (
    <DashboardCard
      backgroundColor
      isDanger={isForBlockedIssues && localIssues?.length > 5}
      web={web}
    >
      {!web ? (
        <ModalApp
          showCloseIcon
          isOpen={isIssuesFilterOpen}
          closeModal={() => setIsIssuesFilterOpen(false)}
          header={`${title}   ${localIssues?.length}`}
          body={(
            <IssuesFilterApp
              isOpen={isIssuesFilterOpen}
              filterOptions={issuesFilterOptions}
              setFilterOptions={setIssuesFilterOptions}
              setFilteredItems={setLocalIssues}
              items={items}
              isForBlockedIssues={isForBlockedIssues}
              web={web}
              isForTeamLeadDashboard={isForTeamLeadDashboard}
            />
          )}
        />
      ) : null}
      <View
        style={[
          styles.titleContainer,
          {
            paddingBottom: web ? 20 : 10,
            borderBottomColor:
              isForBlockedIssues && localIssues?.length > 5 ? '#F5F5F5' : '#8C8C8C',
            zIndex: 200,
          },
        ]}
      >
        <View
          style={{
            flexDirection: web ? 'row' : 'column',
            alignItems: web ? 'center' : 'flex-start',
            flex: 3 / 4,
          }}
        >
          {Label(title, web, web ? styles.title : styles.titleApp)}
          {Label(localIssues?.length, web, styles.issuesAmount)}
        </View>
        <View
          style={{
            flex: 1 / 4,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <Tooltip
            tooltipContent={isForTeamLeadDashboard ? 'Measured by Week' : 'Measured by Day'}
            web={web}
            isLeftPosition={!web}
            isModal={!web}
            displayChildrenOnModal={!web}
          >
            <IconComponent
              web={web}
              Icon={isForTeamLeadDashboard ? MeasuredWeek : Day}
              size={isForTeamLeadDashboard ? 20 : 27}
            />
          </Tooltip>
          <View style={styles.marginLeft10}>
            <TouchableOpacity onPress={handleShowIssuesFilter}>
              <IconComponent web={web} Icon={ThreeDotsMenu} size={17} />
            </TouchableOpacity>
            {web ? (
              <IssuesFilter
                isOpen={isIssuesFilterOpen}
                onClickOutside={setIsIssuesFilterOpen}
                filterOptions={issuesFilterOptions}
                setFilterOptions={setIssuesFilterOptions}
                setFilteredItems={setLocalIssues}
                items={items}
                isForBlockedIssues={isForBlockedIssues}
                web={web}
                isForTeamLeadDashboard={isForTeamLeadDashboard}
                statusFilter={statusFilter}
              />
            ) : null}
          </View>
        </View>
      </View>

      <View
        style={[
          styles.labelsContainer,
          isForTeamLeadDashboard && { justifyContent: 'flex-start' },
          { zIndex: 100, marginVertical: web ? 20 : 10 },
        ]}
      >
        {isForBlockedIssues && (
          <>
            <Text
              style={[
                styles.blockedIssuesLabel,
                {
                  fontFamily: web ? 'Roboto' : 'RobotoMono-Medium',
                },
              ]}
            >
              PROJECT
            </Text>
            <Text
              style={[
                styles.blockedIssuesLabel,
                {
                  fontFamily: web ? 'Roboto' : 'RobotoMono-Medium',
                },
              ]}
            >
              ISSUE
            </Text>
            <Text
              style={[
                styles.blockedIssuesLabel,
                {
                  fontFamily: web ? 'Roboto' : 'RobotoMono-Medium',
                },
              ]}
            >
              ASSIGNED
            </Text>
          </>
        )}
        {!isForBlockedIssues && isForTeamLeadDashboard && (
          <>
            <View style={{ width: setLabelWidth('25%') }}>{Label('project', web)}</View>
            <View style={{ width: setLabelWidth('25%') }}>{Label('assigned', web)}</View>
          </>
        )}
        {!isForBlockedIssues && isForTeamLeadDashboard && web && (
          <>
            <View style={{ width: isForOverdue ? '16%' : '13%' }}>{Label('issue', web)}</View>
            <View style={{ width: isForOverdue ? '16%' : '15%' }}>{Label('status', web)}</View>
            <View style={{ width: isForOverdue ? '16%' : 'auto' }}>{Label('name', web)}</View>
          </>
        )}
        {!isForBlockedIssues && isForTeamLeadDashboard && web && isForOverdue && (
          <View style={{ width: isForOverdue ? '16%' : 'auto' }}>{Label('overbudget', web)}</View>
        )}

        {!isForBlockedIssues && !isForTeamLeadDashboard && web && (
          <>
            <View
              style={{
                paddingLeft: web ? 35 : 0,
                width: web ? '30%' : '33.333%',
              }}
            >
              {Label('project', web)}
            </View>
            <View style={[styles.codeContainer, { width: web ? '20%' : '33.333%' }]}>
              {Label('issue', web)}
            </View>
            {web && (
              <>
                <View style={styles.iconContainer}>{Label('github', web)}</View>
                <View style={styles.priorityContainer}>{Label('priority', web)}</View>
              </>
            )}
            <View
              style={{
                width: web ? '15%' : '33.333%',
                alignItems: web ? 'center' : 'flex-end',
              }}
            >
              {Label('assigned', web)}
            </View>
          </>
        )}
      </View>

      <View style={[styles.itemsContainer, { zIndex: 100 }]}>
        {localIssues?.length > 0 ? (
          localIssues
            .slice(0, sliceParam2)
            .map((item, index) => (
              <Issue
                key={index}
                isForOverdue={isForOverdue}
                estimatedHours={item.estimatedHours}
                workedHours={item.workedHours}
                projectName={item.projectName}
                projectIcon={item.projectIcon}
                projectCode={item.projectCode}
                githubUrl={item.githubUrl}
                priority={item.priority}
                devName={item.devName || 'Unassigned'}
                devAvatarUrl={item.devAvatarUrl || Profile}
                issueName={item.issueName}
                status={item.status}
                isForBlockedIssues={isForBlockedIssues}
                isForTeamLeadDashboard={isForTeamLeadDashboard}
                backgroundColor={
                  isForBlockedIssues && localIssues?.length > 5 ? '#5E091D' : '#141414'
                }
                web={web}
              />
            ))
        ) : (
          <Text style={styles.soEmptyMessage}>
            {items?.length === 0 ? 'No items available' : 'So empty, maybe changing filters?'}
          </Text>
        )}
      </View>

      {localIssues?.length > 3 && (
        <View
          style={[
            styles.arrowsContainer,
            {
              transform: [{ rotate: areAllItemsDisplayed ? '180deg' : '0deg' }],
            },
          ]}
        >
          <TouchableOpacity
            onPress={areAllItemsDisplayed ? handleHideItems : handleDisplayMoreItems}
          >
            <IconComponent
              web={web}
              Icon={Arrows}
              size={35}
              fill={areAllItemsDisplayed ? '#ffffff' : 'none'}
            />
          </TouchableOpacity>
        </View>
      )}
    </DashboardCard>
  );
};

Issues.defaultProps = {
  isForBlockedIssues: false,
  isForTeamLeadDashboard: false,
  isForOverdue: false,
  statusFilter: false,
  isForDueToday: false,
};

IconComponent.defaultProps = {
  fill: undefined,
};

export default Issues;
