import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Oxygen-Regular',
  fontSize: 18,
  lineHeight: 18,
};

const styles = StyleSheet.create({
  fontFamily,
  container: {
    height: 'auto',
    padding: 10,
    position: 'relative',
    borderBottomWidth: 2,
    paddingHorizontal: 0,
    paddingTop: 7,
    paddingBottom: 5,
    borderBottomColor: 'rgba(255, 255, 255, 0.65)',
  },
  containerVal: {
    height: 'auto',
    padding: 10,
    position: 'relative',
    borderBottomWidth: 2,
    paddingHorizontal: 0,
    paddingTop: 7,
    paddingBottom: 5,
    borderBottomColor: 'white',
  },
  container2: {
    height: 'auto',
    paddingTop: 7,
    paddingBottom: 5,
    position: 'relative',
    paddingHorizontal: 0,
  },
  dropdownText2: {
    fontFamily: 'Roboto',
    fontSize: 14,
    lineHeight: 22,
    fontWeight: '400',
    color: 'rgba(255, 255, 255, 0.65)',
    marginRight: 5,
    alignSelf: 'flex-start',
  },
  dropdownContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  itemContainer: {
    backgroundColor: '#000000',
    borderWidth: 1,
    borderColor: '#ffffffa6',
    shadowColor: '#000000',
    shadowOffset: { width: -4, height: 8 },
    shadowOpacity: 0.4,
    shadowRadius: 10,
    height: 150,
    borderRadius: 5,
    left: 0,
    right: 0,
    top: 33,
    position: 'absolute',
    zIndex: 99999,
  },
  itemContainer2: {
    width: '100%',
    maxHeight: 150,
    borderRadius: 4,
    backgroundColor: '#b5b5b5',
    shadowColor: '#000000',
    shadowOffset: { width: -4, height: 8 },
    shadowOpacity: 0.4,
    shadowRadius: 10,
    position: 'absolute',
    left: 0,
    right: 0,
    top: 40,
    zIndex: 99999,
  },
  label: {
    textAlign: 'left',
    fontWeight: 'normal',
    fontSize: 12,
    lineHeight: 15,
    color: '#ffffff',
    position: 'absolute',
    top: -10,
  },
  hide: {
    display: 'none',
  },
  show: {
    display: 'flex',
  },
  item: {
    padding: 10,
    paddingHorizontal: 20,
  },
  item2: {
    width: '100%',
    paddingVertical: 2,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  item2Left: {
    flexShrink: 1,
    alignItems: 'center',
    flex: 2,
  },
  textLeft: {
    color: 'rgba(0, 0, 0, 0.65)',
    textAlign: 'center',
  },
  item2Right: {
    flexShrink: 1,
    flex: 1,
  },
  textRight: {
    marginVertical: 5,
    color: 'rgba(0, 0, 0, 0.65)',
  },
  timerIcon: {
    marginRight: 10,
    marginLeft: 20,
  },
  flatList: {
    paddingTop: 10,
  },
  icon: {
    marginRight: 5,
  },
  textBasic: {
    fontFamily: 'Roboto',
    fontSize: 16,
    flex: 1,
    color: 'rgba(255, 255, 255, 0.65)',
  },
  textBasicWhite: {
    fontSize: 16,
    flex: 1,
    color: 'white',
  },
});

export default styles;
