import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: {
    width: '100%',
    maxWidth: '100%',
    paddingBottom: 30,
  },
  content: {
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    color: 'white',
  },
  labelsContainerIssue: {
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  epicsLabel: {
    color: '#F5F5F5',
    fontSize: 12,
    fontWeight: '700',
    marginBottom: 5,
  },
  labelsSubContainer: {
    width: '60%',
  },
  row: {
    flexDirection: 'row',
    width: '40%',
  },
  label: {
    color: '#F5F5F5',
    fontSize: 12,
    fontWeight: '700',
  },
  labelContainer: {
    alignItems: 'center',
    flex: 1,
  },
  lerop: {
    color: 'rgb(185, 180, 180)',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  appIssueContainer: {
    width: '100%',
    zIndex: 50,
    marginBottom: 30,
  },
  childrenContainer: {
    width: '100%',
  },
});

export { styles };
