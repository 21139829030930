import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const styles = StyleSheet.create({
  fontFamily,
  headerText: {
    color: 'white',
    marginBottom: 0,
    fontSize: 26,
    fontFamily: 'RobotoMono-Regular',
    fontStyle: 'normal',
    fontWeight: '700',
  },
  mainContainer: {
    flex: 1,
    paddingBottom: 10,
  },
  mainContainer2: {
    display: 'flex',
    flexDirection: 'row',
  },
  title: {
    color: '#ffffff',
    fontSize: 24,
    marginBottom: 30,
  },
  subContainer1: {
    paddingLeft: 30,
    paddingRight: 30,
    width: 'calc(100% - 310px)',
  },
  subContainer1App: {
    flex: 1,
    paddingVertical: 10,
  },
  subContainer2: {
    width: 310,
    minHeight: '100vh',
  },
  subContainer2App: {
    minHeight: '100%',
  },
  issuesContainer: {
    width: '100%',
    display: 'flex',
    paddingTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  issuesSubContainer1: {
    width: '50%',
    paddingRight: 15,
  },
  issuesSubContainer2: {
    width: '50%',
    paddingLeft: 15,
  },
  bottomAppContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 20,
  },
  cardContainer: {
    width: '100%',
    height: 'auto',
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 30,
  },
  unassignedContainer: {
    marginTop: 64,
  },
});

export default styles;
