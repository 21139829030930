/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, {
  Dispatch, SetStateAction, useEffect, useState,
} from 'react';
import { Linking, View, Text } from 'react-native';
import { useSelector } from 'react-redux';
import ClientBacklog from '../ClientBacklog';
import SoWS from '../SoWS';
import { SowProps } from '../SoWS/SoWS';
import DashboardCard from '../../atoms/DashboardCard';
import ScrollComponent from '../../atoms/ScrollComponent';
import StagingCard from '../../molecules/StagingCard';
import WeeklyStatusReport from '../../organisms/WeeklyStatusReport';
import ClientDashboardHeader from '../../organisms/ClientDashboardHeader';
import { Base } from '../../../types/base';
import ProgressCard from '../../molecules/ProgressCard';
import ProjectPerformanceCards from '../../molecules/ProjectPerformanceCards';
import ClientProjectContainer from '../../organisms/ClientProjectContainer';
import { RootState } from '../../../../src/redux/store';
import { ClientsDashboardDto, ProjectEnvironment } from '../../../types/ClientDashboardDto';
import Spinner from '../../../assets/svg/spinner.svg';
import ProjectCard from '../../molecules/ProjectCard';
import DashboardLayout from '../dashboardLayout/DashboardLayout';
import StatusReport from '../StatusReport';
import styles from './client-dashboard-template.style';
import { ClientDashboardTab } from '../../organisms/ClientDashboardHeader/ClientDashboardHeader';
import Api from '../../../services/apiRequest';

interface ClientDashboardTemplateProps extends Base {
  pdfViewerComponent: React.ReactElement;
  dashboard: ClientsDashboardDto;
  setPdf: Dispatch<SetStateAction<string>>;
}

const ClientDashboardTemplate = ({
  web,
  dashboard,
  pdfViewerComponent,
  setPdf,
}: ClientDashboardTemplateProps) => {
  const [selectedTab, setSelectedTab] = useState<ClientDashboardTab | string>(
    (web && window.location.hash.replace('#', '').replace('%20', ' ')) || 'DASHBOARD',
  );
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { user } = useSelector((state: RootState) => state.session);
  const [epics, setEpics] = useState([]);
  const [issues, setIssues] = useState([]);
  const [sows, setSows] = useState<SowProps[]>([]);

  const fetchSows = async () => {
    setIsLoading(true);
    try {
      const { data } = await Api.get('/sows?full=true');
      setIsLoading(false);
      setSows(data);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    const getBacklogApi = async () => {
      const { data } = await Api.get('/dashboard/client/backlog');
      setEpics(data.epics);
      setIssues(data.issues);
    };
    getBacklogApi();
  }, []);

  useEffect(() => {
    fetchSows();
  }, []);

  useEffect(() => {
    if (web) {
      window.location.hash = selectedTab;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  if (!dashboard) {
    return (
      <View style={styles.loaderContainer}>
        <img src={Spinner} width={30} height={30} alt="spinner" />
      </View>
    );
  }

  if (!dashboard.projects?.length) {
    return (
      <View style={styles.msg}>
        <Text style={{ color: '#fff' }}>
          Your organization does not have assigned projects yet.
          {' '}
        </Text>
      </View>
    );
  }

  const projectKey = Object.keys(dashboard.weeklyStatus)[0];
  const projectCardInfo = user?.organization?.projects?.find((item) => item?.name === projectKey);
  const weeklyStatus = dashboard?.weeklyStatus[projectKey];
  const timeTraking = dashboard?.timeTraking[projectKey];
  const weeklyClientIssues = dashboard?.weeklyClientIssues[projectKey];
  const environments = dashboard?.environments;
  const {
    totalTimeEstimate, totalTimespent, totalIssues, issuesOnDone,
  } = timeTraking;
  const budgetPorcentaje = Math.round(
    ((totalTimeEstimate - totalTimespent) / totalTimeEstimate) * 100,
  );
  const scope = Math.round(((totalIssues - issuesOnDone!) / totalIssues) * 100);

  const handleFormattDate = (date?: Date) => {
    if (date) {
      const formattedDate = new Date(date).toLocaleString('en-us', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      });

      return formattedDate;
    }

    return 'NA';
  };

  const onSelectTab = (tab: ClientDashboardTab) => {
    if (web) {
      window.location.hash = tab;
    }
    setSelectedTab(tab);
  };

  return (
    <DashboardLayout web={web} eventsItems={[]}>
      <ScrollComponent web={web}>
        <View style={styles.subContainer2ComponentsContainer}>
          <ClientDashboardHeader
            web
            selectedTab={selectedTab}
            onSelectTab={onSelectTab}
            clientAvatarUrl={user?.avatar || ''}
            clientFirstName={user?.firstName || ''}
            clientLastName={user?.lastName || ''}
          />
          {selectedTab === 'STATUS REPORTS' && <StatusReport web={web} />}
          {selectedTab === 'DASHBOARD' && (
            <View style={styles.paddingTop20}>
              <View style={styles.row}>
                {/* PROJECT CARD  */}
                <View style={styles.leftRowItem}>
                  <ProjectCard
                    web={web}
                    name={projectCardInfo.name || 'NA'}
                    description={projectCardInfo.description || 'No description available.'}
                    startDate={handleFormattDate(projectCardInfo.createdAt)}
                    // TODO: The end date doesn't come in the API data, we should add it below
                    endDate={handleFormattDate()}
                  />
                </View>
                {/* WEEKLY STATUS REPORT  */}
                <View style={styles.rightRowItem}>
                  <WeeklyStatusReport weeklyStatus={weeklyStatus} />
                </View>
              </View>
              <View style={styles.row}>
                {/* PROGRESS CARD  */}
                <View style={styles.leftRowItem}>
                  <ProgressCard
                    totalHours={timeTraking.totalTimeEstimate}
                    completedHours={timeTraking.totalTimespent}
                    web={web}
                    isForClientDashboardCard
                  />
                </View>
                {/* PORJECT PERFORMANCE CARDS  */}
                <View style={styles.rightRowItem}>
                  <ProjectPerformanceCards
                    schedulePercentage={timeTraking.totalIssues}
                    budgetPercentage={budgetPorcentaje}
                    scope={scope}
                    web={web}
                  />
                </View>
              </View>
              {/* CURRENT SPRINT  */}
              <DashboardCard
                web={web}
                title="CURRENT SPRINT"
                iconType="Timer"
                tooltipContent="Measured by Sprint"
              >
                <View style={styles.envContainer}>
                  <View style={{ width: web ? '30%' : '100%' }}>
                    <ClientProjectContainer
                      title="TO DO"
                      items={weeklyClientIssues.ToDo}
                      web={web}
                    />
                  </View>
                  <View style={{ width: web ? '33%' : '100%' }}>
                    <ClientProjectContainer
                      title="IN PROGRESS"
                      items={weeklyClientIssues['In progress']}
                      web={web}
                    />
                  </View>
                  <View style={{ width: web ? '33%' : '100%' }}>
                    <ClientProjectContainer
                      title="DONE"
                      items={weeklyClientIssues.Done}
                      web={web}
                    />
                  </View>
                </View>
              </DashboardCard>
              {/* ENVIRONMENTS  */}
              <DashboardCard
                web={web}
                title="ENVIRONMENTS"
                iconType="Live"
                tooltipContent="Measured Live from CircleCI"
              >
                <View style={styles.envContainer}>
                  {environments?.map((environment: ProjectEnvironment) => (
                    <View key={environment.id} style={{ width: web ? '49%' : '100%' }}>
                      <StagingCard
                        web={web}
                        title={environment.name}
                        statusLabel={environment.status || 'NA'}
                        lastUpdate={environment.lastUpdate}
                        releaseNotes={environment.notes?.split('\n')}
                        onOpenEnvironment={() => Linking.openURL(environment.url)}
                        updatedAt={environment.updatedAt}
                      />
                    </View>
                  ))}
                </View>
              </DashboardCard>
            </View>
          )}
          {selectedTab === 'BACKLOG' && <ClientBacklog web={web} issues={issues} epics={epics} />}
          {/* TODO: Replace the sections below with its corresponding components */}
          {selectedTab === 'SoWS' && (
            <SoWS
              web={web}
              isLoading={isLoading}
              sows={sows}
              pdfViewerComponent={pdfViewerComponent}
              setPdf={setPdf}
              navigateStatusReportsFunc={() => setSelectedTab('STATUS REPORTS')}
            />
          )}
          {selectedTab === 'DOCUMENTS' && (
            <View
              style={{
                width: '100%',
                maxWidth: '100%',
                height: '100%',
                maxHeight: '100%',
                alignItems: 'center',
              }}
            >
              <Text style={{ color: '#fff' }}>DOCUMENTS</Text>
            </View>
          )}
        </View>
      </ScrollComponent>
    </DashboardLayout>
  );
};

export default ClientDashboardTemplate;
