import React from 'react';
import { View, Text } from 'react-native';
import { Base } from '../../../types/base';
import styles from './text-box.styles';

interface TextBoxProps extends Base {
  children: React.ReactNode;
}

const TextBox: React.FC<TextBoxProps> = ({ children, web }) => (
  <View style={web ? styles.textContainer : styles.textAppContainer}>
    <Text style={styles.text}>{children}</Text>
  </View>
);

export default TextBox;
