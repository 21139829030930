import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  containerCall: {
    width: '100%',
    paddingVertical: 5,
    paddingHorizontal: 10,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  left: {
    flexShrink: 1,
    alignItems: 'center',
    flex: 2,
  },
  textLeft: {
    color: 'rgba(0, 0, 0, 0.65)',
    textAlign: 'center',
    fontSize: 16,
  },
  onHoverItem: {
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  timerIcon: {
    marginRight: 10,
    marginLeft: 20,
  },
  right: {
    flexShrink: 1,
    flex: 1,
  },
  textRight: {
    marginVertical: 5,
    color: '#868686',
    fontSize: 10,
  },
});

export default styles;
