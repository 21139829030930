import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import Arrows from '../../../assets/svg/pagination-item-ellipsis.svg';
import { ProjectProps } from '../ProjectCard/ProjectCard';
import ProjectCard from '../ProjectCard';
import { Base } from '../../../types/base';
import styles from './projects.style';

interface ProjectsProps extends Base {
  items: Array<ProjectProps>;
}

const Projects = ({ web, items }: ProjectsProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [sliceProp2, setSliceProp2] = useState<number>(3);

  const handleShowMoreProjects = () => {
    if (!isOpen) {
      setSliceProp2(items.length);
      setIsOpen(true);
    } else {
      setSliceProp2(3);
      setIsOpen(false);
    }
  };

  return (
    <View style={styles.mainContainer}>
      {web && (
        <View style={styles.moreProjectsButtonContainer}>
          <TouchableOpacity onPress={handleShowMoreProjects}>
            <Text style={styles.moreProjectsButton}>{isOpen ? 'Show Less' : 'More Projects'}</Text>
          </TouchableOpacity>
        </View>
      )}
      <View
        style={[
          styles.projectsContainer,
          {
            flexDirection: web ? 'row' : 'column',
            marginTop: 20,
          },
        ]}
      >
        {items.slice(0, sliceProp2).map((item, index) => (
          <View
            key={index}
            style={[
              styles.projectContainer,
              {
                width: web ? '31%' : '100%',
                marginRight: web ? '2%' : 0,
              },
            ]}
          >
            <ProjectCard
              projectName={item.projectName}
              projectIcon={item.projectIcon}
              projectLabel={item.projectLabel}
              projectUrl={item.projectUrl}
              workedHours={item.workedHours}
              estimatedHours={item.estimatedHours}
              scope={item.scope || 0}
              schedulePercentage={item.schedulePercentage || 0}
              budgetPercentage={item.budgetPercentage || 0}
              warrantyTime={item.warrantyTime}
              web={web}
            />
          </View>
        ))}
      </View>
      {!web && items?.length > 3 && (
        <View
          style={[
            styles.arrowsContainer,
            {
              transform: [{ rotate: isOpen ? '180deg' : '0deg' }],
            },
          ]}
        >
          {isOpen ? (
            <TouchableOpacity onPress={handleShowMoreProjects}>
              <Arrows width={35} height={35} />
            </TouchableOpacity>
          ) : (
            <TouchableOpacity onPress={handleShowMoreProjects}>
              <Arrows width={35} height={35} />
            </TouchableOpacity>
          )}
        </View>
      )}
    </View>
  );
};

export default Projects;
