import { StyleSheet } from 'react-native';

const textFont = {
  fontFamily: 'Oxygen-Bold',
};

const styles = StyleSheet.create({
  textContainer: {
    flex: 1,
    padding: 20,
    maxHeight: '40vh',
    overflow: 'scroll',
  },
  textAppContainer: {
    flex: 1,
    padding: 20,
    overflow: 'scroll',
  },
  text: {
    textFont,
    fontWeight: '400',
    fontSize: 14,
    color: '#FFFFFF',
    lineHeight: 26,
    textAlign: 'justify',
  },
});

export default styles;
