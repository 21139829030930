/* eslint-disable no-nested-ternary */
import React from 'react';
import { View, Text } from 'react-native';
import { VictoryChart, VictoryArea, VictoryAxis } from 'victory';
import styles from './issues-graph.style';
import './issues-graph-gradient.css';
import { Base } from '../../../types/base';

interface IssuesByStatus {
  status: string;
  total: number;
  sort: number;
}

interface IssuesGraphProps extends Base {
  data: IssuesByStatus[];
}

const colors = [
  '#2E5FC3',
  '#264FCA',
  '#8A25D9',
  '#B55CA8',
  '#BF8D89',
  '#FFBB57',
  '#A94C62',
  '#A92C6C',
  '#BF94BE',
  '#FFFFFF',
];

const IssuesGraph = ({ data, web }: IssuesGraphProps) => {
  const amounts = data.map((item) => ({
    status: item.status,
    amount: item.total,
  }));
  const labelTexts: Record<string, string[]> = {
    reopened: ['Reopened'],
    blocked: ['Blocked'],
    todo: ['To Do'],
    codeReview: ['Code Review'],
    readyForQA: ['Ready For QA'],
    readyForRelease: ['Ready For Release'],
    done: ['Done'],
  };
  const lines = [...Array(10)].map((item, i) => <View key={i} style={styles.line} />);
  const maxAmount = Math.max(...data.map((item) => item.total));
  const maxDomain = maxAmount
    + (maxAmount < 100
      ? 300
      : maxAmount < 200
        ? 500
        : 800
    );

  return (
    <View style={styles.mainContainer}>
      <View style={styles.labelsContainer}>
        {amounts.map((item, index) => (
          <View style={styles.labelContainer} key={index}>
            <Text
              style={[
                styles.label,
                { fontFamily: web ? 'Roboto' : 'RobotoMono-Medium' },
              ]}
            >
              {labelTexts[item.status]}
            </Text>
            <Text
              style={[
                styles.amount,
                { fontFamily: web ? 'Roboto' : 'RobotoMono-Medium' },
              ]}
            >
              {item.amount}
            </Text>
          </View>
        ))}
      </View>
      <View style={styles.graphSectionsContainer}>
        <View style={styles.linesContainer}>{lines}</View>
        {amounts.map((dataItem, index) => (
          <View style={styles.graphSectionContainer} key={index}>
            <View style={styles.gradientsContainer}>
              <div className="gradient" />
              <div className="gradient" />
            </View>
            <VictoryChart
              width={500}
              height={500}
              domain={{
                x: [0, 4],
                y: [0 - maxDomain, maxDomain],
              }}
              padding={{
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
              }}
            >
              <VictoryAxis
                style={{
                  grid: { stroke: 'transparent' },
                  axis: { stroke: '#595959' },
                  ticks: { stroke: 'transparent' },
                  tickLabels: { fill: 'transparent' },
                }}
              />
              {colors.map((color, i) => (
                <VictoryArea
                  key={i}
                  data={[0, 0, dataItem.amount * (colors.length / 2 - i), 0, 0]}
                  style={{
                    data: {
                      fill: 'none',
                      stroke: color,
                      strokeWidth: 5,
                    },
                  }}
                  interpolation="basis"
                  animate={{
                    duration: 1000,
                    onLoad: { duration: 1000 },
                  }}
                />
              ))}
            </VictoryChart>
          </View>
        ))}
      </View>
    </View>
  );
};

export default IssuesGraph;
