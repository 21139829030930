import React, { SVGProps } from 'react';

const Subtask: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.21739 0C0.545045 0 0 0.545046 0 1.21739V16.7826C0 17.455 0.545046 18 1.21739 18H16.7826C17.455 18 18 17.455 18 16.7826V1.21739C18 0.545045 17.455 0 16.7826 0H1.21739ZM7.42259 4.31989H4.81737C4.14502 4.31989 3.59998 4.86494 3.59998 5.53729V8.1425C3.59998 8.81485 4.14502 9.35989 4.81737 9.35989H8.63998V13.1825C8.63998 13.8548 9.18502 14.3999 9.85737 14.3999H12.8226C13.4949 14.3999 14.04 13.8548 14.04 13.1825V10.5773C14.04 9.90494 13.4949 9.35989 12.8226 9.35989H8.63998V5.53728C8.63998 4.86494 8.09493 4.31989 7.42259 4.31989Z"
      fill="#2684FF"
    />
  </svg>
);

export default Subtask;
