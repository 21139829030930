import React, { FC } from 'react';
import { Image } from 'react-native';

type SizeDetail = {
  width: number;
  height: number;
};

type Sizes = {
  xs: SizeDetail;
  sm: SizeDetail;
  md: SizeDetail;
  lg: SizeDetail;
};

const sizes: Sizes = {
  xs: { width: 250, height: 50 },
  sm: { width: 200, height: 40 },
  md: { width: 250, height: 50 },
  lg: { width: 600, height: 400 },
};

interface LogoProps {
  uriImage?: string;
  size?: keyof Sizes;
}

const defaultProps: LogoProps = {
  size: 'md',
  uriImage: '',
};

const Logo: FC<LogoProps> = ({ uriImage, size = 'md' }) => (
  <Image
    style={sizes[size]}
    source={{
      uri: uriImage,
    }}
  />
);

Logo.defaultProps = defaultProps;

export default Logo;
