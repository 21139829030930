import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const styles = StyleSheet.create({
  fontFamily,
  container: {
    width: '100%',
    height: 'auto',
  },
  header: {
    width: '100%',
    borderBottomWidth: 2,
    borderBottomColor: '#595959',
    paddingVertical: 12,
    justifyContent: 'space-between',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    color: '#595959',
    fontWeight: '700',
    letterSpacing: 1,
  },
  button: {
    width: 50,
    color: '#595959',
    padding: 0,
  },
  body: {
    width: '100%',
    height: 'auto',
    paddingHorizontal: 50,
    paddingVertical: 50,
  },
  description: {
    color: '#fff',
    fontSize: 12,
  },
  epicContainer: {
    width: '100%',
    height: 'auto',
    backgroundColor: '#262626',
    borderRadius: 5,
    marginBottom: 5,
  },
  epicSquare: {
    width: 15,
    height: 15,
    borderRadius: 5,
  },
});

export default styles;
