import React from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Role } from '../../../shared/types/enums';

const Root = () => {
  const { accessToken, user: { role } } = useSelector((state: RootState) => state.session);

  if (accessToken) {
    if (role === Role.CLIENT) {
      return <Navigate to="/client_dashboard" />;
    }
    return <Navigate to="/dashboard" />;
  }

  return <Navigate to="/login" />;
};

export default Root;
