import React, { SVGProps } from 'react';

const UpIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="16"
    height="9"
    viewBox="0 0 16 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      transform="translate(16 9) rotate(180)"
      d="M7.99281 8.1667C7.8627 8.1648 7.73845 8.11225 7.64646 8.02021L1.14646 1.52022C1.09847 1.47414 1.06016 1.41896 1.03377 1.3579C1.00738 1.29683 0.993439 1.23111 0.992763 1.1646C0.992086 1.09808 1.00469 1.03209 1.02983 0.970502C1.05498 0.908914 1.09216 0.852962 1.1392 0.805923C1.18624 0.758884 1.24219 0.721703 1.30378 0.696558C1.36536 0.671414 1.43135 0.658811 1.49787 0.659487C1.56439 0.660163 1.63011 0.674106 1.69117 0.700498C1.75223 0.72689 1.80742 0.7652 1.85349 0.813185L7.99998 6.95967L14.1465 0.813185C14.1925 0.7652 14.2477 0.726889 14.3088 0.700498C14.3698 0.674107 14.4356 0.660165 14.5021 0.659489C14.5686 0.658813 14.6346 0.671415 14.6962 0.69656C14.7578 0.721704 14.8137 0.758884 14.8608 0.805923C14.9078 0.852962 14.945 0.908914 14.9701 0.970502C14.9953 1.03209 15.0079 1.09808 15.0072 1.1646C15.0065 1.23111 14.9926 1.29683 14.9662 1.3579C14.9398 1.41896 14.9015 1.47414 14.8535 1.52022L8.35349 8.02021C8.30622 8.06751 8.24994 8.10484 8.18799 8.13C8.12604 8.15516 8.05967 8.16764 7.99281 8.1667Z"
      fill="#BCBCBC"
    />
  </svg>
);

export default UpIcon;
