import React, { FC, useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import styles from './collapsable.style';
import { Base } from '../../../types/base';
import { UpIcon } from '../../../assets/icons';
import DropDownIconUp from '../../../assets/svg/dropDownIconUp.svg';

export interface CollapsableProps extends Base {
  collapsed?: boolean;
  title: string;
  children?: React.ReactNode;
}

const defaultProps = {
  collapsed: false,
  children: undefined,
};

const Collapsable: FC<CollapsableProps> = ({
  collapsed, title, web, children,
}) => {
  const [collapse, setCollapse] = useState(false);
  const handleCollapse = () => {
    setCollapse(!collapse);
  };

  return (
    <View style={styles.container}>
      <View
        style={[
          styles.header,
          web ? styles.paddingWeb : styles.paddingApp,
          {
            borderBottomWidth: collapsed ? 0 : 2,
          },
        ]}
      >
        <Text style={styles.title}>{title}</Text>
        {!collapsed && (
          <View
            style={[
              {
                transform: [{ rotate: collapse ? '180deg' : '90deg' }],
              },
            ]}
          >
            <TouchableOpacity onPress={handleCollapse}>
              {web ? <UpIcon width={15} height={15} /> : <DropDownIconUp width={15} height={15} />}
            </TouchableOpacity>
          </View>
        )}
      </View>
      <View
        style={[
          web ? styles.containerChild : styles.containerChildApp,
          {
            display: collapse || collapsed ? 'flex' : 'none',
          },
        ]}
      >
        {children}
      </View>
    </View>
  );
};

Collapsable.defaultProps = defaultProps;

export default Collapsable;
