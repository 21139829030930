import React from 'react';
import { View } from 'react-native';
import Header from '../../molecules/Header';
import ForgotPasswordForm from '../../organisms/ForgotPasswordForm';
import { Base } from '../../../types/base';
import webStyles from './forgot-password.style';

interface ForgotPasswordProps extends Base {
  sendData: (param: object) => void;
  isLoading: boolean;
  success: boolean;
  errorMessage: string;
}

const ForgotPasswordTemplate = ({
  web,
  sendData,
  isLoading,
  success,
  errorMessage,
}: ForgotPasswordProps) => (
  <View style={webStyles.container}>
    <Header title="Forgot Password" />
    <ForgotPasswordForm
      web={web}
      sendData={sendData}
      isLoading={isLoading}
      success={success}
      errorMessage={errorMessage}
    />
  </View>
);

export default ForgotPasswordTemplate;
