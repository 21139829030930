import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  mainContainer: {
    height: '100%',
  },
  fullWidth: {
    width: '100%',
  },
  marginTop10: {
    marginTop: 10,
  },
  marginTop20: {
    marginTop: 20,
  },
  marginTop40: {
    marginTop: 40,
  },
  marginBottom40: {
    marginBottom: 40,
  },
  row: {
    flexDirection: 'row',
  },
  overflowHidden: {
    overflow: 'hidden',
  },
  backText: {
    color: '#959595',
    marginLeft: 5,
    fontSize: 14,
    fontWeight: '400',
  },
  sowDetailsContainer: {
    backgroundColor: '#121212',
  },
  downloadButtonWeb: {
    width: 150,
    alignSelf: 'flex-end',
  },
  downloadButton: {
    padding: 5,
    backgroundColor: '#262626',
    borderRadius: 4,
    marginTop: 20,
    marginBottom: 30,
    alignItems: 'center',
    justifyContent: 'center',
  },
  pagination: {
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
  alignSelf: {
    alignSelf: 'center',
  },
});

export default styles;
