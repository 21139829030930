import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  label: {
    paddingVertical: 4,
    borderRadius: 2,
    flexDirection: 'row',
  },
  defaultLabel: {
    justifyContent: 'center',
    backgroundColor: '#1F1F1F',
    width: 104,
    paddingHorizontal: 5,
  },
  deliveryLabel: {
    backgroundColor: '#141414',
    width: 84,
    paddingLeft: 7,
  },
  labelText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontSize: 12,
    lineHeight: 14,
  },
  pending: {
    color: '#0ABAB5',
  },
  inProgress: {
    color: '#C9E375',
  },
  draft: {
    color: '#F0CC28',
  },
  closed: {
    color: '#F93C6F',
  },
  defaultText: {
    fontWeight: '400',
  },
  deliveryText: {
    fontWeight: '500',
    paddingLeft: 9,
  },
  onTrackLabel: {
    borderWidth: 1,
    borderColor: '#127B78',
  },
  delayedLabel: {
    borderWidth: 1,
    borderColor: '#A4462E',
  },
  onTrackText: {
    color: '#21EBE5',
  },
  delayedText: {
    color: '#EC6240',
  },
  na: {
    color: '#BFBFBF',
  },
});

export default styles;
