import React, { SVGProps } from 'react';

const UI: React.FC<SVGProps<SVGSVGElement>> = ({ color, ...rest }) => (
  <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg" {...rest}>
    <path
      d="M13.312 0.283203H4.87451C4.59795 0.283203 4.37451 0.506641 4.37451 0.783203V4.4707H0.687012C0.410449 4.4707 0.187012 4.69414 0.187012 4.9707V9.2207C0.187012 9.49727 0.410449 9.7207 0.687012 9.7207H9.12451C9.40108 9.7207 9.62451 9.49727 9.62451 9.2207V5.5332H13.312C13.5886 5.5332 13.812 5.30977 13.812 5.0332V0.783203C13.812 0.506641 13.5886 0.283203 13.312 0.283203ZM5.43701 1.3457H8.56201V4.4707H5.43701V1.3457ZM4.37451 8.6582H1.24951V5.5332H4.37451V8.6582ZM8.56201 8.6582H5.43701V5.5332H8.56201V8.6582ZM12.7495 4.4707H9.62451V1.3457H12.7495V4.4707Z"
      fill={color || '#959595'}
    />
  </svg>
);

export default UI;
