import { StyleSheet } from 'react-native';

const fontFamily = {
  fontFamily: 'Roboto',
};

const styles = StyleSheet.create({
  fontFamily,
  container: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subContainer: {
    width: '100%',
    marginLeft: 10,
    borderRightWidth: 50,
    borderRightColor: 'transparent',
  },
  projectName: {
    color: '#EFEFEF',
    fontSize: 15,
    letterSpacing: 1.5,
    fontWeight: '700',
    fontFamily: 'Oxygen',
  },
  label: {
    color: '#BFBFBF',
    fontSize: 13,
    fontWeight: '400',
    fontFamily: 'Roboto',
  },
  image: {
    width: 40,
    height: 40,
    borderRadius: 100,
  },
});

export default styles;
