import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 5,
    paddingBottom: 6,
    paddingHorizontal: 8,
    borderRadius: 14,
  },
  text: {
    fontFamily: 'Roboto',
    color: '#D9D9D9',
    marginLeft: 5,
    fontWeight: '500',
  },
  iconContainer: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  info: {
    fontFamily: 'Roboto',
    fontSize: 12,
    color: '#D9D9D9',
    marginLeft: 3,
  },
});

export default styles;
