import React, { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import { UpIcon } from '../../../assets/icons';
import { Base } from '../../../types/base';
import HoverItem from '../../atoms/HoverItem';
import HoverableItem from '../HoverableItem';
import styles from './menu-section.style';

type MenuItemExtraProps = {
  title: string;
  link: string;
  icon: React.Component;
};
interface MenuItemProps {
  item: MenuItemExtraProps;
}
interface MenuSectionProps extends Base {
  sectionTitle: string;
  icon: React.Component;
  items: Array<MenuItemExtraProps>;
  isOpen: boolean;
}

const RenderItem = ({ item }: MenuItemProps) => {
  const [isHover, setIsHover] = useState(false);

  const mouseEnter = () => {
    setIsHover(true);
  };

  const mouseLeave = () => {
    setIsHover(false);
  };

  return (
    <HoverableItem onMouseEnter={mouseEnter} onMouseLeave={mouseLeave}>
      <View style={[styles.itemContainer, isHover && styles.selectedItem]}>
        <Text style={styles.itemTitle}>{item.title}</Text>
      </View>
    </HoverableItem>
  );
};

const defaultProps = {
  web: false,
};

const MenuSection = ({
  web,
  sectionTitle,
  icon,
  items,
  isOpen,
}: MenuSectionProps) => {
  const [displayItems, setDisplayItems] = useState(false);
  const [localOpen, setLocalOpen] = useState(false);

  useEffect(() => {
    setLocalOpen(isOpen);
    setDisplayItems(false);
  }, [isOpen]);

  const renderItems = () => setDisplayItems(!displayItems);

  return (
    <View style={styles.container}>
      <HoverItem
        title={sectionTitle}
        isOpen={localOpen}
        web={web}
        suffixIcon={(
          items && items.length > 0 ? <UpIcon /> : undefined
        )}
        isItemsCollapsed={displayItems}
        onPress={renderItems}
        icon={icon}
      />
      {displayItems && items && (
        <View style={!localOpen && styles.absoluteList}>
          <View
            style={[
              styles.listContainer,
              !localOpen && styles.listCollapsedContainer,
            ]}
          >
            <View style={{ width: '100%' }}>
              {items.map((item) => (
                <RenderItem key={item.title} item={item} />
              ))}
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

MenuSection.defaultProps = defaultProps;
export default MenuSection;
