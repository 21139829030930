import React from 'react';
import { View, Text } from 'react-native';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Spinner from '../../../assets/svg/spinner.svg';
import Button from '../../atoms/Button';
import PasswordInput from '../../atoms/PasswordInput';
import { Base } from '../../../types/base';
import webStyles from './reset-password-form.style';

interface ResetPasswordFormProps extends Base {
  resetPasssword: (param: string) => void;
  isLoading: boolean;
  success: boolean;
  errorMessage: string;
}

const ResetPasswordForm = ({
  web,
  resetPasssword,
  isLoading,
  success,
  errorMessage,
}: ResetPasswordFormProps) => {
  const validationSchema = yup.object({
    password: yup
      .string()
      .required('Password is required')
      .matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/, 'Incorrect format'),
    confirmPassword: yup
      .string()
      .required('Confirm password is required')
      .equals([yup.ref('password'), null], 'Password does not match'),
  });

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmPassword: '',
    },
    validationSchema,
    onSubmit: (values) => {
      resetPasssword(values.password);
    },
  });

  const onSuccessRender = () => (!success ? (
    <Button disabled={false} onPress={formik.handleSubmit}>
      save
    </Button>
  ) : (
    <Text style={webStyles.successText}>Password successfully updated!</Text>
  ));

  return (
    <View style={webStyles.formContainer}>
      <View style={webStyles.inputContainer}>
        <PasswordInput
          placeHolder="New Password"
          label="New Password"
          value={formik.values.password}
          onChange={formik.handleChange('password')}
          web={web}
        />
        {formik.touched.password && formik.errors.password && (
          <Text style={webStyles.textError}>{formik.errors.password}</Text>
        )}
      </View>
      <View style={webStyles.inputContainer}>
        <PasswordInput
          placeHolder="Confirm Password"
          label="Confirm Password"
          value={formik.values.confirmPassword}
          onChange={formik.handleChange('confirmPassword')}
          web={web}
        />
        {formik.touched.confirmPassword && formik.errors.confirmPassword && (
          <Text style={webStyles.textError}>
            {formik.errors.confirmPassword}
          </Text>
        )}
      </View>
      <View style={webStyles.buttonContainer}>
        {!isLoading ? (
          onSuccessRender()
        ) : (
          <View style={webStyles.spinnerContainer}>
            {web ? (
              <img src={Spinner} alt="spinner" width={35} height={35} />
            ) : (
              <Spinner width={35} height={35} />
            )}
          </View>
        )}
      </View>
      {!success && errorMessage && (
        <Text style={webStyles.apiCallErrorText}>{errorMessage}</Text>
      )}
    </View>
  );
};

export default ResetPasswordForm;
