import React, { SVGProps } from 'react';

const LightBeak: React.FC<SVGProps<SVGSVGElement>> = (props) => (
  <svg
    width="6"
    height="3"
    viewBox="0 0 6 3"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5.76478 -0.000128031L3.34229 2.42236L0.9198 -0.000127792L5.76478 -0.000128031Z"
      fill="#ffffff"
    />
  </svg>
);

export default LightBeak;
