import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: 80,
    flexDirection: 'row',
    alignItems: 'center',
    paddingVertical: 5,
    paddingHorizontal: 10,
    borderRadius: 2,
  },
  iconContainer: {
    marginRight: 5,
  },
  text: {
    fontSize: 12,
    color: '#FAFAFA',
    textTransform: 'capitalize',
    fontWeight: '700',
    fontFamily: 'Roboto',
  },
});

export default styles;
