import React from 'react';
import { View, Text } from 'react-native';
import { Base } from '../../../types/base';
import styles from './basic-table.style';

// Before using this component please be sure to understand how the property flex works
// You can find more information here: https://reactnative.dev/docs/flexbox
interface LabelProps {
  label: string;
  flex: number;
}

interface ItemProps {
  item: React.ReactElement | string;
}

interface RowProps {
  items: ItemProps[];
  alignCenter?: boolean;
}

export interface Tableprops extends Base {
  title?: string;
  data: {
    labels: LabelProps[];
    rows: RowProps[];
  };
  hasBackground?: boolean;
  darkRows?: boolean;
  children?: React.ReactNode;
  headerTools?: React.ReactNode;
}

const defaultProps = {
  title: '',
  hasBackground: false,
  darkRows: false,
  children: undefined,
  headerTools: undefined,
};

const BasicTable = ({
  title,
  data,
  children,
  hasBackground,
  darkRows,
  headerTools,
  web,
}: Tableprops) => {
  const columnsWidth = data.labels.map((item) => item.flex);

  const handleSetColumnWidth = (index: number) => columnsWidth[index];

  const handleRenderRowItem = (item: string | React.ReactNode, index: number) => {
    if (typeof item === 'string') {
      return (
        <View
          key={index}
          style={
            web
              ? [styles.paddingRight, { flex: handleSetColumnWidth(index) }]
              : styles.paddingVertical10
          }
        >
          {web ? (
            <Text style={styles.item} numberOfLines={1} ellipsizeMode="tail">
              {item}
            </Text>
          ) : (
            <Text style={[styles.item, styles.alignTextCenter]}>{item}</Text>
          )}
        </View>
      );
    }

    return (
      <View
        key={index}
        style={
          web
            ? [styles.paddingRight, { flex: handleSetColumnWidth(index) }]
            : [styles.fullWidth, styles.alignItemsCenter, styles.paddingVertical10]
        }
      >
        {item}
      </View>
    );
  };

  return (
    <View style={[styles.fullWidth, hasBackground && styles.hasBackgroundStyle]}>
      {(title || headerTools) && (
        <View
          style={[
            styles.row,
            styles.fullWidth,
            styles.alignSpaceBetween,
            styles.alignItemsCenter,
            { paddingBottom: web ? 20 : 10 },
            title && styles.titleContainer,
            !hasBackground && { marginTop: 20 },
          ]}
        >
          {title ? <Text style={styles.title}>{title}</Text> : null}
          {headerTools || null}
        </View>
      )}
      {children && <View style={[styles.fullWidth, styles.childrenContainer]}>{children}</View>}
      {web && (
        <View style={[styles.fullWidth, styles.row, styles.labelsContainer]}>
          {data.labels.map((item, index) => (
            <View key={index} style={[styles.paddingRight, { flex: item.flex }]}>
              <Text style={styles.label}>{item.label}</Text>
            </View>
          ))}
        </View>
      )}
      <View style={[styles.fullWidth, { marginTop: 5 }]}>
        {data.rows?.length ? (
          data.rows.map((row, index) => (
            <View
              key={index}
              style={[
                styles.rowItem,
                (web || row.alignCenter) && { alignItems: 'center' },
                {
                  backgroundColor: hasBackground || darkRows ? '#141414' : '#262626',
                  flexDirection: web ? 'row' : 'column',
                  padding: web ? 15 : 10,
                },
              ]}
            >
              {row.items?.map((item, i) => handleRenderRowItem(item, i))}
            </View>
          ))
        ) : (
          <View
            style={[
              styles.rowItem,
              {
                backgroundColor: hasBackground || darkRows ? '#141414' : '#262626',
              },
            ]}
          >
            <Text style={styles.noItemsText}>No items available</Text>
          </View>
        )}
      </View>
    </View>
  );
};

BasicTable.defaultProps = defaultProps;

export default BasicTable;
