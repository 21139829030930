import React from 'react';
import { useNavigate } from 'react-router-dom';
import EmailConfirmationTemplate from '../../../shared/components/templates/EmailConfirmation';
import styles from '../../styles/page.style';

const EmailConfirmation = () => {
  const navigate = useNavigate();

  const redirect = () => navigate('/forgot-password');

  return (
    <div style={styles.container}>
      <EmailConfirmationTemplate redirect={redirect} />
    </div>
  );
};

export default EmailConfirmation;
