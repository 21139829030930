/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { View, Text, TouchableOpacity } from 'react-native';
import HoverableItem from '../../molecules/HoverableItem';
import { Beak, LightBeak } from '../../../assets/icons';
import BeakIcon from '../../../assets/svg/beak.svg';
import LightBeakIcon from '../../../assets/svg/lightbeak.svg';
import ModalApp from '../ModalApp';
import { Base } from '../../../types/base';
import styles from './tooltip.style';

export interface BadgeProps extends Base {
  children?: React.ReactNode;
  tooltipContent: React.ReactNode;
  theme?: 'light' | 'dark';
  isLeftPosition?: boolean;
  width?: number;
  isModal?: boolean;
  displayChildrenOnModal?: boolean;
}

const Tooltip: React.FC<BadgeProps> = ({
  children,
  tooltipContent,
  web,
  theme,
  isLeftPosition,
  width,
  isModal,
  displayChildrenOnModal,
}) => {
  const [isActive, setIsActive] = useState(false);
  const LightIcon = web ? LightBeak : LightBeakIcon;
  const DarkIcon = web ? Beak : BeakIcon;

  return (
    <View style={styles.contentContainer}>
      {/* For the app version we're going to use the ModalApp,
      this way the user is able to close when touching any part of the screen */}
      {isModal && !web ? (
        <ModalApp
          body={(
            <View style={styles.justifyCenter}>
              {/* If you want to show the children on the modal */}
              {displayChildrenOnModal ? (
                <View style={styles.marginBottom10}>{children}</View>
              ) : null}
              <Text
                style={[
                  styles.tooltipText,
                  { color: theme === 'dark' ? '#BABABA' : 'black', paddingLeft: 10 },
                  !web && { fontSize: 12 },
                ]}
              >
                {tooltipContent}
              </Text>
            </View>
          )}
          isOpen={isActive}
          closeModal={() => setIsActive(false)}
        />
      ) : (
        <View
          style={[
            styles.container,
            {
              display: isActive ? 'flex' : 'none',
              backgroundColor: theme === 'dark' ? '#434343' : 'white',
              right: isLeftPosition ? '20%' : undefined,
              zIndex: 2,
              width: width || !web ? 120 : 'auto',
            },
          ]}
        >
          <Text style={[styles.tooltipText, { color: theme === 'dark' ? '#BABABA' : 'black' }]}>
            {tooltipContent}
          </Text>
          <View
            style={[
              styles.beakContainer,
              {
                right: isLeftPosition ? 0 : undefined,
              },
            ]}
          >
            {theme === 'light' ? (
              <LightIcon width={20} height={20} />
            ) : (
              <DarkIcon width={20} height={20} />
            )}
          </View>
        </View>
      )}
      <HoverableItem onMouseEnter={() => setIsActive(true)} onMouseLeave={() => setIsActive(false)}>
        {!web ? (
          <TouchableOpacity
            onPress={() => setIsActive(!isActive)}
            style={[styles.childrenContainer, { zIndex: 1 }]}
          >
            {children}
          </TouchableOpacity>
        ) : (
          <View style={[styles.childrenContainer, { zIndex: 1 }]}>{children}</View>
        )}
      </HoverableItem>
    </View>
  );
};

Tooltip.defaultProps = {
  theme: 'dark',
  isLeftPosition: false,
  children: null,
  width: 0,
  isModal: false,
  displayChildrenOnModal: false,
};
export default Tooltip;
