import React from 'react';
import { View } from 'react-native';
import { Base } from '../../../types/base';
import ConfirmationNDA from '../../molecules/ConfirmationNDA';
import webStyles from './nda-confirmation-client.style';

interface NDAConfifrmationClientTemplateProps extends Base {
  redirect: () => void;
}

const NDAConfifrmationClientTemplate = ({ redirect, web }: NDAConfifrmationClientTemplateProps) => {
  setTimeout(() => {
    redirect();
  }, 2000);

  return (
    <View style={web ? webStyles.container : webStyles.containerApp}>
      <ConfirmationNDA />
    </View>
  );
};
export default NDAConfifrmationClientTemplate;
