import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    padding: 20,
    width: '100%',
    backgroundColor: '#1F1F1F',
    marginBottom: 20,
  },
  title: {
    fontSize: 18,
    fontFamily: 'Roboto',
    fontWeight: '400',
    lineHeight: 21,
    color: '#1890FF',
    textDecorationLine: 'underline',
    textTransform: 'uppercase',
    marginBottom: 10,
  },
  hours: {
    color: '#BFBFBF',
    fontFamily: 'Roboto',
    fontSize: 12,
    fontWeight: '500',
    lineHeight: 20,
  },
  description: {
    fontSize: 14,
    fontFamily: 'Roboto',
    fontWeight: '400',
    lineHeight: 16,
    color: '#BFBFBF',
  },
  priorityDiv: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    width: '100%',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
});

export default styles;
