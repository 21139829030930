import {
  GestureResponderEvent, View, Text, TouchableOpacity,
} from 'react-native';
import React, { CSSProperties, ReactNode } from 'react';
import { Close } from '../../../assets/icons';
import DB from '../../../assets/icons/DBIcon';
import WebApp from '../../../assets/icons/WebApp';
import UI from '../../../assets/icons/UI';
import DBMobile from '../../../assets/svg/DB.svg';
import WebAppMobile from '../../../assets/svg/WebApp.svg';
import UIMobile from '../../../assets/svg/UI.svg';
import CloseApp from '../../../assets/svg/close.svg';
import IssueLink from '../../atoms/IssueLink';
import IssuePriority from '../../atoms/IssuePriority';
import IssueIcon from '../../atoms/IssueIcon';
import { Base } from '../../../types/base';
import Dropdown from '../../atoms/Dropdown';
import styles from './IssueCard.style';

type IssueType = 'Story' | 'Subtask' | 'Bug';
type IssuePriority = 'Lowest' | 'Low' | 'Medium' | 'High' | 'Highest';

interface IssueCardProps extends Base {
  type: IssueType;
  name: string;
  issue: string;
  priority: IssuePriority;
  width?: CSSProperties['width'];
  onClose?: (e: GestureResponderEvent) => void;
  children?: ReactNode;
  created: string;
  update: string;
  link: string;
}

const defaultProps: Partial<IssueCardProps> = {
  width: '100%',
  onClose: (): void => {
    throw new Error('Function not implemented.');
  },
  children: null,
};

const IssueCard: React.FC<IssueCardProps> = ({
  type,
  priority,
  name,
  width,
  issue,
  onClose,
  web,
  children,
  created,
  update,
  link,
}): JSX.Element => {
  const typesIssue = [
    {
      id: '1',
      title: 'User Story',
    },
  ];

  const typesProject = [
    {
      id: '1',
      title: 'Web App',
      ItemIcon: web ? <WebApp /> : <WebAppMobile />,
    },
    {
      id: '2',
      title: 'UI',
      ItemIcon: web ? <UI /> : <UIMobile />,
    },
    {
      id: '3',
      title: 'DB',
      ItemIcon: web ? <DB /> : <DBMobile />,
    },
  ];
  console.log(link);

  return (
    <View style={[styles.container, { width }]}>
      <View style={styles.containerIssue}>
        <View style={styles.col2}>
          <View style={styles.cardFirstRow}>
            <View style={styles.code}>
              <View style={styles.issueIconContainer}>
                <IssueIcon type={type} web={web} />
              </View>
              <IssueLink url="" title={issue} />
            </View>
            <TouchableOpacity style={styles.collapse} onPress={onClose}>
              {web ? <Close /> : <CloseApp width={15} height={15} />}
            </TouchableOpacity>
          </View>
          <View style={web ? styles.contentName : styles.contentNameApp}>
            <Text style={web ? styles.name : styles.namesApp}>{name}</Text>
          </View>
          <View
            style={[
              web ? styles.cardSecondRow : styles.cardSecondRowApp,
              {
                flexDirection: web ? 'row' : 'column-reverse',
                alignItems: web ? 'center' : 'flex-start',
              },
            ]}
          >
            <View
              style={[
                styles.flexDirectionRow,
                styles.spaceBetween,
                {
                  width: web ? '66.666%' : '100%',
                },
              ]}
            >
              <View style={styles.dropdownContainer}>
                <Dropdown
                  type="secondary"
                  label=""
                  items={typesIssue}
                  placeholder="Type Issue"
                  iconColor="#4382F7"
                  placeHolderColor="#4382F7"
                  isLeftAligned
                  web={web}
                />
              </View>
              <View style={styles.dropdownContainer}>
                <Dropdown
                  type="secondary"
                  label=""
                  items={typesProject}
                  placeholder="Type Project"
                  iconColor="#959595"
                  placeHolderColor="#959595"
                  web={web}
                />
              </View>
            </View>
            <View
              style={[
                styles.codeAndPriorityRow,
                { marginBottom: web ? 0 : 10, width: web ? '33.333' : '100%' },
              ]}
            >
              <IssuePriority web={web} priority={priority} />
            </View>
          </View>
        </View>
      </View>
      {children}
      <View style={web ? styles.issueInfo : styles.issueInfoApp}>
        <View style={styles.flexDirectionRow}>
          <Text style={styles.infoText}>Created</Text>
          <Text style={styles.infoText2}>{created}</Text>
        </View>
        <View style={styles.flexDirectionRow}>
          <Text style={styles.infoText}>Update</Text>
          <Text style={styles.infoText2}>{update}</Text>
        </View>
      </View>
    </View>
  );
};

IssueCard.defaultProps = defaultProps;

export default IssueCard;
