import React from 'react';
import { View, Text } from 'react-native';
import Story from '../../atoms/Story';
import { Base } from '../../../types/base';
import styles from './week-tasks-style';
import { CustomIssue, Storys } from '../../../types/TeamLeadDashboard';

interface WeekTasksProps extends Base {
  items: Storys;
}

const WeekTasks = ({ items, web }: WeekTasksProps) => {
  const storys = Object.keys(items);

  return (
    <View style={[styles.container, { paddingLeft: web ? 60 : 0 }]}>
      <Text style={styles.title}>WEEK&apos;S TASKS</Text>
      <View style={{ width: '100%' }}>
        {storys?.map((item) => (
          <View style={[styles.itemContainer, { paddingLeft: web ? 50 : 20 }]} key={item}>
            <Story
              subtasks={items[item].issues as CustomIssue[]}
              storyData={items[item].story}
              web={web}
            />
          </View>
        ))}
      </View>
    </View>
  );
};

export default WeekTasks;
