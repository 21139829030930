import { StyleSheet } from 'react-native';

const styles = StyleSheet.create({
  container: {
    width: '48%',
    borderWidth: 2,
    borderColor: '#0ABAB5',
    paddingVertical: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  selectedItemContainer: {
    width: '48%',
    borderWidth: 2,
    borderColor: '#0ABAB5',
    backgroundColor: '#0ABAB5',
    paddingVertical: 10,
    borderRadius: 5,
    marginBottom: 10,
  },
  text: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '700',
    color: '#0ABAB5',
  },
  selectedText: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: '700',
    color: 'white',
  },
});

export default styles;
